import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Segmented, Table, Tooltip, Typography } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faLink } from '@fortawesome/free-solid-svg-icons'

import { Btn } from 'components/Btn'
import { FlexSpace } from 'components/FlexSpace'
import { ColorAmount } from 'components/ColorAmount'
import { VList } from 'components/Virtuallist'

import { UploadTxModal } from './UploadTx.modal'
import { MatchModal } from './MatchTx.modal'

import { sortByDate, stringToDate } from 'utils/dates'
import { ITx } from 'types/tx.types'
import { IMatcher } from 'types/matcher'
import { IAccount } from 'types/company.types'
import { currentCompany } from 'recoil/companies.state'

type FilterTx = 'Matched' | 'Unmatched' | 'All'

interface Props {
  minHeight?: number
  matcher: IMatcher
  account: IAccount
  onRefresh: () => void
}

export const TxListBank: ReactFC<Props> = (props) => {
  const { account, matcher, minHeight, onRefresh } = props

  const company = useRecoilValue(currentCompany)
  const viewOnly = company?.role.id === 2000

  const [filter, setFilter] = useState<FilterTx>('Unmatched')
  const [selectedTx, setSetSelectedTx] = useState<ITx[]>([])
  const [uploadModal, setUploadModal] = useState(false)
  const [matchModal, setMatchModal] = useState(false)
  const [hover, setHover] = useState<number>(-1)

  const list = matcher.bank
  const selected = list.filter((v) => v.selected)
  const matched = list.filter((tx) => tx.matchedTo?.length)

  const height = Math.max(minHeight || 0, 200)

  const dataSource =
    filter === 'Matched'
      ? list.filter((tx) => tx.matchedTo?.length)
      : filter === 'Unmatched'
        ? list.filter((tx) => !tx.matchedTo?.length)
        : list

  const matchedLenght = list.filter((v) => v.matchedTo.length).length

  const vComponents = useMemo(() => VList({ height, vid: 'bank', resetTopWhenDataChange: false }), [filter, height])

  useEffect(() => {
    const el = document.querySelector('.BankTable .ant-table-body')
    if (el) el.scrollTop = 0
  }, [filter])
  
  if (!height) return null

  const handleClick = (tx: ITx) => {
    setSetSelectedTx([tx])
    setMatchModal(true)
  }

  const handleMatchSelected = () => {
    setSetSelectedTx(list.filter((v) => v.selected))
    setMatchModal(true)
  }

  return (
    <FlexSpace direction="vertical" style={{ paddingTop: 20 }} size="large">
      <FlexSpace direction="horizontal" spacebetween>
        <Typography.Title level={5} style={{ margin: 0 }}>
          Bank Transactions: {list.length} total
        </Typography.Title>
        {!!selected.length && <Typography.Text className="Faded">Selected: {selected.length}</Typography.Text>}
        {!selected.length && <Typography.Text className="Faded">Matched: {matched.length}</Typography.Text>}
      </FlexSpace>
      <FlexSpace direction="horizontal" spacebetween style={{ flexWrap: 'wrap' }}>
        <Segmented options={['Matched', 'Unmatched', 'All']} value={filter} onChange={(v: any) => setFilter(v)} />
        {!!selected.length && !viewOnly && (
          <FlexSpace>
            <Btn title="Add to QuickBooks" onClick={() => setUploadModal(true)} />
            <Btn title="Match Selected" onClick={() => handleMatchSelected()} />
          </FlexSpace>
        )}
        {!selected.length && !viewOnly && (
          <FlexSpace>
            <Btn title="Unmatch All" disabled={!matchedLenght} onClick={() => matcher.unmatchAll()} />
            <Btn title="Rematch" onClick={() => matcher.matchAll()} />
          </FlexSpace>
        )}
      </FlexSpace>
      <UploadTxModal
        account={account}
        list={list.filter((v) => v.selected)}
        open={uploadModal}
        onClose={(refresh) => {
          setUploadModal(false)
          if (refresh) matcher.selectBank([])
          if (refresh) onRefresh()
        }}
      />
      <MatchModal
        type="bank"
        list={selectedTx}
        matcher={matcher}
        open={matchModal}
        onClose={() => setMatchModal(false)}
      />
      <Table
        dataSource={dataSource}
        columns={columns(handleClick, hover)}
        rowKey={'_id'}
        className="RecTable Table BankTable"
        pagination={false}
        scroll={{ y: height }}
        components={list.length > 40 ? vComponents : undefined}
        rowSelection={{
          columnWidth: 50,
          hideSelectAll: false,
          selectedRowKeys: selected.map((v) => v._id),
          onChange: (ids) => matcher.selectBank(ids as string[]),
          getCheckboxProps: (tx: ITx) => ({ disabled: isMatched(tx) })
        }}
        onRow={(_, rowIndex) => {
          return {
            onMouseEnter: () => { setHover(rowIndex || 0) },
            onMouseLeave: () => { setHover(-1) },
          }
        }}
      />
    </FlexSpace>
  )
}

function columns(onClick: (tx: ITx) => void, hover: number) {
  return [
    {
      title: 'Date',
      width: 130,
      dataIndex: 'date',
      className: 'TdHover',
      render: (value: string) => {
        const date = stringToDate(value, 'MM-DD-YYYY')
        return (
          <Tooltip title="Click to Match" mouseEnterDelay={1}>
            <Typography.Text>{date}</Typography.Text>
          </Tooltip>
        )
      },
      sorter: (a: ITx, b: ITx) => sortByDate(a.date, b.date),
      onCell: (tx: ITx) => ({ onClick: () => onClick(tx) })
      // ...filterDate('date', 'MM/DD/YYYY')
    },
    {
      title: 'Amount',
      width: 130,
      dataIndex: 'amount',
      className: 'TdHover',
      render: (v: number) => (
        <Tooltip title="Click to Match" mouseEnterDelay={1}>
          <ColorAmount amount={v} />
          {' '}
        </Tooltip>
      ),
      sorter: (a: ITx, b: ITx) => a.amount - b.amount,
      onCell: (tx: ITx) => ({ onClick: () => onClick(tx) })
      // ...filterSearch('amount')
    },
    {
      title: 'Memo',
      dataIndex: 'description',
      ellipsis: true,
      className: 'TdHover',
      onCell: (tx: ITx) => ({ onClick: () => onClick(tx) }),
      render: (v: string) => (
        <Tooltip title="Click to Match" mouseEnterDelay={1} placement="topLeft">
          <Typography.Text>{v}</Typography.Text>
        </Tooltip>
      )
      // ...filterSearch('description')
    },
    {
      title: 'Matched',
      dataIndex: 'id',
      width: 100,
      render: (_: any, tx: ITx, i: number) => {
        const ckEl =  tx.forceMatch || tx.matchedTo.length 
          ? <div style={{ opacity: 0.5 }} className="ck"><div></div></div>
          : <div style={{ opacity: 0.5 }} className="ck" />

        return <FlexSpace>
          <div>{ckEl}</div>
          <div style={{ opacity: i === hover ? 1 : 0 }}>
            {!tx.forceMatch && !tx.matchedTo.length ? (
              <Tooltip title="Click to match" mouseEnterDelay={1}>{' '}
                <FontAwesomeIcon className='Pointer' icon={faLink} color="#0F9648" transform={{ rotate: 45 }} onClick={() => onClick(tx)} />
              </Tooltip>) : (
                <FontAwesomeIcon className='Pointer' icon={faCircleInfo} color="#CACACA"  onClick={() => onClick(tx)} />
            )}
          </div>
        </FlexSpace>
      }
    }
  ]
}

// const ActionBtn = () => {
//   return <Btn title="Match" shape="round" size="small" />
// }

const isMatched = (tx: ITx): boolean => tx.matchedTo.length > 0
