import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Card, message, Spin, Typography } from 'antd'
import { PlaidLink } from 'react-plaid-link'

import { useRestriction } from 'hooks/useRestriction'

import { FlexSpace } from 'components/FlexSpace'
import { Btn } from 'components/Btn'

import { api } from 'utils/axios'
import { currentCompany } from 'recoil/companies.state'
import { toSafeDateString } from 'utils/dates'
import { pladToTxs } from 'utils/tx'
import { ITx } from 'types/tx.types'
import { IAccount, IntegrationType } from 'types/company.types'
import { errorMsg } from 'utils/errorMsg'
import { analytics } from 'utils/analytics'
import { plaidAtom } from 'recoil/plaid.state'

const { Title, Text } = Typography

interface Props {
  account?: IAccount
  range: any
  token: string
  refresh: number
  onChange: (tsx: ITx[]) => void
  onCancel: () => void
}

export const Plaid: ReactFC<Props> = (props) => {
  const { account, token, range, refresh, onChange, onCancel } = props

  const setPlaidState = useSetRecoilState(plaidAtom)
  const company = useRecoilValue(currentCompany)
  const plaid = company?.integrations.find((v) => v.id === IntegrationType.Plaid)

  const restrictedText = useRestriction(1)

  const [loading, setLoading] = useState(false)
  const [txLoading, setTxLoading] = useState(false)
  const [plaidAccounts, setPlaidAccounts] = useState<any[]>([])
  const [selected, setSelected] = useState<any>()
  const [block, setBlock] = useState(false)

  const isCC = !!account?.isCredit

  useEffect(() => {
    if (!plaid) return
    fetchAccounts()
    setSelected(undefined)
  }, [plaid, refresh])

  const fetchAccounts = () => {
    if (!company) return
    setLoading(true)
    api
      .get(`/plaid/accounts?applicationId=${company.id}`)
      .then(({ data }) => {
        setPlaidAccounts(data?.map((v: any) => JSON.parse(v.connectionInfo)) || [])
      })
      .catch((err) => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
  }

  const fetchToken = (publicToken: string) => {
    const body = { publicToken, applicationId: company?.id }
    return api
      .post(`/plaid/exchange`, body)
      .then(({ data }) => console.log(data))
      .catch((err) => message.error(errorMsg(err), 5))
  }

  const handleSuccees = (publicToken: string) => {
    setLoading(true)
    fetchToken(publicToken).then(() => fetchAccounts())
    analytics.event.plaid.connect()
  }

  const handleSelectAccount = (acc: any) => {
    if (restrictedText)
      return message.warning(restrictedText, 5)

    if (range?.length !== 2) return message.warning('Please select "Checkout through" date range')
    if (!company) return

    setSelected(acc)
    setTxLoading(true)

    const params = {
      applicationId: company.id,
      accountId: acc.account_id,
      from: toSafeDateString(range[0]),
      to: toSafeDateString(range[1])
    }

    api
      .get(`/plaid/transactions`, { params })
      .then(({ data, status }) => {
        if (status === 206) {
          message.warning('List of transactions last updated before end date.', 2)
        } else if (status === 204) {
          message.warning('Please wait while Plaid pulls your transactions for the first time. Try again in 2 mins.', 2)
          setPlaidState(params as any)
        } else if (!data.length)
          message.warning('No transactions found in this account for the given date range.', 10)
        else onChange(pladToTxs(data, !isCC))
      })
      .catch((err) => {
        if (err?.response?.data?.statusCode === 403) {
          message.error('Plaid integration token expired. Please reconnect.', 5)
        } else message.error(errorMsg(err), 5)
      })
      .finally(() => setTxLoading(false))
  }

  const handleCancel = () => {
    setSelected(undefined)
    onCancel()
  }

  const handleExit = () => {
    setBlock(true)
    setTimeout(() => setBlock(false))
  }

  if (!token) return null


  const accounts = plaidAccounts.map((v) => {
    return v.accounts.map((a:any) => ({...a, institution: v.institution.name}))
  }).flat()

  console.log(accounts)


  return (
    <FlexSpace direction="vertical">
      <Title level={5}>Choose a previously connected bank account</Title>
      <Card>
        <Spin spinning={loading}>
          {!plaidAccounts.length && <Text>No connected accounts</Text>}
          <FlexSpace direction="vertical">
            {accounts?.map((v) => (
              <Spin spinning={selected?.account_id === v.account_id && txLoading} key={v.account_id}>
                <FlexSpace spacebetween onClick={() => handleSelectAccount(v)} style={{ cursor: 'pointer' }}>
                  <Text strong type={selected?.account_id === v.account_id ? 'success' : 'secondary'}>
                    <FlexSpace direction="horizontal" size="large">
                      {selected?.account_id === v.account_id ? <Selected /> : <Empty />}
                      <span>{v.name} <small className='dimmed'>({v.institution})</small></span>
                    </FlexSpace>
                  </Text>
                  <Text type="secondary">•••• {v.mask}</Text>
                </FlexSpace>
              </Spin>
            ))}
          </FlexSpace>
        </Spin>
      </Card>
      <FlexSpace direction="horizontal" spacebetween>
        {restrictedText && (
          <Btn
            type="primary"
            title="Connect a new bank account"
            tooltip={restrictedText}
            mouseEnterDelay={0.01}
            mouseLeaveDelay={0.01}
            disabled
          />
        )}
        {!restrictedText && !block && (
          <PlaidLink
            token={token}
            onSuccess={handleSuccees}
            onExit={handleExit}
            className="ant-btn ant-btn-primary"
            style={{
              width: 240,
              height: 40,
              backgroundColor: '#0F9648',
              display: 'block',
              borderRadius: 6,
              border: 'none',
              color: 'white',
              fontSize: 16,
              fontFamily: 'Figtree',
              cursor: 'pointer'
            }}
            children={'Connect a new bank account'}
          />
        )}
        {!!selected && <Btn title="Cancel" onClick={() => handleCancel()} />}
      </FlexSpace>
    </FlexSpace>
  )
}

const Empty = () => (
  <div className="ck" />
)

const Selected = () => (
  <div className="ck">
    <div style={{ background: '#0f9648' }}></div>
  </div>
)
