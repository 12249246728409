import { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Card, Select, Typography } from 'antd'

import { Btn } from 'components/Btn'
import { FlexSpace } from 'components/FlexSpace'
import { MatchAccountsModal } from './Accounts.modal'
import { MinorServicesModal } from './MinorServices.modal'
import { MinorServicesModal2 } from './MinorServices2.modal'

import { companiesAtom, companyRulesAtom, currentAccounts, currentCompany } from 'recoil/companies.state'
import { IRule } from 'types/compliance.types'
import { api } from 'utils/axios'

const { Title, Text } = Typography

interface RulesSetupProps {
  accountingMethod: string
  onChangeAccountingMethod: (method: string) => void
}

export const RulesSetup: ReactFC<RulesSetupProps> = (props) => {
  const { accountingMethod, onChangeAccountingMethod } = props

  const accounts = useRecoilValue(currentAccounts)
  const company = useRecoilValue(currentCompany)
  const rules = useRecoilValue(companyRulesAtom)

  const setRules = useSetRecoilState(companyRulesAtom)
  const setCompanies = useSetRecoilState(companiesAtom)

  const [accModal, setAccModal] = useState(false)
  const [minorModal, setMinorModal] = useState(false)

  const flatRules = rules.map((v) => v.children || []).flat()
  const uncatTxRule = flatRules.find((v) => v.title === 'Uncategorized Transactions' && v.app_rule_id)
  const sbcpRule = flatRules.find((v) => v.title === 'Payroll Review for S-Corporations' && v.app_rule_id)
  const ppRule = flatRules.find((v) => v.title === 'Payroll Review for Partnerships' && v.app_rule_id)

  const smallbusiness = company?.entity?.title.includes('1120') // === 'Small business corporation, two or more owners (Form 1120S)'
  const partnership = company?.entity?.title.includes('1065') // === 'Partnership or limited liability company (Form 1065)'

  const cardstyle = { background: 'rgb(236, 250, 241)', border: 'none' }

  const uncatAccounts = company?.settings?.uncat?.selected || []

  const getAccName = (qboId: string) => {
    return accounts?.find((v) => v.qboId === qboId)?.name
  }

  const refreshRules = () => {
    return api.get<IRule[]>(`/application_rules/${company?.id}`)
      .then(({data}) => setRules(data))
  }

  const refreshCompanies = () => {
    return api.get('applications')
      .then(({data}) => setCompanies(data))
      .catch(console.log)
  }

  const handleMinorClose = (owner: string = '') => {
    if (!sbcpRule) return
    setMinorModal(false)
    api.patch(`/application_rules/${sbcpRule.app_rule_id}`, { owner }).finally(() =>
      setTimeout(() => {
        refreshRules()
      }, 250)
    )
  }

  const handleMinorClose2 = (owner: string = '') => {
    if (!ppRule) return
    setMinorModal(false)
    api.patch(`/application_rules/${ppRule.app_rule_id}`, { owner }).finally(() =>
      setTimeout(() => {
        refreshRules()
      }, 250)
    )
  }

  const handleAccsClose = (accounts?: string[]) => {
    setAccModal(false)
    if (!uncatTxRule) return
    if (!accounts) return

    const settings = { ...company?.settings, uncat: { selected: accounts } }

    api.post(`/applications/${company?.id}/settings`, { settings })
      .then(res => res.data)
      .finally(() => refreshCompanies())
  }

  return (
    <FlexSpace direction="vertical" >
      <Title level={5}>Setup questions</Title>
      <Card style={cardstyle}>
        <FlexSpace direction="vertical" size="large">
          <Text strong>Accounting Method</Text>
          <Select 
            style={{ width: 200 }}
            value={accountingMethod || "Cash"}
            onChange={onChangeAccountingMethod}
            options={[
              { value: 'Cash', label: 'Cash-Basis' },
              { value: 'Accrual', label: 'Accrual-Basis' }
            ]}
          />
        </FlexSpace>
      </Card>
      {!!uncatTxRule?.app_rule_id && (
        <Card style={cardstyle} >
          <FlexSpace direction="vertical">
            <FlexSpace spacebetween>
              <Text strong>Uncategorized Transactions</Text>
              <Btn
                size="small"
                title="Setup"
                onClick={() => setAccModal(true)}
                danger={!uncatAccounts.length}
              />
            </FlexSpace>
            <Text>
              <small>Check for Uncategorized Transactions in the GL account(s)</small>
            </Text>
            {!!uncatAccounts.length && (
              <FlexSpace direction="vertical" style={{ marginTop: 5 }}>
                <Text strong>Selected accounts:</Text>
                {uncatAccounts.map((v, i) => (
                  <small key={i}>{getAccName(v)}</small>
                ))}
              </FlexSpace>
            )}
          </FlexSpace>
          <MatchAccountsModal
            title="Uncategorized Transactions"
            visible={accModal}
            onClose={handleAccsClose}
            value={uncatAccounts}
          />
        </Card>
      )}
      {smallbusiness && !!sbcpRule && (
        <Card style={cardstyle}>
          <FlexSpace direction="vertical">
            <FlexSpace spacebetween>
              <Text strong>Small Business Corporation Payroll</Text>
              <Btn size="small" title="Setup" onClick={() => setMinorModal(true)} danger={!sbcpRule?.config?.owner} />
            </FlexSpace>
            <Text>
              <small>Small business corporation (one or more owners) should have payroll</small>
            </Text>
          </FlexSpace>
          <MinorServicesModal visible={minorModal} onClose={handleMinorClose} />
        </Card>
      )}
      {partnership && !!ppRule && (
        <Card style={cardstyle}>
          <FlexSpace direction="vertical">
            <FlexSpace spacebetween>
              <Text strong>Partnership Payroll</Text>
              <Btn size="small" title="Setup" onClick={() => setMinorModal(true)} danger={!ppRule?.config?.owner}  />
            </FlexSpace>
            <Text style={{ width: 500, display: 'block' }}>
              <small>
                An individual cannot be both a partner and an employee for purposes of 
                wage withholding, payroll taxes or FUTA 
                (<a rel="noreferrer" href="https://www.irs.gov/businesses/partnerships#:~:text=Each%20partner%20reports%20their%20share,U.S.%20Return%20of%20Partnership%20Income." target="_blank">IRS reference</a>) 
              </small>
            </Text>
          </FlexSpace>
          <MinorServicesModal2 visible={minorModal} onClose={handleMinorClose2} />
        </Card>
      )}
    </FlexSpace>
  )
}
