import { useEffect, useState } from 'react'

import { api } from 'utils/axios'

export const usePaidToken = () => {
  const [token, setToken] = useState('')
  useEffect(() => {
    api
      .get('/plaid/link_token')
      .then(({ data }) => setToken(data.link_token))
      .catch((err) => console.log(err))
  }, [])

  return { token }
}
