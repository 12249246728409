import { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useSearchParams } from 'react-router-dom'
import { Alert, Divider, message, Progress, Spin, Typography } from 'antd'

import { Btn } from 'components/Btn'
import { Header } from 'components/Header'
import { FlexSpace } from 'components/FlexSpace'
import { RangePicker } from 'components/RangePicker'
import { ShopifyConnect } from 'components/ShopifyConnect'
import { ShopifyMatch } from 'components/ShopifyMatch'

import { ComplianceSetting } from '../components/ComplianceSettings'
import { RulesSetup } from '../components/RulesSetup'

import { ComplianceCheck } from '../old'

import { errorMsg } from 'utils/errorMsg'
import { companiesAtom, companyRulesAtom, currentAccounts, currentCompany } from 'recoil/companies.state'
import { IAccount, ICompany, IntegrationStatus, IntegrationType } from 'types/company.types'
import { currentUser } from 'recoil/user.state'
import { stringToDayjs, toSafeDateString } from 'utils/dates'
import { ICompliance } from 'types/compliance.types'
import { config } from 'utils/config'
import { analytics } from 'utils/analytics'
import { api } from 'utils/axios'

interface SearchParams {
  daterange: string
}

interface Props {
  onChange: (v: ICompliance) => void
}

export const ComplianceRun: ReactFC<Props> = (props) => {
  const { onChange } = props

  const company = useRecoilValue(currentCompany)
  const accounts = useRecoilValue(currentAccounts)
  const rules = useRecoilValue(companyRulesAtom)
  const user = useRecoilValue(currentUser)
  const setCompanies = useSetRecoilState(companiesAtom)

  const [searchParams] = useSearchParams()
  const search: SearchParams = Object.fromEntries(searchParams) as any

  const [loading, setLoading] = useState(false)
  const [statusMsg, setStatusMsg] = useState('')
  const [dateRange, setDateRange] = useState<any>([])
  const [accountingMethod, setAccountingMethod] = useState<string>('Cash')

  let isShopifyConnected = false
  const rutterIntegration = company?.integrations.find(v => v.id === IntegrationType.Rutter)
  if (rutterIntegration) {
    const { connectionInfo } = rutterIntegration as any
    isShopifyConnected = connectionInfo?.platform === 'SHOPIFY'
  }
  
  // const shopifyStore = rutterIntegration?.connectionInfo?.store
  const shopifyMatched = company?.settings?.merchant?.matched
  const isShopifyReady = isShopifyConnected 
    && rutterIntegration?.status === IntegrationStatus.Active
    && !!shopifyMatched

  const store = (rutterIntegration?.connectionInfo as any)?.store
  const storeName = store?.store?.store_name ? `'${store?.store?.store_name}'` : ''

  console.log(rutterIntegration)
  console.log('isShopifyReady', isShopifyReady, rutterIntegration?.status, company?.settings?.merchant)
  console.log('isShopifyConnected', isShopifyConnected)
  console.log('shopifyMatched', shopifyMatched)

  const isPluginInstalled = !!document.documentElement.hasAttribute('equility-extension')
  const isQBOConnected = !!company?.integrations.find(v => v.id === IntegrationType.QBO)?.status

  const flatRules = rules.map((v) => v.children || []).flat().filter(v => v.app_rule_id)
  const uncatTxRule = flatRules.find((v) => v.title === 'Uncategorized Transactions' && v.app_rule_id)
  const sbcpRule = flatRules.find((v) => v.title === 'Payroll Review for S-Corporations' && v.app_rule_id)
  const ppRule = flatRules.find((v) => v.title === 'Payroll Review for Partnerships' && v.app_rule_id)

  const bankAccs = accounts.filter((v) => isBank(v)).sort((a,b) => parseInt(b.qboId) - parseInt(a.qboId))
  const accNumber = bankAccs[0]?.qboId

  const from = toSafeDateString(dateRange[0])
  const to = toSafeDateString(dateRange[1])
  const backurl = encodeURIComponent(window.location.pathname)
  const basicUrl = 'https://accounts.intuit.com/app/sign-in?app_group=QBO&asset_alias=Intuit.accounting.core.qbowebapp&redirect_uri='
  const qboUrl = `https://app.qbo.intuit.com/app/companyselection?pagereq=switchCompany&companyId=${company?.qbo_id}`
  const navUrl = `&navigationURL=reconcile?accountId=${accNumber}%2526showHistory%3Dtrue%2526displayReport%3Dtrue`
  const addStr = `%2526equilityRefreshData%3D1%2526backurl%3D${backurl}%2526daterange%3D${from}--${to}`

  const doneUrl = basicUrl + encodeURIComponent(qboUrl + navUrl + addStr)
  const doneLinkRef = useRef<HTMLAnchorElement>(null)

  // console.log('accountingMethod', accountingMethod)

  console.log(company)

  useEffect(() => {
    const { daterange } = search
    if (!daterange || !company?.id || !!loading || dateRange.length === 2) return

    const am = localStorage.getItem('copliance-' + company.id + '-' + daterange) || ''
    if (am) setAccountingMethod(am)
    // console.log(am, 'am')

    const [from, to] = daterange.split('--')
    setDateRange([stringToDayjs(from), stringToDayjs(to)])
    runResults(company.id, from, to, am)
    localStorage.removeItem('qbosync')
  }, [search, company?.id])

  const handleRun = (force = false) => {
    if (!company) return
    if (!dateRange[0] || !dateRange[1]) return

    if (!force) {
      if (!isPluginInstalled)
        return message.warning('Please install our Chrome Extension to take full advantage of Equility')
    }

    const uncatAccounts = company?.settings?.uncat?.selected || []

    if (!isQBOConnected)
      return message.warning('Please reconnect to QuickBooks')
    if (!!uncatTxRule?.app_rule_id && !uncatAccounts.length)
      return message.warning('You have selected the ‘Uncategorized Transaction’ financial review, but have not selected an account for us to monitor')
    if (!!sbcpRule?.app_rule_id && !sbcpRule.config?.owner)
      return message.warning('You have selected the ‘Small business corporation payroll’ financial review, but have not setted if the owner of the business provide more than minor services to their corporation')
    if (!!ppRule?.app_rule_id && !ppRule.config?.owner)
      return message.warning('You have selected the ‘Partnership payroll’ financial review, but have not provided details related to the owner’s involvement in the business. Please answer this question, in order to proceed forward with this compliance check')

    analytics.event.compliance.start()

    const from = toSafeDateString(dateRange[0])
    const to = toSafeDateString(dateRange[1])

    if (force)
      runResults(company.id, toSafeDateString(dateRange[0]), toSafeDateString(dateRange[1]))
    else {
      runSync(`${from}--${to}`)
    }
  }

  const runSync =  async (daterange: string) => {
    if (!user) return

    const backurl = window.location.href.split('?')[0] + '?daterange=' + daterange
    const data = { type: "FROM_PAGE_TASK", company, backurl }

    localStorage.setItem('qbosync', JSON.stringify({ date: Date.now(), url: backurl, accountingMethod }))
    localStorage.setItem('copliance-' + company?.id + '-' + daterange, accountingMethod)
    window.postMessage(data, "*")
  }

  const runResults = async (appId: number, startDate: string, endDate: string, method = accountingMethod) => {  
    if (!company) return
     
    const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
    
    setLoading(true)
    window.scrollTo(0, 0)
    try {
      // console.log('runResults accountingMethod', method)
      await wait(2000)
      const accs = await getAccounts(company?.id || 0)
      const check = new ComplianceCheck(appId, rules, accs, startDate, endDate, setStatusMsg, method, company)
      const results = await check.runRulesCheck()
      const meta: any = {}

      if (isShopifyConnected) {
        meta['shopify'] = await check.runShopifyCheck(accs)
      }
      
      onChange({
        application_id: appId,
        checked_through_start: startDate,
        checked_through_end: endDate,
        method: method,
        results,
        meta
      })
    } catch (error: Error | any) {
      message.error(errorMsg(error), 5)
    } finally {
      setLoading(false)
    }
  }

  const refreshCompanies = () => {
    return api.get<ICompany[]>('applications')
      .then(({data}) => setCompanies(data))
      .catch(console.log)
  }

  const disabled = !dateRange[0] || !dateRange[1] || !isQBOConnected
  
  const [textMsg, percentMsg, rest] = statusMsg?.split('|')

  const _base = <>Analyzing data, please wait...<br/>{textMsg}<br/></>
  const _rest = rest ? <><small>{rest}</small><br /></> : <br />
  const _percent = <Progress
    type="circle" 
    percent={(parseInt(percentMsg))} 
    strokeColor={{ '0%': '#DDF0E1', '100%': '#0f9648' }}
    style={{ marginTop: 20 }}
  />

  const tip = <>{_base}{_rest}{_percent}</>

  return (
    <Spin spinning={loading} tip={tip}>
      <FlexSpace direction="vertical" size="large" style={{ marginTop: 20 }} >
        <Header size={28}>Run financial review</Header>
        <Typography.Text style={{ display: 'block', maxWidth: '80%' }}>
          The financial review relies on the user having defined their chart of account 
          ‘Type’ and ‘Detail Type’ correctly within QuickBooks Online. 
          To review your chart of account details within QuickBooks Online, 
          follow the following instructions:
          <ol>
            <li>Go To: Accounting &gt; Chart of Accounts</li>
            <li>Review the ‘Type’ and ‘Detail Type’ to ensure that these are defined properly for each of your GL accounts.</li>
          </ol>
        </Typography.Text>
        {/* <a style={{ fontWeight: 600 }} href="https://www.youtube.com/watch?v=BNC3RZH0WLQ" target="_blank" rel="noreferrer">
          Financial Review Walkthrough Video
        </a> */}
        <FlexSpace direction="vertical">
          <Typography.Title level={5}>Financial Review Date Range</Typography.Title>
          <RangePicker value={dateRange} onChange={setDateRange} />
        </FlexSpace>
        <ComplianceSetting />
        <RulesSetup onChangeAccountingMethod={setAccountingMethod} accountingMethod={accountingMethod} />
        <FlexSpace direction="vertical">
          <Typography.Title level={5}>Connected E-Commerce Stores​</Typography.Title>
          {isShopifyConnected && (
            <>
              {isShopifyReady && (
                <Typography.Text>
                  Your Shopify store <b>{storeName}</b> is connected and ready for review
                </Typography.Text>
              )}
              {!isShopifyReady && (
                <Typography.Text>
                  Your Shopify store <b>{storeName}</b> is connected, but not ready for review. It may take a few minutes to sync your data.
                </Typography.Text>
              )}
              <ShopifyMatch title='Setup accounts' onChange={refreshCompanies} />
            </>
          )}
          {!isShopifyConnected && (
            <>
              <Typography.Text>
                Please connect your Shopify store to review your Shopify transactions
              </Typography.Text>
              <ShopifyConnect title='Connect' onChange={refreshCompanies} type="primary" />
            </>
          )}
        </FlexSpace>
        <Divider />
        <FlexSpace direction="horizontal">
          <Btn
            title="Start"
            type="primary"
            width={200}
            loading={loading}
            disabled={disabled}
            onClick={() => handleRun(true)}
          />
          {config.isLocal && <Btn title="Fast start" loading={loading} onClick={() => handleRun(true)} />}
        </FlexSpace>
        <a ref={doneLinkRef} href={doneUrl} style={{ display: 'none' }}>Done</a>
        {!isQBOConnected && (
          <Alert
            type="warning"
            message="Please reconnect to QuickBooks"
            description="Go to Company Settings > Integration > Reconnect"
          />
        )}
      </FlexSpace>
    </Spin>
  )
}

function getAccounts(companyId: number | string) {
  return api.get<IAccount[]>(`/integrations/qbo/accounts?application=${companyId}`)
    .then(({ data }) => data)
}

function isBank(acc: IAccount) {
  const subTypes = ['CashOnHand', 'CreditCard', 'Checking']
  const types = ['Bank', 'Credit Card']
  return types.includes(acc.accountType) || subTypes.includes(acc.accountSubType)
}