import { useState } from 'react'
import { Button, Card, Divider, Slider, Tooltip, Typography } from 'antd'

import { Block } from 'components/Block'
import { FlexSpace } from 'components/FlexSpace'

import { stringToDate } from 'utils/dates'

const { Text } = Typography

const marks = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }

interface RecInfoProps {
  accountName: string
  accountType: string
  startDate: string
  endDate: string
  dayrange?: number
  viewOnly?: boolean
  footer?: React.ReactNode
  onDayrangeChange: (value: number) => void
}

export const RecInfo = (props: RecInfoProps) => {
  const { accountName, accountType } = props
  const { startDate, endDate } = props
  const { dayrange, onDayrangeChange } = props
  const { viewOnly, footer } = props

  const [editDayrange, setEditDayrange] = useState(dayrange || 3)
  const [showEditDayrange, setShowEditDayrange] = useState(false)

  const textStyle = { fontSize: 14 }

  const handleDone = () => {
    onDayrangeChange(editDayrange)
    setShowEditDayrange(false)
  }
  
  return (
    <Card size="small" className="DifferenceActions">
      <FlexSpace direction="vertical" style={{ marginTop: 5 }} className="ant-space-small">
        <Tooltip title={`Reconciling ${accountName} Account`} overlayStyle={{ maxWidth: 500 }}>
          <Text ellipsis style={textStyle}>
            Reconciling <b>{accountName}</b> account
          </Text>
        </Tooltip>
        <Text style={textStyle}>
          Account type: <strong>{accountType}</strong>
        </Text>
        <Text style={textStyle}>
          Start date: <strong>{stringToDate(startDate, 'LL')}</strong>
        </Text>
        <Text style={textStyle}>
          End date: <strong>{stringToDate(endDate, 'LL')}</strong>
        </Text>
        <Block>
          <FlexSpace>
            <Tooltip title="The number of days before or after the transaction date to look for potential matches">
              <Text style={textStyle}>
                <span>Date Range Differential: </span>
                <b>{dayrange} {dayrange === 1 ? 'day' : 'days'}</b>
              </Text>
            </Tooltip>
            {!showEditDayrange && !viewOnly && (
              <Button type="link" size="small" onClick={() => setShowEditDayrange(true)}>
                <b><small>[ Edit ]</small></b>
              </Button>
            )}
            {showEditDayrange && (
              <Button type="link" size="small" onClick={handleDone}>
                <b><small>[ Done ]</small></b>
              </Button>
            )}
          </FlexSpace>
          {showEditDayrange && (
            <Slider
              defaultValue={dayrange} 
              min={1} max={5} 
              marks={marks} 
              onChange={setEditDayrange}
            />
          )}
        </Block>
      </FlexSpace>
      {!viewOnly && <Divider style={{ marginTop: 10, marginBottom: 10 }} />}
      {!viewOnly && footer}
    </Card>
  )
}
