import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { message, Modal, Select, Table, TreeSelect } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faPaste } from '@fortawesome/free-regular-svg-icons'


import { Btn } from 'components/Btn'
import { ColorAmount } from 'components/ColorAmount'

import { currentAccounts, currentCompany, currentCustomers, currentVendors } from 'recoil/companies.state'
import { IAccount, IAccountTreeElement, IQboCustomer } from 'types/company.types'
import { ITx, TRANSACTION_TYPES } from 'types/tx.types'
import { api } from 'utils/axios'
import { toSafeDateString } from 'utils/dates'
import { FlexSpace } from 'components/FlexSpace'
import { accountName } from 'utils/accountName'

interface Props {
  list: ITx[]
  account: IAccount
  open: boolean
  onClose: (refresh?: boolean) => void
}

export const UploadTxModal: ReactFC<Props> = (props) => {
  const { account, list, open, onClose } = props

  const company = useRecoilValue(currentCompany)
  const accounts = useRecoilValue(currentAccounts)
  const treeData = arrToTree(accounts)

  const vendors = useRecoilValue(currentVendors)
  const customers = useRecoilValue(currentCustomers)
  const agents = [...customers, ...vendors]

  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState({} as any)
  const [accCopy, setAccCopy] = useState()

  const isCC = !!account?.isCredit
  const typeOptions = getTypeOptions(!!account?.isCredit, !!account?.isBank)

  // console.log(meta)

  useEffect(() => {
    const newMeta = {} as any
    list.forEach(tx => {
      if (!!account?.isBank) 
        newMeta[tx._id] = isCC 
          ? { type: tx.amount >= 0 ? 0 : 1 }
          : { type: tx.amount >= 0 ? 1 : 0 }
      else {
        newMeta[tx._id] = { type: 0 } 
      }
    })
    setMeta(newMeta)
    setLoading(false)
  }, [list, open])

  const handleUpdateTx = (tx: ITx, txmeta: any) => {
    const newMeta = Object.assign({}, meta)
    newMeta[tx._id] = newMeta[tx._id] || {}
    newMeta[tx._id] = {...newMeta[tx._id], ...txmeta}
    setMeta(newMeta)
  }

  const handleSubmit = () => {
    const transactions = list.map(tx => prepareTx(tx, meta, account, typeOptions, customers))

    if (transactions.find(v => !v.accountId))
      return message.warning('Selection of account is mandatory. Please check input data')

    setLoading(true)

    api.post('/integrations/qbo/transactions', {  transactions, applicationId: company?.id})
      .then(() => {
        message.info(`Transaction(s) were added to QuickBooks`)
        onClose(true)
      })
      .catch(() => message.error(`Failed to add transactions to QuickBooks`))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      width={1300}
      title="Export To QuickBooks"
      open={open}
      onCancel={() => onClose()}
      footer={[
        <Btn title="Cancel" onClick={() => onClose()} key="ertc" />,
        <Btn title="Upload" onClick={handleSubmit} type="primary" key="ertok" loading={loading} />
      ]}
      children={
        <Table
          className="Table"
          rowKey={'_id'}
          dataSource={list}
          columns={columns(treeData, agents, typeOptions, meta, handleUpdateTx, accCopy, setAccCopy)}
          pagination={false}
          scroll={{ y: 400 }}
        />
      }
    />
  )
}

function columns(
  treeData: any, 
  agents: IQboCustomer[], 
  typeOptions: any[], 
  meta: any, 
  updateTx: (tx: ITx, data: any) => void,
  accCopy: any, setAccCopy: (v: any) => void
) {
  return [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      width: 130
    },
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'account',
      width: 340,
      render: (_: number, tx: ITx) => (
        <FlexSpace>
          <TreeSelect
            showSearch
            style={{ width: '230px' }}
            dropdownStyle={{ maxHeight: 400, width: 600 }}
            placeholder="Please select account"
            allowClear
            treeDefaultExpandAll
            treeNodeFilterProp="title"
            treeData={treeData}
            fieldNames={{
              label: 'fullname',
              value: 'qboId'
            }}
            filterTreeNode={(input, option) => 
              (option?.fullname ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={meta[tx._id]?.accountId}
            onChange={v => updateTx(tx, { accountId: v })}
          />
          <Btn
            type="text" 
            tooltip="copy"
            icon={<FontAwesomeIcon icon={faCopy} />} 
            disabled={!meta[tx._id]?.accountId}
            onClick={() => setAccCopy(meta[tx._id]?.accountId)}
          />
          <Btn
            type="text"
            tooltip="paste"
            disabled={!accCopy}
            icon={<FontAwesomeIcon icon={faPaste} />}
            onClick={() => updateTx(tx, { accountId: accCopy })}
          />
        </FlexSpace>
      )
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (_: number, tx: ITx) => {
        return (
          <FlexSpace>
            <Select 
              showSearch
              allowClear
              placeholder="Select a name"
              disabled={!agents.length}
              style={{ width: 200 }}
              options={agents.map(v => ({ value: v.Id, label: v.DisplayName }))}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={meta[tx._id]?.name}
              onChange={v => updateTx(tx, { name: v })}
            />
          </FlexSpace>
        )
      }
    },
    {
      title: 'Memo',
      key: 'description',
      dataIndex: 'description',
      ellipsis: true
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      width: 160,
      render: (_: string, tx: ITx) => {
        return (
          <Select
            style={{ width: 130 }}
            options={typeOptions}
            value={meta[tx._id]?.type}
            onChange={v => updateTx(tx, { type: v })}
          />
        )
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      width: 140,
      render: (v: number) => <ColorAmount amount={v} />
    }
  ]
}

function arrToTree(items: IAccount[] = []) {
  const arr: IAccountTreeElement[] = [...items.map((v) => ({ ...v }))]
  arr.forEach((v) => {
    v.fullname = accountName(v.name, v.number)
    const el = arr.find((vv) => vv.qboId === v.parentQBOId)
    if (el) {
      if (!el.children) el.children = [v]
      else {
        if (!el.children.find((vvv) => vvv.qboId === v.qboId)) el.children.push(v)
      }
    }
  })

  return arr.filter((v) => !v.parentQBOId)
}

function prepareTx(tx: ITx, meta: any, account: IAccount, typeOptions: any[], customers: IQboCustomer[]) {
  const type = typeOptions[meta[tx._id].type]
  const isCustomer = customers.find(v => v.Id === meta[tx._id].name)
  return {
    accountId: meta[tx._id]?.accountId,
    amount: tx.amount,
    description: tx.description || tx.memo,
    type: type.label,
    txnDate: toSafeDateString(tx.date),
    fundingAccountId: account.qboId + '',
    credit: !!account?.isCredit,
    vendor: !isCustomer ? meta[tx._id].name : undefined,
    customer: isCustomer ? meta[tx._id].name : undefined
  }
}

function getTypeOptions(isCredit: boolean, isBank: boolean) {
  if (!isBank)
    return [
      { label: TRANSACTION_TYPES.JE, value: 0 }
    ]
  return !isCredit
    ? [
        { label: TRANSACTION_TYPES.EXPENSE, value: 0 },
        { label: TRANSACTION_TYPES.DEPOSIT, value: 1 }
      ]
    : [
        { label: TRANSACTION_TYPES.EXPENSE, value: 0 },
        { label: TRANSACTION_TYPES.CC, value: 1 }
      ]
}