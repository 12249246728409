import { FunctionComponent, useEffect, useState } from 'react'
import { Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'

import { Btn } from 'components/Btn'

export enum SaveAction {
  Save,
  Finish,
  Cancel
}

interface SaveBtnProps {
  loading?: boolean
  disabled?: boolean
  finished?: boolean
  onClick: (action: SaveAction) => void
}

export const SaveBtn: FunctionComponent<SaveBtnProps> = (props) => {
  const { loading, disabled, finished, onClick } = props

  const [action, setAction] = useState<SaveAction>(SaveAction.Save)

  useEffect(() => {
    finished && setAction(SaveAction.Cancel)
  }, [finished])

  let items: MenuProps['items'] = [
    {
      label: 'Save for later',
      key: 'saveforlater',
      onClick: () => setAction(SaveAction.Save)
    },
    {
      label: 'Finish and save',
      key: 'finishandsave',
      onClick: () => setAction(SaveAction.Finish)
    },
    {
      label: 'Close without saving',
      key: 'closewithoutsaving',
      onClick: () => setAction(SaveAction.Cancel)
    }
  ]

  if (finished) return (
    <Btn danger
      type="primary"
      title="Close without saving"
      onClick={() => onClick(SaveAction.Finish)}
    />
  )

  const types: any = ['primary', 'primary', 'default']

  const btnProps: any = {
    icon: <DownOutlined />,
    menu: { items },
    type: types[action],
    loading,
    disabled,
    danger: action === SaveAction.Cancel
  }

  return (
    <Dropdown.Button {...btnProps} onClick={() => onClick(action)}>
      {(items[action] as any).label}
    </Dropdown.Button>
  )
}
