import { FC, forwardRef, useState } from 'react'

import './styles.scss'

interface EditorProps {
  ref: any
  formula: any[]
  caret?: number
  setCaret: (value?: number) => void
  onChange: (value: any) => void
  onBackspace: () => void
}

export const FormulaEditor: FC<EditorProps> = forwardRef(function Editor(props, editorRef: any) {
  const { formula, onChange, onBackspace } = props
  const { caret, setCaret } = props
  
  const [focus, setFocus] = useState(false)

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()

    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    if (numbers.includes(e.key)) {
      return onChange({ id: 1, name: e.key, type: 'N' })
    }

    const keys = ['+', '-', '*', '/', '(', ')']
    if (!keys.includes(e.key) && e.key !== 'Backspace') return

    if (e.key === 'Backspace') return onBackspace()

    onChange({ id: 1, name: e.key, type: 'O' })
  }

  const editorProps = {
    ref: editorRef,
    onKeyDown,
    onClick: () => { setCaret() },
    onFocus: () => { setFocus(true) },
    onBlur: () => { setFocus(false) },
  }

  const onElClick = (e: React.MouseEvent<HTMLSpanElement>, i: number) => {
    e.stopPropagation()
    setCaret(i)
  }

  return (
    <div {...editorProps} tabIndex={0} className="FormulaEditor">
      {formula.map((v, i) => (
        <span key={v.type + ':' + i}>
          <El v={v} onClick={(e) => onElClick(e, i)} />
          {caret === i && focus && <Caret  />}
        </span>
      ))}
      {caret === undefined && focus && <Caret />}
    </div>
  )
})

interface ElProps {
  v: any
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void
}

const El = (props: ElProps) => {
  const { v, onClick } = props
  const color = v.error ? 'red' : 'black'
  const className = v.type === 'O' ? 'FormulaEditorOp' : 'FormulaEditorEl'

  return (
    <span onClick={onClick} style={{ cursor: 'text' }}>
      <span style={{ color }} className={className} >
        {v.name} 
        {v.subType && (
          <small> ({v.subType})</small>
        )}
      </span>
    </span>
  )
}

const Caret = () => (
  <span className="Caret">|</span>
)
