import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Col, Row } from 'antd'

import { Btn } from 'components/Btn'
import { Page } from 'components/Page'
import { FlexSpace } from 'components/FlexSpace'
import { FullLoader } from 'components/FullLoader'
import { SubscribeBtn } from './components/SubscribeBtn'
import { Price } from './components/Price'
import { AppSumoPlan } from './components/AppSumoPlan'
import { HeadTitle } from 'components/HeadTitle'

import { currentUser } from 'recoil/user.state'
import { currentPlan } from 'recoil/price.state'
import { companiesAtom } from 'recoil/companies.state'
import { api } from 'utils/axios'

export const Billing: ReactFC = () => {
  const user = useRecoilValue(currentUser)
  const prices = useRecoilValue(currentPlan)
  const companies = useRecoilValue(companiesAtom)

  const [portal, setPortal] = useState('')

  // const trialDays = prices?.trialDays || 0
    // const trialEndingDate = (user?.subscription?.status === 'trialing' && user?.subscription?.nextChargeDate) || 0
  const subscriptionStatus = user?.subscription?.status || 'canceled'
  const isActiveSubscription = subscriptionStatus === 'active' || subscriptionStatus === 'trialing'
  const userAccessLevel = user?.subscription?.accessLevel || 0
  const ownCompanies = companies.filter((v) => v.role.id === 0).length
  const maxQuantity = user?.subscription?.quantity || 0
  const isAppSumoUser = user?.subscription?.isAppSumo
  const appSumoDiscount = isAppSumoUser ? user.subscription?.discount?.coupon?.amount_off || 0 : 0
  const isTrial = subscriptionStatus === 'trialing'
  const isTrialAvailable = !user?.subscribed_before && user?.subscription?.status !== 'trialing' && !isAppSumoUser
  
  useEffect(() => {
    const backUrl = window.location.href
    api.post('/stripe/portal', { backUrl })
      .then((r) => setPortal(r.data))
  }, [user])

  const handleSubscribe = (price: string) => {
    const quantity = ownCompanies || 1
    const backUrl = window.location.href

    api.post('/stripe/checkout', { quantity, price, backUrl })
      .then((r) => (window.location.href = r.data))
  }

  const handlePortal = () => {
    window.location.href = portal
  }

  if (!user) return <FullLoader />

  return (
    <Page title={'Billing'}>
      <HeadTitle title="Billing" />
      <FlexSpace direction="vertical" size="large">
        {/* <TrialAlert trialDays={trialDays} isTrialAvailable={isTrialAvailable} trialEndingDate={trialEndingDate} /> */}
        {isAppSumoUser && (<AppSumoPlan />)}
        <Row gutter={25}>
          {prices?.list.map((v, i) => (
            <Col span={8} key={v.id}>
              <FlexSpace direction="vertical">
                <Price
                  name={v.name}
                  selected={i === userAccessLevel}
                  quantity={maxQuantity}
                  companyCount={ownCompanies}
                  price={v.price}
                  accessLevel={v.accessLevel}
                  isTrial={isTrial}
                  appSumoDiscount={appSumoDiscount}
                />
                {userAccessLevel < v.accessLevel && !isActiveSubscription && (
                  <SubscribeBtn name={v.name} isTrial={isTrialAvailable} onClick={() => handleSubscribe(v.id)} />
                )}
                {userAccessLevel === v.accessLevel && !!v.accessLevel && (
                  <Btn
                    block
                    type="primary"
                    size="large"
                    title="Manage subscription"
                    disabled={!portal}
                    onClick={handlePortal}
                  />
                )}
              </FlexSpace>
            </Col>
          ))}
        </Row>
      </FlexSpace>
    </Page>
  )
}
