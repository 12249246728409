import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { config } from './config'

export const sentry = {
  init: (dsn: string) => {
    Sentry.init({
      dsn,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: config.env,
      initialScope: {
        tags: {
          start: new Date().toISOString(),
          url: window.location.href,
          host: window.location.hostname
        }
      }
    })
  }
}
