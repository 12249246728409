import { Badge, Card, Typography } from 'antd'

import { FlexSpace } from './FlexSpace'

interface BadgedCardProps {
  title: string
  description: string
  sub?: string
  icon?: string
  active?: string
  hideBage?: boolean
}

export const BadgedCard: ReactFC<BadgedCardProps> = (props) => {
  const { active, title, description, sub, icon, hideBage, children } = props

  const card = (
    <Card title={<CardTitle title={title} icon={icon} />} style={{ height: 250 }}>
      <FlexSpace direction="vertical" size="large">
        <Typography>{description}</Typography>
        {!!sub && <Typography style={{ width: 300, lineHeight: 1.5 }}><small>{sub}</small></Typography>}
        {children}
      </FlexSpace>
    </Card>
  )

  if (hideBage) return card

  const ribbonProps = active === 'active' || active === 'logged in'
    ? { text: active }
    : active === 'warning' || active === 'not logged in'
      ? { text: active, color: 'gold' }
      : { text: active || 'disconnected', color: 'volcano' }

  return <Badge.Ribbon {...ribbonProps}>{card}</Badge.Ribbon>
}

interface CardTitleProps {
  title: string
  icon?: string
}

const CardTitle: ReactFC<CardTitleProps> = (props) => {
  const { title, icon } = props

  return (
    <FlexSpace>
      {icon && <img src={icon} alt="integration icon" />}
      <Typography.Title level={4} style={{ margin: 0 }}>
        {title}
      </Typography.Title>
    </FlexSpace>
  )
}
