import { selector } from 'recoil'

import { api } from 'utils/axios'

import { IBank } from 'types/reconcile.types'
import { ICompanyEntity } from 'types/company.types'
import { IExpert } from 'types/expert.types'

const dataBanks = selector<IBank[]>({
  key: 'dataBanks',
  get: async () => {
    try {
      const { data } = await api.get<IBank[]>('/dictionary/banks')
      return data
    } catch (error) {
      return []
    }
  }
})

const dataEntities = selector<ICompanyEntity[]>({
  key: 'dataEntities',
  get: async () => {
    try {
      const { data } = await api.get<ICompanyEntity[]>('/applications/entities')
      data.unshift({ id: 0, title: 'I don’t know my entity type' })
      return data
    } catch (error) {
      return []
    }
  }
})

const dataExperts = selector<IExpert[]>({
  key: 'dataExperts',
  get: async () => {
    try {
      const { data } = await api.get<IExpert[]>('/dictionary/experts')
      return data
    } catch (error) {
      return []
    }
  }
})

export { dataExperts, dataBanks, dataEntities }
