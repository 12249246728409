import { IResultMessage, IRule, IRuleResult, RuleMessageActionType } from "types/compliance.types"
import { toSafeDateString } from "utils/dates"
import { queryQboTransactions } from "./apiutils"
import { IAccount } from "types/company.types"

interface INumberOfTransactionsRule {
  rule: IRule
  defaultRuleResult: IRuleResult
  accounts: IAccount[]
  applicationId: number
  startDate: string
  endDate: string
  accountingMethod: string
}

export const uncatTransactionsRule = async ({
  rule,
  defaultRuleResult,
  accounts,
  applicationId,
  startDate,
  endDate,
  accountingMethod
}: INumberOfTransactionsRule, accList: string[] = []): Promise<IRuleResult> => {
  // const accList = rule?.config?.accounts || []

  if (!accList.length) {
    defaultRuleResult.status = 'Passed'
    defaultRuleResult.results = [
      {
        message: 'You do not have any accounts of this type',
        status: 'Passed'
      }
    ]

    return defaultRuleResult
  }

  const accsToCheck = accounts.filter(v => accList.includes(v.qboId))

    let resultMessages: IResultMessage[] = []
    for (let account of accsToCheck) {
      const { transactions, mapList } = await queryQboTransactions({
        applicationId: applicationId,
        from: toSafeDateString(startDate),
        to: toSafeDateString(endDate),
        accountQboId: account.qboId,
        accountingMethod
      })

      if (!!transactions?.Rows?.Row?.length) {
        const meta = {
          txs: [{
            type: account.name,
            list: mapList.map((v: any) => ({...v, acc: account})),
          }],
          account,
          isUncat: true
        }

        resultMessages.push({
          status: 'Failed',
          message: `Please reallocate the transactions that are listed within your` +
            ` '${getAccName(account)}' account. Number of Transactions: ${transactions.Rows.Row.length}`,
          meta,
          action: {
            text: 'Accurate',
            type: RuleMessageActionType.Force
          },
        })
      }
    }

    defaultRuleResult.info = 'Accounts being reviews: ' + accsToCheck.map(v => getAccName(v)).join(', ')

    if (!resultMessages.length) {
      defaultRuleResult.status = 'Passed'
      defaultRuleResult.results = [{
        message: 'There are no transactions in these accounts to be reviewed.',
        status: 'Passed',
        meta: {
          isUncat: true,
          accs: accsToCheck
        }
      }]
    } else {
      defaultRuleResult.status = 'Failed'
      defaultRuleResult.results = [...resultMessages]
    }
  
  return defaultRuleResult
}

function getAccName(acc: any) {
  if (!acc.number) return acc.name
  return acc.number + ' ' + acc.name
}