import { debounce } from 'lodash'
import { useEffect, useState } from 'react'

import { config } from 'utils/config'

const events = ['click', 'load', 'scroll']
const checkInterval = 1000

export function useSessionTimeout(): [boolean, () => void] {
  const [start, setStart] = useState(Date.now())
  const [expired, setExpired] = useState(false)

  useEffect(() => {
    events.forEach((event) => window.addEventListener(event, resetTimer, { passive: true }))
    return () => events.forEach((event) => window.removeEventListener(event, resetTimer))
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() > start + config.timeout.logout)
        setExpired(true)
    }, checkInterval)

    return () => clearInterval(interval)
  }, [start])

  const resetTimer = debounce(() => {
    setStart(Date.now())
  }, checkInterval)

  const resetSessionTimeout = () => {
    setStart(Date.now())
    setExpired(false)
  }

  return [expired, resetSessionTimeout]
}
