import { Avatar } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'

interface UserAvatarProps {
  url?: string
}

const Icon = () => <FontAwesomeIcon icon={faUser} size={'sm'} />

export const UserAvatar: ReactFC<UserAvatarProps> = ({ url }) => {

  const style = {
    background: '#9FD7B7',
    outline: '2px solid white'
  }

  if (!url) return <Avatar size={40} icon={<Icon />} style={style} />

  return (
    <Avatar
      size={40}
      style={style}
      src={<img referrerPolicy="no-referrer" src={url} alt="avatar" />}
      icon={<Icon />}
      crossOrigin="anonymous"
    />
  )
}
