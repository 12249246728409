import { ICompliance } from './compliance.types'
import { IRole, IUser } from './user.types'

export interface ICompany {
  id: number
  qbo_id: string
  name: string
  alias?: string
  image: string | null
  role: IRole
  integrations: ICompanyIntegration[]
  entity?: ICompanyEntity | null
  lastaccessed: string
  isPaid?: string
  owner?: IUser
  accessLevel?: number
  compliance?: ICompliance
  progress?: number
  settings: ICompanySettings | null
}

export interface ICompanySettings {
  entity?: ICompanyEntity
  payroll?: {
    matched: {
      [key: string]: string[]
    }
  },
  merchant?: {
    matched: {
      [key: string]: string[]
    }
  },
  uncat?: {
    selected: string[]
  }
}

export interface ICompanyEntity {
  id: number
  title: string
}

export enum IntegrationType {
  QBO = 1,
  Plaid = 2,
  Rutter = 3,
}
export enum IntegrationStatus {
  Failed,
  Active,
  Pending,
}

export interface ICompanyIntegration {
  keyId: number
  id: IntegrationType
  name: string
  status: IntegrationStatus
  connectionInfo: Object
}

export interface IAccount {
  // id: number
  name: string
  title: string
  number: string
  parentQBOId: string
  qboId: string
  accountType: string
  accountSubType: string
  classification: string
  reconciled_through: string
  warning: string
  active: boolean
  ending_balance?: string
  fullname?: string

  isCredit?: boolean
  isBank?: boolean
  isReconcile?: boolean
}

export interface IAccountTreeElement extends IAccount {
  children?: IAccountTreeElement[]
}

export interface IQboCustomer {
  Id: number
  Balance: number
  DisplayName: string
}

export interface IQboVendor {
  Id: number
  Balance: number
  DisplayName: string
}