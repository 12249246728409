export interface ChartValue {
    date: string;
    amount: number;
}

export interface ChartDataSet {
    key: string;
    type: string;
    values: ChartValue[];
}

export interface ChartComponentProps {
    chartDataSets: ChartDataSet[]
    type: string
}

export type SerializedColumnBy = "month" | "quarter" | "year";


export interface Item {
    key: string;
    name: string;
    children?: Item[];
}

export interface ChartTypesProps {
    data: Item[];
    onSelectionChange: (keys: string[]) => void;
    selectedKeys: string[];
    setSelectedKeys: (keys: string[]) => void;
}

export enum AccountTypes {
    'PL' = 'Profit & Loss Summary',
    'BS' = 'Balance Sheet Summary',
    'CF' = 'Cash Flow Summary',
}

export const AccountTypesArray = ['PL', 'BS', 'CF']
