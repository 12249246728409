import { FunctionComponent } from 'react'
import { Modal, Space, Table, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { toCurrency } from 'utils/numbers'
import { Block } from 'components/Block'
import { api } from 'utils/axios'
import { Btn } from 'components/Btn'

interface Props {
  data: any
  isReconcile?: boolean
  visible: boolean
  onCancel: () => void
}

export const DetailsModal: FunctionComponent<Props> = (props) => {
  const { data, isReconcile, visible, onCancel } = props

  if (!data) return null

  const title = isReconcile ? 'Uncleared Transaction Details' : 'Details'

  const handleDetails = () => {
    api.post(`balances/details`, data)
      .then(r => {
        window.open(r.data.file, '_blank')
      })
  }

  return (
    <Modal open={visible} footer={null} width={1600} onCancel={() => onCancel()} title={title}>
      <FlexSpace direction="vertical" size="large">
        {data?.txs &&
          data.txs.map((v: any) => (
            <FlexSpace direction="vertical" key={v.type}>
              <Typography>{v.type}</Typography>
              {!v.list.length && (
                <Typography.Text>
                  <small>No transactions</small>
                </Typography.Text>
              )}
              {!!v.list.length && <TxTable data={v.list} type={v.type} />}
            </FlexSpace>
          ))}
          <FlexSpace style={{ justifyContent: 'end' }}>
            <Btn size="small" onClick={handleDetails}>Download</Btn>
            <Btn size="small" onClick={onCancel}>Close</Btn>
          </FlexSpace>
      </FlexSpace>
    </Modal>
  )
}


interface TxTableProps {
  data: any[]
  type?: string
}

const TxTable: FunctionComponent<TxTableProps> = (props) => {
  const { data } = props

  const dataSource = data.map((tx, i) => ({ 
    id: i,
    ...tx,
    date: tx.date || tx.data,
    account: tx.acc?.number ? tx.acc?.number + ' ' + tx.acc.name : tx.acc?.name,
    accSubType: tx.acc?.accountSubType?.split(/(?=[A-Z])/).join(' '),
    name: tx.customer || tx.vendor
  }))

  const accountIds = data
    .map(v => v.acc.id)
    .filter((v, i, a) => a.indexOf(v) === i)

  const treeData = accountIds
    .map((v, i) => {
      
      const accountItems = dataSource.filter(a => a.acc.id === v)
      const children = accountItems.map((vv, i) => {
        const _total = accountItems.slice(0, i + 1).reduce((sum, tx) => sum + parseFloat(tx.amount), 0)
        return { ...vv, total: toCurrency(_total) }
      })
      const total = toCurrency(children.reduce((sum, tx) => sum + parseFloat(tx.amount), 0))
      const balance = children[children.length - 1].balance
      const { id, account, accSubType } = children[0]
      const _id = id + '_' + children.length + '_' + i
      const footer = { id: 'footer' + _id, total, date: 'Total', accTotal: true }

      return { id: _id, account, accSubType: accSubType, children: [...children, footer], total, balance }
    })

  const total = data.reduce((sum, tx) => sum + parseFloat(tx.amount), 0)

  return <Table
    key={Date.now()}
    rowKey={"id"}
    onRow={onRow}
    rowClassName="DetailModalRow"
    dataSource={treeData}
    columns={columns}
    scroll={{ y: 500 }}
    size="small"
    pagination={false}
    expandable={{ defaultExpandAllRows: true }}
    footer={() => <TxTableFooter total={total} />}
  />
}

const TxTableFooter = ({ total }: { total: number }) => {
  return (
    <Block style={{ textAlign: 'right', paddingRight: 10 }}>
      <Space>
        <Typography.Text strong>Total: </Typography.Text>
        <Typography.Text>{toCurrency(total)}</Typography.Text>
      </Space>
    </Block>
  )
}

const renderBold = (v: string, el: any) => !el.amount ? <b>{v}</b> : v
const onRow = (record: any) => record.accTotal ? ({ style: { background: '#fafafa' } }) : ({})

const columns = [
  { title: '', dataIndex: 'id', key: 'id', width: 50, render: () => '' },
  { title: 'Date', dataIndex: 'date', key: 'date', width: 130, render: renderBold },
  { title: 'Account', dataIndex: 'account', key: 'account', width: 300, ellipsis: true, render: renderBold },
  { title: 'Name', dataIndex: 'name', key: 'name', width: 250, ellipsis: true },
  { title: 'Memo', dataIndex: 'memo', key: 'memo', width: 250, ellipsis: true },
  { title: 'Type', dataIndex: 'type', key: 'type', width: 180, ellipsis: true },
  { title: 'Num', dataIndex: 'num', key: 'num', width: 120, ellipsis: true },
  { title: 'Amount', dataIndex: 'amount', key: 'amount', width: 120, render: (v: number) => v ? toCurrency(v) : null },
  { title: 'Total', dataIndex: 'total', key: 'total', width: 120, render: renderBold },
]