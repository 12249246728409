import { reconcileRule } from './reconcileRule'
import { payrollRule } from './payrollRule'
import { numberOfTransactionsRule } from './numberOfTransactionsRule'
import { depreciationRule } from './depreciationRule'
import { undepositFundsRule } from './undepositFundsRule'
import { balanceRule } from './balanceRule'

import { uncatTransactionsRule } from './uncatTransactionsRule'
import { IRule, IRuleResult, payrollType } from 'types/compliance.types'
import { log } from 'utils/log'
import { toSafeDateString } from 'utils/dates'
import { getBalances, getQboReport2, getShopifyReport, queryQboTransactions } from './apiutils'
import * as rTypes from 'types/compliance.types'
import { IAccount, ICompany } from 'types/company.types'
import { toCurrency } from 'utils/numbers'

export class ComplianceCheck {
  private applicationId: number
  private rules: IRule[]
  private accounts: any[]
  private startDate: string
  private endDate: string
  private ruleResults: IRuleResult[] = []
  private payrollAccounts: any[] | null = []
  private accountingMethod = 'Cash'
  private company: ICompany

  public idx = 0

  cb: (msg: string) => void = () => {}

  constructor(
    applicationId: number,
    rules: IRule[],
    accounts: any[],
    startDate: string,
    endDate: string,
    cb: (msg: string) => void,
    accountingMethod = 'Cash',
    company: ICompany
  ) {
    this.applicationId = applicationId
    this.rules = rules.map(v => v.children || []).flat().filter(v => v.app_rule_id)
    this.accounts = accounts
    this.startDate = startDate
    this.endDate = endDate
    this.accountingMethod = accountingMethod
    this.company = company
    cb && (this.cb = cb)
  }

  public async runRulesCheck() {
    const balances = await getBalances(this.applicationId, {
      start_date: toSafeDateString(this.startDate),
      end_date: toSafeDateString(this.endDate),
      accountin_method: this.accountingMethod
    })

    for (let rule of this.rules) {
      let ruleResult: IRuleResult = {
        ruleName: rule.title,
        ruleDescription: rule.description
      } as any

      this.idx++
      const percent = 100 / this.rules.length * this.idx
      this.cb(rule.title + '|' + percent)

      let ruleArgs = {
        balances,
        rule,
        defaultRuleResult: this.getDefaultResult(rule),
        accounts: this.accounts,
        applicationId: this.applicationId,
        startDate: this.startDate,
        endDate: this.endDate,
        accountingMethod: this.accountingMethod,
        defaultCbText:rule.title + '|' + percent,
        cb: this.cb,
        idx: this.idx,
        rulesLenght: this.rules.length,
        idxAdd: () => this.idx++
      }

      // console.log(this.accountingMethod)

      switch (rule.title.toLowerCase()) {
        case 'checking & credit card review':
        case 'checking & credit card reconciliation':
          ruleResult = await reconcileRule(ruleArgs)
          break
        case 'sole proprietors payroll':
        case 'sole proprietor / pass-through llc payroll':
        case 'payroll review for sole proprietor / pass-through llc':
          ruleResult = await this.runPayrollRule(rule, false)
          break
        case 'small business corporation payroll':
        case 'payroll review for s-corporations':
          ruleResult = await this.runPayrollRule2(rule, true)
          break
        case 'partnership payroll':
        case 'payroll review for partnerships':
          ruleResult = await this.runPayrollRule3(rule, true)
          break
        case 'equity review for corporations':
          ruleResult = await numberOfTransactionsRule({ ...ruleArgs, accountType: rTypes.corporationEquityType, cType: 'equity' })
          break
        case 'equity review for sole proprietors / pass-through llc':
        case 'equity review for sole proprietors':
          ruleResult = await numberOfTransactionsRule({ ...ruleArgs, accountType: rTypes.soleProprietorEquityType, cType: 'equity' })
          break
        case 'equity review for partnerships':
          ruleResult = await numberOfTransactionsRule({ ...ruleArgs, accountType: rTypes.partnershipEquityType, cType: 'equity' })
          break
        case 'equity review for s-corporations':
          ruleResult = await numberOfTransactionsRule({ ...ruleArgs, accountType: rTypes.sCorporationEquityType, cType: 'equity' })
          break
        case 'equity review for non-profits':
          ruleResult = await numberOfTransactionsRule({ ...ruleArgs, accountType: rTypes.nonProfitEquityType, cType: 'equity' })
          break
        case 'liabilities review for sole proprietors / pass-through llc': // liability review for sole proprietors / pass-through llc
        case 'liabilities review for sole proprietors':
        case 'liabilities review for non-profits':
          ruleResult = await numberOfTransactionsRule({ ...ruleArgs, accountType: rTypes.liabilitiesType, cType: 'liability' })
          break
        case 'asset review for sole proprietors / pass-through llc':
        case 'asset review for sole proprietors':
        case 'asset review for partnerships':
        case 'asset review for non-profits':
          ruleResult = await numberOfTransactionsRule({ ...ruleArgs, accountType: rTypes.assetsType, cType: 'asset'})
          break
        case 'depreciation & amortization review':
          ruleResult = await depreciationRule({ ...ruleArgs, accountType: rTypes.depricationType })
          break
        case 'undeposited funds review':
          ruleResult = await undepositFundsRule(ruleArgs)
          break
        case 'uncategorized asset':
          ruleResult = await balanceRule({ ...ruleArgs, accountType: rTypes.otherAssetsType })
          break
        case 'opening balance equity':
          ruleResult = await balanceRule({ ...ruleArgs, accountType: rTypes.equityType })
          break
        case 'uncategorized transactions':
          ruleResult = await uncatTransactionsRule({ ...ruleArgs }, this.company.settings?.uncat?.selected)
          break
        default:
          log('default, rule not found:', rule?.name || rule?.title)
      }
      this.ruleResults.push(ruleResult)
    }
    return this.ruleResults
  }

  public async runShopifyCheck(accounts: IAccount[]): Promise<IRuleResult[]> {

    this.cb('Shopify sync|' + 100)

    const shopifyReport = await getShopifyReport(
      this.applicationId, 
      toSafeDateString(this.startDate), 
      toSafeDateString(this.endDate)
    )

    const matched = this.company?.settings?.merchant?.matched || {}
    const matchedAccs = Object.values(matched).map(v => v).flat()

    const qboData = await getQboReport2(
      matchedAccs,
      this.applicationId, 
      toSafeDateString(this.startDate), 
      toSafeDateString(this.endDate)
    )

    // qboData to oject
    const qboReportObj = qboData.reduce((acc, v) => {
      acc[v.id] = v.amount
      return acc
    }, {} as any)


    console.log('shopifyReport', shopifyReport)
    console.log('shopifyAccounts', shopifyAccounts)
    console.log('qboReport', qboReportObj)

    const result: IRuleResult = {
      id: Date.now(),
      ruleName: 'Shopify Review',
      ruleDescription: '',
      status: 'Warning',
      results: [],
      rule: {
        id: Date.now(),
        key: 'shopifyVsQbo',
        name: 'Shopify Review',
        description: ``,
        title: 'Shopify Review',
        isDefault: false,
        isSelected: false,
        failureMsg: 'Shopify and QBO do not match',
        type: 'shopify',
      },
    }

    const rResults = []

    for (let accType of shopifyAccounts) {
      if (accType.isCalc) continue

      const shopifyData = shopifyReport.accural
      const shopifyValue = (shopifyData as any)[accType.id]

      const qboMatched = matched[accType.id]
      // console.log('qboMatched', qboMatched)
      const qboValue = qboMatched?.reduce((acc: number, id: string) => acc + (qboReportObj[id] || 0), 0) || 0

      const diff = toCurrency(qboValue - shopifyValue) 
      const isPassed = diff === '$0.00'

      const accs = accounts.filter(v => qboMatched.includes(v.qboId)).map(v => v.name).join('‘, ‘')

      const accountMsg = `<strong>${accType.name}</strong> - ${isPassed ? 'Passed' : 'Failed'}<br /><br />`
      const shopifyMsg = `Shopify Total: ${toCurrency(shopifyValue)}<br />`
      const qboMsg = `QuickBooks Online Total: ${toCurrency(qboValue)}<br /><br />`

      const msg = accountMsg + shopifyMsg + qboMsg

      const r: rTypes.IResultMessage = isPassed ? {
        message: `${msg}The total of ‘${accs}‘, the QuickBooks Online general ledger account(s) that you mapped to Shopify ‘${accType.name}’, matches the total from Shopify`,
        status: 'Passed'
      } : {
        message: `${msg}The total of ‘${accs}‘, the QuickBooks Online general ledger account(s) that you mapped to Shopify ‘${accType.name}’,  differs from the total within Shopify by ${diff}`,
        status: 'Warning'
      }

      rResults.push(r)
    }

    const isPassed = rResults.every(v => v.status === 'Passed')
    return [{...result, results: rResults, status: isPassed ? 'Passed' : 'Failed'}]
  }

  private getDefaultResult(rule: IRule): IRuleResult {
    return {
      id: rule.id,
      ruleName: rule.name,
      ruleDescription: rule.description,
      status: 'Warning', // as default
      rule
    }
  }

  private async getPayrollAccounts() {
    let payrollAccounts = this.accounts.filter(
      (account) =>
        account.accountType === payrollType.accountType &&
        payrollType.accountSubTypes.some((subtype: any) => subtype === account.accountSubType)
    )
    if (payrollAccounts.length) {
      for (let account of payrollAccounts) {
        const { transactions } = await queryQboTransactions({
          applicationId: this.applicationId,
          from: toSafeDateString(this.startDate),
          to: toSafeDateString(this.endDate),
          accountQboId: account.qboId,
          unreconciled: false,
          accountingMethod: this.accountingMethod
        })
        
        account = {...account, transactions}
      }
      this.payrollAccounts = payrollAccounts
    } else {
      this.payrollAccounts = null
    }
  }

  private async runPayrollRule(rule: IRule, includePayroll: boolean): Promise<IRuleResult> {
    let payrollResult: IRuleResult = this.getDefaultResult(rule)

    log('runPayrollRule', rule)
    
    if (!this.payrollAccounts) {
      payrollResult.status = 'Passed'
      payrollResult.results = [
        {
          message: 'You do not have any transactions within the designated payroll GL accounts, and are compliant during this period.',
          status: 'Passed'
        }
      ]
      return payrollResult
    } else if (!this.payrollAccounts.length) {
      await this.getPayrollAccounts()
    }
    payrollResult = payrollRule(rule, payrollResult, this.payrollAccounts, this.startDate, this.endDate, includePayroll)
    return payrollResult
  }

  private async runPayrollRule2(rule: IRule, includePayroll: boolean): Promise<IRuleResult> {
    let payrollResult: IRuleResult = this.getDefaultResult(rule)

    log('runPayrollRule for small business corporation payroll', rule)

    if (rule.config?.owner === 'fail') {
      payrollResult.status = 'Failed'
      payrollResult.results = [
        {
          message: rule.failureMsg,
          status: 'Failed'
        }
      ]
      return payrollResult
    } else {
      payrollResult.status = 'Passed'
      payrollResult.results = [
        {
          message: `You're payroll is compliant based on entity-based guidelines from the IRS`,
          status: 'Passed'
        }
      ]
    }
    
    return payrollResult
  }

  private async runPayrollRule3(rule: IRule, includePayroll: boolean): Promise<IRuleResult> {
    let payrollResult: IRuleResult = this.getDefaultResult(rule)

    log('runPayrollRule for Partnership payroll', rule)

    if (rule.config?.owner === 'fail') {
      payrollResult.status = 'Passed'
      payrollResult.results = [
        {
          message: `You do not have any partners on payroll, and are payroll compliance per IRS regulations.`,
          status: 'Passed'
        }
      ]
      return payrollResult
    } else {
      payrollResult.status = 'Failed'
      payrollResult.results = [
        {
          message: 'There are one or more partners on payroll, which is not allowed by IRS regulations and you are not compliant.',
          status: 'Failed'
        }
      ]
    }
    
    return payrollResult
  }
}

const shopifyAccounts: IAccType[] = [
  // {id: 'gross', name: 'Gross Sales', isCalc: false},
  {id: 'discount', name: 'Discounts', isCalc: false},
  {id: 'returns', name: 'Returns', isCalc: false},
  {id: 'net', name: 'Net Sales', isCalc: true},
  {id: 'shipping', name: 'Shipping', isCalc: false},
  // {id: 'tax', name: 'Taxes', isCalc: false},
  // {id: 'liability', name: 'Gift Cards', isCalc: false},
  {id: 'total', name: 'Total', isCalc: true},
  {id: 'payments', name: 'Payments', isCalc: true},
]

interface IAccType {
  id: string
  name: string
  isCalc: boolean
}