import { useEffect, useState } from 'react'

import { ITx } from 'types/tx.types'
import { IMatcher } from 'types/matcher'
import { stringToDayjs } from 'utils/dates'
import { combinations } from 'utils/combinations'
import { log } from 'utils/log'

export function useMatcher(q: ITx[], b: ITx[], range = 2, matched: ITx[] = []): IMatcher {
  const [qbo, setQbo] = useState<ITx[]>(q)
  const [bank, setBank] = useState<ITx[]>(b)
  const [loaded, setLoaded] = useState(false)
  const [dayrange, setDayrange] = useState(range)

  log('useMatch dayrange', dayrange, matched)

  useEffect(() => {
    setQbo(q)
    setBank(b)
    setLoaded(true)
  }, [q, b])

  const refreshQbo = (txs: ITx[]) => {
    const qboTxIds = qbo.map(v => v.id)
    const txsIds = txs.map(v => v.id)

    const diff = txs.filter(tx => !qboTxIds.includes(tx.id))
    const diff2 = qbo.filter(tx => !txsIds.includes(tx.id))

    const diff2Ids = diff2.map(v => v._id)
    const nextQbo = qbo.filter(tx => !diff2Ids.includes(tx._id))

    setQbo([...nextQbo, ...diff])
  }

  const matchAll = () => {
    const _qbo: ITx[] = cloneArr(qbo)
    const _bank: ITx[] = cloneArr(bank)

    // find exact 
    _qbo.filter(v => !v.matchedTo.length && !v.forceMatch).forEach((qboTx) => {
      const __bank = _bank.filter(v => !v.matchedTo.length)
      const bankTx = findMatch(qboTx, __bank)
      if (bankTx) {
        bankTx.matchedTo = [cloneObj(qboTx)]
        qboTx.matchedTo = [cloneObj(bankTx)]
      }
    })

    // find in dayrange 
    _qbo.filter(v => !v.matchedTo.length && !v.forceMatch).forEach((qboTx) => {
      const __bank = _bank.filter(v => !v.matchedTo.length)
      const bankTx = findMatch(qboTx, __bank, dayrange)
      if (bankTx) {
        bankTx.matchedTo = [cloneObj(qboTx)]
        qboTx.matchedTo = [cloneObj(bankTx)]
      }
    })

    // find same amount potential
    _qbo.filter(v => !v.matchedTo.length && !v.forceMatch).forEach((qboTx) => {
      const __bank = _bank.filter(v => !v.matchedTo.length)
      const bankTxs = __bank.filter(v => qboTx.amount === v.amount)
      if (bankTxs.length) {
        qboTx.potentialMatches = [...cloneArr(bankTxs)]
        bankTxs.forEach(bankTx => (
          bankTx.potentialMatches = [cloneObj(qboTx)]
        ))
      }
    })

    // find combined potentials
    _qbo.filter(v => !v.matchedTo.length && !v.forceMatch && !v.potentialMatches?.length).forEach((qboTx) => {
      const __bank = _bank.filter(v => !v.matchedTo.length && isInDateRange(qboTx.date, v.date))
      qboTx.potentialMatches = combinations(qboTx, __bank)
    })
    _bank.filter(v => !v.matchedTo.length && !v.potentialMatches?.length).forEach((bankTx) => {
      const __qbo = _qbo.filter(v => !v.matchedTo.length && !v.forceMatch && isInDateRange(bankTx.date, v.date))
      bankTx.potentialMatches = combinations(bankTx, __qbo)
    })

    setBank(_bank)
    setQbo(_qbo)
  }

  const match = (txs1: ITx[], txs2: ITx[]) => { 
    const _qbo: ITx[] = cloneArr(qbo)
    const _bank: ITx[] = cloneArr(bank)

    txs1.forEach(tx1 => {
      const _qTx = _qbo.find(v => v._id === tx1._id)
      const _bTx = _bank.find(v => v._id === tx1._id)

      if (_qTx) {
        _qTx.matchedTo = cloneArr(txs2)
      } else if (_bTx) {
        _bTx.matchedTo = cloneArr(txs2)
        _bTx.selected = false
      }
    })

    txs2.forEach(tx2 => {
      const _qTx = _qbo.find(v => v._id === tx2._id)
      const _bTx = _bank.find(v => v._id === tx2._id)
      const _tx2 = _qTx || _bTx
      if (_tx2) {
        _tx2.matchedTo = cloneArr(txs1)
        _tx2.selected = false
      }
    })

    setQbo(_qbo)
    setBank(_bank)
  }

  const forcematch = (tx: ITx) => {
    const _qbo: ITx[] = cloneArr(qbo)
    const _qTx = _qbo.find(v => v._id === tx._id)

    if (_qTx) {
      _qTx.forceMatch = true
    }

    setQbo(_qbo)
  }

  const unmatch = (tx: ITx) => {
    const _qbo: ITx[] = cloneArr(qbo)
    const _bank: ITx[] = cloneArr(bank)

    const _qTx = _qbo.find(v => v._id === tx._id)
    const _bTx = _bank.find(v => v._id === tx._id)

    if (_qTx) {
      const _bTxs = _bank.filter(v => _qTx.matchedTo.map(vv => vv._id).includes(v._id))
      _qTx.potentialMatches = [..._qTx.matchedTo]
      _qTx.matchedTo = []
      _qTx.forceMatch = false
      _bTxs.forEach(v => {
        v.matchedTo = []
        v.potentialMatches = [_qTx]
        v.selected = false
      })
    } else if (_bTx) {
      const _qTxs = _qbo.filter(v => _bTx.matchedTo.map(vv => vv._id).includes(v._id))
      _bTx.potentialMatches = [..._bTx.matchedTo]
      _bTx.matchedTo = []
      _qTxs.forEach(v => { 
        v.matchedTo = []
        v.potentialMatches = [_bTx]
      })
    }

    setQbo(_qbo)
    setBank(_bank)
  }

  const unmatchAll = () => {
    setBank(bank.map((v) => ({ ...v, matchedTo: [], selected: false })))
    setQbo(qbo.map((v) => ({ ...v, matchedTo: [] })))
  }

  const selectBank = (ids: string[]) => {
    setBank(bank.map((v) => ({ ...v, selected: !!ids.find((id) => id === v._id) })))
  }

  const selectQbo = (ids: string[]) => {
    setQbo(qbo.map((v) => ({ ...v, selected: !!ids.find((id) => id === v._id) })))
  }

  const markAsDeleted = (ids: string[]) => {
    setQbo(qbo.map((v) => (
      { ...v, selected: false, deleted:v.deleted || !!ids.find((id) => id === v._id) }
    )))
  }

  const changeDayrange = (v: number) => {
    setDayrange(v)
    matchAll()
  }

  return {
    qbo,
    bank,
    match,
    unmatch,
    forcematch,
    matchAll,
    selectBank,
    selectQbo,
    markAsDeleted,
    unmatchAll,
    setQbo,
    setBank,
    refreshQbo,
    changeDayrange,
    loaded,
    dayrange
  }
}

function isSameDate(a: string, b: string) {
  return stringToDayjs(a)?.isSame(stringToDayjs(b))
}

function isInDateRange(a: string, b: string, dayrange = 2) {
  const adate = stringToDayjs(a)
  const bdate = stringToDayjs(b)
  if (!adate || !bdate) return false

  const weekday = adate.day()
  const dateRange = weekday === 0 || weekday === 5 ? dayrange + 1 : dayrange
  return adate.isBetween(bdate.subtract(dateRange, 'day'), bdate.add(dateRange, 'day'))
}

function findMatch(qboTx: ITx, bank: ITx[], dayrange?: number) {
  return bank.find((bankTx) => {
    const isAmount = bankTx.amount === qboTx.amount
    const isDate = !dayrange
      ? isSameDate(qboTx.date, bankTx.date)
      : isInDateRange(qboTx.date, bankTx.date, dayrange)
    const isMatch = isAmount && isDate && !bankTx.matchedTo.length
    return isMatch
  })
}

function cloneArr(arr: ITx[]): ITx[] {
  // return arr.map(a => ({...a}))
  return JSON.parse(JSON.stringify(arr))
}

function cloneObj(obj: ITx): ITx {
  const matchedTo: ITx[] = []
  const potentialMatches: ITx[] = []
  return JSON.parse(JSON.stringify({ ...obj, matchedTo, potentialMatches }))
}