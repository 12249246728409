const numberFormatter = new Intl.NumberFormat('en-US', { 
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

export function toNumber(value: number | string) {
  const v = getNumber(value)
  return numberFormatter.format(v)
}

export function toCurrency(value: number, parenthesis = true) {
  if (value < 0) {
    if (parenthesis)
      return '(' + currencyFormatter.format(value).replace('-', '') + ')'
    else 
      return currencyFormatter.format(value).replace('-', '')
  }
  return currencyFormatter.format(value || 0)
}


function getNumber(value: number | string) {
  return typeof value === 'string'
    ? parseFloat(value) || 0
    : value || 0
}

export function toSafeDecimal(value: number | string) {
  return typeof value === 'string'
    ? parseFloat(parseFloat(value).toFixed(2))
    : parseFloat(value.toFixed(2))
}