import { Suspense } from 'react'
import { useRecoilValue } from 'recoil'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Tabs } from 'antd'

import { Page } from 'components/Page'
import { FullLoader } from 'components/FullLoader'
import { HeadTitle } from 'components/HeadTitle'

import { General } from './tabs/General.tab'
import { Accounts } from './tabs/Accounts.tab'
import { Integrations } from './tabs/Integrations.tab'

import { currentCompany } from 'recoil/companies.state'

interface Props {
  title?: string
}

export const CompanySettings: ReactFC<Props> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const company = useRecoilValue(currentCompany)

  const items = [
    { label: 'Company', key: '/' },
    { label: 'Chart of accounts', key: '/accounts' },
    { label: 'Integrations', key: '/integrations' }
  ]

  const baseUrl = `/company/${company?.id}/settings`
  const activeKey = location.pathname.replace(baseUrl, '') || '/'
  const handleChange = (key: string) => navigate(`${baseUrl}${key}`)

  return (
    <Page title={'Company Settings'}>
      <HeadTitle title={'Company Settings'} />
      <Tabs items={items} activeKey={activeKey} onChange={(key) => handleChange(key)} />
      <Suspense fallback={<FullLoader />}>
        <Routes>
          <Route path="/" element={<General company={company} />} />
          <Route path="/accounts" element={<Accounts company={company} />} />
          <Route path="/integrations/" element={<Integrations items={company?.integrations || []}/>} />
        </Routes>
      </Suspense>
    </Page>
  )
}
