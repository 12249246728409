const defaultQboHost = 'https://app.qbo.intuit.com'

const isLocal = process.env.REACT_APP_ENV === 'local'
const isDev = process.env.REACT_APP_ENV === 'development'
const isProd = process.env.REACT_APP_ENV === 'production'

const measurmentId = isProd ? 'G-7WECYNSF11' : 'G-YBT7XVW90P'

export const config = {
  env: process.env.REACT_APP_ENV || 'local',
  isDev: isDev,
  isLocal: isLocal,
  api: {
    baseURL: process.env.REACT_APP_API_BASE_URL || ''
  },
  stripe: {
    apiKey: process.env.REACT_APP_STRIPE_API_KEY || '',
    timeout: 5000
  },
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  qboHost: process.env.REACT_APP_QBO_HOST || defaultQboHost,
  links: {
    privacy: 'https://equilityhq.com/privacy-policy/',
    terms: 'https://equilityhq.com/terms-and-conditions/',
    qbo: 'https://app.qbo.intuit.com/app/',
    downloadPlugin: 'https://chrome.google.com/webstore/detail/lanyap-extension/idnaidbjgchinhlfgjdpjmkgmblaemgi?hl=en&authuser=3'
  },
  timeout: {
    logout: 29 * 60 * 1000 // millisecs
  },
  prefix: 'equilityhq_',
  extensionId: 'idnaidbjgchinhlfgjdpjmkgmblaemgi',
  csvbox: {
    licenseKey: 'hEKT8TOuof8YtPhzNVFsrgqUnZhN38',
    useStagingServer: false
  },
  ga: {
    id: measurmentId
  },
  recaptcha: {
    reCaptchaKey: '6LemRnApAAAAAMcG1ExdQeldEIB2jpoja7Vxeyjn',
  },
  commandbar: {
    org: 'b5987f0b'
  },
  sentry: {
    dsn: 'https://6afeaa4aca024818a4ee50bb4e0480a1@o914539.ingest.sentry.io/6100316'
  }
}
