import { useState } from 'react'
import { Divider, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { Btn } from 'components/Btn'

import { ISetupData } from '../utils'

interface StepsProps {
  data: ISetupData
  onSubmit: () => void
}

const { Text, Title } = Typography

export const DoneStep = (props: StepsProps) => {
  const { data, onSubmit } = props

  const [loading, setLoading] = useState(false)

  return (
    <FlexSpace direction="vertical" size="large">
      <Title level={5}>That’s it, you’ve done it!</Title>
      <Text strong>Thanks for the help! You’re now ready to use Equility!</Text>
      <Divider />
      <Btn 
        type="primary" 
        title="Let's Go!"
        disabled={!data?.id} 
        loading={loading} 
        onClick={() => {
          onSubmit()
          setLoading(true)
          setTimeout(() => setLoading(false), 2000)
        }} 
      />
    </FlexSpace>
  )
}
