import { selector } from 'recoil'

import { api } from 'utils/axios'
import { IPlan } from 'types/subscription.types'

const currentPlan = selector<IPlan | null>({
  key: 'currentPlan',
  get: async () => {
    try {
      const { data } = await api.get<IPlan>('/stripe/plan')
      return data
    } catch (error) {
      return null
    }
  }
})

export { currentPlan }
