import React from "react";
import {toCurrency} from "../utils/numbers";
import {Typography} from "antd";
import {FlexSpace} from "./FlexSpace";

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
}
export const ChartCustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <FlexSpace align='start'
                style={{backgroundColor: '#fff', padding: '10px', border: '1px solid #ddd', borderRadius: '4px', flexDirection: 'column'}}>
                {payload.map((entry, index) => (
                    <Typography.Text key={`item-${index}`} style={{color: entry.color}}>
                        {`${entry.name}: ${toCurrency(entry.value)}`}
                    </Typography.Text>
                ))}
            </FlexSpace>
        );
    }

    return null;
};
