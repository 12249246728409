import { selector } from 'recoil'

import { api } from 'utils/axios'
import { IUser } from 'types/user.types'

import { authAtom } from './auth.state'

const currentUser = selector<IUser | null>({
  key: 'currentUser',
  get: async ({ get }) => {
    const auth = get(authAtom)
    if(!auth) return null

    try {
      const { data } = await api.get<IUser>('user')
      const { data: subscription } = await api.get('stripe/subscription')
      const { data: invites } = await api.get('invites/user')
      return { ...data, subscription, invites }
    } catch (error) {
      return null
    }
  }
})

export { currentUser }
