import { Space, SpaceProps } from 'antd'

interface FlexSpaceProps extends SpaceProps {
  spacebetween?: boolean
}

export const FlexSpace: ReactFC<FlexSpaceProps> = (props) => {
  const { spacebetween, style, ...rest } = props

  const justify = spacebetween ? { justifyContent: 'space-between' } : {}

  return (
    <Space {...rest} style={{ ...style, ...justify, display: 'flex' }} />
  )
}
