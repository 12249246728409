import React, { useState, useEffect } from 'react';
import { Table, Typography } from 'antd';
import {  toCurrency } from '../utils/numbers';
import { formatDateByPeriod } from '../utils/dates';
import { AccountTypes } from '../types/chart.types';
import {DataItem, NestedTableProps} from "../types/financial.type";

export const NestedTable: React.FC<NestedTableProps>  = ({ data, accountType, expandLevel, dateRange, period }) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);



    const columns = [
        {
            title: <Typography.Text style={{ paddingRight: 10, fontWeight: 'bolder' }}>{ AccountTypes[accountType] }</Typography.Text>,
            key: 'name',
            render: (v: any, record: any) =>
                <Typography.Text style={isRowExpanded(record.key) || (record.type === "Summary" || (record.type === 'Section' && !record.children?.length))  ? { fontWeight: 'bolder' } : {}}>
                    {v.name}
                </Typography.Text>
        },
        {
            title: <Typography.Text style={{ paddingRight: 10, fontWeight: 'bolder' }}>
                {formatDateByPeriod(dateRange?.base?.start_date, period)}
            </Typography.Text>,
            key: 'amount',
            dataIndex: 'baseValue',
            render: (v: any, record: any) => renderContent(toCurrency(v), record, (record.type === "Summary" || (record.type === 'Section' && !record.children?.length)))
        },
        {
            title: <Typography.Text style={{ paddingRight: 10, fontWeight: 'bolder' }}>
                {formatDateByPeriod(dateRange?.comparative?.start_date, period)}
            </Typography.Text>,
            key: 'comparativeValue',
            dataIndex: 'comparativeValue',
            render: (v: any, record: any) => renderContent(toCurrency(v), record, (record.type === "Summary" || (record.type === 'Section' && !record.children?.length)))
        },
    ];
    if (accountType !== 'CF') {
      columns.push(        {
        title: <Typography.Text style={{ paddingRight: 10, fontWeight: 'bolder' }}>Variance $</Typography.Text>,
        key: 'varianceAmount',
        dataIndex: 'varianceAmount',
        render: (v: any, record: any) => {
            const varianceStyle = v > 0 ? { color: 'green' } : v < 0 ? { color: 'red' } : {};
            const varianceText = v ? `${toCurrency(v)}` : '-';
            return renderContent(varianceText, record, (record.type === "Summary" || (record.type === 'Section' && !record.children?.length)), varianceStyle);
        }

    },
      {
          title: <Typography.Text style={{ paddingRight: 10, fontWeight: 'bolder' }}>Variance %</Typography.Text>,
          key: 'variancePercentage',
          dataIndex: 'variancePercentage',
          render: (v: any, record: any) => {
              const varianceStyle = v > 0 ? { color: 'green' } : v < 0 ? { color: 'red' } : {};
              const varianceText = v ? `${v.toFixed(2)} %` : '-';
              return renderContent(varianceText, record, (record.type === "Summary" || (record.type === 'Section' && !record.children?.length)), varianceStyle);
          }

      })
    }

    const preprocessData = (data: DataItem[], parentId: string | null = null): DataItem[] => {
      return data;
    };

    const updateExpandedRowKeys = (data: DataItem[], level: number, currentKeys: string[] = []): string[] => {
        data.forEach(item => {
            if (level > 1 && item.children && item.children.length > 0) {
                currentKeys.push(item.key);
                updateExpandedRowKeys(item.children, level - 1, currentKeys);
            }
        });
        return currentKeys;
    };

    const rowClassName = (record: DataItem) => {
        return isRowExpanded(record.key) || (record.type === "Summary" || (record.type === 'Section' && !record.children?.length)) ? 'borderBottomRow' : '';
    };

    const isRowExpanded = (key: string) => expandedRowKeys.includes(key);

    const renderContent = (text: string | number, record: DataItem, isBold = false, additionalStyle: React.CSSProperties = {}) => {
        if (isRowExpanded(record.key ?? '')) return <></>;

        return (
            <Typography.Text style={{ paddingRight: 10, fontWeight: isBold ? 'bolder' : 'normal', ...additionalStyle }}>
                {text}
            </Typography.Text>
        );
    };

    useEffect(() => {
        setExpandedRowKeys(updateExpandedRowKeys(preprocessData(data), expandLevel));
    }, [expandLevel, data]);

    return (
        <>
            <Table
                columns={columns}
                className="financialsTable"
                dataSource={preprocessData(data)}
                expandedRowKeys={expandedRowKeys}
                onExpand={(expanded, record) => {
                    const newKeys = expanded
                        ? [...expandedRowKeys, record.key]
                        : expandedRowKeys.filter(key => key !== record.key);
                    setExpandedRowKeys(newKeys);
                }}
                pagination={false}
                expandIcon={() => null}
                rowClassName={rowClassName}
            />
        </>
    );
};

