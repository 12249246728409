import { Card, Divider, Typography, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { FlexSpace } from 'components/FlexSpace'

import { toCurrency } from 'utils/numbers'

const { Text, Title } = Typography
const { useToken } = theme

interface PriceProps {
  name: string
  price: number
  quantity: number
  companyCount: number
  selected: boolean
  accessLevel: number
  isTrial?: boolean
  appSumoDiscount?: number
}

export const Price: ReactFC<PriceProps> = (props) => {
  const { name, price, selected, quantity, companyCount,   } = props
  const { appSumoDiscount, accessLevel, isTrial } = props
  const { token } = useToken()

  const cardStyle = selected ? { boxShadow: `inset 0 0 0 2px ${token.colorPrimary}` } : {}
  const titleStyle = selected ? { margin: 0, color: token.colorPrimary } : { margin: 0 }

  if (appSumoDiscount)
  console.log(price * (quantity || 1), (appSumoDiscount / 100))

  return (
    <Card className="PriceCard" style={cardStyle}>
      <FlexSpace direction="vertical">
        <FlexSpace spacebetween>
          <Title level={4} style={titleStyle}>
            {name} {isTrial && selected && '(trial)'}
          </Title>
          {selected && <FontAwesomeIcon size="lg" icon={faCheck} color={token.colorPrimary} />}
        </FlexSpace>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Text>Available Companies: {quantity || 0}</Text>
        <Text>Active Companies: {companyCount || 0}</Text>
        <Text>Price per company: {price ? toCurrency(price) : 'FREE'}</Text>
        {appSumoDiscount && selected ? (
          <Text>
            Subscription price: 
            <del>{toCurrency(price * (quantity || 1))}</del>
            &nbsp;
            {
              price * (quantity || 1) - (appSumoDiscount / 100) <= 0 
              ? 'FREE' 
              : toCurrency(price * (quantity || 1) - (appSumoDiscount / 100))
            }
          </Text>
          ) : (
          <Text>Subscription price: {price ? toCurrency(price * (quantity || 1)) : 'FREE'}</Text>
        )}
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Text>Bank Reconciliation - {accessLevel === 0 ? 'CSV Upload Only' : 'Full Access'}</Text>
        <Text>Financial Review - {accessLevel < 2 ? 'No Access' : 'Full Access'}</Text>
      </FlexSpace>
    </Card>
  )
}
