
import { IResultMessage, IRule, IRuleResult, RuleMessageActionType } from 'types/compliance.types'
import { stringToDayjs, toSafeDateString } from 'utils/dates'
import { toCurrency } from 'utils/numbers'
import { queryQboTransactions } from './apiutils'
import { IAccount } from 'types/company.types'

interface IReconcileRule {
  balances: any[]
  rule: IRule
  defaultRuleResult: IRuleResult
  accounts: any[]
  applicationId: number
  startDate: string
  endDate: string
  defaultCbText?: string
  accountingMethod: string
  cb?: (v: string) => void
  idx: number
  rulesLenght: number
  idxAdd: () => void
}

export const reconcileRule = async (sourceData: IReconcileRule): Promise<IRuleResult> => {
  const { balances, accounts } = sourceData
  const { applicationId, startDate, endDate } = sourceData
  const { defaultRuleResult, accountingMethod } = sourceData

  // console.time('reconcileRule')

  const result = { ...defaultRuleResult }

  const bankAndCC = accounts
    .filter(v => v.accountType === 'Credit Card' || v.accountType === 'Bank')
    .filter(v => v.active)

  if (!bankAndCC.length) {
    result.status = 'Passed'
    result.results = [{
      message: 'You do not have any bank or credit card accounts',
      status: 'Passed',
      meta: { reconcile: {} }
    }]

    return result
  }

  const mapList = await Promise.all(
    bankAndCC.map(v => getTxList(applicationId, v.qboId, startDate, endDate, accountingMethod))
  )

  const failedMessages: IResultMessage[] = []
  const passedMessages: IResultMessage[] = []
  const warningMessages: IResultMessage[] = []

  const action = {
    text: 'Accurate',
    type: RuleMessageActionType.Force
  }

  for (let account of bankAndCC) {
    const meta = prepareMeta(endDate, account, balances, mapList[bankAndCC.indexOf(account)])
    // console.log(account.qboId, meta)
    
    if (!meta.reconciledThrough) {
      const status = 'Failed'
      const message = getFailedMessage(account, meta)
      failedMessages.push({ status, meta, message, action })
    } else if (!meta.isSameOrAfter || !!meta.uncleared) {
      const status = 'Failed'
      const message = getFailedMessage2(account, meta)
      failedMessages.push({ status, meta, message, action })
    } else if (!!account.warning) {
      const status = 'Failed'
      const message = getWarningMessage(account, meta)
      failedMessages.push({ status, meta, message, action })
    } else {
      const status = 'Passed'
      const message = getPassedMessage(account, meta)
      passedMessages.push({ status, meta, message })
    }
  }

  if (passedMessages.length && !failedMessages.length && !warningMessages.length) {
    result.status = 'Passed'
    result.results = passedMessages
  } else {
    result.status = 'Failed'
    result.results = [...failedMessages, ...passedMessages, ...warningMessages]
  }

  // console.timeEnd('reconcileRule')

  return result
}

// Messages 

function getFailedMessage(account: IAccount, meta: any) {
  const { balance } = meta

  const accountMsg = `<strong>${account.name}</strong> Account`
  const endingBalance = balance ? toCurrency(balance) : '$0.00'
  const endingBalanceMsg = `Ending&nbsp;balance:&nbsp;${endingBalance}.`

  return `${accountMsg} has never been reconciled. ${endingBalanceMsg}` 
}

function getFailedMessage2(account: IAccount, meta: any) {
  const { balance, uncleared, reconciledThrough, isSameOrAfter } = meta

  const accountMsg = `<strong>${account.name}</strong> Account`
  const dateMsg = !!isSameOrAfter
    ? ` is properly reconciled through`
    : ` is only reconciled through`
  const reconciledThroughMsg = ` <strong>${reconciledThrough}</strong>`
  const uncldMsg = !uncleared
    ? `. No uncleared transactions. `
    : !isSameOrAfter
      ? ` and you also have <b>${uncleared}</b> uncleared transactions that need to be reviewed. `
      : `, however, there are <b>${uncleared}</b> uncleared transactions that need to be reviewed. `
  const endingBalance = balance ? toCurrency(balance) : '$0.00'
  const endingBalanceMsg = `Ending&nbsp;balance:&nbsp;${endingBalance}.`

  return accountMsg + dateMsg + reconciledThroughMsg + uncldMsg + endingBalanceMsg
}

function getWarningMessage(account: IAccount, meta: any) {
  const accountMsg = `<strong>${account.name}</strong> Account`
  const defaultWarningMsg = ` warning: Since this account was last reconciled, `
    + `changes were made to the account register. Please reconcile this `
    + `account again, and then re-run the compliance test.`
  
  return  `${accountMsg} ${defaultWarningMsg}`
}

function getPassedMessage(account: IAccount, meta: any) {
  const { reconciledThrough, uncleared, balance } = meta

  const accountMsg = `<strong>${account.name}</strong> Account`
  const reconciledMsg = ` is reconciled through <strong>${reconciledThrough}</strong>`
  const unclearedMsg = uncleared
    ? `with <strong>${uncleared}</strong> uncleared transactions. `
    : `. No uncleared transactions. `
  const endingBalance = balance ? toCurrency(balance) : `$0.00`
  const endingBalanceMsg = `Ending&nbsp;balance:&nbsp;${endingBalance}.`
  
  return accountMsg + reconciledMsg + unclearedMsg + endingBalanceMsg
}

// Utils

function prepareMeta(endDate: string, account: IAccount, balances: any[], mapList: []) {
  const balance = balances.find(v => v.id === parseInt(account.qboId))?.balance || 0

  const reconciledThrough = account.reconciled_through?.split('T')[0] || ''
  const reconciledThroughDate = reconciledThrough ? stringToDayjs(reconciledThrough) : null

  const uncleared: number = mapList.length || 0

  const toDate = stringToDayjs(endDate)

  const isSameOrAfter = account.reconciled_through 
    ? reconciledThroughDate?.isSameOrAfter(toDate)
    : false

  return {
    txs: [{
      type: account.name,
      list: mapList.map((v: any) => ({...v, acc: account})),
    }],
    account,
    balance,
    uncleared,
    isSameOrAfter,
    reconciledThrough,
    endDate,
    isReconcile: true
  }
}

async function getTxList(appId: number, accId: string, from: string, to: string, method: string) {
  const { mapList } = await queryQboTransactions({
    applicationId: appId,
    from: toSafeDateString(from),
    to: toSafeDateString(to),
    accountQboId: accId,
    unreconciled: false,
    uncleared: true,
    includePrevUncleared: true,
    accountingMethod: method
  })

  return mapList as []
}