import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { message } from 'antd'

import { Btn } from './Btn'

import { api } from 'utils/axios'
import { currentCompany } from 'recoil/companies.state'

interface Props {
  title?: string | JSX.Element
  type?: 'primary' | 'link' | 'text' | 'default'
  height?: number
  companyId?: number
  onChange?: (token: string) => void
}

export const ShopifyConnect = (props: Props) => {
  const { title, height, type, companyId: cId, onChange } = props

  const company = useRecoilValue(currentCompany)
  const companyId = cId || company?.id

  const [loading, setLoading] = useState(false)

  const onConnect = async () => {
    let i = 0
    localStorage.setItem('eq_message', '')

    setLoading(true)
    try {
      const appUrl = window.location.origin + '/redirect/shopify'
      const redirectUrl = await getRedirectUrl('SHOPIFY', appUrl)
      const newWindow = window.open(redirectUrl, 'Shopify Connect','width=760,height=640');
      const interval = setInterval(() => {
        i++
        const eq_message = localStorage.getItem('eq_message')
        if (eq_message) {
          onFinish(eq_message)
          clearInterval(interval)
        }
        if (newWindow?.closed || i > 300) {
          clearInterval(interval)
        }
      }, 1000)  
    } catch (error: any) {
      message.error(error?.message || 'Error', 5)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = (eq_message: string) => {
    const data = JSON.parse(eq_message || "{}")
    if (data?.type === 'SHOPIFY_ADDED') {
      createIntergation(companyId as number, data.token)
        .then(() => {
          message.info('Shopify connected!')
          !!onChange && onChange(data.token)
        })
        .catch((err) => message.error(err?.message || 'Error', 5))
    } else if (data?.type === 'SHOPIFY_DENIED') {
      message.error(`Error: ${data?.msg || 'Access denied.'}`, 5)
    }
  }

  return <Btn type={type || 'default'} children={title || 'Connect'} disabled={loading} onClick={onConnect} style={{ height }} />
}

function getRedirectUrl (platform: string, redirectUrl: string) {
  return api.post('/integrations/link', { platform, provider: 'Rutter' })
    .then(r => r.data)
    .then(r => r.link_url + '?redirect_url=' + redirectUrl)
}

function createIntergation(companyId: number, token: string) {
  return api.post('/integrations', { token, companyId, provider: 'Rutter' })
    .then(r => r.data)
}

