import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Alert, Card, Form, Input, Layout, message, Typography } from 'antd'

import { Btn } from 'components/Btn'
import { Logo } from 'components/Logo'
import { Header } from 'components/Header'
import { FlexSpace } from 'components/FlexSpace'

import { rules } from './auth.utils'
import { api } from 'utils/axios'

export const NewPass = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const code = query.get('oobCode') || '' 

  const handleSubmit = (data: any) => {
    setLoading(true)
    setError('')
    const { password } = data
    api.post('/auth/reset-password-custom', { password, code })
      .then(() => {
        message.success('Password successfuly changed')
        navigate('/')
      })
      .catch(err => setError(err?.response?.data?.message || err?.message || 'Error'))
      .finally(() => setLoading(false))
  }

  return (
    <Layout className="AuthLayout">
      <Layout.Content className="AuthLayoutContent NoSelect">
        <FlexSpace direction="vertical" size="large">
          <LogoHeader />
          <Card className="AuthCard" bordered={false}>
            <FlexSpace direction="vertical" className="signin-card-content">
              <Header size={46} style={{ marginBottom: 24 }}>
                Set new password
              </Header>
              <Form layout="vertical" size="large" requiredMark={false} onFinish={handleSubmit}>
                <Form.Item name="password" label="Enter your new password" rules={rules.password}>
                  <Input.Password placeholder="password" autoComplete="new-password" />
                </Form.Item>
                <Form.Item>
                  <FlexSpace className="AuthCardFooter" spacebetween>
                    <Btn title="Submit" type="primary" loading={loading} htmlType="submit" width={464} />
                  </FlexSpace>
                </Form.Item>
              </Form>
              {error && <Alert message="Error" description={error} type="error" showIcon />}
              <FlexSpace direction="horizontal" spacebetween>
                <FlexSpace direction="horizontal" className="AuthFooterLink">
                  <Typography.Text>Back to</Typography.Text>
                  <Link to="/signin">Sign In</Link>
                </FlexSpace>
              </FlexSpace>
            </FlexSpace>
          </Card>
        </FlexSpace>
      </Layout.Content>
    </Layout>
  )
}

const LogoHeader = () => (
  <div className="AuthTitleContainer">
    <Logo width={187} />
  </div>
)
