import { Select, Spin, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'

import { IAccount } from 'types/company.types'
import { accountName } from 'utils/accountName'

type Matched = { [key: string]: string[] }

interface IAccType {
  id: string
  name: string
  isCalc: boolean
}

interface Props {
  value: Matched
  onChange: (value: Matched) => void
  baseAccs: IAccType[]
  accounts: IAccount[]
}

export const MatchAccounts = (props: Props) => {
  const { value, onChange } = props
  const { baseAccs, accounts } = props

  const selected = Object.values(value).flat()
  console.log('selected', selected)

  const accList: Acc[] = accounts
    .map((v) => ({
      name: accountName(v.name, v.number),
      value: v.qboId,
      label: (
        <FlexSpace spacebetween>
          <span>{accountName(v.name, v.number)}</span>
          <small><i style={{ paddingRight: 10, opacity: 0.5 }}>{v.accountType}</i></small>
        </FlexSpace>
      ),
      disabled: selected.includes(v.qboId),
    }))

  return (
    <Spin spinning={!accounts.length}>
      <FlexSpace direction="vertical" size="large">
      <FlexSpace direction="vertical">
        {baseAccs
          .filter((v) => !v.isCalc)
          .map((accType) => (
            <FlexSpace key={accType.id}>
              <Typography.Text style={labelStyle}>{accType.name}</Typography.Text>
              <Select
                className="SelectPrimary"
                style={selectStyle}
                placeholder="Account"
                options={accList}
                filterOption={(input, option) =>
                  (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                }
                allowClear
                showSearch
                mode="multiple"
                maxTagCount="responsive"
                value={value[accType.id]}
                onChange={(v) => onChange({ ...value, [accType.id]: v })}
              />
            </FlexSpace>
          ))}
      </FlexSpace>
        <Typography.Text type="secondary" style={{ paddingTop: 60 }}>
          * Please note that you can only use a general ledger account in one of the Shopify fields (ie, Gross Sales, Discounts, etc)
        </Typography.Text>
      </FlexSpace>
    </Spin>
  )
}


const labelStyle: React.CSSProperties = {
  width: 200,
  display: 'block',
}

const selectStyle: React.CSSProperties = {
  width: 500,
}

interface Acc {
  name: string
  label: JSX.Element
  value: string
}