import { useRecoilValue } from 'recoil'

import { currentUser } from 'recoil/user.state'
import { currentCompany } from 'recoil/companies.state'

export function useRestriction(minLevel = 0) {
  const user = useRecoilValue(currentUser)
  const company = useRecoilValue(currentCompany)

  const isOwner = company?.role.id === 0
  const accessLevel = isOwner 
    ? user?.subscription?.accessLevel || 0
    : company?.accessLevel || 0

  // console.log(accessLevel, minLevel)

  const restrictionText = isOwner
    ? 'To use this feature, please upgrade your subscription.'
    : 'To use this feature, please ask your company owner to upgrade your subscription.'

  return minLevel <= accessLevel ? '' : restrictionText
}

export function useBeta() {
  const user = useRecoilValue(currentUser)

  return !!user?.beta
}
