import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { Divider, Form, Input, Typography } from 'antd'
import { Alert, Card, Col, Layout, Row, message } from 'antd'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { Btn } from 'components/Btn'
import { Header } from 'components/Header'
import { FlexSpace } from 'components/FlexSpace'
import { GLogin } from 'components/GLogin'
import { Block } from 'components/Block'
import { HeadTitle } from 'components/HeadTitle'
import { SideInfo } from './SideInfo'

import { rules } from './auth.utils'
import { api } from 'utils/axios'
import { config } from 'utils/config'
import { analytics } from 'utils/analytics'
import { authAtom } from 'recoil/auth.state'
import { IAuthCredentials, ISigninData } from 'types/auth.types'

import './style.css'

export const SignIn: ReactFC = () => {
  const [auth, setAuth] = useRecoilState(authAtom)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const isPlugin = (search.get('plugin') || '')
  const returnUrl = (search.get('returnUrl') || '').split('?')

  const { from } = (location.state as any) 
    || returnUrl 
      ? { from: { pathname: returnUrl[0], search: returnUrl[1] } } 
      : { from: { pathname: '/' } }

  // console.log(search, isPlugin)

  useEffect(() => {
    if (isPlugin && auth) 
      message.success('You are now signed in to the plugin', 5)
  }, [isPlugin, auth])

  const handleSubmit = (data: ISigninData) => {
    setLoading(true)
    api.post<IAuthCredentials>('auth/signin', data)
      .then(r => r.data)
      .then(r => {
        setAuth(r)
        analytics.event.signIn()
      })
      .catch(err => setError(err?.response?.data?.message || err?.message || 'Error'))
      .finally(() => setLoading(false))
  }
  
  const handleGoogle = (token: string) => {
    setLoading(true)
    api.post<IAuthCredentials>('auth/google-signin', { token, plugin: false, code: token })
      .then(r => r.data)
      .then(r => {
        setAuth(r)
        analytics.event.signInGoogle()
      })
      .catch(err => setError(err?.response?.data?.message || err?.message || 'Error'))
      .finally(() => setLoading(false))
  }

  if (auth) return <Navigate to={from.pathname || '/'} replace />

  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <HeadTitle title="Sign In" />
      <Layout className="AuthLayout">
        <Layout.Content className="AuthLayoutContent NoSelect">
          <Row>
            <Col span={12}>
              <SideInfo />
            </Col>
            <Col span={12}>
              <FlexSpace direction="vertical" size="large">
                <Card className="AuthCard" bordered={false}>
                  <FlexSpace direction="vertical" className="signin-card-content">
                    <Header size={46} style={{ marginBottom: 24 }}>Sign In</Header>
                    <GLogin onSuccess={handleGoogle} loading={loading} onError={setError}  />
                    <Divider><small>or with your email and below</small></Divider>
                    <Form layout="vertical" size="large" requiredMark={false} onFinish={handleSubmit}>
                      <Form.Item name="email" label="Email" rules={rules.email}>
                        <Input placeholder="example@mail.com" autoFocus autoComplete="new-email" />
                      </Form.Item>
                      <Form.Item name="password" label={<PasswordLabel />} rules={rules.password}>
                        <Input.Password placeholder="password" autoComplete="new-password" />
                      </Form.Item> 
                      <Form.Item>
                        <Block className="AuthCardFooter">
                          <Btn title="Sign In" type="primary" loading={loading} htmlType="submit" block />
                        </Block>
                      </Form.Item>
                    </Form>
                    {error && <Alert message="Error" description={error} type="error" showIcon />}
                    <FlexSpace direction="horizontal" className="AuthFooterLink">
                      <Typography.Text>New customer?</Typography.Text>
                      <Link to="/signup">Start here</Link>
                    </FlexSpace>
                  </FlexSpace>
                </Card>
              </FlexSpace>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </GoogleOAuthProvider>
  )
}

const PasswordLabel = () => (
  <FlexSpace spacebetween className="AuthLabel">
    <span className="Label">Password</span>
    <Link to="/reset-password">Forgot my password</Link>
  </FlexSpace>
)
