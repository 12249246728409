// import { useState } from 'react'
// import { useRecoilValue, useSetRecoilState } from 'recoil'
// import { Divider, Spin, Typography, message } from 'antd'
// import { useFinchConnect } from '@tryfinch/react-connect'

// import { Btn } from 'components/Btn'
// import { FlexSpace } from 'components/FlexSpace'
// import { Page } from 'components/Page'
// import { RangePicker } from 'components/RangePicker'

// import { api } from 'utils/axios'
// import { companiesAtom, currentAccounts, currentCompany } from 'recoil/companies.state'
// import { toCurrency } from 'utils/numbers'
// import { ICompany } from 'types/company.types'
// import { errorMsg } from 'utils/errorMsg'
// import { stringToDayjs } from 'utils/dates'

// const defaultFrom = '2023-09-01'
// const defaultTo = '2023-11-30'
// const defaultDateRange = [stringToDayjs(defaultFrom), stringToDayjs(defaultTo)]

export const FinchPage = () => {
  // const company = useRecoilValue(currentCompany)
  // const accounts = useRecoilValue(currentAccounts)
  // const setCompanies = useSetRecoilState(companiesAtom)

  // const initialMatched = company?.settings?.payroll?.matched ?? {}
  // console.log('initialMatched', initialMatched)
  // console.log('company', company)

  // const [code, setCode] = useState('')
  // const [loading, setLoading] = useState(false)
  // const [matched, setMatched] = useState<{[key: string]: string[]}>(initialMatched)
  // const [dateRange, setDateRange] = useState<any>(defaultDateRange)
  // const [payroll, setPayroll] = useState<FinchReport>()
  // // const [accounting, setAccounting] = useState<QboReport>()

  // const integration = company?.integrations?.find(v => v.name === 'Finch')
  // const isConnected = !!integration?.status

  // const refreshCompanies = () => {
  //   return api
  //     .get<ICompany[]>('applications')
  //     .then(({ data }) => setCompanies(data))
  //     .catch(console.log)
  // }

  // const onSuccess = ({ code }: any) => {
  //   setLoading(true)
    
  //   if (!code) return console.error('No code')
  //   if (!company) return console.error('No company')

  //   console.log('CODE', code, company.id)
  //   setCode(code)
  //   api.post('/integrations', { token: code, companyId: company.id, provider: 'Finch' })
  //     .then(() => refreshCompanies())
  //     .catch(err => message.error(errorMsg(err), 5))
  //     .finally(() => setLoading(false))
  // }

  // const onError = ({ errorMessage }: any) => console.error(errorMessage);
  // const onClose = () => console.log('User exited Finch Connect');

  // const { open } = useFinchConnect({
  //   clientId: '8562796a-a3e4-4ac7-87c6-36f6d937e519', // PROD
  //   products: ['company', 'directory', 'payment', 'individual'],
  //   sandbox: false, // PROD
  //   onSuccess,
  //   onError,
  //   onClose,
  // })

  // console.log('code', code)
  // console.log('matched', matched) 

  // const onSubmit = () => {
    // const from = dateRange[0].format('YYYY-MM-DD')
    // const to = dateRange[1].format('YYYY-MM-DD')

    // setLoading(true)
    // getPayrollReport(company?.id, from, to)
    //   .then(setPayroll)
    //   .finally(() => setLoading(false))
    // getQboReport(Object.values(matched), company?.id, from, to)
    //   .then(setAccounting)

    // const settings = {
    //   payroll: {
    //     matched
    //   }
    // }
    // saveSettings(company?.id, settings)
  // }

  // const getAccName = (id: string) => {
  //   const acc = accounts.find(v => v.qboId === id)
  //   return acc?.name ?? ''
  // }

  // return (
  //   <Page title="Finch">
  //     <FlexSpace spacebetween>
  //       <Typography.Title level={3}>Finch </Typography.Title>
  //       <Typography>Status: {isConnected ? 'Connected' : 'Disconnected'}</Typography>
  //       <Btn type="primary" onClick={() => open()} loading={loading}>
  //         {isConnected ? 'Reconnect': 'Connect'}
  //       </Btn>
  //     </FlexSpace>
  //     <Divider />
  //     <FlexSpace direction="vertical">
  //       {/* <AccountSelector
  //         title="Setup accounts"
  //         // value={matched}
  //         options={qboAccounts.map(v => ({ label: v.name, value: v.id }))} 
  //         // onChange={setMatched}
  //       /> */}
  //     </FlexSpace>
  //     <Divider />
  //     <FlexSpace spacebetween>
  //       <Typography.Title level={4} style={{ margin: 0 }}>Results</Typography.Title>
  //       <RangePicker value={dateRange} onChange={setDateRange} />
  //       <Btn type="primary" onClick={onSubmit} title="Run" loading={loading} width={100} />
  //     </FlexSpace>
  //     <Divider />
  //     <FlexSpace direction="vertical">
  //       <Spin spinning={loading}>
  //       <FlexSpace>
  //         <Typography.Text style={{ width: 200, display: 'block' }}>
  //           <b>Finch Data</b>
  //         </Typography.Text>
  //         <Typography.Text style={{ width: 200, display: 'block' }}>
  //           <b>Finch Value</b>
  //         </Typography.Text>
  //         <Typography.Text style={{ width: 400, display: 'block' }}>
  //           <b>QBO Account</b>
  //         </Typography.Text>
  //         <Typography.Text style={{ width: 200, display: 'block' }}>
  //           <b>Account Total</b>
  //         </Typography.Text>
  //         <Typography.Text style={{ width: 200, display: 'block' }}>
  //           <b>Diff</b>
  //         </Typography.Text>
  //       </FlexSpace>
  //       {Object.entries(matched).map(([key, value]) => (
  //         <FlexSpace key={key}>
  //           <Typography.Text style={{ width: 200, display: 'block' }}>
  //             <b>{key}</b>
  //           </Typography.Text>
  //           <Typography.Text style={{ width: 200, display: 'block' }}>
  //             <b>{toCurrency((payroll as any)?.[key] || 0)}</b>
  //           </Typography.Text>
  //           <Typography.Text style={{ width: 400, display: 'block' }}>
  //             {/* <b>{getAccName(value)}</b> */}
  //           </Typography.Text>
  //           <Typography.Text style={{ width: 200, display: 'block' }}>
  //             {/* <b>{toCurrency(accounting?.[value] || 0)}</b> */}
  //           </Typography.Text>
  //           <Typography.Text style={{ width: 200, display: 'block' }}>
  //             {/* <b>{toCurrency(((payroll as any)?.[key] || 0) - ((accounting as any)?.[value] || 0))}</b> */}
  //           </Typography.Text>
  //         </FlexSpace>
  //       ))}
  //       <FlexSpace>
  //         <pre>{JSON.stringify(payroll, null, 2)}</pre>
  //       </FlexSpace>
  //       </Spin>
  //     </FlexSpace>
  //   </Page>
  // )

  return null
}


// function getPayrollReport(companyId=106, from='2023-09-01', to='2023-11-30') {
//   return api.get<FinchReport>(`/finreview/payroll` , { params: { companyId, from, to } })
//     .then(res => res.data)
// }

// function getQboReport(accounts: string[], companyId=106, from='2023-09-01', to='2023-11-30') {
//   return api.get<QboReport>(`/finreview/accounting/totals` , { params: { accounts, companyId, from, to } })
//     .then(res => res.data)
// }

// function saveSettings(companyId=106, settings: Object) {
//   return api.post(`/applications/${companyId}/settings`, { settings })
//     .then(res => res.data)
// }

// interface IAccType {
//   id: string
//   name: string
//   isCalc: boolean
// }

// const qboAccounts: IAccType[] = [
//   {id: 'company_debit', name: 'Company debit', isCalc: false},
//   {id: 'gross_pay', name: 'Gross pay', isCalc: false},
//   {id: 'net_pay', name: 'Net pay', isCalc: false},
//   {id: 'employee_taxes', name: 'Employee taxes', isCalc: false},
//   {id: 'employer_taxes', name: 'Employer taxes', isCalc: false},
//   {id: 'payroll_fees', name: 'Payroll Fees', isCalc: false},
// ]

// interface QboReport {
//   [id: string]: number
// }

// interface FinchReport {
//   company_debit: number
//   gross_pay: number
//   net_pay: number
//   employee_taxes: number
//   employer_taxes: number
// }