import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Divider, Form, Input, Modal, Select, SelectProps, Steps, message } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { Block } from 'components/Block'

import { api } from 'utils/axios'
import { currentCompany } from 'recoil/companies.state'
import { IUser } from 'types/user.types'

const { TextArea } = Input

interface Props {
  id?: number
  open: boolean
  data: {
    from: string
    to: string
  }
  setOpen: (value: boolean) => void
}

export const ShareModal: ReactFC<Props> = (props) => {
  const { id, open, data, setOpen } = props

  const company = useRecoilValue(currentCompany)
  const [messageApi, contextHolder] = message.useMessage()
  const [users, setUsers] = useState<IUser[]>([])
  const [submittable, setSubmittable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [toEmail, setToEmail] = useState('')


  const [form] = Form.useForm<{user: string, email: string, message: string}>()
  const values = Form.useWatch([], form)

  const options: SelectProps['options'] = users.map((v) => (
    { key: v.id, value: v.email, label: v.email })
  )

  const items = [ { title: 'Recipient' }, { title: 'Message' } ]

  useEffect(() => {
    getUsers()
    form.resetFields()
    setStep(1)
    setToEmail('')
  }, [open])

  useEffect(() => {
    form.validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false))
  }, [values])

  const getUsers = () => {
    api.get<IUser[]>(`/applications/${company?.id}/members`)
      .then(({ data }) => setUsers(data))
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleNext = () => {
    const { user, email } = form.getFieldsValue()
    const user_ = users.find((v) => v.email === user)
    const username = !!user_ 
      ? `${user_.firstName} ${user_.lastName}`
      : user || email

    const initialMessage = `Hi ${username}, here is your financial assessment report for ${company?.name} for ${data.from} to ${data.to}.`
    form.setFieldsValue({ message: initialMessage })
    setStep(2)
    setToEmail(user || email)
  }

  const handleOk = () => {
    setLoading(true)
    const { message } = form.getFieldsValue()
    api.post(`/application_rules/${id}/share/`, { email: toEmail, message })
      .then(() => {
        setLoading(false)
        setOpen(false)
        messageApi.success('Report sent')
      })
      .catch(() => {
        setLoading(false)
        messageApi.error('Failed to send report')
      })
  }

  const email = form.getFieldValue('email')
  const user = form.getFieldValue('user')

  const modalProps = {
    open,
    okText: step === 1 ? 'Next' : 'Send Report',
    okButtonProps: { disabled: !submittable || (!user && !email), loading },
    onCancel: handleCancel,
    onOk: step === 1 ?  handleNext : handleOk,
    width: '70%'
  }

  return (
    <Modal {...modalProps}>
      {contextHolder}
      <Form layout={'vertical'} form={form} style={{ paddingTop: 40 }}>
        <Steps current={step} items={items} />
        {step === 1 && (
          <Block style={{ marginTop: 20 }}>
            <FlexSpace direction="vertical">
              <Form.Item name="user" label="Select team member">
                <Select
                  className="CSelect"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  options={options}
                  disabled={!!email || loading}
                />
              </Form.Item>
            </FlexSpace>
            <Divider>or</Divider>
            <FlexSpace direction="vertical">
              <Form.Item name="email" rules={emailRules} label="Enter recipient's email address">
                <Input
                  placeholder="example@mail.com" 
                  allowClear 
                  className="CInput"
                  disabled={!!user || loading}
                />
              </Form.Item>
            </FlexSpace>
          </Block>
        )}
        {step === 2 && (
          <Block style={{ marginTop: 20 }}>
            <FlexSpace direction="vertical">
              <Form.Item name="message" label="Message">
                <TextArea
                  rows={10}
                  placeholder="Enter message or leave blank to use default message"
                  className="CTextArea"
                  style={{ resize: 'none', height: 'auto !important', minHeight: 200 }}
                />
              </Form.Item>
            </FlexSpace>
          </Block>
         )}
      </Form>
    </Modal>
  )
}

var emailRules: any = [
  { message: 'Please input your email' },
  { type: 'email', message: 'Invalid email' }
]