
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Result } from 'antd'

import { Btn } from 'components/Btn'
import { Logo } from 'components/Logo'

import { authAtom } from 'recoil/auth.state'
import { companiesAtom } from 'recoil/companies.state'
import { Suspense } from 'react'
import { FullLoader } from 'components/FullLoader'

export const QboReconcile = () => {
  return (
    <Suspense fallback={<FullLoader />}>
      <ReconcileRedirect />
    </Suspense>
  )
}

export const ReconcileRedirect = () => {
  const location = useLocation()

  const [searchParams] = useSearchParams()
  const search = Object.fromEntries(searchParams) as any

  const [auth] = useRecoilState(authAtom)
  const companies = useRecoilValue(companiesAtom)

  const signInUrl = `/signin?returnUrl=${location.pathname + location.search}`
  if (!auth) {
    localStorage.setItem('qbo_reconcile_redirect', JSON.stringify(search))
    return <Navigate to={signInUrl} />
  }

  const data = localStorage.getItem('qbo_reconcile_redirect')
  const redirectData = data ? JSON.parse(data) : search
  const company = companies.find((v) => v.qbo_id === redirectData.applicationId)

  console.log(data, redirectData, company)

  if (!company) return <NoCompany />

  const path = createUrl('/company/' + company.id + '/reconcile/run', redirectData)
  localStorage.removeItem('qbo_reconcile_redirect')

  return <Navigate to={path} />
}

const NoCompany = () => {
  return (
    <Result
      icon={<Logo width={300} />}
      title="You don't have access to the company"
      subTitle="Please add the company to your account."
      extra={
        <Btn 
          type="primary" 
          href="/" 
          size="large" 
          style={{ paddingTop: 10 }} 
          title="Go to dashboard"
        />
      }
    />
  )
}

export function createUrl(baseURL: string, data: any = {}) {
  const search = new URLSearchParams(data)
  return baseURL + '?' + search.toString()
}
