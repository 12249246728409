import { IRule, IRuleResult, IResultMessage, payrollAccountMapping, RuleMessageActionType } from "types/compliance.types"
import { config } from "utils/config"


const qboHost = config.qboHost

export const payrollRule = (
  rule: IRule,
  defaultRuleResult: IRuleResult,
  payrollAccounts = [] as any[],
  startDate: string,
  endDate: string,
  includePayroll: boolean
): IRuleResult => {
  if (includePayroll) {
    let hasPayroll = payrollAccounts?.some((account) => !!account?.transactions?.length)
    if (hasPayroll) {
      defaultRuleResult.status = 'Passed'
      defaultRuleResult.results = [
        {
          message: 'There are no payroll transactions to be reviewed',
          status: 'Passed'
        }
      ]
    } else {
      defaultRuleResult.status = 'Failed'
      defaultRuleResult.results = [
        {
          message: rule.failureMsg,
          status: 'Failed'
        }
      ]
    }
  } else {
    const resultMessages: IResultMessage[] = []
    payrollAccounts?.forEach((account) => {
      if (!!account?.transactions?.length)
        resultMessages.push({
          status: 'Warning',
          message: `Number of ${(payrollAccountMapping as any)[account.accountSubType]} Transactions: ${
            account.transactions.length
          }`,
          action: {
            type: RuleMessageActionType.URL,
            text: 'View Report',
            url: `${qboHost}/app/reportv2?token=ACCTL_QUICKREPORT&show_logo=false&date_macro=custom&low_date=${startDate}&high_date=${endDate}&customized=yes&groupby=(Account/AccountTypeID,Account/OrderName&account=${account.qboId}`
          }
        })
    })
    if (!resultMessages.length) {
      defaultRuleResult.status = 'Passed'
      defaultRuleResult.results = [
        {
          message: 'There are no payroll transactions to be reviewed',
          status: 'Passed'
        }
      ]
    } else {
      defaultRuleResult.status = 'Failed'
      defaultRuleResult.results = [{ status: 'Failed', message: rule.failureMsg }, ...resultMessages]
    }
  }
  return defaultRuleResult
}
