import { atom, AtomEffect, selector } from 'recoil'

import { api } from 'utils/axios'
import { storage } from 'utils/storage'
import { IAccount, ICompany, IQboCustomer, IQboVendor } from 'types/company.types'

import { authAtom } from './auth.state'
import { IReconcile } from 'types/reconcile.types'
import { ICompliance, IRule } from 'types/compliance.types'

const companiesList = selector<ICompany[]>({
  key: 'companies',
  get: async ({ get }) => {
    const auth = get(authAtom)
    if (!auth) return []

    try {
      const { data } = await api.get<ICompany[]>('applications')
      return data
    } catch (error) {
      // return []
      throw error
    }
  }
})

const companiesAtom = atom<ICompany[]>({
  key: 'companiesAtom',
  default: companiesList,
})

const localStorageEffect: AtomEffect<number> = ({ onSet }) => {
  onSet((newValue) => {
    storage.setItem('companyId', newValue)
  })
}

const companyAtom = atom<any>({
  key: 'companyId',
  default: storage.getItem('companyId'),
  effects: [localStorageEffect]
})

const currentCompany = selector<ICompany | null>({
  key: 'company',
  get: ({ get }) => {
    const id = get(companyAtom)
    const list = get(companiesAtom)

    return list.find((v) => v.id === id) || null
  }
})

const currentAccounts = selector({
  key: 'accounts',
  get: async ({ get }) => {
    const id = get(companyAtom)
    try {
      const { data } = await api.get<IAccount[]>(`/integrations/qbo/accounts?application=${id}`)
      // const { data } = await api.get<IAccount[]>(`/applications/${id}/accounts`)
      return data
    } catch (error) {
      throw error
    }
  }
})

const currentReconciliations = selector({
  key: 'reconciliations',
  get: async ({ get }) => {
    const id = get(companyAtom)
    try {
      const { data } = await api.get<IReconcile[]>(`/reconciliation/all?application=${id}`)
      return data
    } catch (error) {
      // throw error
      return []
    }
  }
})

const currentCompliances = selector({
  key: 'currentCompliances',
  get: async ({ get }) => {
    const id = get(companyAtom)
    try {
      const { data } = await api.get<ICompliance[]>(`/application_rules/results/${id}`)
      return data
    } catch (error) {
      return []
    }
  }
})

const currentCompanyRules = selector({
  key: 'rules',
  get: async ({ get }) => {
    const id = get(companyAtom)
    try {
      const { data } = await api.get<IRule[]>(`/application_rules/${id}`)
      return data
    } catch (error) {
      return []
    }
  }
})

const companyRulesAtom = atom<IRule[]>({
  key: 'companyRulesAtom',
  default: currentCompanyRules,
})

const currentVendors = selector({
  key: 'currentVendors',
  get: async ({ get }) => {
    const id = get(companyAtom)
    try {
      const params = { application: id, search: '' }
      const { data } = await api.get(`/integrations/qbo/vendors`, { params })
      return data?.QueryResponse?.Vendor as IQboCustomer[] || [] as IQboCustomer[]
    } catch (error) {
      return []
    }
  }
})

const currentCustomers = selector({
  key: 'currentCustomers',
  get: async ({ get }) => {
    const id = get(companyAtom)
    try {
      const params = { application: id, search: '' }
      const { data } = await api.get(`/integrations/qbo/customers`, { params })
      return data?.QueryResponse?.Customer as IQboVendor[] || [] as IQboVendor[]
    } catch (error) {
      return []
    }
  }
})

export {
  companiesAtom,
  companiesList,
  currentCompany,
  currentAccounts,
  currentCompliances,
  currentReconciliations,
  companyRulesAtom,
  currentCompanyRules,
  currentVendors,
  currentCustomers,
  companyAtom
}
