import { Modal, Typography } from 'antd'

import { Btn } from 'components/Btn'

interface Props {
  visible: boolean
  onClose: (value?: string) => void
}

export const MinorServicesModal2: ReactFC<Props> = (props) => {
  const {visible, onClose} = props

  const handleClick = (v: 'yes' | 'no') => {
    if (v === 'yes') onClose('pass')
    if (v === 'no') onClose('fail')
  }

  const handleClose = () => {
    onClose()
  }

  const footer = [
    <Btn title="No" key="no" danger onClick={() => handleClick('no')} />,
    <Btn title="Yes" key="yes" type="primary" onClick={() => handleClick('yes')} />
  ]

  return (
    <Modal open={visible} footer={footer} onCancel={() => handleClose()} title={'Setup'} width={500}>
      <Typography.Text>
        Are any partners receiving payroll?
      </Typography.Text>
    </Modal>
  )
}
