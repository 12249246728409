import { message } from "antd"
import { FullLoader } from "components/FullLoader"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { api } from "utils/axios"

export const Landing: ReactFC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  if (location.search.includes('mode')) {
    const query = new URLSearchParams(location.search)
    const mode = query.get('mode') || '' 
    const oobCode = query.get('oobCode') || '' 

    switch (mode) {
      case 'resetPassword':
        return <Navigate to={'/reset' + query} replace />
      case 'verifyEmail':
        if (oobCode) {
          api
            .patch('auth/verify-email', { oobCode })
            .then(() => {
              navigate('/')
              message.success('Email verified')
            })
            .catch(() => navigate('/signin'))
        } 
        break
      default:
        break
    }
  }
  
  return <FullLoader />
}