import { useEffect, useState } from 'react'
import { Divider, Radio, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { Btn } from 'components/Btn'

import { ISetupData, getEntities } from '../utils'
import { ICompanyEntity } from 'types/company.types'

const { Text, Title } = Typography

interface StepsProps {
  data: ISetupData
  onSubmit: (data: ISetupData) => void
}

export const CompanyStep = (props: StepsProps) => {
  const { data, onSubmit } = props

  const [value, setValue] = useState(data?.entity?.id || 0)
  const [entities, setEntities] = useState<ICompanyEntity[]>([])

  const onDone = () => {
    const entity = entities.find(e => e.id === value)
    onSubmit({ entity })
  }

  useEffect(() => {
    setValue(data?.entity?.id || 0)
  }, [data.entity])

  useEffect(() => {
    getEntities().then(setEntities)
  }, [])

  return (
    <FlexSpace direction="vertical" size="large">
      <Title level={5}>Let's start with the basic information about your company</Title>
      <Text strong>Please select your company’s legal entity type​:</Text>
      <Radio.Group onChange={e => setValue(e.target.value)} value={value}>
        <FlexSpace direction="vertical" size={'large'}>
          {entities.map((entity) => (
            <Radio key={entity.id} value={entity.id}>{entity.title}</Radio>
          ))}
        </FlexSpace>
      </Radio.Group>
      <Divider />
      <Btn type="primary" title="Continue" onClick={onDone} />
    </FlexSpace>
  )
}
