import { Typography } from 'antd'

import { Logo } from 'components/Logo'
import { Block } from 'components/Block'
import { FlexSpace } from 'components/FlexSpace'

import Icon from 'assets/img/checkmark.png'

export const SideInfo: ReactFC = () => {
  return (
    <Block style={{ width: 400, marginTop: 60 }}>
      <Block style={{ width: 186, marginBottom: 80 }}>
        <LogoHeader />
      </Block>
      <FlexSpace style={{ alignItems: 'center', marginTop: 40 }}>
        <img src={Icon} alt="icon" style={{ marginRight: 8 }} />
        <Typography.Title level={4} style={{ margin: 0 }}>
          Get started quickly
        </Typography.Title>
      </FlexSpace>
      <Typography.Paragraph style={{ marginLeft: 50 }}>
        Integrate with QuickBooks Online to
        <br />
        retrieve financial information seamlessly
      </Typography.Paragraph>
      <FlexSpace style={{ alignItems: 'center', marginTop: 40 }}>
        <img src={Icon} alt="icon" style={{ marginRight: 8 }} />
        <Typography.Title level={4} style={{ margin: 0 }}>
          Automate your financial review
        </Typography.Title>
      </FlexSpace>
      <Typography.Paragraph style={{ marginLeft: 50 }}>
        Avoid manually reconciling your
        <br />
        accounts with our automation features
      </Typography.Paragraph>
      <FlexSpace style={{ alignItems: 'center', marginTop: 40 }}>
        <img src={Icon} alt="icon" style={{ marginRight: 8 }} />
        <Typography.Title level={4} style={{ margin: 0 }}>
          Verify financial accuracy
        </Typography.Title>
      </FlexSpace>
      <Typography.Paragraph style={{ marginLeft: 50 }}>
        Receive an automated, unbiased, and
        <br />
        independent assessment of accounting
        <br />
        accuracy
      </Typography.Paragraph>
    </Block>
  )
}

const LogoHeader = () => (
  <div className="AuthTitleContainer">
    <Logo width={187} />
  </div>
)