import { atom } from 'recoil'

const plaidAtom = atom<IPlaid | null>({
  key: 'plaid',
  default: null,
})

export { plaidAtom }

interface IPlaid {
  request: {
    applicationId: number
    accountId: string
    from: string
    to: string
  }
}