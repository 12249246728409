import { useGoogleLogin } from '@react-oauth/google'
import { Typography } from 'antd'

import { Btn } from './Btn'
import { Block } from './Block'
import { FlexSpace } from './FlexSpace'

import { ReactComponent as Logo } from '../assets/img/google.svg'

interface Props {
  onSuccess: (token: string) => void
  onError: (err: string) => void
  loading?: boolean
  disabled?: boolean
  tooltip?: string
  signup?: boolean
}

export const GLogin: ReactFC<Props> = (props) => {
  const { onSuccess, onError, loading, disabled, tooltip, signup } = props

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => onSuccess(codeResponse.access_token),
    onError: (err) => onError(err?.error_description || 'Error')
  })

  const btnStyle = {
    background: 'rgba(218, 240, 226, 0.5)',
    borderColor: 'rgba(218, 240, 226, 0.5)',
    opacity: disabled || loading ? 0.5 : 1
  }

  const btnProps = {
    block: true,
    tooltip: tooltip || 'Sign in with Google',
    disabled: loading || disabled,
    style: btnStyle
  }

  const title = signup ? 'Sign Up with Google' : 'Sign In with Google'

  return (
    <Btn onClick={() => login()} {...btnProps} size="large">
      <FlexSpace style={{ justifyContent: 'center', height: 25,  }}>
        <Block style={{ width: 25, paddingTop: 7 }}>
          <Logo />
        </Block>
        <Typography.Text strong>
          {title}
        </Typography.Text>
      </FlexSpace>
    </Btn>
  )
}
