import { useEffect, useRef, useState } from 'react'
import { Select, Typography } from 'antd'

import { Btn } from './Btn'
import { Block } from './Block'
import { FlexSpace } from './FlexSpace'

import { config } from 'utils/config'

const { csvbox } = config

interface Props {
  onImport?: (v: boolean, data?: any) => void
  isCC?: boolean
}

export const CSVBtn: ReactFC<Props> = (props) => {
  const { onImport, isCC } = props
  const { useStagingServer } = csvbox

  const uuid = generateUuid()
  const iframe = useRef<HTMLIFrameElement>(null)

  const [modal, setModal] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [selected, setSelected] = useState(options[0].value)

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  useEffect(() => {
    setDisabled(true)
    setTimeout(() => {
      setDisabled(false)
    }, 3000)
  }, [selected])

  useEffect(() => {
    setSelected(isCC ? options[2].value : options[0].value)
  }, [isCC])

  const handleMessage = (ev: MessageEvent<any>) => {
    eventListener(ev, (status: boolean, data?: any) => {
      setModal(false)
      if (status && data) onImport?.(status, data)
    })
  }

  const openModal = () => {
    if (modal || !iframe?.current?.contentWindow) return

    setModal(true)
    iframe.current.contentWindow.postMessage('openModal', '*')
  }

  const handleLoad = (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
    event.currentTarget.contentWindow?.postMessage(
      {
        customer: null,
        columns: null,
        options: null,
        unique_token: uuid
      },
      '*'
    )
  }

  const iframeEnv = useStagingServer ? 'staging' : 'app'
  const iframeSrc = `https://${iframeEnv}.csvbox.io/embed/${selected}?library-version=1.1.5&framework=react`

  return (
    <Block>
      <FlexSpace size="large" direction="vertical">
        <FlexSpace size="large" style={{ flexWrap: 'wrap' }}>
          <Select
            id="CSVBtn_select"
            options={options}
            style={{ width: 400 }}
            value={selected}
            onChange={setSelected}
            loading={disabled}
          />
          <Btn
            type="primary"
            title="Upload file"
            onClick={openModal}
            data-csvbox-initator
            data-csvbox-token={uuid}
            disabled={disabled}
          />
        </FlexSpace>
        <Typography.Text strong style={{ color: 'darkred' }}>
          Upload Instructions
        </Typography.Text>
        <Block>
          <ul>
            {selected === 'hEKT8TOuof8YtPhzNVFsrgqUnZhN38' && (
              <li>
                <b>Asset Account - Amounts in one column</b>
                <ul>
                  <li>Money Spent - These amounts need to be negative</li>
                  <li>Money Received - These amounts need to be positive</li>
                </ul>
              </li>
            )}
            {selected === 'LHtnMpReSYicllPWJ5KcVU9nOqOccV' && (
              <li>
                <b>Asset Account - Amounts in two columns</b>
                <ul>
                  <li>Money Spent & Received - Both amounts need to be positive</li>
                </ul>
              </li>
            )}
            {selected === 'zlN9QCA2vqce8gFPayWaHiJWPfGaCT' && (
              <li>
                <b>Liability account - Amounts in one column</b>
                <ul>
                  <li>Money Spent - These amounts need to be positive</li>
                  <li>Money Received - These amounts need to be negative</li>
                </ul>
              </li>
            )}
            {selected === 'v8JR5rcYKkRk0SgLY7Z1EuQJGsDhOL' && (
              <li>
                <b>Liability account - Amounts in two columns</b>
                <ul>
                  <li>Money Spent & Received - Both amounts need to be positive</li>
                </ul>
              </li>
            )}
          </ul>
        </Block>
      </FlexSpace>
      <Block style={{ ...holderStyle, display: modal ? 'block' : 'none' }}>
        <iframe
          title={uuid}
          ref={iframe}
          style={iframeStyle}
          data-csvbox-token={uuid}
          src={iframeSrc}
          onLoad={handleLoad}
        />
      </Block>
    </Block>
  )
}

const holderStyle: any = {
  display: 'none',
  zIndex: 2147483647,
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
}

const iframeStyle: any = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '0px',
  left: '0px'
}

const options = [
  {
    value: 'hEKT8TOuof8YtPhzNVFsrgqUnZhN38',
    label: 'Asset account - Amounts in one column'
  },
  {
    value: 'LHtnMpReSYicllPWJ5KcVU9nOqOccV',
    label: 'Asset account - Amounts in two columns'
  },
  {
    value: 'zlN9QCA2vqce8gFPayWaHiJWPfGaCT',
    label: 'Liability account - Amounts in one column'
  },
  {
    value: 'v8JR5rcYKkRk0SgLY7Z1EuQJGsDhOL',
    label: 'Liability account - Amounts in two column'
  }
]

function generateUuid() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

function eventListener(event: MessageEvent<any>, onComplete: (state: boolean, data?: any) => void) {
  switch (event.data.legacyName) {
    case 'mainModalHidden':
      onComplete(false)
      break
    case 'uploadSuccessful':
      onComplete(true)
      break
    case 'uploadFailed':
      onComplete(false)
      break
    default:
      break
  }

  if (typeof event.data == 'object') {
    if (event.data.type && event.data.type === 'data-on-submit') {
      let metadata = event.data.data
      metadata['column_mappings'] = event.data.column_mapping
      delete metadata['unique_token']
    } else if (event.data.type && event.data.type === 'data-push-status') {
      if ((event.data.data.import_status = 'success')) {
        if (event.data && event.data.row_data) {
          let primary_row_data = event.data.row_data
          let headers = event.data.headers
          let rows: any[] = []
          let dynamic_columns_indexes = event.data.dynamicColumnsIndexes
          let virtual_columns_indexes = event.data.virtualColumnsIndexes || []

          let dropdown_display_labels_mappings = event.data.dropdown_display_labels_mappings
          primary_row_data.forEach((row_data: any) => {
            let x: any = {}
            let dynamic_columns: any = {}
            let virtual_data: any = {}
            row_data.data.forEach((col: any, i: number) => {
              if (col === undefined) {
                col = ''
              }
              if (!!dropdown_display_labels_mappings[i] && !!dropdown_display_labels_mappings[i][col]) {
                col = dropdown_display_labels_mappings[i][col]
              }
              if (dynamic_columns_indexes.includes(i)) {
                dynamic_columns[headers[i]] = col
              } else if (virtual_columns_indexes.includes(i)) {
                virtual_data[headers[i]] = col
              } else {
                x[headers[i]] = col
              }
            })
            if (row_data.unmapped_data) {
              x['_unmapped_data'] = row_data.unmapped_data
            }
            if (dynamic_columns && Object.keys(dynamic_columns).length > 0) {
              x['_dynamic_data'] = dynamic_columns
            }
            if (virtual_data && Object.keys(virtual_data).length > 0) {
              x['_virtual_data'] = virtual_data
            }
            rows.push(x)
          })
          let metadata = event.data.data
          metadata['rows'] = rows
          delete metadata['unique_token']
          onComplete(true, metadata)
        } else {
          let metadata = event.data.data
          delete metadata['unique_token']
          onComplete(true, metadata)
        }
      } else {
        onComplete(false, event.data.data)
      }
    }
  }
}
