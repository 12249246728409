import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Badge, Layout, Menu, Tooltip } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet, faUser, faRightFromBracket, faList, faLandMineOn } from '@fortawesome/free-solid-svg-icons'
import { faSquareCaretLeft, faSquareCaretRight } from '@fortawesome/free-solid-svg-icons'

import { Logo, LogoSm } from 'components/Logo'
import { Block } from 'components/Block'
import { CompanyMenu } from 'components/CompanyMenu'
import { FlexSpace } from 'components/FlexSpace'

import { authAtom } from 'recoil/auth.state'
import { currentCompany } from 'recoil/companies.state'
import { currentUser } from 'recoil/user.state'
import { config } from 'utils/config'

interface Props {
  onCollapse: (collapsed: boolean) => void
}

export const AppSidebar: ReactFC<Props> = (props) => {
  const { onCollapse } = props

  const setAuth = useSetRecoilState(authAtom)
  const company = useRecoilValue(currentCompany)

  const user = useRecoilValue(currentUser)

  const location = useLocation()
  const navigate = useNavigate()

  const [selected, setSelected] = useState('/')
  const [collapsed, setCollapsed] = useState(!!localStorage.getItem('qe_sidebar'))

  useEffect(() => {
    setSelected(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    if (config.isLocal) return
    if (!user) return

    window.CommandBar.boot(user.email)

    const routerFunc = (newUrl: any) => navigate(newUrl)
    window.CommandBar.addRouter(routerFunc)

    return () => {
      window.CommandBar.shutdown()
    }
  }, [user, config])

  const handleLogout = () => setAuth(null)
  
  const handleCollapse = (v: boolean) => {
    onCollapse(v)
    setCollapsed(v)
    localStorage.setItem('qe_sidebar', v ? '1' : '')
  }

  const label = collapsed
      ? 'Account'
      : user?.invites?.length 
        ? <Badge count={user.invites.length} style={{ right: -15 }}>Account</Badge> 
        : <span>Account</span>

  const icon = collapsed && user?.invites?.length
    ? <Badge count={user.invites.length} style={{ top: -5, right: -5, lineHeight: '22px' }}>
        <FontAwesomeIcon icon={faUser} />
      </Badge>
    : <FontAwesomeIcon icon={faUser} />

  const items = [
    { label: 'All Companies', key: '/', onClick: () => navigate('/'), icon: <FontAwesomeIcon icon={faList} /> },
    { label: 'Billing', key: '/billing', onClick: () => navigate('/billing'), icon: <FontAwesomeIcon icon={faWallet} /> },
    { label, key: '/account', onClick: () => navigate('/account'), icon },
    { label: 'Logout', key: '/logout', onClick: () => handleLogout(), icon: <FontAwesomeIcon icon={faRightFromBracket} /> }
  ]

  if (user?.admin) {
    items.unshift({ label: 'Admin', key: '/admin', onClick: () => navigate('/admin'), icon: <FontAwesomeIcon icon={faLandMineOn} /> })
  }

  return (
    <Layout.Sider className="AppSidebar NoSelect" width={281} trigger={null} collapsedWidth={105} collapsible collapsed={collapsed}>
      <Block className="AppSidebarContainer">
        <FlexSpace direction="vertical" spacebetween style={{ height: '100vh' }}>
          <Block>
            <FlexSpace spacebetween>
              <Block className="AppSidebarContainerLogo">
                {collapsed ? <LogoSm width={43} /> : <Logo width={120} />}
              </Block>
            </FlexSpace>

            {!!company && (
              <Block className="AppSidebarContainerBlock">
                <CompanyMenu company={company} collapsed={collapsed} />
              </Block> 
            )}

            <Block className="AppSidebarMenu">
              <Menu items={items} selectedKeys={[selected]} mode="inline" />
            </Block>
          </Block>

          <Block style={{ width: 108, display: 'flex', height: 80, alignItems: 'center', justifyContent: 'center'}} className="CollapseBtn">
            {collapsed && (
              <Tooltip title="Expand sidebar" placement="right">
                <FontAwesomeIcon size="2x" icon={faSquareCaretRight} onClick={() => handleCollapse(false)} />
              </Tooltip>
            )}
            {!collapsed && (
                <Tooltip title="Collapse sidebar" placement="right">
                  <Block className="CollapseBtn">
                    <FontAwesomeIcon size="2x" icon={faSquareCaretLeft} onClick={() => handleCollapse(true)} />
                  </Block>
                </Tooltip>
              )}
          </Block>
        </FlexSpace>
      </Block>
    </Layout.Sider>
  )
}
