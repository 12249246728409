import { Suspense } from 'react'
import { RecoilRoot } from 'recoil'

import { AppRoutes } from './AppRoutes'

import { FullLoader } from 'components/FullLoader'
import { DebugObserver } from 'components/DebugObserver'

export const App = () => {
  return (
    <RecoilRoot>
      <DebugObserver />
      <Suspense fallback={<FullLoader />}>
        <AppRoutes />
      </Suspense>
    </RecoilRoot>
  )
}
