import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Checkbox, Input, Modal, Segmented, Space, Table, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'

import { currentAccounts } from 'recoil/companies.state'

interface Props {
  title?: string
  visible: boolean
  value: string[]
  onClose: (value?: string[]) => void
}

export const MatchAccountsModal: ReactFC<Props> = (props) => {
  const {title, visible, value, onClose} = props

  const accounts = useRecoilValue(currentAccounts)

  const [selected, setSelected] = useState<string[]>([])
  const [filter, setFilter] = useState('')
  const [all, setAll] = useState(true)

  useEffect(() => {
    setSelected(value)
    setAll(!value?.length)
  }, [value, visible])

  const handleSelect = (id: string) => {
    const arr = [...selected]
    const isSelected = selected.indexOf(id) > -1
    if (!isSelected)
      setSelected([...selected, id])
    else {
      arr.splice(selected.indexOf(id), 1)
      setSelected(arr)
    }
  }

  const handleClose = (isOk = false) => {
    isOk ? onClose(selected) : onClose()
  }

  const columns = [{
    title: 'Number',
    dataIndex: 'number',
    key: 'number',
    sorter: (a: any, b: any) => a.number?.localeCompare(b.number)
  },
  {
    title: 'Account Name',
    dataIndex: 'name',
    key: 'name',
    width: 450,
    sorter: (a: any, b: any) => a.name?.localeCompare(b.name)
  },{
    title: '',
    dataIndex: 'qboId',
    width: 50,
    render: (qboId: string) => (
      <Checkbox checked={selected.indexOf(qboId) > -1} onChange={() => handleSelect(qboId)} />
    )
  }]

  const modalTitle = title ? 'Select accounts: ' + title : 'Select accounts'
  const dataSource = accounts
    .filter(v => v.active)
    .filter(v => {
      if (!filter) return true
      if (!all) return true
      const f = filter.toLowerCase()
      return v.name?.toLowerCase().includes(f) || v.number?.includes(f)
    })
    .filter(v => all ? true : selected.includes(v.qboId))

  return (
    <Modal open={visible} onCancel={() => handleClose()} onOk={() => handleClose(true)} title={modalTitle} width={700}>
      <FlexSpace direction="vertical">
        <FlexSpace direction="horizontal" spacebetween>
          <Input
            type="text"
            placeholder='Quick Search'
            value={filter}
            onChange={v => setFilter(v.target.value || '')}
            allowClear 
            style={{ width: 400 }}
            disabled={!all}
          />
          <Space>
            <Typography.Text>Show:</Typography.Text>
            <Segmented
              options={['All', 'Selected']}
              value={all ? 'All' : 'Selected'}
              onChange={v => setAll(v === 'All')}
            />
          </Space>
        </FlexSpace>
        
        <Table
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          scroll={{ y: 540 }}
          pagination={false}
        />
      </FlexSpace>
    </Modal>
  )
}
