import { useRef } from 'react'
import { theme } from 'antd'
import days, { Dayjs } from 'dayjs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'

import IMask from 'imask'

import { FlexSpace } from './FlexSpace'

import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker, { RangePickerProps } from 'antd/es/date-picker/generatePicker'


const { useToken } = theme

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

const DATE_FORMAT = 'MM/DD/YYYY'
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE }
  },
  format: (date: Date) => days(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => days(date, DATE_FORMAT),
  pattern: DATE_FORMAT
})

type Props = RangePickerProps<Dayjs>

export const RangePicker: ReactFC<Props> = (props) => {
  const { value, onChange } = props

  const { token } = useToken()

  const ref1 = useRef<any>(null)
  const ref2 = useRef<any>(null)

  const wrapperStyle: React.CSSProperties = {
    width: 425,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: token.colorBorder,
    borderRadius: token.borderRadius
  }

  return (
    <FlexSpace direction="horizontal" spacebetween style={wrapperStyle}>
      <DatePicker
        format={DATE_FORMAT}
        variant="borderless"
        value={value?.[0] || null}
        onChange={(v) => {
          onChange?.([v, value?.[1] || null], ['', ''])
          if (!value?.[1]) ref2.current.focus()
        }}
        onKeyDown={onMaskedKeyDown}
        placeholder="Start date"
        ref={ref1}
      />
      <Separator />
      <DatePicker
        format={DATE_FORMAT}
        variant="borderless"
        value={value?.[1] || null}
        onChange={(v) => 
          onChange?.([value?.[0] || null, v], ['', ''])
        }
        onKeyDown={onMaskedKeyDown}
        placeholder="End date"
        ref={ref2}
      />
    </FlexSpace>
  )
}

const Separator = () => <FontAwesomeIcon icon={faAnglesRight} color="#000" style={{ opacity: 0.4 }} />

function onMaskedKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
  const input = event.target as HTMLInputElement
  input.value = MASKED.resolve(input.value)
  setTimeout(() => {
    if (input.value.length === 10) input?.blur()
  })
}

