import React, {useEffect, useState} from 'react'
import {DatePicker, Radio, Select, Spin, Table, Typography, Tag} from 'antd'
import {Block} from 'components/Block'
import {AreaChartOutlined, BarChartOutlined, LineChartOutlined} from '@ant-design/icons';
import {useRecoilValue} from "recoil";
import {ChartTypes} from "../../../components/ChartTypes";
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {ChartComponent} from "../../../components/Chart";
import {ChartDataSet, SerializedColumnBy} from "../../../types/chart.types";
import {api} from 'utils/axios';
import {toCurrency} from 'utils/numbers';
import {formatDateByPeriod} from 'utils/dates';
import dayjs, {Dayjs} from 'dayjs';
import {currentCompany} from "../../../recoil/companies.state";
import {FlexSpace} from 'components/FlexSpace';
import {Btn} from 'components/Btn';
import { MultiGroupDropdown } from 'components/MultiGroupDropdown';
import { DATA_SOURCES, SHOPIFY_ACCOUNTS } from 'utils/accountName';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const {Option} = Select;
dayjs.extend(quarterOfYear);

export const Charts = () => {
    const company = useRecoilValue(currentCompany)
    const [loading, setLoading] = useState(false);
    const [fetchShopify, setFetchShopify] = useState(false);
    const [chartDataSets, setChartDataSets] = useState<ChartDataSet[]>([]);
    const [shopifyDataSet, setShopifyDataSet] = useState<ChartDataSet[]>([]);
    const [nestedMenuData, setNestedMenuData] = useState<any>([])
    const [selected, setSelected] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [selectedSource, setSelectedSource] = useState<object[]>([DATA_SOURCES.QB]);
    const [selectedSourceKeys, setSelectedSourceKeys] = useState<string[]>([DATA_SOURCES.QB.key]);
    const [date, setDate] = useState<Dayjs | null>(dayjs().startOf('month').add(-1, 'months'));
    const [start_date, setStartDate] = useState<string>();
    const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs().startOf('month').add(-3, 'months'));
    const [tableData, setTableData] = useState<any[]>([]);
    const [tableColumns, setTableColumns] = useState<any>([]);
    const accountingMethods = [
        {value: 'Cash', label: 'Cash-Basis'},
        {value: 'Accrual', label: 'Accrual-Basis'}
    ];
    const serializedColumnByTypes = [
        {name: 'Month', value: 'month', type: 'month'},
        {name: 'Quarter', value: 'quarter', type: 'quarter'},
        {name: 'Year', value: 'year', type: 'year'},
    ];
    const [selectedChartType, setSelectedChartType] = useState<string>('line');
    const [accountingMethod, setAccountingMethod] = useState<string>('Cash');
    const [accountType] = useState<string>('PL');
    const [serializedColumnBy, setSerializedColumnBy] = useState<SerializedColumnBy>('month');

    const getDefaultValue = () => {
        switch (serializedColumnBy) {
            case 'month':
                return dayjs().startOf('month').add(-1, 'months');
            case 'quarter':
                return dayjs().startOf('quarter').add(-1, 'quarters');
            case 'year':
                return dayjs().startOf('year').add(-1, 'years');
            default:
                return dayjs();
        }
    };

    const capitalizeFirstLetter = (string: SerializedColumnBy) => {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    useEffect(() => {
        getFinReviewAccounts();
    }, [accountType, accountingMethod, fromDate]);

    useEffect(() => {
      const shopifyAccounts = SHOPIFY_ACCOUNTS.reduce((acc: string[], curr) => {
        if (selectedKeys.includes(curr.key)) {
          acc.push(curr.key)
        }
        return acc;
      }, []);
      const qboAccounts = selectedKeys.filter((key) => !shopifyAccounts.includes(key));
      if (start_date && selectedKeys.length) {
        getFinReviewCharts(qboAccounts, !!shopifyAccounts.length && !shopifyDataSet.length).then(res => {
          const qboData = res.filter((el: any) => el.type !== 'shopify');
          const shopifyData = shopifyDataSet.length ? shopifyDataSet : res.filter((el: any) => el.type === 'shopify');
          if (!shopifyDataSet.length) {
            setShopifyDataSet(shopifyData);
          }
          setChartDataSets([...qboData, ...shopifyData.filter((el: any) => shopifyAccounts.includes(el.key.toLowerCase()))])
        })
      }
    }, [selected, accountingMethod, accountType, serializedColumnBy, date, fromDate]);

    useEffect(() => {
        const datasetsToRemove = chartDataSets.filter(ds => !selected.includes(ds.key));
        if (datasetsToRemove.length > 0) {
            setChartDataSets(prevDataSets => prevDataSets.filter(ds => selected.includes(ds.key)));
        }
    }, [selected]);

    useEffect(() => {
      if (fetchShopify) {
        setNestedMenuData([...nestedMenuData, ...SHOPIFY_ACCOUNTS]);
      } else {
        setNestedMenuData(nestedMenuData.filter((el: any) => !el.shopify))
        setChartDataSets(chartDataSets.filter((el: any) => el.type !== 'shopify'))
        setSelected(selected.filter((key: string) => !SHOPIFY_ACCOUNTS.find(acc => acc.key !== key)))
        setSelectedKeys(selected.filter((key: string) => !SHOPIFY_ACCOUNTS.find(acc => acc.key !== key)));
      }
    }, [fetchShopify])

    const formatEndDate = (endDate: Dayjs | null) => {
        let formattedDate = dayjs(endDate);

        switch (serializedColumnBy) {
            case 'month':
                formattedDate = formattedDate.endOf('month');
                break;
            case 'quarter':
                formattedDate = formattedDate.endOf('quarter');
                break;
            case 'year':
                formattedDate = formattedDate.endOf('year');
                break;
            default:
                break;
        }

        return formattedDate.format('YYYY-MM-DD');
    };

    const formatStartDate = (endDate: Dayjs | null) => {
        let formattedDate = dayjs(endDate);

        switch (serializedColumnBy) {
            case 'month':
                formattedDate = formattedDate.startOf('month');
                break;
            case 'quarter':
                formattedDate = formattedDate.startOf('quarter');
                break;
            case 'year':
                formattedDate = formattedDate.startOf('year');
                break;
            default:
                break;
        }

        return formattedDate.format('YYYY-MM-DD');
    };

    const getFinReviewCharts = async (selectedKeys: string[], shopify: boolean = false) => {
        setLoading(true);
        const params = {
            applicationId: company?.id,
            accounting_method: accountingMethod,
            start_date: formatStartDate(fromDate),
            end_date: formatEndDate(date),
            key: selectedKeys.join(','),
            summarize_column_by: capitalizeFirstLetter(serializedColumnBy),
            shopify
        }
        const base = await api.get('/finreview/chart/data', {params})
        .then((res) => {
          return res;
          // return res?.data.data.map((el: any) => {
          //   return {
          //     key: el.name,
          //     values: transformData(el.name, el.values)
          //   }
          // });
        }).finally(() => {
            setLoading(false);
        });

        const chartData = base?.data.data.map((el: any) => {
          return {
            key: el.name,
            type: el.type,
            values: transformData(el.name, el.values)
          }
        })
        const cols: any = [{
          title: 'Date',
          dataIndex: 'date',
          render: (v: any) => formatDateByPeriod(v, serializedColumnBy)
        }];
        const data: any = {};
        base.data.data.forEach((period: any) => {
            if (period.type !== 'shopify') {
                cols.push({
                    title: period.name,
                    dataIndex: period.name,
                    hidden: cols.length > 5,
                    render: (v: any) => toCurrency(v)
                })
                period.values.forEach((v: any) => {
                    data[v.date] = {
                        ...(data[v.date] ? data[v.date] : {}),
                        date: v.date,
                        [period.name]: v.amount,
                    }
                })
            }
        })

        setTableColumns(cols);
        setTableData(Object.values(data));
        return chartData
    }

    const transformData = (name: string, data: any[]) => {
        return data.map(item => {
            let formattedDate;

            switch (serializedColumnBy) {
                case 'month':
                    formattedDate = dayjs(item.date).format('YYYY-MM');
                    break;
                case 'quarter':
                    formattedDate = `Q${dayjs(item.date).quarter()} ${dayjs(item.date).format('YYYY')}`;
                    break;
                case 'year':
                    formattedDate = dayjs(item.date).format('YYYY');
                    break;
                default:
                    formattedDate = dayjs(item.date).format();
            }

            return {
                ...item,
                date: formattedDate,
                [name]: parseFloat(item.amount),
                amount: parseFloat(item.amount),
            };
        });
    };

    const getFinReviewAccounts = () => {
        setLoading(true);
        const params = {
            applicationId: company?.id,
            accounting_method: accountingMethod,
            start_date: formatStartDate(fromDate),
            end_date: formatEndDate(date),
            type: accountType,
        }

        api.get<any>('/finreview/chart/info', {params})
            .then((res: any) => {
                setNestedMenuData([...res.data.accounts, ...(fetchShopify ? SHOPIFY_ACCOUNTS : [])]);
                setStartDate(res.data.start_date);
                // setFromDate(dayjs().startOf('month').add(-3, 'months'))
            })
    }

    useEffect(() => {
        const shopifyData = chartDataSets.filter((item) => item.type === 'shopify');
        if (shopifyData.length) {
            const colsToUpdate: any = [];
            const dataToUpdate: any = {};

            shopifyData.forEach((item) => {
                if (!colsToUpdate.some((col: any) => col.title === item.key)) {
                    colsToUpdate.push({
                        title: item.key,
                        dataIndex: item.key,
                        hidden: tableColumns.length > 5,
                        render: (v: any) => toCurrency(v),
                    });
                }

                item.values.forEach((v) => {
                    if (!dataToUpdate[v.date] || !dataToUpdate[v.date][item.key]) {
                        dataToUpdate[v.date] = {
                            ...dataToUpdate[v.date],
                            date: v.date,
                            [item.key]: v.amount,
                        };
                    }
                });
            });

            setTableColumns((prevCols: any) => {
                const updatedCols = [...prevCols];
                colsToUpdate.forEach((col: { title: any; }) => {
                    if (!updatedCols.find(c => c.title === col.title)) {
                        updatedCols.push(col);
                    }
                });
                return updatedCols;
            });

            setTableData(prevData => {
                const updatedData: any = { ...prevData };
                Object.keys(dataToUpdate).forEach(date => {
                    if (updatedData[date]) {
                        updatedData[date] = { ...updatedData[date], ...dataToUpdate[date] };
                    } else {
                        updatedData[date] = dataToUpdate[date];
                    }
                });
                return Object.values(updatedData);
            });
        }
    }, [chartDataSets, selected]);


    const handleSelectionChange = (newSelected: string[]) => {
        setSelected(newSelected);
    };

    const handleDataSourceChange = (keys: string[]) => {
      if (keys.includes(DATA_SOURCES.SP.key)) {
        setFetchShopify(true);
      }
      setSelectedSource(Object.values(DATA_SOURCES).filter(el => keys.includes(el.key)));
    }

    const handleSourceRemove = (source: any) => {
      if (source.key === DATA_SOURCES.SP.key) {
        setFetchShopify(false);
      }
      const selectedSource = Object.values(DATA_SOURCES).filter(el => source.key !== el.key)
      setSelectedSource(selectedSource);
      setSelectedSourceKeys(selectedSource.map(el => el.key))
    }

    const onChartTypeChange = (e: any) => {
        setSelectedChartType(e.target.value);
    }

    const onAccountMethodChange = (e: string) => {
        setChartDataSets([]);
        setAccountingMethod(e);
    }

    const onSerializedColumnByTypeChange = (newValue: SerializedColumnBy) => {
      setShopifyDataSet([]);
      setSerializedColumnBy(newValue);
        let newFromDate;
        switch (newValue) {
            case 'month':
                newFromDate = dayjs(date).subtract(3, 'month');
                break;
            case 'quarter':
                newFromDate = dayjs(date).subtract(1, 'quarter');
                break;
            case 'year':
                newFromDate = dayjs(date).subtract(1, 'year');
                break;
            default:
                newFromDate = dayjs();
        }

        setFromDate(newFromDate);

    }

    const handleDateChange = (value: Dayjs | null) => {
      setDate(value);
    };

    const handleFromDateChange = (value: Dayjs | null) => {
      setShopifyDataSet([]);
      setFromDate(value);
    };

    const exportToCSV = (tableData: any[], tableColumns: any[]): void => {
        const csvHeader = tableColumns.map(column => column.title).join(',') + '\n';
        const csvRows = tableData.map(row =>
            tableColumns.map(column => {
              return row[column.dataIndex] && row[column.dataIndex] !== '0' ? `${row[column.dataIndex]}` : `0.00`;
            }).join(',')
        ).join('\n');

        const csvContent = csvHeader + csvRows;

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${serializedColumnBy}ly_report_${dayjs(start_date).format('DD-MM-YYYY')}_${dayjs(date).format('DD-MM-YYYY')}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const disabledComparedDate = (current: Dayjs) => {
        if (!date) return false;

        let periodStart;
        switch (serializedColumnBy) {
            case 'month':
                periodStart = date.clone().add(-1, 'months');
                break;
            case 'quarter':
                periodStart = date.clone().add(-1, 'quarter');
                break;
            case 'year':
                periodStart = date.clone().add(-1, 'year');
                break;
            default:
                return false;
        }

        return current.isAfter(periodStart, 'day');
    };

    const serializedColumnByOptions = serializedColumnByTypes.map(type => <Option key={type.value}>{type.name}</Option>);

    return (
        <Block style={{minHeight: 500}}>
            <FlexSpace spacebetween>
                <RadioGroup onChange={onChartTypeChange} defaultValue="line">
                    <RadioButton value="line"><LineChartOutlined/></RadioButton>
                    <RadioButton value="bar"><BarChartOutlined/></RadioButton>
                    <RadioButton value="area"><AreaChartOutlined/></RadioButton>
                </RadioGroup>
                <FlexSpace>
                    <Typography.Text>From the</Typography.Text>
                    <Select
                        style={{width: 100}}
                        value={serializedColumnBy}
                        onChange={onSerializedColumnByTypeChange}>
                        {serializedColumnByOptions}
                    </Select>
                    <Typography.Text>of</Typography.Text>
                    <DatePicker
                        picker={serializedColumnBy}
                        value={fromDate}
                        onChange={handleFromDateChange}
                        disabledDate={disabledComparedDate}
                    />
                    <Typography.Text>to</Typography.Text>
                    <DatePicker
                        picker={serializedColumnBy}
                        defaultValue={getDefaultValue()}
                        value={date}
                        onChange={handleDateChange}
                        // disabledDate={disabledDate}
                    />
                </FlexSpace>
            </FlexSpace>

            <FlexSpace style={{marginTop: 40}}>
              <span>Accounting Method: </span>
                <Select

                    style={{width: 200}}
                    value={accountingMethod}
                    onChange={onAccountMethodChange}
                    options={accountingMethods}
                />
            </FlexSpace>

            <FlexSpace style={{marginTop: 40}}>
              <span>Display Connect Data From </span>
                <MultiGroupDropdown
                enableSearch={false}
                style={{width: 300}}
                  items={Object.values(DATA_SOURCES)}
                  onChange={handleDataSourceChange}
                  selectedKeys={selectedSourceKeys}
                  setSelectedKeys={setSelectedSourceKeys}
                />
                <span style={{ marginRight: '10px' }}></span>
          {selectedSource?.map((source: any, index) => (
            <span key={index}>
              {index !== 0 ? 'and ' : ''}
              <Tag
                closable={selectedSource.length > 1}
                onClose={(e) => {
                  e.preventDefault()
                  return handleSourceRemove(source)
                }}
              >
                {source.name}
              </Tag>
            </span>
          ))}
            </FlexSpace>

            <Spin spinning={loading}>
            {!!nestedMenuData.length && <ChartTypes data={nestedMenuData}
                                                  selectedKeys={selectedKeys}
                                                  setSelectedKeys={setSelectedKeys}
                                                  onSelectionChange={handleSelectionChange}
            />}

            {!!chartDataSets.length &&
                <div style={{marginTop: 40}}>
                    <ChartComponent chartDataSets={chartDataSets} type={selectedChartType}/>
                </div>
            }
            </Spin>
            {(!!tableData.length &&  !!tableColumns.length) && <FlexSpace style={{margin: '20px 0', justifyContent: 'flex-end'}}>
                <Btn onClick={() => exportToCSV(tableData, tableColumns)} type="primary">
                    Export to CSV
                </Btn>
            </FlexSpace>}
            <Table columns={tableColumns}
                   pagination={{
                       showSizeChanger: true,
                       pageSizeOptions: ['10', '25', '50', '100'],
                       defaultPageSize: 10,
                   }}
                   dataSource={tableData} size="middle" loading={loading} />
        </Block>
    )
}
