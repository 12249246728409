import axios, { AxiosError, AxiosRequestConfig } from 'axios'

// import { log } from './log'
import { config } from './config'
import { storage } from './storage'
import { logoutEvent } from './event'
import { shareAuth } from './plugin'

import { IAuthCredentials } from 'types/auth.types'

interface CustomConfig extends AxiosRequestConfig {
  _retry: boolean
}

// ----------------------------------------------------------------------

const api = axios.create(config.api)
api.defaults.headers.common['Content-Type'] = 'application/json'

const exclude = ['auth/signin', 'auth/signup', 'auth/refresh', 'auth/google-signin', 'auth/reset-password', 'auth/update-password']

api.interceptors.request.use((config) => {
  const auth = storage.getItem<IAuthCredentials>('auth')
  const token = auth?.token
  
  if (token) config.headers!.Authorization = `Bearer ${token}`

  return config
})

export { api }

// ----------------------------------------------------------------------

let isFetching = false
let que: any[] = []

api.interceptors.response.use(
  (response) => {
    const url = response.config.url
    if (exclude.find(v => v === url) && response.data.token) {
      setAuthCredentials(response.data)
    }

    return response
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as CustomConfig
    const status = error.response?.status
    const url = originalRequest?.url || (originalRequest as any)?.config?.url

    if (!originalRequest || originalRequest?._retry)
      return Promise.reject(error)

    if (exclude.find(v => url.includes(v)))
      return Promise.reject(error)

    if (status === 403)
      return Promise.reject(error)

    // if (status !== 401) {
    //   originalRequest._retry = true
    //   return axios(originalRequest)
    // }

    if (status === 401) {
      const auth = storage.getItem<IAuthCredentials>('auth')
      const refresh_token = auth?.refresh_token

      if (refresh_token) {
        if (!isFetching) {
          isFetching = true

          api.post(`auth/refresh`, { refresh_token })
            .then(({ data }) => {
              setAuthCredentials(data)
              isFetching = false

              que.forEach(cb => cb(data.token))
            }).catch(() => clearAuth())
          }
        
        return new Promise((resolve) => {
          que.push((token: string) => {
            originalRequest.headers!.Authorization = `Bearer ${token}`
            originalRequest._retry = true

            resolve(axios(originalRequest))
          })
        })
      } else {
        clearAuth()
      }
    }

    return Promise.reject(error)
  }
)

export function clearAuth() {
  storage.removeItem('auth')
  logoutEvent.dispatch()
  shareAuth(null)
}

export function setAuthCredentials(newValue: any) {
  const oldValue = getAuthCredentials() || {}
  newValue
    ? storage.setItem('auth', {...oldValue, ...newValue})
    : clearAuth()

  shareAuth(newValue || null)
}

export function getAuthCredentials() {
  const newValue =  storage.getItem<IAuthCredentials>('auth')
  shareAuth(newValue || null)
  return newValue
}