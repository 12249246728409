import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { StepProps, Steps, message } from 'antd'

import { Page } from 'components/Page'
import { Block } from 'components/Block'
import { FlexSpace } from 'components/FlexSpace'

import { AccountingStep } from './steps/Accounting.step'
import { CompanyStep } from './steps/Company.step'
import { CommercialStep } from './steps/Commercial.step'
import { BankStep } from './steps/Bank.step'
import { DoneStep } from './steps/Done.step'
import { WelcomeStep } from './steps/Welcome.step'

import { ISetupData, getCompanies, saveSettings, updateCompany } from './utils'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { companiesAtom, companyAtom, currentCompany } from 'recoil/companies.state'

interface CustomStepProps extends StepProps {
  content?: React.ReactNode
}

interface SetupProps {
  type: 'welcome' | 'new' | 'edit'
}

export const SetupPage = (props: SetupProps) => {
  const { type } = props

  const navigate = useNavigate()
  const setCompany = useSetRecoilState(companyAtom)
  const setCompanies = useSetRecoilState(companiesAtom)
  const company = useRecoilValue(currentCompany)

  const [step, setStep] = useState(0)
  const [data, setData] = useState<ISetupData>({})

  const isEdit = type === 'edit'
  const isWelcome = type === 'welcome'

  useEffect(() => {
    // console.log('useEffect SetupPage', type, company)
    if (type === 'edit') setData(company || {})
    else setData({})
  }, [type, company?.id])

  const refreshCompanies = () => {
    return getCompanies()
      .then((companies) => {
        setCompanies(companies)
        return companies
      })
  }

  const refresh = () => {
    refreshCompanies()
      .then((companies) => {
        const d = companies.find(c => c.id === data.id)
        d && setData(d)
      })
  }

  const onDataChange = (d: ISetupData) => {
    const nextData = { ...data, ...d }
    setData(nextData)
  }

  const welcomeSteps: CustomStepProps[] = [
    {
      title: 'Welcome',
      content: <WelcomeStep onSubmit={() => setStep(1)} />,
    }, 
  ]

  const baseStep = isWelcome ? 1 : 0
  const setupSteps: CustomStepProps[] = [
    {
      title: 'Company',
      content: <CompanyStep data={data} onSubmit={(d) => {
        onDataChange(d)
        setStep(baseStep + 1)
        if (company?.id && d.entity)
          updateCompany({ ...company, entity: d.entity })
            .then(() => refresh())
      }} />,
    }, {
      title: 'Accounting',
      content: <AccountingStep 
        data={data} 
        onSubmit={(d) => {
          onDataChange(d)
          setStep(baseStep + 2)
          if (d.id && d.settings)
            saveSettings(d.id, d.settings).then(() => refresh())
          if (d.id && d.entity)
            updateCompany(d as any).then(() => refresh())
        }} 
        onBack={() => setStep(baseStep)}
        onChange={() => refresh()}
      />,
    }, {
      title: 'E-Commerce',
      content: <CommercialStep 
        data={data} 
        onSubmit={(d) => {
          onDataChange(d)
          setStep(baseStep + 3)

          if (d.id && d.settings)
            saveSettings(d.id, d.settings)
        }} 
        onBack={() => setStep(baseStep + 1)}
        onChange={() => refresh()}
      />,
    }, {
      title: 'Banks & Cards',
      content: <BankStep 
        data={data} 
        onSubmit={() => setStep(baseStep + 4)} 
        onBack={() => setStep(baseStep + 2)} 
        onChange={() => refresh()}
      />
    }, {
      title: 'Done!',
      content: <DoneStep 
        data={data} 
        onSubmit={() => {
          if (data.id) {
            refreshCompanies()
              .then(() => {
                setCompany(data.id)
                navigate('/company/' + data.id + '/settings')
              })
          } else {
            message.error('Please connect your accounting platform')
          }
        }} 
      />
    }
  ]

  const stepsList = isWelcome ? welcomeSteps.concat(setupSteps) : setupSteps

  const title = isWelcome ? 'Welcome' : 
    isEdit ? 'Setup company' : 'Add company'

  return (
    <Page title={title}>
      <FlexSpace direction="vertical" size="large">
        <Steps current={step} items={stepsList} onChange={setStep} />
        <Block>{stepsList[step].content}</Block>
      </FlexSpace>
    </Page>
  )
}
