import { log } from './log'

export function errorMsg(err: any) {
  log('Error', err)

  if (err?.response?.data?.statusCode === 403 || err?.message === '403') {
    return 'Please reconnect to QuickBooks. Go to Company Settings > Integration > Reconnect'
  }

  return err?.response?.data?.message || err?.message || 'Error'
}
