import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'
import { Input, Menu, Popover, Tooltip, Typography, theme } from 'antd'
import { ItemType } from 'antd/es/menu/hooks/useItems'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faChevronRight, faGauge } from '@fortawesome/free-solid-svg-icons'
import { faCodeCompare, faCog } from '@fortawesome/free-solid-svg-icons'
import { faComment } from '@fortawesome/free-regular-svg-icons'

import { useBeta, useRestriction } from 'hooks/useRestriction'

import { FlexSpace } from './FlexSpace'
import { CompanyTitle } from './CompanyTitle'
import { Block } from './Block'

import { ICompany } from 'types/company.types'
import { companiesAtom, companyAtom } from 'recoil/companies.state'
import { api } from 'utils/axios'

const { useToken } = theme

interface Props {
  company: ICompany
  collapsed: boolean
}

export const CompanyMenu: ReactFC<Props> = (props) => {
  const { company, collapsed } = props

  const location = useLocation()
  const navigate = useNavigate()
  const { token } = useToken()

  const baseUrl = '/company/' + company.id
  const [selected, setSelected] = useState(baseUrl)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)

  const setCompany = useSetRecoilState(companyAtom)
  const companies = useRecoilValue(companiesAtom)
  const restrictedText = useRestriction(2)
  const beta = useBeta()

  const complianceLabel = collapsed
    ? 'Financial Review - ' + restrictedText
    : <Tooltip title={restrictedText} placement="right">Financial Review</Tooltip>
    
  const items: ItemType[] = [{
    key: 'analytics',
    icon: <FontAwesomeIcon icon={faGauge} />,
    label: 'Analytics',
    onClick: () => navigate(baseUrl + '/analytics')
  },{
    key: 'reconcile',
    icon: <FontAwesomeIcon icon={faCodeCompare} />,
    label: 'Reconciliation',
    onClick: () => navigate(baseUrl + '/reconcile')
  },{
    key: 'compliance',
    icon: <FontAwesomeIcon icon={faCheckDouble} style={{ opacity: restrictedText ? 0.5 : 1 }} />,
    label: restrictedText ? complianceLabel : 'Financial Review',
    disabled: !!restrictedText && !collapsed,
    onClick: () => restrictedText ? false : navigate(baseUrl + '/compliance')
  },{
    key: 'accountant',
    icon: <FontAwesomeIcon icon={faComment} style={{ opacity: restrictedText ? 0.5 : 1 }} />,
    label: 'Advisory',
    onClick: () => navigate(baseUrl + '/advisory')
  },{
    key: 'settings',
    icon: <FontAwesomeIcon icon={faCog} />,
    label: 'Company Settings',
    onClick: () => navigate(baseUrl + '/settings')
  }].filter(v => {
    if (v.key === 'custom')
      return beta
    if (v.key === 'setup')
      return beta
    return true
  })

  // console.log(items)

  useEffect(() => {
    const path = location.pathname.replace(baseUrl + '/', '').split('/')
    setSelected(path[0])
  }, [location.pathname])

  const text = () => <Typography style={{ paddingLeft: 10 }}>Select a company</Typography>
  const compaliesList = (companies || [])
    .filter(v => {
      const aName = v.alias?.toLowerCase() || v.name?.toLowerCase()
      return aName.includes(search.toLowerCase())
    }).sort((a, b) => {
      const aName = a.alias?.toLowerCase() || a.name?.toLowerCase()
      const bName = b.alias?.toLowerCase() || b.name?.toLowerCase()
      return aName.localeCompare(bName)
    })

  const activeColor = (id: number) => company.id === id ? token.colorPrimary : token.colorText

  const handleSelectCompany = (id: number) => {
    if (!id) return
    setOpen(false)
    setCompany(id)
    navigate(`/company/${id}/settings/integrations`)
    api.post(`/logger`, { appId: id })
      .catch(console.log)
  }

  const content = () => (
    <FlexSpace direction="vertical" style={{ padding: 10 }}>
      <Input
        placeholder="Find a company" 
        value={search} 
        onChange={v => setSearch(v.currentTarget.value)}
      />
      <Block style={{ overflow: 'scroll', maxHeight: 200 }}>
        {compaliesList.map((v) => (
          <Block style={{ paddingTop: 10, paddingBottom: 10 }} onClick={() => handleSelectCompany(v.id)}>
            <Typography.Text key={v.id} style={{ color: activeColor(v.id), cursor: 'pointer' }}>
              {v.alias || v.name}
            </Typography.Text>
          </Block>
        ))}
      </Block>
    </FlexSpace>
  )

  return (
    <FlexSpace direction="vertical" className="CompanyMenu">
      <Popover open={open} placement="left" title={text} content={content} trigger="click" onOpenChange={setOpen}>
      <FlexSpace direction="horizontal" spacebetween style={{ marginBottom: 10, cursor: 'pointer', position: 'relative' }} >
        <CompanyTitle company={company} ellipsis type="success" collapsed={collapsed} />
        <FontAwesomeIcon icon={faChevronRight} color={token.colorPrimary} style={{ right: 0, top: 8,  position: 'absolute' }} />
      </FlexSpace>
      </Popover>
      <Menu items={items} mode="inline" selectedKeys={[selected]} />
    </FlexSpace>
  )
}
