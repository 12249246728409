import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Typography, Modal, message, Button, ButtonProps } from 'antd'

import { Btn } from './Btn'
import { FlexSpace } from './FlexSpace'

import { errorMsg } from 'utils/errorMsg'

import { currentPlan } from 'recoil/price.state'
import { currentUser } from 'recoil/user.state'
import { companiesAtom, companyAtom } from 'recoil/companies.state'
import { toCurrency } from 'utils/numbers'
import { api } from 'utils/axios'

import QBOImg from 'assets/qbo/C2QB_green_btn_tall_default.svg'
import { ICompany } from 'types/company.types'

interface Props extends ButtonProps {
  
}

export const AddCompany: ReactFC<Props> = (props) => {
  const navigate = useNavigate()

  const user = useRecoilValue(currentUser)
  const plan = useRecoilValue(currentPlan)
  const companies = useRecoilValue(companiesAtom)
  const setCompany = useSetRecoilState(companyAtom)
  const setCompanies = useSetRecoilState(companiesAtom)

  const [loading, setLoading] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [limitedModal, setLimitedModal] = useState(false)

  const refreshCompanies = () => {
    return api.get<ICompany[]>('applications')
      .then(({data}) => setCompanies(data))
      .catch(console.log)
  }

  if (!user) return null

  const accessLevel = user?.subscription?.accessLevel || 0
  const price = plan?.list.find(v => v.accessLevel === accessLevel)?.price || 0

  // console.log('accessLevel', accessLevel, price)

  const discount = user.subscription?.discount?.coupon || null
  const quantity = user.subscription?.quantity || 0
  const nextQuantity = companies.filter(v => v.role.id === 0).length + 1

  // console.log(discount)

  const nextPrice = price * nextQuantity
  const nextDiscount = discount
    ? discount.percent_off
      ? (nextPrice * discount.percent_off) / 100
      : (discount.amount_off / 100)
    : 0

  const nextTotal = Math.max(nextPrice - nextDiscount, 0)  

  // console.log('nextQuantity', nextQuantity, nextPrice)
  // console.log(nextTotal, nextDiscount)

  const onConnect = async () => {
    setLoading(true)
    api('/integrations/qbo')
      .then(r => {
        setAddModal(false)
        const newwindow = window.open(r.data, 'QuickBooks','width=760,height=640');

        let i = 0
        localStorage.setItem('eq_message', '')
        const interval = setInterval(function() {
          i++
          let eq_message = localStorage.getItem('eq_message')
          // console.log(eq_message)
          if (eq_message) {
            const data = JSON.parse(eq_message || "{}")
            if (data?.type === 'COMPANY_ADDED') {
              const { isPresent, id, name } = data
              message.info(`Company '${name}' ${isPresent ? 'updated' : 'added'}!`)
              navigate(`/`)
              refreshCompanies().finally(() => setCompany(id))
            }
            if (data?.type === 'COMPANY_ADD_DENIED') {
              message.error(`Error: ${data?.msg || 'Access denied.'}`, 15)
            }
            clearInterval(interval)
            newwindow?.close()
          }
          if (newwindow?.closed || i > 300) {
            clearInterval(interval)
          }
        }, 500)
      })
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
  }

  const handleClick = () => {
    const isFirstCompany = nextQuantity === 1
    const isAppSumo = !!user.subscription?.isAppSumo
    const isPaymentMethod = user.subscription?.paymentMethods?.length
    const isTrial = user.subscription?.status === 'trialing'

    // console.log('isFirstCompany', isFirstCompany, user.subscription)

    if (!!user.subscription && !isTrial && !isPaymentMethod && !isAppSumo) {
      return message.error('Please add a payment method before adding a new company.', 5)
    }

    if (isAppSumo) {
      const maxCompaniesStr = user.subscription?.discount?.coupon?.metadata?.quantity
      const maxCompanies = maxCompaniesStr ? parseInt(maxCompaniesStr) : 0
      // console.log('maxCompanies', maxCompanies, nextQuantity)

      if (!!user.subscription && !isFirstCompany && !isPaymentMethod && (nextQuantity > maxCompanies)) {
        return message.error('Please add a payment method before adding a new company.', 5)
      }
    }

    if (!!user.subscription && !user.subscription.isActive) {
      return message.error('Your subscription is not active. Please contact support.', 5)
    }

    if (isTrial) 
      return navigate('/addcompany')

    !!user.subscription && (quantity < nextQuantity)
      ? setLimitedModal(true)
      : navigate('/addcompany')
  }

  const handleUpgrage = () => {
    setLoading(true)
    const payload = { quantity: nextQuantity }
    api.put('/stripe/subscription', payload)
      .then(() => {
        message.success('Subscription upgraded', 5)
        setLimitedModal(false)
        navigate('/addcompany')
      })
      .catch(err => message.error(errorMsg(err), 5))
  }

  const limitModalProps = {
    onOk: handleUpgrage,
    onCancel: () => setLimitedModal(false),
    okText: quantity ? 'Upgrade' : 'Go to Billing'
  }

  const addModalFooter = [
    <FlexSpace style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }} key="fxs">
      <Button onClick={() => setAddModal(false)}>Cancel</Button>
      <Button style={{ padding: 0 }} onClick={onConnect} disabled={loading}>
        <img src={QBOImg} alt="connect to QuickBooks" style={{ height: 46 }} />
      </Button>
    </FlexSpace>
  ]

  const modalMsg = 'In order to add a new company, we will need to increase your monthly billing to account for the additional company.'

  return (
    <>
      <Btn type="primary" onClick={handleClick} title="Add Company" width={200} {...props} />

      <Modal title="Add Company" open={limitedModal} {...limitModalProps} key="upgdade_add">
        <FlexSpace direction="vertical">
          <Typography.Text>{modalMsg}</Typography.Text>
          <Typography.Text>
            Your new monthly subscription price will be <b>{nextTotal ? toCurrency(nextTotal) : 'still free'}</b>
          </Typography.Text>
        </FlexSpace>
      </Modal>

      <Modal title="Add Company" open={addModal} footer={addModalFooter} key="qbo_add" onCancel={() => setAddModal(false)}>
        <Typography.Text>Please click "Connect to QuickBooks" and choose QuickBooks Company to connect</Typography.Text>
      </Modal>
    </>
  )
}


let integrationProgress = false
export const queryCreateIntegration = (qboQuery: string, application?: number) => {
  if (integrationProgress) return Promise.resolve(null)
  integrationProgress = true
  return api.post<any>('/integrations/qbo', { url: qboQuery, application })
    .then(r => {
      integrationProgress = false
      return r.data
    })
}