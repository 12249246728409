import { useEffect, useState } from 'react'
import { Card, Divider, Spin, Typography, message } from 'antd'
import { PlaidEmbeddedLink, PlaidLinkOnSuccessMetadata } from 'react-plaid-link'

import { usePaidToken } from 'hooks/usePlaidToken'

import { FlexSpace } from 'components/FlexSpace'
import { Btn } from 'components/Btn'

import { ISetupData, deletePlaidItem, savePlaidToken } from '../utils'

const { Text, Title } = Typography

interface StepsProps {
  data: ISetupData
  onSubmit: () => void
  onBack: () => void
  onChange: () => void
}

export const BankStep = (props: StepsProps) => {
  const { data, onSubmit, onBack, onChange } = props

  const { token } = usePaidToken()

  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState<IPlaidItem[]>([])

  const status = data?.integrations?.find((i) => i.name === 'Plaid')?.status || 0
  const isConnect = status === 1 || meta.length > 0

  useEffect(() => {
    const plaidItems = (data?.integrations || [])
      .filter((i) => i.name === 'Plaid')
      .map(v => v.connectionInfo)

    setMeta(plaidItems as IPlaidItem[])
  }, [data?.integrations])
  
  const config = {
    token,
    onSuccess: (publicToken: string) => {
      console.log('onSuccess', meta)
      if (!data.id) return
      setLoading(true)
      savePlaidToken(publicToken, data.id)
        .then((r: IPlaidItem) => setMeta([...meta, r]))
        .finally(() => setLoading(false))
    },
    onExit: console.log,
  }

  const onDelete = (i: number) => {
    const item = meta[i]
    if (!data.id) return
    setLoading(true)
    deletePlaidItem(data.id, item.item_id)
      .then(message.success('Item deleted', 5))
      .catch(err => message.error(err.message, 5))
      .finally(() => onChange())
      .finally(() => setLoading(false))
  }

  return (
    <FlexSpace direction="vertical" size="large">
      <Title level={5}>Connect your banks and cards</Title>
      <Text strong>Please remember that we cannot and will not make any modifications to your apps. Our integrations are needed to pull financial data and our access can be revoked at any time.​</Text>
      <Spin spinning={!token}>
        {!!token && (
          <PlaidEmbeddedLink {...config} style={{ height: 344 }} />
        )}
      </Spin>
      {!!meta.length && (
        <Title level={5}>Connected banks and cards</Title>
      )}
      <Spin spinning={loading}>
        {meta.map((m, i) => (
          <Card key={i} title={m.institution?.name || 'Accounts'} extra={<Btn title="Delete" onClick={() => onDelete(i)}/>}>
            <FlexSpace direction="vertical">
            {m.accounts.map((a, j) => (
              <Text key={j}>{a.name} <small className='dimmed'>(*{a.mask})</small></Text>
            ))}
            </FlexSpace>
          </Card>
        ))}
      </Spin>
      <Divider />
      <FlexSpace>
        <Btn title="Back" onClick={onBack} />
        <Btn type="primary" title={isConnect ? 'Continue' : 'Skip'} onClick={onSubmit} />
      </FlexSpace>
    </FlexSpace>
  )
}

interface IPlaidItem {
  item_id: string
  institution: PlaidLinkOnSuccessMetadata['institution']
  accounts: PlaidLinkOnSuccessMetadata['accounts']
}