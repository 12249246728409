import { Helmet } from 'react-helmet'

interface PageTitleProps {
  title: string
}

export const HeadTitle: ReactFC<PageTitleProps> = ({ title, children }) => {
  return (
    <Helmet>
      <title>Equility : {title}</title>
      {children}
    </Helmet>
  )
}
