import { useState } from 'react'
import { Modal, Typography } from 'antd'
import { Link } from 'react-router-dom'

import { Btn } from 'components/Btn'

const linkUrl = 'https://www.irs.gov/businesses/small-businesses-self-employed/s-corporation-employees-shareholders-and-corporate-officers'

interface Props {
  visible: boolean
  onClose: (value?: string) => void
}

export const MinorServicesModal: ReactFC<Props> = (props) => {
  const {visible, onClose} = props

  const [step, setStep] = useState(0)

  const handleClick = (v: 'yes' | 'no') => {
    if (v === 'yes' && !step) return setStep(1)

    if (v === 'yes' && step) onClose('pass')
    if (v === 'no' && !step) onClose('pass')
    if (v === 'no' && step) onClose('fail')
    setStep(0)
  }

  const handleClose = () => {
    onClose()
    setStep(0)
  }

  const footer = [
    !step && <Btn title="Cancel" key="back" onClick={() => handleClose()} />,
    <Btn title="No" key="no" danger onClick={() => handleClick('no')} />,
    <Btn title="Yes" key="yes" type="primary" onClick={() => handleClick('yes')} />
  ]

  const first = <Typography.Text>
    Does the owner of the business provide more than minor services to their corporation?
  </Typography.Text>

  const second = <Typography.Text>
    When corporate owners/officers who provide more than minor services to their corporation are required
    to receive compensation that is subject to federal employment taxes 
    (<Link target="_blank" to={linkUrl}>IRS link</Link>).
    Please confirm whether the owner is on payroll:
  </Typography.Text> 

  return (
    <Modal open={visible} footer={footer} onCancel={() => handleClose()} title={'Setup'} width={500}>
      {step === 0 ? first : second}
    </Modal>
  )
}
