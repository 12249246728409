
import { AccountType, IRuleResult } from 'types/compliance.types'
import { toSafeDateString } from 'utils/dates'
import { toCurrency } from 'utils/numbers'
import { queryQboTransactions } from './apiutils'


interface IBalaceFundsRule {
  balances: any[]
  accountType: AccountType
  defaultRuleResult: IRuleResult
  accounts: any[]
  applicationId: number
  startDate: string
  endDate: string
  accountingMethod: string
}

export const balanceRule = async (sourceData: IBalaceFundsRule): Promise<IRuleResult> => {
  const { accounts } = sourceData
  const { accountType: at, applicationId, startDate, endDate } = sourceData
  const { defaultRuleResult, accountingMethod } = sourceData

  let accList = [...accounts].filter(v => v.accountSubType === at.accountSubTypes[0])
  if (at.name)
    accList = accList.filter(v => v.name === at.name)

  if (!accList.length) {
    defaultRuleResult.status = 'Passed'
    defaultRuleResult.results = [
      {
        message: 'You do not have any accounts of this type',
        status: 'Passed'
      }
    ]
  } else {
    for (let account of accList) {
      const { transactions, mapList } = await queryQboTransactions({
        applicationId: applicationId,
        from: toSafeDateString(startDate),
        to: toSafeDateString(endDate),
        accountQboId: account.qboId,
        unreconciled: false,
        accountingMethod
      })

      const meta =  {
        txs: [{
          type: at.accountType,
          list: mapList.map((v: any) => ({...v, acc: account})),
        }]
      }

      if (!!transactions?.Rows?.Row?.length || account.balance) {
        const lastBalance = mapList[mapList.length - 1]?.balance || 0          
        const generalBalance = account.balance || lastBalance

        if (generalBalance === 0) {
          defaultRuleResult.status = 'Passed'
          defaultRuleResult.results = [
            {
              message: `Your ${account.name} balance is $0.00 for this period`,
              status: 'Passed'
            }
          ]
        } else {
          const lastBalanceTxt = generalBalance ? toCurrency(generalBalance) : 'not $0.00'
          const lastBalanceDate = endDate
          defaultRuleResult.status = 'Failed'
          defaultRuleResult.results = [
            {
              message: `The balance of your ${account.name} account is ${lastBalanceTxt} on ${lastBalanceDate}`,
              status: 'Failed',
              meta
            }
          ]
        }
      } else {
        defaultRuleResult.status = 'Passed'
        defaultRuleResult.results = [
          {
            message: `Your ${account.name} balance is $0.00 for this period`,
            status: 'Passed',
          }
        ]
        
      }
    }
  }
  return defaultRuleResult
}
