import { useState } from 'react'
import { Layout } from 'antd'

import { Block } from 'components/Block'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { PlaidFetcher } from 'components/PlaidFetcher'

import { AppSidebar } from './AppSidebar'
import { TrialBanner } from 'components/TrialBanner'

const year = new Date().getFullYear()

export const AppLayout: ReactFC = (props) => {
  const { children } = props

  const [expand, setExpand] = useState(!localStorage.getItem('qe_sidebar'))

  const handleCollapse = (collapsed: boolean) => {
    setExpand(!collapsed)
  }

  const layoutClass = 'BaseLayoutContent ' + (expand ? '' : 'BaseLayoutContentExpanded')

  return (
    <Layout className="BaseLayout">
      <AppSidebar onCollapse={handleCollapse} />
      <Layout>
        <Layout.Content className={layoutClass}>
          <PlaidFetcher />
          <TrialBanner />
          <ErrorBoundary>{children}</ErrorBoundary>
        </Layout.Content>
        <Layout.Footer>
          <Block className="AppCopyright">
            <span>© 2022 - {year}, Equility.</span>
            &nbsp;
            <span>All rights reserved.</span>
            &nbsp;
            <a href="https://www.equilityhq.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
            &nbsp;
            <span>and</span>
            &nbsp;
            <a href="https://www.equilityhq.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>.
          </Block>
        </Layout.Footer>
      </Layout>
    </Layout>
  )
}
