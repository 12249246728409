import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Input, Modal, Space, Typography, theme } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { faMessage as faMessageEmpty } from '@fortawesome/free-regular-svg-icons'

import { Btn } from 'components/Btn'
import { DetailsModal } from './TxDetails.modal'

import { IResultMessage, IRuleResultStatus, RuleMessageActionType } from 'types/compliance.types'
import { currentUser } from 'recoil/user.state'
import { FlexSpace } from 'components/FlexSpace'
import { unixToDate } from 'utils/dates'

const { useToken } = theme

enum NoteVisible { None, Overdrive, Accurat, NonAccurate, Save }

interface MessageActionProps {
  btnProps?: any
  msg: IResultMessage
  finished?: boolean
  onChange: (value: IResultMessage) => void
}

export const MessageAction: ReactFC<MessageActionProps> = (props) => {
  const { msg, finished, btnProps, onChange } = props

  const { token } = useToken()
  const user = useRecoilValue(currentUser)

  const [note, setNote] = useState(msg.meta?.note || '')

  const [visible, setVisible] = useState(false)
  const [noteVisible, setNoteVisible] = useState<NoteVisible>(NoteVisible.None)
  const [selectedAccurate, setSelectedAccurate] = useState(0)

  const isReconcile = !!msg.meta?.isReconcile || !!msg.meta?.isUncat
  const isOveride = !!msg.meta?.overide
  const showAccurate = msg.action?.type === RuleMessageActionType.Force && !isReconcile
  const showDetails = !!msg.meta?.txs?.map((v: any) => v.list).flat().length

  const toggleDetails = () => setVisible(!visible)

  useEffect(() => {
    setNote(msg.meta?.note || '')
  }, [msg.meta])

  if (!user) return null

  const handleAccurate = (status: IRuleResultStatus, meta = msg.meta) => {
    const isAccurateText = status === 'Passed' ? 'accurate' : 'inaccurate'
    const username = (user.firstName || '') + (user.firstName ? ' ' : '') + (user.lastName || '') || user.email
    const startText = `${username} confirmed that the Undeposited Funds account balance`
    const addText = status === 'Passed' ? '' : 'Please amend the balance within QuickBooks and rerun the compliance check.'
    const endDateTxt = meta.endDate

    const _message = msg.message.replace(`<strong>${meta?.account?.name}</strong>`, '')

    const message = isReconcile
      ? isOveride ? meta.overide.src : `<b>${meta?.account.name}</b> ${username} overrode the failed status. ` + _message 
      : startText + ` <b>${msg.balance}</b> on <b>${endDateTxt}</b> is ${isAccurateText}. ` + addText

    setSelectedAccurate(status === 'Passed' ? 2 : 1)
    const overide = isAccurateText === 'accurate' ? { src: msg.message, username } : null

    onChange({ ...msg, status, message, meta: { ...meta, overide } })
  }

  const handleSaveNote = () => {
    const username = (user.firstName || '') + (user.firstName ? ' ' : '') + (user.lastName || '') || user.email
    const nextmeta = { ...msg.meta, note, last_updated_by: username, note_last_updated_on: Date.now() }

    switch (noteVisible) {
      case NoteVisible.NonAccurate:
        handleAccurate('Failed', nextmeta)
        break;
      case NoteVisible.Accurat:
      case NoteVisible.Overdrive:
        handleAccurate('Passed', nextmeta)
        break;
      default:
        onChange({ ...msg, meta: nextmeta })
        break;
    }
    
    setNoteVisible(NoteVisible.None)
  }

  if (finished) 
    return (
      <Space>
        <Btn 
          type="text"
          tooltip="Notes"
          icon={note
            ? <FontAwesomeIcon icon={faMessage} color={token.colorError} />
            : <FontAwesomeIcon icon={faMessageEmpty} />
          }
          onClick={() => setNoteVisible(NoteVisible.Save)}
        />
        {showDetails && (
          <Btn
            title="Details"
            type="link"
            danger={msg.status === 'Failed' || msg.status === 'Warning'}
            size="small"
            onClick={toggleDetails}
            {...btnProps}
          />
        )}
        <DetailsModal data={msg.meta} visible={visible} onCancel={toggleDetails} key={Date.now()} isReconcile={msg.meta?.isReconcile} />
        <Modal open={noteVisible !== NoteVisible.None} onCancel={() => setNoteVisible(NoteVisible.None)} onOk={() => setNoteVisible(NoteVisible.None)} title="Notes" okText="Save">
          <FlexSpace direction="vertical">
          <Input placeholder='Note' value={note} onChange={e => setNote(e.currentTarget.value)} disabled />
          {msg?.meta?.last_updated_by && (
            <Typography>Note by: <b>{msg.meta.last_updated_by}</b> on <b>{unixToDate(msg.meta.note_last_updated_on)}</b></Typography>
          )}
          </FlexSpace>
        </Modal>
      </Space>
    )

  if (showAccurate)
    return (
      <Space>
        <Btn 
          type="text"
          tooltip="Notes"
          icon={note
            ? <FontAwesomeIcon icon={faMessage} color={token.colorError} />
            : <FontAwesomeIcon icon={faMessageEmpty} />
          }
          onClick={() => setNoteVisible(NoteVisible.Save)}
        />
        <Btn danger={selectedAccurate === 1} type={selectedAccurate === 1 ? 'primary' : 'default'} title="Not Accurate" size="small" onClick={() => setNoteVisible(NoteVisible.NonAccurate)} />
        <Btn type={selectedAccurate === 2 ? 'primary' : 'default'} title="Accurate" size="small" onClick={() => setNoteVisible(NoteVisible.Accurat)} />
        <Modal open={noteVisible !== NoteVisible.None} onCancel={() => setNoteVisible(NoteVisible.None)} onOk={() => handleSaveNote()} title="Notes" okText="Save">
          <FlexSpace direction="vertical">
          <Input placeholder='Note' value={note} onChange={e => setNote(e.currentTarget.value)} />
          {msg?.meta?.last_updated_by && (
            <Typography>Note by: <b>{msg.meta.last_updated_by}</b> on <b>{unixToDate(msg.meta.note_last_updated_on)}</b></Typography>
          )}
          </FlexSpace>
        </Modal>
      </Space>
    )

  return (
    <Space>
      <Btn 
        type="text"
        tooltip="Notes"
        icon={note
          ? <FontAwesomeIcon icon={faMessage} color={token.colorError} />
          : <FontAwesomeIcon icon={faMessageEmpty} />
        }
        onClick={() => setNoteVisible(NoteVisible.Save)}
      />
      {showDetails && (
        <Btn
          title="Details"
          type="link"
          danger={msg.status === 'Failed' || msg.status === 'Warning'}
          size="small"
          onClick={toggleDetails}
          {...btnProps}
        />
      )}
      {isReconcile && !msg?.meta?.overide && msg.status !== 'Passed' && (
        <Btn type="default" title="Override" size="small" onClick={() => setNoteVisible(NoteVisible.Overdrive)} />
      )}
      {!!isOveride && (
        <Btn type="default" title="Revert" size="small" onClick={() => handleAccurate('Failed')} />
      )}
      <DetailsModal data={msg.meta} visible={visible} onCancel={toggleDetails} key={Date.now()} isReconcile={msg.meta?.isReconcile} />
      <Modal open={noteVisible !== NoteVisible.None} onCancel={() => setNoteVisible(NoteVisible.None)} onOk={() => handleSaveNote()} title="Notes" okText="Save">
        <FlexSpace direction="vertical">
        <Input placeholder='Note' value={note} onChange={e => setNote(e.currentTarget.value)} />
        {msg?.meta?.last_updated_by && (
          <Typography>Note by: <b>{msg.meta.last_updated_by}</b> on <b>{unixToDate(msg.meta.note_last_updated_on)}</b></Typography>
        )}
        </FlexSpace>
      </Modal>
    </Space>
  )
}
