import { Typography } from "antd"

interface Props {
  size: number,
  style?: React.CSSProperties
  className?: string
}

export const Header: ReactFC<Props> = (props) => {
  const { style, size, className, children, ...rest } = props

  const customClassName = `Header ${className}`

  return (
    <Typography.Title level={1} {...rest} style={{ fontSize: size, margin: 0, ...style }} className={customClassName}>
      {children}
    </Typography.Title>
  )
}