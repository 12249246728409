import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Col, Row } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { FullLoader } from 'components/FullLoader'

import { Difference } from '../components/Difference'
import { TxListBank } from '../components/TxListBank'
import { TxListQbo } from '../components/TxListQbo'

import { useMatcher } from 'hooks/useMatcher'
import { usePrompt } from 'hooks/useBlock'
import { currentAccounts, currentCompany } from 'recoil/companies.state'
import { IReconcile } from 'types/reconcile.types'
import { ITx } from 'types/tx.types'
import { api } from 'utils/axios'
import { qboToTxs } from 'utils/tx'
// import { log } from 'utils/log'

interface Props {
  result?: IReconcile
}

export const ReconcileResult: ReactFC<Props> = (props) => {
  const { result } = props

  const company = useRecoilValue(currentCompany)
  const accounts = useRecoilValue(currentAccounts)

  // const viewOnly = company?.role.id === 2000
  
  const height = useWindowHeight()
  const rowRef = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState(false)
  const [top, setTop] = useState(0)
  const [blockExit, setBlockExit] = useState(!result?.reconciliation_id)

  usePrompt('Results are unsaved. Are you sure you want to leave?', blockExit)

  const q = result?.temp_data.qbo || []
  const b = result?.temp_data.bank || []

  const matcher = useMatcher(q, b, result?.dayrange, result?.result || [])
  const { qbo, bank } = matcher

  useEffect(() => {
    setTop(rowRef?.current?.getBoundingClientRect().top || 0)
    setBlockExit(!result?.reconciliation_id)
  }, [result])

  useEffect(() => {
    if (matcher.bank.length && matcher.qbo.length) {
      matcher.matchAll()
    }
  }, [matcher.bank.length, matcher.qbo.length])

  const minHeight = top ? height - top - 225 : 0 // 242

  const clearedQbo = qbo.filter((v) => v.matchedTo?.length || v.forceMatch)
  const selectedBank = bank.filter((v) => v.selected && !v.matchedTo?.length)
  const diffData = getDiffData([...clearedQbo, ...selectedBank], result)

  const account = accounts
    .find(v => v.qboId === result?.qbo_account_id)

  if (!result || !account) return <FullLoader />

  const handleRefresh = () => {
    setLoading(true)

    const params = {
      accountQboId: account.qboId,
      applicationId: company?.id,
      from: result.start_date,
      to: result.end_date,
      unreconciled: true,
      includePrevUncleared: true
    }

    api.get('/integrations/qbo/transactions', { params })
      .then(({ data }) => {
        const qbolist = qboToTxs(data.mapList)
        matcher.refreshQbo(qbolist)
      })
      .catch()
      .finally(() => setLoading(false))
  }

  return (
    <FlexSpace direction="vertical" className="FullHeight" style={{ paddingTop: 16 }}>
      <Difference account={account} data={diffData} rec={result} matcher={matcher} setBlockExit={setBlockExit} />
      <Row gutter={25} ref={rowRef} style={{ minHeight: minHeight + 180 }}>
        <Col span={12}>
          <TxListQbo
            loading={loading}
            list={qbo}
            matcher={matcher}
            matchList={bank}
            minHeight={minHeight}
            onMatch={() => {}}
            onRefresh={handleRefresh}
          />
        </Col>
        <Col span={12}>
          <TxListBank
            matcher={matcher}
            minHeight={minHeight}
            account={account}
            onRefresh={handleRefresh}
          />
        </Col>
      </Row>
    </FlexSpace>
  )
}

function useWindowHeight() {
  const [size, setSize] = useState(0)
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerHeight)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

function getDiffData(diffArr: ITx[], result?: IReconcile) {
  return {
    deposits: diffArr.filter((v) => v.amount > 0).length,
    payments: diffArr.filter((v) => v.amount <= 0).length,
    depositsAmount: diffArr.filter((v) => v.amount > 0).reduce((prev, v) => prev + v.amount, 0),
    paymentsAmount: diffArr.filter((v) => v.amount <= 0).reduce((prev, v) => prev + v.amount, 0),
    startingBalance: parseFloat(result?.starting_balance || '0'),
    endingBalance: parseFloat(result?.ending_balance || '0')
  }
}
