import { IAuthCredentials } from 'types/auth.types'
import { log } from './log'
import { getAuthCredentials } from './axios'

export function isPluginInstalled() {
  return !!document.documentElement.hasAttribute('equility-extension')
}

export function isPluginAuth(email: string) {
  return new Promise((resolve, reject) => {
    window.addEventListener(
      'message',
      function (event: any) {
        // We only accept messages from this window to itself [i.e. not from any iframes]
        if (event.source !== window) return

        if (event.data.type && event.data.type === 'FROM_PLUGIN') {
          log('isPluginAuth', !!email && email === event?.data?.r?.email)
          if (event.data.message.type === 7) {
            !!event.data.r
              ? email === event.data.r.email
                ? resolve(true)
                : reject(false)
              : reject(false)
          }
        }
      },
      false
    )

    // console.log('isPluginAuth start')

    const data = { type: 'FROM_PAGE_AUTH' }
    window.postMessage(data, '*')

    setTimeout(() => {
      // console.log('isPluginAuth timeout')
      reject(false)
    }, 1000)
  })
}


window.addEventListener(
  'message',
  function (event: any) {
    // We only accept messages from this window to itself [i.e. not from any iframes]
    if (event.source !== window) return

    if (event.data.type && event.data.type === 'GET_AUTH') {
      const auth = getAuthCredentials()
      shareAuth(auth)
      // console.log('shareAuth', auth)
    }
  },
  false
)

export function shareAuth(auth: IAuthCredentials | null) {
  const data = { type: 'FROM_PAGE_SETAUTH', auth }
  window.postMessage(data, '*')
  // console.log('shareAuth', data)
}