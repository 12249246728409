export default class CustomEvent {
  event: Event
  name: string

  constructor(name: string) {
    this.name = name
    this.event = new Event(name)
  }

  dispatch() {
    document.dispatchEvent(this.event)
  }

  subscribe(cb: () => void) {
    document.addEventListener(this.name, () => {
      console.log(`The ${this.name} event was triggered`)
      cb()
    })
  }
}

export const logoutEvent = new CustomEvent('logout')