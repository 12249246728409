import { atom } from 'recoil'

const appAtom = atom<IApp>({
  key: 'app',
  default: {},
})

export { appAtom }

interface IApp {
  invite?: string
}