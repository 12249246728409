// import { useNavigate } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useSetRecoilState } from 'recoil'
import { Alert, Button, Descriptions, Divider, message, Typography } from 'antd'

import { Page } from 'components/Page'
import { Btn } from 'components/Btn'
import { Block } from 'components/Block'
import { FlexSpace } from 'components/FlexSpace'
import { HeadTitle } from 'components/HeadTitle'
import { ChangePassword } from './ChangePassword'
import { ChangeEmail } from './ChangeEmail'

import { authAtom } from 'recoil/auth.state'
import { currentUser } from 'recoil/user.state'
import { stringToDate } from 'utils/dates'
import { api } from 'utils/axios'

export const Account: ReactFC = () => {
  const user = useRecoilValue(currentUser)
  const setAuth = useSetRecoilState(authAtom)
  const refreshUserInfo = useRecoilRefresher_UNSTABLE(currentUser)

  const handleLogout = () => setAuth(null)

  const invites = user?.invites || []

  const handleInvite = (id: string) => {
    api.patch('/invites', { id })
      .then(() => {
        refreshUserInfo()
        message.info('Invite accepted')
      })
      .catch(() => message.warning('Invite is not acceptable'))
  }

  const declineInvite = (id: string) => {
    api.delete('/invites/' + id)
      .then(() => {
        refreshUserInfo()
        message.info('Invite declined')
      })
      .catch(() => message.warning('Invite is not declinable'))
  }

  if (!user) return null

  return (
    <Page title="Account">
      <HeadTitle title="Account" />
      <FlexSpace direction="vertical">
        {!!invites.length && (
          <>
            <FlexSpace direction="vertical">
              {invites.map((v) => (
                <Alert
                  showIcon
                  type="warning"
                  message={`You've been invited to "${v.application.name}"`}
                  action={
                    <FlexSpace>
                      <Button size="small" type="text" onClick={() => handleInvite(v.id)}>
                        Accept
                      </Button>
                      <Button danger size="small" type="text" onClick={() => declineInvite(v.id)}>
                        Decline
                      </Button>
                    </FlexSpace>
                  }
                />
              ))}
            </FlexSpace>
            <Divider />
          </>
        )}
        <Descriptions bordered>
          <Descriptions.Item label="First name" span={3}>
            {user?.firstName}
          </Descriptions.Item>
          <Descriptions.Item label="Last name" span={3}>
            {user?.lastName}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {user?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Email verified" span={3}>
            {user?.isEmailVerified ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Created on" span={3}>
            {stringToDate(user?.createdOn || '')}
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        {/* {!user.googleSignIn && <ChangeEmail />}
        {!user.googleSignIn && !!user.firebaseUid && <ChangePassword />}
        {(user.googleSignIn || !user.firebaseUid) && (
          <Block>
            <Typography.Title level={4}>Change Password</Typography.Title>
            <Typography.Text>
              You're using your Google account to sign in so there's no password to change.
            </Typography.Text>
            <Divider />
          </Block>
        )} */}
        <Btn title="Logout" danger onClick={() => handleLogout()} />
      </FlexSpace>
    </Page>
  )
}
