import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { message } from 'antd'

import { useSessionTimeout } from 'hooks/useSessionTimeout'

import { authAtom } from 'recoil/auth.state'
import { config } from 'utils/config'


export const SessionTimeout = () => {
  const setAuth = useSetRecoilState(authAtom)
  const [expired, resetSessionTimeout] = useSessionTimeout()

  useEffect(() => {
    resetSessionTimeout()
  }, [])

  useEffect(() => {
    if (!expired) return
    if (config.isLocal) return
    message.warning({ content: 'Your session has expired', key: 'expired', duration: 5 })
    setAuth(null)
  }, [expired])

  return null
}
