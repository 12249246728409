import { Rule } from 'antd/lib/form'

export const rules: { [key: string]: Rule[] } = {
  firstName: [{ required: true, message: 'Please input your first name' }],
  lastName: [{ required: true, message: 'Please input your last name' }],
  email: [
    { required: true, message: 'Please input your email' },
    { type: 'email', message: 'Invalid email' }
  ],
  password: [
    { required: true, message: 'Please input your password' },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/,
      message: 'Must contain 9 characters, one uppercase, one lowercase, one number and one special case character'
    }
  ],
  appsumo: [{ required: true, message: 'Please input your AppSumo Code' }],
  terms: [
    { validator: (_, value) => value ? Promise.resolve() : Promise.reject('Please indicate that you have read and agree to the Privacy Policy and Terms of Use')}
  ],
  source: [{ required: true, message: 'Please select' }],
  sourcedetails: [{ required: true, message: 'Please fill out this field' }]
}