import { Button, Flex } from 'antd'

import { IProvider } from 'types/provider.type'

interface ProviderBtnProps {
  data: IProvider
  onClick: () => void
}

export const ProviderBtn = (props: ProviderBtnProps) => {
  const { data, onClick } = props

  return (
    <Button style={{ height: 70 }} className='btn-custom' disabled={!data.active} onClick={onClick}>
      <Flex align="center" justify="center" style={btnStyle}>
        <img src={data.logo} alt={data.title} style={data.imageStyle} />
        {!data.active && <span style={spanStyle}>Comming Soon</span>}
      </Flex>
    </Button>
  )
}

const spanStyle: React.CSSProperties = {
  opacity: 1,
  position: 'absolute',
  top: 0,
  fontSize: 10,
}

const btnStyle: React.CSSProperties = {
  width: 200,
  height: 70,
  overflow: 'hidden',
}
