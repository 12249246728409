import { CsvBoxTx, ITx } from 'types/tx.types'
import { toSafeDecimal } from './numbers'

export function toTransaction() {}

export function csvBoxToTxs(data: CsvBoxTx[], invertAmount?: boolean) {
  return data.map((v, i) => {
    const id = uid()
    const amount = toRegularNumber(v.Amount)
    const expence = toRegularNumber(v.Expence)
    const received = toRegularNumber(v.Received)
    const spent = v.Spent ? 0 - toRegularNumber(v.Spent) : 0
    const payment = v.Payment ? 0 - toRegularNumber(v.Payment) : 0

    console.log(amount, expence, received, spent, payment)
    
    const total = amount || expence || received || (spent || payment)

    return {
      _id: id,
      id: id,
      date: v.Date,
      amount: toSafeDecimal(invertAmount ? 0 - total : total),
      description: v.Description,
      memo: v.Description,
      matchedTo: []
    }
  })
}

function toRegularNumber(value?: string) {
  if (!value) return 0

  const v = value.replaceAll(/,/g, '') // remove commas

  if (v.includes('(')) {
    return 0 - parseFloat(v.replace(/\(|\)/g, ''))
  }
  if (v.includes('-')) {
    return parseFloat(v)
  }
  return parseFloat(v)
}

export function csvToTxs(data: string[][], bankFormat: any, invertAmount?: boolean): ITx[] {
  const [header, ...list] = data

  const dateIndex = header.indexOf(bankFormat.date)
  const amountIndex = header.indexOf(bankFormat.amount)
  const descriptionIndex = header.indexOf(bankFormat.description)

  return list.map((v, i) => {
    const amount = parseFloat(v[amountIndex])
    return {
      _id: i + v[dateIndex] + v[amountIndex],
      id: i,
      date: v[dateIndex],
      amount: invertAmount ? 0 - amount : amount,
      description: v[descriptionIndex],
      memo: v[descriptionIndex],
      matchedTo: []
    }
  })
}

export function pladToTxs(data: [], invertAmount?: boolean): ITx[] {
  return data.map((v: any) => {
    return {
      _id: v.transaction_id,
      id: v.transaction_id,
      date: v.date,
      amount: invertAmount ? 0 - v.amount : v.amount,
      description: v.original_description || v.payment_meta?.reason || v.name || '',
      memo: v.original_description || v.payment_meta?.reason || v.name || '',
      matchedTo: []
    }
  })
}

export function qboToTxs(data: []): ITx[] {
  return data.map((v: any) => {
    return {
      _id: uid(),
      matchedTo: [],
      description: v.memo,
      ...v
    }
  })
}

export function uid() {
  let a = new Uint32Array(3);
  window.crypto.getRandomValues(a);
  return (performance.now().toString(36)+Array.from(a).map(A => A.toString(36)).join("")).replace(/\./g,"");
 }