
export interface IRule {
  id: number
  key: string
  title: string
  description: string
  isDefault: boolean
  isSelected: boolean
  failureMsg: string
  type: string
  config?: IRuleConfig | null
  children?: IRule[]

  name: string
  
  app_rule_id?: number
  rule_id?: number
  disabled?: boolean
}

export interface IRuleConfig {
  accounts?: string[]
  owner?: string
}

export interface IRules {
  r: any[]
  checklist_rules: IRule[]
  application_rules: { id: number; rule: IRule, config: null | IRuleConfig }[]
}

export interface ICompliance {
  id?: number
  application_id: number
  checked_on?: string
  checked_through_start: string
  checked_through_end: string
  results: IRuleResult[]
  finished?: boolean
  method?: string
  meta?: any
}

export enum RuleMessageActionType {
  URL,
  Force
}

export type IRuleMessageAction = {
  text: string
  url?: string
  type: RuleMessageActionType
}

export type IRuleResultStatus = 'Passed' | 'Failed' | 'Warning'

export type IResultMessage = {
  status: IRuleResultStatus
  message: string
  balance?: string
  action?: IRuleMessageAction
  meta?: any
}

export interface IRuleResult {
  id: number
  ruleName: string
  ruleDescription: string
  info?: string
  status: IRuleResultStatus
  link_url?: string
  results?: IResultMessage[]
  rule: IRule
}

export const payrollAccountMapping = {
  PayrollExpenses: 'Payroll Expenses',
  PayrollTaxExpenses: 'Payroll Tax Expenses',
  PayrollWageExpenses: 'Payroll Wage Expenses'
}

export const equityAccountMapping = {
  OwnersEquity: "Owner's Equity",
  PartnerContributions: 'Partner Contributions',
  PartnerDistributions: 'Partner Distributions',
  PartnersEquity: "Partner's Equity",
  PersonalExpense: 'Personal Expense',
  PersonalIncome: 'Personal Income'
}

export interface AccountType {
  name?: string
  accountType: string
  accountSubTypes: string[]
}

export const corporationEquityType: AccountType = {
  accountType: 'Equity',
  accountSubTypes: [
    'OwnersEquity', 
    'PartnerContributions', 
    'PartnerDistributions', 
    'PartnersEquity',
    'AccumulatedAdjustment', 
    'EstimatedTaxes',
    'Healthcare',
    'HealthSavingsAccountContributions',
    'PersonalExpense', 
    'PersonalIncome'
  ]
}

export const soleProprietorEquityType: AccountType = {
  accountType: 'Equity',
  accountSubTypes: [
    'CommonStock',
    'PaidInCapitalOrSurplus',
    'PartnerContributions',
    'PartnerDistributions',
    'PartnersEquity',
    'PreferredStock',
    'TreasuryStock'
  ]
}

export const partnershipEquityType: AccountType = {
  accountType: 'Equity',
  accountSubTypes: ['CommonStock', 'PaidInCapitalOrSurplus', 'PreferredStock', 'TreasuryStock','OwnersEquity'],
}

export const sCorporationEquityType: AccountType = {
  ...soleProprietorEquityType,
  accountSubTypes: [...soleProprietorEquityType.accountSubTypes]
}

export const nonProfitEquityType: AccountType = {
  ...soleProprietorEquityType,
  accountSubTypes: [
    ...soleProprietorEquityType.accountSubTypes, 
    'OwnersEquity',
    'AccumulatedAdjustment', 
    'EstimatedTaxes',
    'Healthcare',
    'HealthSavingsAccountContributions',
    'PersonalExpense', 
    'PersonalIncome'
  ]
}

export const payrollType: AccountType = {
  accountType: 'Expense',
  accountSubTypes: ['PayrollExpenses', 'PayrollTaxExpenses', 'PayrollWageExpenses']
}

export const liabilitiesType: AccountType = {
  accountType: 'Long Term Liabilities',
  accountSubTypes: ['ShareholderNotesPayable']
}

export const assetsType: AccountType = {
  accountType: 'Other Current Asset',
  accountSubTypes: ['LoansToStockholders']
}

export const equityType: AccountType = {
  accountType: 'Equity',
  accountSubTypes: ['OpeningBalanceEquity']
}

export const uncategorizedAsset: AccountType = {
  name: 'Uncategorized Asset',
  accountType: 'Other Current Asset',
  accountSubTypes: ['OtherCurrentAssets']
}

export const otherAssetsType: AccountType = {
  name: 'Uncategorized Asset',
  accountType: 'Other Current Asset',
  accountSubTypes: ['OtherCurrentAssets']
}

export const depricationType: AccountType = {
  accountType: 'Other Expense',
  accountSubTypes: ['Depreciation']
}
