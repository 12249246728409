import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer, BarChart, Bar, AreaChart, Area,
} from 'recharts';
import {ChartComponentProps} from "../types/chart.types";
import {ChartCustomTooltip} from "./ChartCustomTooltip";
import {Result} from "antd";


export const ChartComponent: React.FC<ChartComponentProps> = ({ chartDataSets, type }) => {
    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300'];
    const data: any[] = [];

    const formatYAxisTick = (value: number) => {
        if (Math.abs(value) >= 1000) {
            return `${ Math.sign(value) * Math.round(Math.abs(value) / 1000) }k`;
        }
        return value.toString();
    };


    chartDataSets.forEach(d => {
        const values = d.values;
        values.forEach(v => {
            const foundedIndex = data.findIndex(k => v.date === k.date);
            if (foundedIndex !== -1) {
                data[foundedIndex] = { ...data[foundedIndex], [d.key]: v.amount };
            } else {
                data.push({
                    ...v, [d.key]: v.amount
                });
            }
        });
    });

    return (
        <>
        {data.length === 0 && <EmptyList /> }
            {type === 'line' && (<ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}
                           margin={{top: 5, right: 20, bottom: 5, left: 0}}>
                    <XAxis dataKey={'date'}/>
                    <YAxis tickFormatter={formatYAxisTick}/>
                    <Tooltip content={<ChartCustomTooltip />} />
                    {chartDataSets.map((data, index: number) => (
                        <Line
                            key={`line-${index}`}
                            type="monotone"
                            dataKey={data.key}
                            stroke={colors[index % colors.length]}
                            activeDot={{r: 8}}
                            strokeWidth={2}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>)}
            {type === 'bar' && (<ResponsiveContainer width="100%" height={300}>
                <BarChart data={data}
                           margin={{top: 5, right: 20, bottom: 5, left: 0}}>
                    <XAxis dataKey={'date'}/>
                    <YAxis tickFormatter={formatYAxisTick}/>
                    <Tooltip content={<ChartCustomTooltip />} />
                    {chartDataSets.map((data, index: number) => (
                        <Bar
                            key={`line-${index}`}
                            dataKey={data.key}
                            fill={colors[index % colors.length]}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>)}
            {type === 'area' && (<ResponsiveContainer width="100%" height={300}>
                <AreaChart data={data}
                          margin={{top: 5, right: 20, bottom: 5, left: 0}}>
                    <XAxis dataKey={'date'}/>
                    <YAxis tickFormatter={formatYAxisTick}/>
                    <Tooltip content={<ChartCustomTooltip />} />
                    {chartDataSets.map((data, index: number) => (
                        <Area
                            key={`line-${index}`}
                            type="monotone"
                            dataKey={data.key}
                            fill={colors[index % colors.length]}
                            stackId={data.key}
                            stroke="#8884d8"
                            strokeWidth={2}
                        />
                    ))}
                </AreaChart>
            </ResponsiveContainer>)}
        </>
    );
};


const EmptyList = () => {
    return (
            <Result
                icon={false}
                title="No report data!"
            />
    )
}
