import { atom, AtomEffect } from 'recoil'

import * as api from 'utils/axios'
import { IAuth } from 'types/auth.types'
import { logoutEvent } from 'utils/event'
import { shareAuth } from 'utils/plugin'


const localStorageEffect: AtomEffect<IAuth> = ({ onSet, setSelf }) => {
  onSet((newValue) => {
    api.setAuthCredentials(newValue)
    shareAuth(newValue)
  })

  logoutEvent.subscribe(() => setSelf(null))
}

const authAtom = atom<IAuth>({
  key: 'auth',
  default: api.getAuthCredentials(),
  effects: [localStorageEffect]
})

export { authAtom }