import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Popconfirm, Segmented, Table, Tooltip, Typography } from 'antd'

import { ColorAmount } from 'components/ColorAmount'
import { FlexSpace } from 'components/FlexSpace'
import { Btn } from 'components/Btn'
import { VList } from 'components/Virtuallist'

import { MatchModal } from './MatchTx.modal'

import { sortByDate, stringToDate } from 'utils/dates'
import { ITx } from 'types/tx.types'
import { IMatcher } from 'types/matcher'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faLink } from '@fortawesome/free-solid-svg-icons'
import { currentCompany } from 'recoil/companies.state'

type FilterTx = 'Matched' | 'Unmatched' | 'All'

interface Props {
  loading: boolean
  list: ITx[]
  matcher: IMatcher
  matchList: ITx[]
  minHeight?: number
  onMatch: () => void
  onRefresh: () => void
}

export const TxListQbo: ReactFC<Props> = (props) => {
  const { loading, list, minHeight, matcher, onRefresh } = props

  const company = useRecoilValue(currentCompany)
  const viewOnly = company?.role.id === 2000

  const [filter, setFilter] = useState<FilterTx>('Unmatched')
  const [matchModal, setMatchModal] = useState(false)
  const [selectedTx, setSetSelectedTx] = useState<ITx[]>([])
  const [hover, setHover] = useState<number>(-1)

  const height = Math.max(minHeight || 0, 200)

  const cleared = list.filter((tx) => tx.matchedTo?.length || tx.forceMatch)
  const selected = list.filter((v) => v.selected)

  let dataSource =
    filter === 'Matched'
      ? list.filter((tx) => tx.matchedTo?.length || tx.forceMatch)
      : filter === 'Unmatched'
        ? list.filter((tx) => !tx.matchedTo?.length && !tx.forceMatch)
        : list

  dataSource = dataSource.filter((tx) => !tx.deleted)

  const vComponents = useMemo(
    () => VList({ height, vid: 'qbo', debounceListRenderMS: 100, resetTopWhenDataChange: false }),
    [filter, height]
  )

  useEffect(() => {
    const el = document.querySelector('.QBOTable .ant-table-body')
    if (el) el.scrollTop = 0
  }, [filter])

  if (!height) return null

  const handleClick = (tx: ITx) => {
    setSetSelectedTx([tx])
    setMatchModal(true)
  }

  const toggleMatch = (tx: ITx) => {
    if (tx.forceMatch || tx.matchedTo.length) matcher.unmatch(tx)
    else matcher.forcematch(tx)
  }

  const handleDelete = () => {
    matcher.markAsDeleted(selected.map(v => v._id))
  }

  return (
    <FlexSpace direction="vertical" style={{ paddingTop: 20 }} size="large" className="FullHeight">
      <FlexSpace spacebetween>
        <Typography.Title level={5} style={{ margin: 0 }}>
          QuickBooks Transactions: {list.length} total
        </Typography.Title>
        <Typography.Text className="Faded">Matched: {cleared.length}</Typography.Text>
      </FlexSpace>
      <FlexSpace direction="horizontal" spacebetween style={{ flexWrap: 'wrap' }}>
        <Segmented options={['Matched', 'Unmatched', 'All']} value={filter} onChange={(v: any) => setFilter(v)} />
        {!viewOnly && !selected.length && (
          <Btn title="Refresh" onClick={onRefresh} loading={loading} />
        )}
        {!viewOnly && !!selected.length && (
          <FlexSpace>
            <Btn 
              danger 
              title="Delete" 
              onClick={() => handleDelete()} 
              confirm="Are you sure you want to delete reconciliations?"
            />
          </FlexSpace>
        )}
      </FlexSpace>
      <MatchModal
        type="qbo"
        list={selectedTx}
        matcher={matcher}
        open={matchModal}
        onClose={() => setMatchModal(false)}
      />
      <Table
        loading={loading}
        dataSource={dataSource}
        columns={columns(handleClick, toggleMatch, hover, viewOnly)}
        rowKey={'_id'}
        className="RecTable Table QBOTable"
        pagination={false}
        components={vComponents}
        scroll={{ y: height, scrollToFirstRowOnChange: true }}
        rowSelection={{
          columnWidth: 50,
          hideSelectAll: false,
          selectedRowKeys: selected.map((v) => v._id),
          onChange: (ids) => matcher.selectQbo(ids as string[]),
          getCheckboxProps: (tx: ITx) => ({ disabled: isMatched(tx) })
        }}
        onRow={(_, rowIndex) => {
          return {
            onMouseEnter: () => { setHover(rowIndex || 0) },
            onMouseLeave: () => { setHover(-1) },
          }
        }}
      />
    </FlexSpace>
  )
}

function columns(onClick: (tx: ITx) => void, toggleMatch: (tx: ITx) => void, hover: number, viewOnly: boolean) {
  return [
    {
      title: 'Date',
      width: 130,
      dataIndex: 'date',
      className: 'RecTableTd TdHover',
      render: (value: string) => stringToDate(value, 'MM-DD-YYYY'),
      sorter: (a: ITx, b: ITx) => sortByDate(a.date, b.date),
      onCell: (tx: ITx) => ({ onClick: () => onClick(tx) })
    },
    {
      title: 'Amount',
      width: 130,
      dataIndex: 'amount',
      className: 'TdHover',
      render: (v: number) => <ColorAmount amount={v} />,
      sorter: (a: ITx, b: ITx) => a.amount - b.amount,
      onCell: (tx: ITx) => ({ onClick: () => onClick(tx) })
      // ...filterSearch('amount')
    },
    {
      title: 'Memo',
      dataIndex: 'description',
      className: 'TdHover',
      ellipsis: true,
      onCell: (tx: ITx) => ({ onClick: () => onClick(tx) })
      // ...filterSearch('description')
    },
    {
      title: 'Matched',
      dataIndex: 'id',
      width: 100,
      render: (_: any, tx: ITx, i: number) => {
        const ckEl = tx.forceMatch || tx.matchedTo?.length ? (
          <Popconfirm title={unclearMsg} okText="Yes" cancelText="No" onConfirm={() => toggleMatch(tx)}>
            <div style={{ opacity: 0.5 }} className="ck"><div></div></div>
          </Popconfirm>
        ) : (
          <Popconfirm title={clearMsg} okText="Yes" cancelText="No" onConfirm={() => toggleMatch(tx)}>
            <div style={{ opacity: 0.5 }} className="ck" />
          </Popconfirm>
        )

        const ckElViewOnly =  tx.forceMatch || tx.matchedTo.length 
          ? <div style={{ opacity: 0.5 }} className="ck"><div></div></div>
          : <div style={{ opacity: 0.5 }} className="ck" />

        return <FlexSpace>
          <div>{viewOnly ? ckElViewOnly : ckEl}</div>
          <div style={{ opacity: i === hover ? 1 : 0 }}>
            {!tx.forceMatch && !tx.matchedTo.length ? (
              <Tooltip title="Click to match" mouseEnterDelay={1}>{' '}
                <FontAwesomeIcon className='Pointer' icon={faLink} color="#0F9648" transform={{ rotate: 45 }} onClick={() => onClick(tx)} />
              </Tooltip>) : (
                <FontAwesomeIcon className='Pointer' icon={faCircleInfo} color="#CACACA"  onClick={() => onClick(tx)} />
            )}
          </div>
        </FlexSpace>
      }
    }
  ]
}

const isMatched = (tx: ITx): boolean => tx.matchedTo.length > 0 || !!tx.forceMatch

var unclearMsg = 'This transaction will be marked as uncleared within QuickBooks. Are you sure?'
var clearMsg = 'This transaction will be marked as cleared within QuickBooks. Are you sure?'
