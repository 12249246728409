import { useEffect, useState } from 'react'
import { useRutterLink } from 'react-rutter-link'
import { Alert, Divider, Flex, Spin, Tag, Typography, message, theme } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { Btn } from 'components/Btn'

import { ISetupData, createIntergation, getAccounts } from '../utils'
import { MatchAccounts } from '../components/MatchAccounts'
import { IAccount } from 'types/company.types'
import { IProvider } from 'types/provider.type'
import { ProviderBtn } from '../components/ProviderBtn'
import { ShopifyConnect } from 'components/ShopifyConnect'

const { Text, Title } = Typography
const { useToken } = theme

interface StepsProps {
  data: ISetupData
  onSubmit: (data: ISetupData) => void
  onChange: () => void
  onBack: () => void
}

type Matched = { [key: string]: string[] }

export const CommercialStep = (props: StepsProps) => {
  const { data, onSubmit, onChange, onBack } = props

  const { token } = useToken()

  const isRutter = !!data?.integrations?.find(v => v.name === 'Rutter')

  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(isRutter)
  const [accounts, setAccounts] = useState<IAccount[]>([])
  const [matched, setMatched] = useState<Matched>(data.settings?.merchant?.matched || {})

  useEffect(() => {
    if (!data?.id) return
    if (isRutter) setDone(true)   
  }, [data])

  // console.log('data', data?.integrations)
  // console.log('status', data?.integrations?.find((i) => i.name === 'Rutter')?.status)

  const integration = data?.integrations?.find((i) => i.name === 'Rutter')
  const itegrationStaus = integration?.status
  const status: number = itegrationStaus || (done ? 2 : 0)
  const store = (integration?.connectionInfo as any)?.store
  const storeName = store?.store?.store_name ? `(${store?.store?.store_name})` : ''

  useEffect(() => {
    if (!data?.id) return
    setLoading(true)
    getAccounts(data.id)
      .then(setAccounts)
      .finally(() => setLoading(false))
  }, [])

  const rutterConfig = {
    publicKey: 'f1d459e4-c68f-459f-8655-fc5feeb48437', // PROD
    onSuccess: (token: string) => {
      setLoading(true)
      console.log('Rutter token', token)
      createIntergation(data.id as number, token, 'Rutter')
        .then(() => setDone(true))
        .catch(console.log)
        .finally(() => {
          setLoading(false)
          onChange()
        })        
    },
    onError: console.log,
    onEvent: (data: any, event: any) => console.log(111, data, event),
    onLoad: console.log,
    debug: true,
  }

  // console.log('CommercialStep', data, done)

  const { error, open } = useRutterLink(rutterConfig)

  // console.log(error, ready)

  // const onShopifyClick = () => {
  //   if (!data?.id) {
  //     message.error('Please connect your accounting platform first')
  //     return
  //   } 
  //   open({ platform: 'SHOPIFY' })
  // }

  const onClick = (p: IProvider) => {
    if (!data?.id)
      return message.error('Please connect your accounting platform first')

    open({ platform: p.id })
  }

  if (done) {
    return (
      <FlexSpace direction="vertical" size="large">
        <FlexSpace spacebetween style={{ alignItems: 'baseline' }}>
          <Title level={5}>
            <FlexSpace size="large">
              <span>E-commerce platform: Shopify {storeName}</span>
              {status === 0 && <Tag color={token.colorError}>disconnected</Tag>}
              {status === 1 && <Tag color={token.colorSuccess}>active</Tag>}
              {status === 2 && <Tag color={token.colorWarning}>syncing...</Tag>}
            </FlexSpace>
          </Title>
          <FlexSpace>
            {/* <Btn
              title="Reconnect" 
              onClick={() => onShopifyClick()}
              type={status === 0 ? 'primary' : 'default'}
            /> */}
            {/* {status === 1 && (
              <Btn title="Disconnect" loading={loading} onClick={onDisconnect} disabled />
            )} */}
          </FlexSpace>
        </FlexSpace>
        <Text>We need to know how you match your e-commerce data to your general ledger accounts</Text>
        <Text>Setup your accounts:</Text>
        <MatchAccounts
          value={matched}
          baseAccs={shopifyAccounts}
          accounts={accounts}
          onChange={v => setMatched({...matched, ...v,})}
        />
        <Divider />
        <FlexSpace>
          <Btn title="Back" onClick={onBack} />
          <Btn type="primary" title="Continue" onClick={() => {
            const settings = { ...data.settings, merchant: { matched } }
            onSubmit({ ...data, settings })
          }} />
        </FlexSpace>
      </FlexSpace>
    )
  }

  const ShopifyTemp = () => <ShopifyConnect 
    companyId={data.id}
    height={70}
    title={
      <Flex align="center" justify="center" style={btnStyle}>
        <img src={shopifyTemp.logo} alt={shopifyTemp.title} style={shopifyTemp.imageStyle} />
      </Flex>
    }
    onChange={(token) => {
      console.log('Shopify token', token)
      onChange()
    }}
  />

  return (
    <FlexSpace direction="vertical" size="large">
      <Title level={5}>Connect your merchant platform</Title>

      <Text>
        Please remember that we cannot and will not make any modifications to your apps. Our integrations are needed to
        pull financial data and our access can be revoked at any time.
      </Text>

      <Spin spinning={loading}>
        <Flex wrap="wrap" gap="small" style={{ maxWidth: 1000 }}>
          <ShopifyTemp />
          {providers.map((p) => (
            <ProviderBtn onClick={() => onClick(p)} data={p} key={p.id} />
          ))}
        </Flex>
      </Spin>

      {error && <Alert type="error" message={error.message} />}


      <Divider />
      <FlexSpace>
        <Btn title="Back" onClick={onBack} />
        <Btn type="primary" title="Skip" onClick={() => onSubmit(data)} />
      </FlexSpace>
    </FlexSpace>
  )
}

const shopifyTemp = {
  id: 'SHOPIFY',
    title: 'Shopify',
    logo: 'https://www.logo.wine/a/logo/Shopify/Shopify-Logo.wine.svg',
    active: true,
    imageStyle: { height: 90, marginLeft: 0, marginTop: -20 },
}

const providers: IProvider[] = [
  {
    id: 'AMAZON',
    title: 'Amazon',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg',
    active: false,
    imageStyle: { height: 30, marginLeft: 0, marginTop: 0, opacity: 0.5 },
  },
  {
    id: 'WALMART',
    title: 'Walmart',
    logo: 'https://purepng.com/public/uploads/large/purepng.com-walmart-logologobrand-logoiconslogos-251519939045oqelv.png',
    active: false,
    imageStyle: { height: 40, marginLeft: 0, marginTop: -15, opacity: 0.5 },
  },
  {
    id: 'BIGCOMMERCE',
    title: 'BigCommerce',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Bc-logo-dark.svg/2560px-Bc-logo-dark.svg.png',
    active: false,
    imageStyle: { height: 30, marginLeft: 0, marginTop: -15, opacity: 0.5 },
  },
  {
    id: 'ETSY',
    title: 'Etsy',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Etsy_logo.svg/2560px-Etsy_logo.svg.png',
    active: false,
    imageStyle: { height: 30, marginLeft: 0, marginTop: -5, opacity: 0.5 },
  },
  {
    id: 'SQUARESPACE',
    title: 'Squarespace',
    logo: 'https://static1.squarespace.com/static/5134cbefe4b0c6fb04df8065/t/5bd1e161419202b8ab974c21/1582743997338/squarespace-logo-horizontal-black.jpg',
    active: false,
    imageStyle: { height: 30, marginLeft: 0, marginTop: -10, opacity: 0.5 },
  }
]

const shopifyAccounts = [
  {id: 'gross', name: 'Gross Sales', isCalc: false},
  {id: 'discount', name: 'Discounts', isCalc: false},
  {id: 'returns', name: 'Returns', isCalc: false},
  {id: 'net', name: 'Net Sales', isCalc: true},
  {id: 'shipping', name: 'Shipping', isCalc: false},
  // {id: 'tax', name: 'Taxes', isCalc: false},
  // {id: 'liability', name: 'Gift Cards', isCalc: false},
  {id: 'total', name: 'Total', isCalc: true},
  {id: 'payments', name: 'Payments', isCalc: true},
]


const btnStyle: React.CSSProperties = {
  width: 200,
  height: 70,
  overflow: 'hidden',
}