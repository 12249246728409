import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Navigate, useNavigate } from 'react-router-dom'
import { Alert, Card, Layout, Typography, message } from 'antd'

import { Btn } from 'components/Btn'
import { Logo } from 'components/Logo'
import { Header } from 'components/Header'
import { FlexSpace } from 'components/FlexSpace'

import { api } from 'utils/axios'
import { currentUser } from 'recoil/user.state'
import { authAtom } from 'recoil/auth.state'

import './style.css'

const savedLast = parseInt(localStorage.getItem('last') || '0') 

export const VerifyEmail: ReactFC = () => {
  const user = useRecoilValue(currentUser)
  const setAuth = useSetRecoilState(authAtom)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [last, setLast] = useState(savedLast)
  const [timer, setTimer] = useState<number>(0)

  useEffect(() => {
    const r = localStorage.getItem('last')
    if (r) setLast(+r)
    else handleResend()
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      if (last + 60000 < Date.now()) {
        localStorage.removeItem('last')
        setLast(0)
      } else {
        setTimer(Math.floor((last + 60000 - Date.now()) / 1000))
      }
    }, 1000)
    return () => clearInterval(timer)
  }, [last])

  const handleResend = () => {
    localStorage.setItem('last', Date.now().toString())
    setLast(Date.now())
    setLoading(true)
    api
      .post('auth/verify-email')
      .then(() => {
        localStorage.setItem('last', Date.now().toString())
        message.success('Email with verification instructions was sent to your inbox')
      })
      .catch((err) => setError(err?.response?.data?.message || err?.message || 'Error'))
      .finally(() => setLoading(false))
  }

  const handleCancel = () => {
    localStorage.removeItem('last')
    setAuth(null)
    navigate('/signin')
  }

  if (user?.isEmailVerified) return <Navigate to="/" replace={true} />

  return (
    <Layout className="AuthLayout">
      <Layout.Content className="AuthLayoutContent NoSelect">
        <FlexSpace direction="vertical" size="large">
          <LogoHeader />
          <Card className="AuthCard" bordered={false}>
            <FlexSpace direction="vertical" className="signin-card-content">
              <Header size={36} style={{ marginBottom: 24 }}>
                Verify your email address
              </Header>
              <Typography.Paragraph>
                We sent an email to you with a link to get started.
              </Typography.Paragraph>
              <Btn
                block
                size="large" 
                title={timer > 0 ? `Resend email (${timer}s)` : 'Resend email'}
                type="primary" 
                loading={loading} 
                onClick={handleResend}
                disabled={timer > 0}
              />
              <Btn block title="Cancel" onClick={handleCancel} />
              {error && <Alert message="Error" description={error} type="error" showIcon />}
            </FlexSpace>
          </Card>
        </FlexSpace>
      </Layout.Content>
    </Layout>
  )
}

const LogoHeader = () => (
  <div className="AuthTitleContainer">
    <Logo width={187} />
  </div>
)
