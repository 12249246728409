import { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Divider, Form, Input, Select, Checkbox, Typography } from 'antd'
import { Alert, Card, Col, Layout, Row } from 'antd'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Btn } from 'components/Btn'
import { Header } from 'components/Header'
import { GLogin } from 'components/GLogin'
import { FlexSpace } from 'components/FlexSpace'
import { Block } from 'components/Block'
import { HeadTitle } from 'components/HeadTitle'
import { SideInfo } from './SideInfo'

import { rules } from './auth.utils'
import { api } from 'utils/axios'
import { config } from 'utils/config'
import { analytics } from 'utils/analytics'
import { authAtom } from 'recoil/auth.state'
import { ISignupData } from 'types/auth.types'

import './style.css'

export const SignUp = () => {
  const [form] = Form.useForm<ISignupData>()
  const [auth, setAuth] = useRecoilState(authAtom)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [source, setSource] = useState('')

  const handleSubmit = async (data: ISignupData) => {
    if (!executeRecaptcha) return setError('Error with reCaptcha. Please try again later.')
    const recaptcha = await executeRecaptcha('signup')

    setLoading(true)
    api
      .post('auth/signup', { ...data, password: data.pass, recaptcha })
      .then(r => {
        setAuth(r.data)
        analytics.event.signUp()
      })
      .catch((err) => setError(err?.response?.data?.message || err?.message || 'Error'))
      .finally(() => setLoading(false))
  }

  const handleGoogle = (token: string) => {
    setLoading(true)
    api
      .post('auth/google-signin', { token, plugin: false, code: token })
      .then(r => {
        setAuth(r.data)
        analytics.event.signUpGoogle()
      })
      .catch((err) => setError(err?.response?.data?.message || err?.message || 'Error'))
      .finally(() => setLoading(false))
  }

  if (auth) return <Navigate to="/" replace />

  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <HeadTitle title='Sign Up' />
      <Layout className="AuthLayout">
        <Layout.Content className="AuthLayoutContent NoSelect">
          <Row>
            <Col span={12}>
              <SideInfo />
            </Col>
            <Col span={12}>
              <FlexSpace direction="vertical" size="large">
                <Card className="AuthCard" bordered={false}>
                  <FlexSpace direction="vertical" className="signin-card-content">
                    <Header size={46} style={{ marginBottom: 24 }}>
                      Sign Up
                    </Header>
                    <GLogin onSuccess={handleGoogle} loading={loading} onError={setError} signup />
                    <Divider><small>or with your email and below</small></Divider>
                    <Form layout="vertical" requiredMark={false} onFinish={handleSubmit} form={form}>
                      <Form.Item name="email" label="Email" rules={rules.email}>
                        <Input placeholder="example@mail.com" autoFocus autoComplete="new-password" />
                      </Form.Item>
                      <Form.Item name="firstName" label="First Name" rules={rules.firstName}>
                        <Input placeholder="John" autoComplete="off" />
                      </Form.Item>
                      <Form.Item name="lastName" label="Last Name" rules={rules.lastName}>
                        <Input placeholder="Johnson" autoComplete="off" />
                      </Form.Item>
                      <Form.Item name="pass" label="Password" rules={rules.password}>
                        <Input.Password placeholder="password" autoComplete="new-password" />
                      </Form.Item>
                      <Form.Item name="source" label="How did you hear of Equility?" rules={rules.source} >
                        <Select placeholder="Select" style={{ width: '100%' }} className='CSelect' onChange={setSource}>
                          <Select.Option value="intuit">Intuit App Store</Select.Option>
                          <Select.Option value="appsumo">AppSumo</Select.Option>
                          <Select.Option value="social">Social Media</Select.Option>
                          <Select.Option value="search">Organic Search (ie, Google)</Select.Option>
                          <Select.Option value="wordofmouth">Word of Mouth</Select.Option>
                          <Select.Option value="other">Other</Select.Option>
                        </Select>
                      </Form.Item>
                      {source === 'other' && (<Form.Item name="sourcedetails" rules={rules.sourcedetails}>
                        <Input placeholder="How did you hear of Equility?" autoComplete="off"  />
                      </Form.Item>)}
                      <Form.Item name="terms" valuePropName="checked" rules={rules.terms}>
                        <Typography.Text type="secondary">
                          <Checkbox>
                            <span>I agree to the</span>
                            &nbsp;
                            <a href="https://www.equilityhq.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
                            &nbsp;
                            <span>and</span>
                            &nbsp;
                            <a href="https://www.equilityhq.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>
                          </Checkbox>
                        </Typography.Text>
                      </Form.Item>
                      <Form.Item>
                        <Block className="AuthCardFooter">
                          <Btn title="Sign Up" type="primary" loading={loading} htmlType="submit" block />
                        </Block>
                      </Form.Item>
                    </Form>
                    {error && <Alert message="Error" description={error} type="error" showIcon />}
                    <FlexSpace direction="horizontal" className="AuthFooterLink">
                      <Typography.Text>Already customer?</Typography.Text>
                      <Link to="/signin">Sign In</Link>
                    </FlexSpace>
                  </FlexSpace>
                </Card>
              </FlexSpace>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </GoogleOAuthProvider>
  )
}
