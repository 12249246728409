import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { Logo } from './Logo'
import { Block } from './Block'
import { Header } from './Header'
import { FlexSpace } from './FlexSpace'
import { UserAvatar } from './UserAvatar'

import { currentUser } from 'recoil/user.state'

interface Props {
  title: string
}

export const Page: ReactFC<Props> = (props) => {
  const { children, title } = props

  const user = useRecoilValue(currentUser)

  return (
    <FlexSpace direction="vertical" size="large" className="Page">
      <table style={{ width: '100%' }}>
        <thead><tr><th>
          <Block className="print printHeader">
            <Logo  />
          </Block>
        </th></tr></thead>
        <tbody><tr><td> 
          <FlexSpace direction="horizontal" spacebetween className="hideprint">
            <Header size={32}>{title}</Header>
            <Link to="/account">
              <FlexSpace direction="horizontal" >
                <UserAvatar url={user?.photoUrl} />
              </FlexSpace>
            </Link>
          </FlexSpace>
          <Block className="AppPageContent">{children}</Block>
        </td></tr></tbody>
        <tfoot><tr><td>
          <div className='print'><br /><br /></div>  
        </td></tr></tfoot>
      </table>
      <Block className="print printFooter">
        <Logo width={100} />
      </Block>
    </FlexSpace>
  )
}
