import { Input } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons'

import { Block } from './Block'

interface Props {
  loading?: boolean
  disabled?: boolean
  width?: number
  value: string
  onChange: (value: string) => void
}

export const Search: ReactFC<Props> = (props) => {
  const { loading, width, value, onChange } = props

  const icon = loading 
    ? <FontAwesomeIcon icon={faSpinner} spinPulse /> 
    : <FontAwesomeIcon icon={faMagnifyingGlass} />
  
  const clearIcon = <FontAwesomeIcon icon={faXmark} onClick={() => onChange('')} />

  const style = { width: width || 278 }

  return (
    <Block className="Search">
      <Input
        placeholder="Search"
        value={value}
        style={style}
        onChange={e => onChange(e.target.value)}
      />
      <Block className="SearchIcon">
        {value ? clearIcon : icon }
      </Block>
    </Block>
  )
}
