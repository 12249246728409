import { Alert } from 'antd'
import { useRecoilValue } from 'recoil'

import { currentUser } from 'recoil/user.state'
import { unixToDate } from 'utils/dates'

const isTrialBannerHidden = localStorage.getItem('qe_trial_banner')

export const TrialBanner: ReactFC = () => {
  const user = useRecoilValue(currentUser)
  const subscription = user?.subscription || null

  if (isTrialBannerHidden) return null
  if (!subscription) return null
  if (subscription.status !== 'trialing') return null

  const trialEndingDate = (user?.subscription?.status === 'trialing' && user?.subscription?.nextChargeDate) || 0
  const chargeDate = unixToDate(trialEndingDate || 0)

  if (!trialEndingDate) return null

  const onClose = () => {
    localStorage.setItem('qe_trial_banner', '1')
  }

  return (
    <Alert
      message={'Your trial membership ends on ' + chargeDate}
      description="At the end of your trial, you will automatically be downgraded to the free tier. If you'd like to continue using the Advanced Tier, simply upgrade your subscription and enter your credit card information."
      type="warning"
      onClose={onClose}
      showIcon
      closable
      style={{ marginBottom: 20 }}
    />
  )
}
