import { withErrorBoundary, useErrorBoundary } from 'react-use-error-boundary'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { AxiosError } from 'axios'
import { Alert } from 'antd'

import { Btn } from './Btn'

import { companiesAtom } from 'recoil/companies.state'
import { ICompany } from 'types/company.types'
import { api } from 'utils/axios'
import { log } from 'utils/log'

export const ErrorBoundary = withErrorBoundary<React.PropsWithChildren>((props) => {
  const { children } = props

  const navigate = useNavigate()

  const setCompanies = useSetRecoilState(companiesAtom)
  
  const [error, resetError] = useErrorBoundary(
    // You can optionally log the error to an error reporting service
    (error, errorInfo) => {
      log(error, errorInfo)
      const code = error instanceof AxiosError ? error.response?.status : 0
      if (code === 403) {
        refreshCompanies()
      }
      if (code === 404) {
        if ((error as any).response?.data?.message?.includes('/applications'))
        resetCompanies()
        resetError()
      }
    }
  )

  const refreshCompanies = () => {
    return api
      .get<ICompany[]>('applications')
      .then(({ data }) => setCompanies(data))
      .catch(console.log)
  }

  const resetCompanies = () => setCompanies([])

  if (error) {
    const message = error instanceof Error ? error.message : (error as string)
    const code = error instanceof AxiosError ? error.response?.status : 0

    const url = window.location.pathname
    const companyId = url.split('/')[2]

    if (code === 403)
      return (
        <Alert
          style={{ margin: '20px 0' }}
          showIcon
          type="error"
          message="Please reconnect to QuickBooks"
          description="Go to Company Settings > Integration > Reconnect"
          action={
            <Btn title="Go" onClick={() => {
              navigate(`/company/${companyId}/settings/integrations`)
              resetError()
            }} />
          }
        />
      )

    return (
      <Alert
        showIcon
        type="error"
        message="Something went wrong"
        description={message}
        action={<Btn title="Try again" onClick={resetError} />}
      />
    )
  }

  return <>{children}</>
})
