import { FC } from 'react'
import { Form, Input, Select } from 'antd'

import { RangePicker } from 'components/RangePicker'
import { ICustomRuleSettings } from '../types'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

interface SettingFormProps {
  initialData: ICustomRuleSettings
  onSubmit: (v: ICustomRuleSettings) => void
}

export const SettingForm: FC<SettingFormProps> = (props) => {
  const { initialData, onSubmit } = props

  const [form] = Form.useForm<ICustomRuleSettings>()

  const unit = Form.useWatch('unit', form)
  const unitSymbol = unit === 'percentage' ? '%' : '$'

  const onValuesChange = (_: any, v: ICustomRuleSettings) => {
    console.log('onValuesChange', v)
    onSubmit(v)
  }

  return (
    <Form {...layout} style={{ maxWidth: 639 }} form={form} onValuesChange={onValuesChange}>
      <Form.Item name="name" label="Name" rules={[{ required: true }]} initialValue={initialData.name}>
        <Input />
      </Form.Item>
      <Form.Item name="daterange" label="Date Range" rules={[{ required: true }]} initialValue={initialData.daterange}>
        <RangePicker disabled />
      </Form.Item>
      <Form.Item name="method" label="Method" rules={[{ required: true }]} initialValue={initialData.method}>
        <Select className="SelectPrimary" style={{ width: 220 }}>
          <Select.Option value="Cash">Cash</Select.Option>
          <Select.Option value="Accrual">Accrual</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="aggregation" label="Aggregation" rules={[{ required: true }]} initialValue={initialData.aggregation}>
        <Select className="SelectPrimary" style={{ width: 220 }}>
          <Select.Option value="balance">Balance</Select.Option>
          <Select.Option value="total">Total</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="unit" label="Unit of Measure" rules={[{ required: true }]} initialValue={initialData.unit}>
        <Select className="SelectPrimary" style={{ width: 220 }}>
          <Select.Option value="percentage">Percentage</Select.Option>
          <Select.Option value="amount">Amount</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="target" label="Target Type:" rules={[{ required: true }]} initialValue={initialData.target}>
        <Select className="SelectPrimary" style={{ width: 220 }}>
          <Select.Option value=">">More than</Select.Option>
          <Select.Option value="<">Less than</Select.Option>
          <Select.Option value="=>">More or equal</Select.Option>
          <Select.Option value="=<">Less or equal</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="value" label="Value" rules={[{ required: true }]} initialValue={initialData.value}>
        <Input placeholder="0.00" style={{ width: 220 }} addonAfter={unitSymbol} />
      </Form.Item>
    </Form>
  )
}
