import { useEffect, useState } from 'react'
import { message, Table, Typography } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate } from '@fortawesome/free-solid-svg-icons'

import { Block } from 'components/Block'
import { FlexSpace } from 'components/FlexSpace'
import { Btn } from 'components/Btn'
import { Search } from 'components/Search'

import { IAccount, IAccountTreeElement, ICompany } from 'types/company.types'
import { api } from 'utils/axios'
import { errorMsg } from 'utils/errorMsg'

interface Props {
  company: ICompany | null
}

export const Accounts: ReactFC<Props> = (props) => {
  const { company } = props

  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState<string[]>([])
  const [accounts, setAccounts] = useState<IAccount[]>([])

  const { treeData, filteredIds } = arrToTree(accounts, filter)

  useEffect(() => {
    handleLoad()
  }, [company])

  useEffect(() => {
    setExpanded(filter ? filteredIds : [])
  }, [filter])

  const handleLoad = () => {
    if (!company?.id) return
    setLoading(true)
    api
      .get<IAccount[]>(`/integrations/qbo/accounts?application=${company.id}`)
      .then(({ data }) => setAccounts(data))
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
  }

  if (!company) return null

  return (
    <FlexSpace direction="vertical" size="large">
      <Block>
        <Typography.Title level={4}>Chart of Accounts</Typography.Title>
        <FlexSpace size="large" direction="vertical">
          <FlexSpace size="large">
            <Search value={filter} onChange={setFilter} />
            <Btn
              title="Refresh"
              type="primary"
              onClick={() => handleLoad()}
              icon={<FontAwesomeIcon icon={faRotate} style={{ marginRight: 10 }} />}
              disabled={loading}
            />
          </FlexSpace>
          <Block className="FullTable">
            <Table
              className="Table"
              dataSource={treeData}
              columns={columns()}
              indentSize={25}
              pagination={false}
              rowKey={'id'}
              loading={loading}
              expandable={{
                expandedRowKeys: expanded,
                onExpandedRowsChange: r => setExpanded(r as string[])
              }}
            />
          </Block>
        </FlexSpace>
      </Block>
    </FlexSpace>
  )
}

function columns() {
  return [
    {
      title: 'Number',
      key: 'number',
      dataIndex: 'number',
      width: 150,
      align: 'right' as any,
      sorter: (a: any, b: any) => a.number?.localeCompare(b.number)
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => a.name?.localeCompare(b.name)
    },
    {
      title: 'Type',
      key: 'classification',
      dataIndex: 'classification',
      width: 300,
      sorter: (a: any, b: any) => a.classification?.localeCompare(b.classification)
    },
    {
      title: 'Detail Type',
      key: 'accountSubType',
      dataIndex: 'accountSubType',
      width: 300,
      sorter: (a: any, b: any) => a.accountSubType?.localeCompare(b.accountSubType)
    },
    {
      title: 'Currency',
      key: 'currency',
      dataIndex: 'currency',
      width: 100,
    }
  ]
}

function arrToTree(items: IAccount[] = [], filter = '') {
  const arr: IAccountTreeElement[] = [...items.map((v) => ({ ...v }))]
  const filteredIds = new Set<string>()

  arr.forEach((v) => {
    const isNameMatch = v.name?.toLowerCase().includes(filter.toLowerCase())
    const isNumberMatch = v.number?.toLowerCase().includes(filter.toLowerCase())
    const isMatch = filter && (isNameMatch || isNumberMatch)

    if (isMatch) {
      findAllParrents(items, v.qboId, (r) => filteredIds.add(r))
      findAllChildrens(items, v.qboId, (r) => filteredIds.add(r))
    }
  })

  const filteredArr = arr.filter((v) => (filter ? filteredIds.has(v.qboId) : true))

  filteredArr.forEach((v) => {
    const el = arr.find((vv) => vv.qboId === v.parentQBOId)
    if (el) {
      if (!el.children) el.children = [v]
      else {
        if (!el.children.find((vvv) => vvv.qboId === v.qboId)) el.children.push(v)
      }
    }
  })

  return { treeData: filteredArr.filter((v) => !v.parentQBOId), filteredIds: [...filteredIds] }
}

function findAllParrents(items: IAccount[], qboId: string, cb: (v: string) => void) {
  function next(nextqboId: string) {
    const el = items.find((v) => v.qboId === nextqboId)
    if (el?.qboId) {
      cb(el.qboId)
      if (el.parentQBOId) next(el.parentQBOId)
    }
  }

  next(qboId)
}

function findAllChildrens(items: IAccount[], qboId: string, cb: (v: string) => void) {
  function next(nextqboId: string) {
    const els = items.filter((v) => v.parentQBOId === nextqboId)
    els.forEach((v) => {
      cb(v.qboId)
      next(v.qboId)
    })
  }
  next(qboId)
}
