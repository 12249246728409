import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { Link, useNavigate } from 'react-router-dom'
import { Alert, Card, Divider, Form, Input, Layout, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { Header } from 'components/Header'
import { HeadTitle } from 'components/HeadTitle'
import { Logo } from 'components/Logo'
import { Block } from 'components/Block'
import { Btn } from 'components/Btn'

import { rules } from './auth.utils'
import { authAtom } from 'recoil/auth.state'
import { api } from 'utils/axios'

const { Text } = Typography

export const AppSumo = () => {
  const navigate = useNavigate()
  const [auth, setAuth] = useRecoilState(authAtom)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = (enrollData: any) => {
    setLoading(true)
    api
      .post('auth/appsumo', enrollData)
      .then((r) => {
        setAuth(r.data)
        setTimeout(() => {
          navigate('/')
          setLoading(false)
        }, 1000)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || err?.message || 'Error')
        setLoading(false)
      })
  }

  if (auth && !loading)
    return (
      <Layout className="AuthLayout">
        <HeadTitle title='Warning' />
        <Layout.Content className="AuthLayoutContent NoSelect">
          <FlexSpace direction="vertical" size="large">
            <LogoHeader />
            <Card className="AuthCard" bordered={false}>
              <Header size={46} style={{ marginBottom: 24 }}>
                Sorry!
              </Header>
              <Text>This offer is non-redeemable. Please note that the AppSumo deal is only applicable for new Equility users.</Text>
              <Divider />
              <Link to="/">Go to Dashboard</Link>
            </Card>
          </FlexSpace>
        </Layout.Content>
      </Layout>
    )

  return (
    <Layout className="AuthLayout">
      <HeadTitle title='Hey Sumo-lings!' />
      <Layout.Content className="AuthLayoutContent NoSelect">
        <FlexSpace direction="vertical" size="large">
          <LogoHeader />
          <Card className="AuthCard" bordered={false}>
            <FlexSpace direction="vertical" className="signin-card-content">
              <Header size={46} style={{ marginBottom: 24 }}>
                Hey Sumo-lings!
              </Header>
              <Text>Please share your Name, AppSumo email and AppSumo code to get enrolled in Equility.</Text>
              <Divider />
              <Form layout="vertical" size="large" requiredMark={false} onFinish={handleSubmit}>
                <Form.Item name="email" label="AppSumo Email" rules={rules.email}>
                  <Input placeholder="example@mail.com" autoFocus autoComplete="new-password" />
                </Form.Item>
                <FlexSpace spacebetween>
                  <Form.Item name="firstName" label="First Name" rules={rules.firstName}>
                    <Input placeholder="John" autoComplete="off" />
                  </Form.Item>
                  <Form.Item name="lastName" label="Last Name" rules={rules.lastName}>
                    <Input placeholder="Johnson" autoComplete="off" />
                  </Form.Item>
                </FlexSpace>
                <Form.Item name="code" label="AppSumo Code" rules={rules.appsumo}>
                  <Input placeholder="AppSumo Code" autoComplete="off" />
                </Form.Item>
                <Form.Item name="password" label="Password" rules={rules.password}>
                  <Input.Password placeholder="password" autoComplete="new-password" />
                </Form.Item>
                <Form.Item>
                  <Block className="AuthCardFooter">
                    <Btn title="Enroll now" type="primary" loading={loading} htmlType="submit" block />
                  </Block>
                </Form.Item>
                {error && <Alert message="Error" description={error} type="error" showIcon />}
              </Form>
            </FlexSpace>
          </Card>
        </FlexSpace>
      </Layout.Content>
    </Layout>
  )
}

const LogoHeader = () => (
  <Block className="AuthTitleContainer">
    <Logo width={187} />
  </Block>
)
