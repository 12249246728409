import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { message, Select, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { FullLoader } from 'components/FullLoader'

import { IRule } from 'types/compliance.types'
import { companiesAtom, companyRulesAtom, currentCompany } from 'recoil/companies.state'
import { dataEntities } from 'recoil/data.state'
import { api } from 'utils/axios'
import { errorMsg } from 'utils/errorMsg'
import { ICompany } from 'types/company.types'

// const { Text } = Typography

export const ComplianceSetting = () => {
  const company = useRecoilValue(currentCompany)
  const entities = useRecoilValue(dataEntities)
  // const rules = useRecoilValue(companyRulesAtom)

  const setCompanies = useSetRecoilState(companiesAtom)
  const setRules = useSetRecoilState(companyRulesAtom)

  const [loading, setLoading] = useState(false)
  const [entityId, setEntityId] = useState<number>()

  // const r = rules.map(v => {
  //   if (v.id !== entityId && !!v.id)
  //     return {...v, disabled: true, children: []}
  //   else return v
  // })

  useEffect(() => {
    if (!company) return
    setEntityId(company.entity?.id)
  }, [company])

  const refreshCompanies = () => {
    return api.get<ICompany[]>('applications')
      .then(({data}) => setCompanies(data))
  }

  const refreshRules = () => {
    return api.get<IRule[]>(`/application_rules/${company?.id}`)
      .then(({data}) => setRules(data))
  }

  const handleChangeEntity = (id: number) => {
    setEntityId(id)
    setLoading(true)
    api.patch(`/applications/${company?.id}`, { entityId: id })
      .then(() => refreshCompanies())
      .then(() => refreshRules())
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
  }

  // const handleRule = (v: any) => {
  //   if (v.children) return true
  //   const action = v.isSelected
  //     ? api.delete(`/application_rules/${v.app_rule_id}`)
  //     : api.post('/application_rules', { application_id: company?.id, rule_id: v.rule_id })
  //   setLoading(true)
  //   action
  //     .then(() => refreshRules())
  //     .catch(err => message.error(errorMsg(err), 5))
  //     .finally(() => setLoading(false))
  // }

  // const cardstyle = { 
  //   background: 'rgb(236, 250, 241)', 
  //   border: 'none', 
  //   borderRadius: 6,
  //   padding: 10,
  //   marginTop: 10
  // }

  if (!company) return <FullLoader />

  return (
    <FlexSpace direction="vertical" style={{ }}>
      <Typography.Title level={5}>Choose your business entity type:</Typography.Title>
      <Select
        options={entities}
        value={entityId || 0}
        onSelect={handleChangeEntity}
        disabled={loading}
        fieldNames={{ label: 'title', value: 'id' }}
        style={{ minWidth: 500 }}
        className="SelectPrimary"
      />
      {/* <Spin spinning={!rules.length || loading}>
        <Typography.Title level={5} style={{ marginTop: 40 }}>Choose what you'd like to review:</Typography.Title>
        <small>Select rules that will be applied during financial review</small>
        <Block style={cardstyle}>
          <Tree.DirectoryTree
            disabled={loading}
            icon={null}
            blockNode={true}
            selectable={false}
            treeData={r}
            className="Tree"
            defaultExpandAll={true}
            titleRender={(nodeData) => <NodeData data={nodeData} />}
            onClick={(_, v) => handleRule(v)}
          />
        </Block>
      </Spin> */}
    </FlexSpace>
  )
}

// interface NodeDataProps {
//   data: IRule
// }

// const NodeData: ReactFC<NodeDataProps> = (props) => {
//   const { data } = props

//   const isParent = !!data.children

//   return (
//     <FlexSpace direction="vertical" size="small" className="TreeTitle" style={{ opacity: data.disabled ? 0.5 : 1 }}>
//       <FlexSpace>
//         {!isParent && <Checkbox checked={data.isSelected} />}
//         <Text strong>{data.title}</Text>
//         {!isParent && (
//           <Popover content={
//             <small 
//               dangerouslySetInnerHTML={{ __html: data.description }}  
//               style={{ display: 'block', maxWidth: 600 }}
//             />
//           }>
//             <Button type="text">
//               <FontAwesomeIcon icon={faCircleExclamation} style={{ color: '#a0e8fc' }} />
//             </Button>
//           </Popover>
//         )}
//       </FlexSpace>
//     </FlexSpace>
//   )
// }
