import React, {useState, useMemo, useEffect} from 'react';
import {Tree, Dropdown, Input, Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {AccountTypesArray} from "../types/chart.types";

const { Search } = Input;
const { TreeNode } = Tree;

interface DropdownItem {
    key: string;
    name: string;
    children?: DropdownItem[];
}

interface MultiGroupDropdownProps {
    style?: object;
    enableSearch?: boolean;
    items: DropdownItem[];
    defaultValue?: string[];
    onChange: (selectedKeys: string[]) => void;
    selectedKeys: any,
    setSelectedKeys: any

}

export const MultiGroupDropdown: React.FC<MultiGroupDropdownProps> = ({
                                                                          style={},
                                                                          enableSearch = true,
                                                                          items,
                                                                          defaultValue = [],
                                                                          onChange,
                                                                          selectedKeys,
                                                                          setSelectedKeys
                                                                      }) => {
    const [searchValue, setSearchValue] = useState('');
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [filteredItems, setFilteredItems] = useState<DropdownItem[]>(items);

    const searchStyle = {
        width: '100%',
    };

    useEffect(() => {
        setFilteredItems(items);
    }, [items]);

    const onExpand = (expandedKeysValue: any) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onSelect = (selectedKeysValue: any, info: any) => {
        const { node } = info;
        const key = node.key;

        if (!selectedKeys.includes(key) && !AccountTypesArray.includes(key)) {
            const newSelectedKeys = [...selectedKeys, key];
            setSelectedKeys(newSelectedKeys);
            onChange(newSelectedKeys);
        }
    };

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue(value.toLowerCase());

        const filterItems = (list: DropdownItem[], searchValue: string): DropdownItem[] => {
            return list.reduce((acc: DropdownItem[], item: DropdownItem) => {
                let newItem = { ...item };
                if (item.name.toLowerCase().includes(searchValue)) {
                    acc.push(newItem);
                } else if (item.children) {
                    const filteredChildren = filterItems(item.children, searchValue);
                    if (filteredChildren.length > 0) {
                        newItem.children = filteredChildren;
                        acc.push(newItem);
                    }
                }
                return acc;
            }, []);
        };

        if (value) {
            const filteredItems = filterItems(items, value);
            setFilteredItems(filteredItems);
            const allKeys = getAllKeys(filteredItems);
            setExpandedKeys(allKeys);
        } else {
            setFilteredItems(items);
            setExpandedKeys([]);
        }

        setAutoExpandParent(true);
    };

    const getAllKeys = (items: DropdownItem[]): string[] => {
        let keys: string[] = [];
        const extractKeys = (items: DropdownItem[]) => {
            items.forEach(item => {
                keys.push(item.key);
                if (item.children) {
                    extractKeys(item.children);
                }
            });
        };
        extractKeys(items);
        return keys;
    };

    const loop = (data: DropdownItem[]): any =>
        data.map((item) => {
            const title =
                <span style={{fontSize: 18}}>{item.name}</span>
            if (item.children) {
                return (
                    <TreeNode key={item.key} title={title}>
                        {loop(item.children)}
                    </TreeNode>
                );
            }

            return <TreeNode key={item.key} title={title} isLeaf  />;
        });

    const dropdownContent = (
        <div  className='custom-dropdown-tree'>
            {enableSearch &&
              <Search
                className="custom-search-input"
                style={searchStyle} placeholder="Search for a metric" onChange={onSearchChange} />}
            <div style={{overflowY: 'auto', maxHeight: 350, width: 550, ...(style ? style : {})}}>

                <Tree
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    onSelect={onSelect}
                    selectedKeys={selectedKeys}
                    multiple
                    checkedKeys={selectedKeys}
                >
                    {useMemo(() => loop(filteredItems), [filteredItems, searchValue, selectedKeys])}
                </Tree>
            </div>
        </div>
    );

    return (
        <Dropdown overlay={dropdownContent} trigger={['click']} onVisibleChange={() => setAutoExpandParent(true)}>
            <Button  size="small" onClick={e => e.preventDefault() }>
                <PlusOutlined />
            </Button>
        </Dropdown>
    );
};

