import { useEffect, useMemo, useState } from 'react'
import { Modal, Segmented, Table } from 'antd'

import { Btn } from 'components/Btn'
import { ColorAmount } from 'components/ColorAmount'
import { FlexSpace } from 'components/FlexSpace'
import { VList } from 'components/Virtuallist'

import { ITx } from 'types/tx.types'
import { IMatcher } from 'types/matcher'
import { sortByDate, stringToDate } from 'utils/dates'
import { useRecoilValue } from 'recoil'
import { currentCompany } from 'recoil/companies.state'

type Filter = 'Matched' | 'All Unmatched' | 'Suggested'

interface Props {
  list: ITx[]
  type: 'bank' | 'qbo'
  matcher: IMatcher
  open: boolean
  onClose: () => void
}

export const MatchModal: ReactFC<Props> = (props) => {
  const { list, type, matcher, open, onClose } = props

  const company = useRecoilValue(currentCompany)
  const viewOnly = company?.role.id === 2000

  const [filter, setFilter] = useState<Filter>('Suggested')
  const [selected, setSelected] = useState<string[]>([])

  // const list = type === 'bank' ? matcher.bank : matcher.qbo
  const matchList = type === 'bank' ? matcher.qbo : matcher.bank

  const isMatched = !!list.filter((v) => v.matchedTo.length || v.forceMatch).length
  const okBtnTitle = isMatched ? 'Unmatch' : 'Match'
  const okBtnType = isMatched ? { danger: true } : ({ type: 'primary' } as any)

  const match = isMatched
    ? list.map(v => v.matchedTo).flat()
    : filter === 'All Unmatched'
      ? matchList.filter(v => !v.matchedTo.length)
      : list.map(v => v.potentialMatches || []).flat()

  const selectedTxs = match.filter(v => selected.includes(v._id))
  const listResult = list.reduce((a, v) => a + v.amount, 0)
  const overalAmount = selectedTxs.reduce((sum, v) => v.amount + sum, 0)

  // const enabled = isMatched || overalAmount === listResult
  const enabled = isMatched || (roundTo(overalAmount) === roundTo(listResult))

  useEffect(() => {
    setTimeout(() => {
      const el = document.querySelector('.TxTable .ant-table-body')
      if (el) el.scrollTop = 0  
    })
  }, [match])

  useEffect(() => {
    setSelected([])
  }, [filter])

  const handleSubmit = () => {
    if (!!isMatched) {
      list.forEach(v => matcher.unmatch(v))
    } else {
      matcher.match(list, selectedTxs)
    }
    onClose()
  }

  const vComponents = useMemo(() => {
    return VList({ height: 400,  vid: 'match', resetTopWhenDataChange: false })
  }, [match.length])

  return (
    <Modal
      width={1400}
      title="Match transactions"
      open={open}
      onCancel={onClose}
      footer={viewOnly ? null : [
        <Btn title="Cancel" onClick={onClose} key="cancel" />, 
        <Btn title={okBtnTitle} onClick={handleSubmit} {...okBtnType} disabled={!enabled} key="ok" />
      ]}
      children={
        <FlexSpace direction="vertical" size="large">
          <Table
            className="Table TxTable"
            rowKey={'_id'}
            dataSource={list}
            columns={columns()}
            pagination={false}
            scroll={{ y: 400 }}
          />
          {!isMatched && (
          <Segmented options={['Suggested', 'All Unmatched']} value={filter} onChange={(v) => setFilter(v as Filter)} />
          )}
          <Table
            className="Table"
            rowKey={'_id'}
            dataSource={match}
            columns={columns()}
            pagination={false}
            scroll={{ y: 400 }}
            components={vComponents}
            rowSelection={!isMatched ? {
              columnWidth: 50,
              hideSelectAll: false,
              selectedRowKeys: selected,
              onChange: (ids) => setSelected(ids as string[]),
            } : undefined}
          />
        </FlexSpace>
      }
    />
  )
}

function columns() {
  return [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      width: 140,
      render: (value: string) => stringToDate(value, 'MM-DD-YYYY'),
      sorter: (a: ITx, b: ITx) => sortByDate(a.date, b.date)
    },
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'account',
      ellipsis: true,
      width: 200,
      sorter: (a: ITx, b: ITx) => {
        const aName = a?.account || ''
        const bName = b?.account || ''
        return aName.localeCompare(bName)
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 200,
      render: (_: any, tx: ITx) => tx?.customer || tx?.vendor,
      sorter: (a: any, b: any) => {
        const aName = a?.customer || a?.vendor || ''
        const bName = b?.customer || b?.vendor || ''
        return aName.localeCompare(bName)
      }
    },
    {
      title: 'Memo',
      key: 'description',
      dataIndex: 'description',
      width: 342,
      ellipsis: true,
      sorter: (a: ITx, b: ITx) => a?.description?.localeCompare(b?.description || '') || 0
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      width: 140,
      sorter: (a: ITx, b: ITx) => a?.type?.localeCompare(b?.type || '') || 0
    },
    {
      title: 'Num',
      key: 'num',
      dataIndex: 'num',
      width: 140,
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      width: 140,
      sorter: (a: ITx, b: ITx) => a.amount - b.amount,
      render: (v: number) => <ColorAmount amount={v} />
    }
  ]
}


function roundTo(amount: number) {
  return parseFloat(amount.toFixed(2))
}