// https://github.com/crawler-django/virtuallist-antd
// MIT © crawler-django

import { useRef, useEffect, useContext, createContext, useReducer, useState, useMemo } from 'react'
import { throttle, isNumber, debounce } from 'lodash'

// ===============reducer ============== //
const initialState = {
  rowHeight: 0,
  curScrollTop: 0,
  totalLen: 0
}

function reducer(state: any, action: any) {
  const { curScrollTop, rowHeight, totalLen, ifScrollTopClear } = action

  let stateScrollTop = state.curScrollTop
  switch (action.type) {
    case 'changeTrs':
      return {
        ...state,
        curScrollTop
      }
    case 'initHeight':
      return {
        ...state,
        rowHeight
      }
    case 'changeTotalLen':
      if (totalLen === 0) {
        stateScrollTop = 0
      }

      return {
        ...state,
        totalLen,
        curScrollTop: stateScrollTop
      }
    case 'reset':
      return {
        ...state,
        curScrollTop: ifScrollTopClear ? 0 : state.curScrollTop
      }
    default:
      throw new Error()
  }
}

// ==============全局常量 ================== //
const DEFAULT_VID = 'vtable'
const vidMap = new Map()
let debounceListRender: any

// ===============context ============== //
const ScrollContext = createContext({
  dispatch: undefined as any,
  renderLen: 1,
  start: 0,
  offsetStart: 0,
  rowHeight: initialState.rowHeight,
  totalLen: 0,
  vid: DEFAULT_VID
})

// =============组件 =================== //

function VCell(props: any): JSX.Element {
  const { children, ...restProps } = props

  return (
    <td {...restProps}>
      <div>{children}</div>
    </td>
  )
}

function VRow(props: any, ref: any): JSX.Element {
  const { dispatch, rowHeight, totalLen, vid } = useContext(ScrollContext)

  const { children, style, ...restProps } = props

  const trRef = useRef<HTMLTableRowElement>(null)

  useEffect(() => {
    const initHeight = (tempRef: any) => {
      if (tempRef?.current?.offsetHeight && !rowHeight && totalLen) {
        const tempRowHeight = tempRef?.current?.offsetHeight ?? 0

        vidMap.set(vid, {
          ...vidMap.get(vid),
          rowItemHeight: tempRowHeight
        })
        dispatch({
          type: 'initHeight',
          rowHeight: tempRowHeight
        })
      }
    }

    initHeight(Object.prototype.hasOwnProperty.call(ref, 'current') ? ref : trRef)
  }, [trRef, dispatch, rowHeight, totalLen, ref, vid])

  return (
    <tr
      {...restProps}
      ref={Object.prototype.hasOwnProperty.call(ref, 'current') ? ref : trRef}
      style={{
        ...style,
        height: rowHeight || 'auto',
        boxSizing: 'border-box'
      }}
    >
      {children}
    </tr>
  )
}

function VWrapper(props: any): JSX.Element {
  const { children, ...restProps } = props

  const { renderLen, start, dispatch, vid, totalLen } = useContext(ScrollContext)

  const contents = useMemo(() => {
    return children[1]
  }, [children])

  const contentsLen = useMemo(() => {
    return contents?.length ?? 0
  }, [contents])

  useEffect(() => {
    if (totalLen !== contentsLen) {
      dispatch({
        type: 'changeTotalLen',
        totalLen: contentsLen ?? 0
      })
    }
  }, [contentsLen, dispatch, vid, totalLen])

  let tempNode = null
  if (Array.isArray(contents) && contents.length) {
    tempNode = [
      children[0],
      contents.slice(start, start + (renderLen ?? 1)).map((item) => {
        if (Array.isArray(item)) {
          // 兼容antd v4.3.5 --- rc-table 7.8.1及以下
          return item[0]
        }
        // 处理antd ^v4.4.0  --- rc-table ^7.8.2
        return item
      })
    ]
  } else {
    tempNode = children
  }

  return <tbody {...restProps}>{tempNode}</tbody>
}

function VTable(props: any, otherParams: any): JSX.Element {
  const { style, children, ...rest } = props
  const { width, ...rest_style } = style

  const { vid, scrollY, reachEnd, onScroll, resetScrollTopWhenDataChange } = otherParams ?? {}

  const [state, dispatch] = useReducer(reducer, initialState)

  const wrap_tableRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)

  const ifChangeRef = useRef(false)

  const [totalLen, setTotalLen] = useState<number>(children[1]?.props?.data?.length ?? 0)

  useEffect(() => {
    setTotalLen(state.totalLen)
  }, [state.totalLen])

  useEffect(() => {
    return () => {
      vidMap.delete(vid)
    }
  }, [vid])

  useEffect(() => {
    ifChangeRef.current = true
    if (isNumber(children[1]?.props?.data?.length)) {
      dispatch({
        type: 'changeTotalLen',
        totalLen: children[1]?.props?.data?.length ?? 0
      })
    }
  }, [children[1].props.data])

  const tableHeight = useMemo<string | number>(() => {
    let temp: string | number = 'auto'

    if (state.rowHeight && totalLen) {
      temp = state.rowHeight * totalLen
    }
    return temp
  }, [state.rowHeight, totalLen])

  const [tableScrollY, setTableScrollY] = useState(0)

  useEffect(() => {
    let temp = 0

    if (typeof scrollY === 'string') {
      temp = (wrap_tableRef.current?.parentNode as HTMLElement)?.offsetHeight ?? 0
    } else {
      temp = scrollY
    }

    if (temp <= 0) {
      temp = 0
    }

    setTableScrollY(temp)
  }, [scrollY, tableHeight])

  const renderLen = useMemo<number>(() => {
    let temp = 1
    if (state.rowHeight && totalLen && tableScrollY) {
      if (tableScrollY <= 0) {
        temp = 0
      } else {
        const tempRenderLen = ((tableScrollY / state.rowHeight) | 0) + 1 + 2
        temp = tempRenderLen
      }
    }

    return temp
  }, [state.rowHeight, totalLen, tableScrollY])

  let start = state.rowHeight ? (state.curScrollTop / state.rowHeight) | 0 : 0
  let offsetStart = state.rowHeight ? state.curScrollTop % state.rowHeight : 0

  if (state.curScrollTop && state.rowHeight && state.curScrollTop > state.rowHeight) {
    start -= 1
    offsetStart += state.rowHeight
  } else {
    start = 0
  }

  useEffect(() => {
    const scrollNode = wrap_tableRef.current?.parentNode as HTMLElement

    if (ifChangeRef?.current) {
      ifChangeRef.current = false

      if (resetScrollTopWhenDataChange) {
        if (scrollNode) {
          scrollNode.scrollTop = 0
        }

        dispatch({ type: 'reset', ifScrollTopClear: true })
      } else {
        dispatch({ type: 'reset', ifScrollTopClear: false })
      }
    }

    if (vidMap.has(vid)) {
      vidMap.set(vid, {
        ...vidMap.get(vid),
        scrollNode
      })
    }
  }, [totalLen, resetScrollTopWhenDataChange, vid, children])

  useEffect(() => {
    const throttleScroll = throttle((e) => {
      const scrollTop: number = e?.target?.scrollTop ?? 0
      const scrollHeight: number = e?.target?.scrollHeight ?? 0
      const clientHeight: number = e?.target?.clientHeight ?? 0

      if (scrollTop === scrollHeight) {
        // reachEnd && reachEnd()
      } else if (scrollTop + clientHeight >= scrollHeight) {
        reachEnd && reachEnd()
      }

      onScroll && onScroll()

      dispatch({
        type: 'changeTrs',
        curScrollTop: renderLen <= totalLen ? scrollTop : 0
      })
    }, 60)

    const ref = wrap_tableRef?.current?.parentNode as HTMLElement

    if (ref) {
      ref.addEventListener('scroll', throttleScroll)
    }

    return () => {
      ref.removeEventListener('scroll', throttleScroll)
    }
  }, [onScroll, reachEnd, renderLen, totalLen])

  debounceListRender(start, renderLen)

  return (
    <div
      className="virtuallist"
      ref={wrap_tableRef}
      style={{
        width: '100%',
        position: 'relative',
        height: tableHeight,
        boxSizing: 'border-box',
        paddingTop: state.curScrollTop
      }}
    >
      <ScrollContext.Provider
        value={{
          dispatch,
          rowHeight: vidMap?.get(vid)?.rowItemHeight,
          start,
          offsetStart,
          renderLen,
          totalLen,
          vid
        }}
      >
        <table
          {...rest}
          ref={tableRef}
          style={{
            ...rest_style,
            width,
            position: 'relative',
            transform: `translateY(-${offsetStart}px)`
          }}
        >
          {children}
        </table>
      </ScrollContext.Provider>
    </div>
  )
}

// ================导出===================
export function VList(props: {
  height: number | string
  onReachEnd?: () => void
  onScroll?: () => void
  onListRender?: (listInfo: { start: number; renderLen: number }) => void
  debounceListRenderMS?: number
  vid?: string
  resetTopWhenDataChange?: boolean
}): any {
  const {
    vid = DEFAULT_VID,
    height,
    onReachEnd,
    onScroll,
    onListRender,
    debounceListRenderMS,
    resetTopWhenDataChange = true
  } = props

  const resetScrollTopWhenDataChange = onReachEnd ? false : resetTopWhenDataChange

  if (!vidMap.has(vid)) {
    vidMap.set(vid, { _id: vid })
  }

  debounceListRender = onListRender
    ? debounce((_start, _renderLen) => {
        onListRender({ start: _start, renderLen: _renderLen })
      }, debounceListRenderMS ?? 300)
    : () => {}

  return {
    table: (p: any) =>
      VTable(p, {
        vid,
        scrollY: height,
        reachEnd: onReachEnd,
        onScroll,
        onListRender,
        resetScrollTopWhenDataChange
      }),
    body: {
      wrapper: VWrapper,
      row: VRow,
      cell: VCell
    }
  }
}

export function scrollTo(option: { row?: number; y?: number; vid?: string }) {
  const { row, y, vid = DEFAULT_VID } = option

  const { scrollNode, rowItemHeight } = vidMap.get(vid)

  if (row) {
    if (row - 1 > 0) {
      scrollNode.scrollTop = (row - 1) * (rowItemHeight ?? 0)
    } else {
      scrollNode.scrollTop = 0
    }
  } else {
    scrollNode.scrollTop = y ?? 0
  }

  return { vid, rowItemHeight }
}
