import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Alert, Button, Col, Flex, message, Row, Spin, Typography } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import { Btn } from 'components/Btn'
import { Block } from 'components/Block'
import { BadgedCard } from 'components/BadgedCard'
import { FlexSpace } from 'components/FlexSpace'

import { companiesAtom, currentCompany } from 'recoil/companies.state'
import { ICompany, ICompanyIntegration, IntegrationType } from 'types/company.types'
import { detectExtension } from 'utils/detectExtension'
import { isPluginAuth } from 'utils/plugin'
import { errorMsg } from 'utils/errorMsg'
import { api } from 'utils/axios'
import { config } from 'utils/config'

import QBOImg from 'assets/qbo/C2QB_green_btn_tall_default.svg'
import { currentUser } from 'recoil/user.state'
import { ShopifyConnect } from 'components/ShopifyConnect'
import { ShopifyMatch } from 'components/ShopifyMatch'
import { PlaidLink } from 'react-plaid-link'

interface Props {
  items: ICompanyIntegration[]
}

export const Integrations: ReactFC<Props> = (props) => {
  const { items } = props

  const isPluginAuthLocal = !!document.documentElement.hasAttribute('equility-extension-auth')

  const user = useRecoilValue(currentUser)
  const company = useRecoilValue(currentCompany)
  const setCompanies = useSetRecoilState(companiesAtom)

  const [loading, setLoading] = useState(false)
  const [qboStatus, setQboStatus] = useState(false)
  const [pluginStatus, setPluginStatus] = useState(isPluginAuthLocal)
  const [pluginInstalled, setPluginInstalled] = useState(detectExtension())
  const [plaidToken, setPlaidToken] = useState('')

  const viewOnly = company?.role.id === 2000

  const plaid = items.find((v) => v.id === IntegrationType.Plaid)
  const rutter = items.find((v) => v.id === IntegrationType.Rutter)
  const isPlaidReady = plaid?.status === 1
  const isShopifyReady = rutter?.status === 1
  const isShopifySyncing = rutter?.status === 2

  const store = (rutter?.connectionInfo as any)?.store
  const storeName = store?.store?.store_name ? `${store?.store?.store_name}` : ''

  const qbo = items.find((v: ICompanyIntegration) => v.id === IntegrationType.QBO)
  const plugin = { status: pluginStatus ? 'logged in' : pluginInstalled ? 'not logged in' : 'not installed' }

  const refreshCompanies = () => {
    return api.get<ICompany[]>('applications')
      .then(({data}) => setCompanies(data))
      .catch(console.log)
  }

  useEffect(() => {
    api
      .get('/plaid/link_token')
      .then(({ data }) => setPlaidToken(data.link_token))
      .catch((err) => message.error(errorMsg(err), 5))
  }, [])

  useEffect(() => {
    if (!company?.id) return
    setLoading(true)
    api.get(`/integrations/qbo/accounts?application=${company.id}`)
      .then(() => {
        setQboStatus(true)
        if (!qbo?.status)
          refreshCompanies()
      })
      .catch(() => {
        setQboStatus(false)
        if (!!qbo?.status)
          refreshCompanies()
      })
      .finally(() => setLoading(false))
  }, [company?.id])

  useEffect(() => {
    setQboStatus(!!qbo?.status)
  }, [qbo?.status])

  useEffect(() => {
    if (!user) return
    isPluginAuth(user.email)
      .then(() => setPluginStatus(true))
      .catch(() => setPluginStatus(false))
  }, [user])

  useEffect(() => {
    const interval = setInterval(() => {
      const isPlugin = detectExtension()
      setPluginInstalled(isPlugin)
      // refreshCompanies()
    }, 1000)

    return function clear() {
      clearInterval(interval)
    }
  }, [])

  const handleDisconnect = () => {
    setLoading(true)
    const payload = { data: { application: company?.id } }
    api
      .delete('/integrations/qbo', payload)
      .then(() => message.info('QuickBooks Access revoked for ' + company?.name))
      .catch((err) => message.error(errorMsg(err), 5))
      .finally(() => refreshCompanies())
      .finally(() => setLoading(false))
  }

  const handleDisconnectShopify = () => {
    setLoading(true)
    const params = { companyId: company?.id, id: rutter?.keyId }
    api
      .delete('/integrations', { params })
      .then(() => message.info('Shopify Access revoked for ' + company?.name))
      .catch((err) => message.error(errorMsg(err), 5))
      .finally(() => refreshCompanies())
      .finally(() => setLoading(false))
  }

  const onConnect = async () => {
    setLoading(true)
    api('/integrations/qbo')
      .then(r => {
        const newwindow = window.open(r.data, 'QuickBooks','width=760,height=640');

        let i = 0
        localStorage.setItem('eq_message', '')
        const interval = setInterval(function() {
          i++
          let eq_message = localStorage.getItem('eq_message')
          // console.log(eq_message)
          if (eq_message) {
            const data = JSON.parse(eq_message || "{}")
            if (data?.type === 'COMPANY_ADDED') {
              const { id, name } = data
              if (id === company?.id)
                message.info(`Company '${company?.name}' reconnected!`)
              else
                message.info(`Another company '${name}' connected!`)
              refreshCompanies()
            }
            if (data?.type === 'COMPANY_ADD_DENIED') {
              message.error(`Error! ${data?.message}}`)
            }
            clearInterval(interval)
            newwindow?.close()
          }
          if (newwindow?.closed || i > 300) {
            clearInterval(interval)
          }
        }, 500)
      })
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
  }

  const ConnectPlaid = () => 
  <PlaidLink
    token={plaidToken}
    onSuccess={() =>refreshCompanies()}
    className="ant-btn ant-btn-primary"
    style={{
      width: 96,
      height: 40,
      backgroundColor: '#0F9648',
      display: 'block',
      borderRadius: 6,
      border: 'none',
      color: 'white',
      fontSize: 16,
      fontFamily: 'Figtree',
      cursor: 'pointer'
    }}
    children={'Connect'}
  />

  const SetupPlaid = () => <Btn title="Setup" disabled onClick={() => {}} />
  const DisconnectShopify = () => <Btn title="Disconnect" danger loading={loading} onClick={handleDisconnectShopify} /> 
  const Disconnect = () => <Btn title="Disconnect" loading={loading} onClick={handleDisconnect} />
  const Reconnect = () => (
    <Button style={{ padding: 0 }} onClick={onConnect} disabled={loading}>
      <img src={QBOImg} alt="connect to QuickBooks" style={{ height: 46 }} />
    </Button>
  )

  const EmptyBlock =() => <Block style={{ height: 48 }} />

  return (
    <FlexSpace direction="vertical" size="large">
      <Typography.Title level={4}>Integrations</Typography.Title>
      <Spin spinning={loading}>
      <Row gutter={24}>
        <Col span={6}>
          <BadgedCard
            title="QuickBooks"
            description="Transaction data for Equility features"
            active={qboStatus ? 'active' : ''}
            children={viewOnly ? <EmptyBlock /> : qboStatus ? <Disconnect /> : <Reconnect />}
          />
        </Col>
        <Col span={5}>
          <BadgedCard
            title="Plaid"
            description="Transactions data from bank accounts"
            active={!!plaid ? isPlaidReady ? 'active' : 'syncing' : ''}
            children={viewOnly
              ? <EmptyBlock /> 
              : isPlaidReady 
                ? <SetupPlaid /> 
                : <ConnectPlaid />
            }
          />
        </Col>
        <Col span={5}>
          <BadgedCard
            title="Shopify"
            description={"Sales data from your Shopify store " + (storeName ? "'" + storeName + "'" : '')}
            active={!!rutter ? isShopifyReady ? 'active' : 'syncing' : ''}
            children={(viewOnly || isShopifySyncing) 
              ? <EmptyBlock /> 
              : isShopifyReady 
                ? <Flex gap={10}>
                  <ShopifyMatch onChange={refreshCompanies} /> 
                  <DisconnectShopify />
                </Flex>
                : <ShopifyConnect onChange={refreshCompanies} type="primary" />
            }
          />
        </Col>
        <Col span={7}>
          {/* <BadgedCard
            title="Chrome Plugin"
            description="Transaction data for Equility features"
            sub="Please note, our plugin only works in the following browsers: Chrome, Edge, Opera, and Brave"
            active={plugin.status}
            children={
              <Link to={config.links.downloadPlugin} target="_blank">
                <span>Download</span>&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Link>
            }
          /> */}
        </Col>
      </Row>
      </Spin>
      <Alert message="In order to use Equility, you need to make sure that your QuickBooks Online company is connected to the platform, and that you have downloaded our plugin onto your browser." />
    </FlexSpace>
  )
}
