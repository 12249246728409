import { Link } from 'react-router-dom'

import logo from '../assets/img/logo.png'
import logosm from '../assets/img/logo-sm.png'
// import logosm from '../assets/img/favicon.png'


interface LogoProps {
  width?: number
}

export const Logo: ReactFC<LogoProps> = ({ width }) => (
  <Link to="/">
    <img src={logo} alt="logo" style={{ maxWidth: width || 160 }} />
  </Link>
)

export const LogoSm: ReactFC<LogoProps> = ({ width }) => (
  <Link to="/">
    <img src={logosm} alt="logo" style={{ maxWidth: width || 160 }} />
  </Link>
)

