import { useEffect } from 'react'
import { Modal, Form, Input, Select } from 'antd'

interface Props {
  open: boolean
  onCancel: () => void
  onSubmit: (email: string, role: number) => void
}

export const InviteUser: ReactFC<Props> = (props) => {
  const { open, onCancel, onSubmit } = props

  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [open])

  const handleOk = () => {
    form.validateFields().then((values) => {
      form.resetFields()
      onSubmit(values.email, values.role || 1000)
      onCancel()
    })
  }

  const modalProps = {
    open,
    onCancel,
    onOk: handleOk,
    okText: 'Send Invite'
  }

  const formProps = {
    form,
    requiredMark: false,
    onFinish: console.log,
    initialValues: {
      role: 1000
    }
  }

  return (
    <Modal title="Invite company member" {...modalProps}>
      <Form layout="vertical" size="large" {...formProps}>
        <Form.Item name="email" rules={emailRules} label="Enter recipient's email address">
          <Input placeholder="example@mail.com" />
        </Form.Item>
        <Form.Item name="role" label="Role">
          <Select style={{ width: 200 }} className="SelectPrimary">
            <Select.Option value={1000}>Member</Select.Option>
            <Select.Option value={2000}>View Only</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

var emailRules: any = [
  { required: true, message: 'Please input your email' },
  { type: 'email', message: 'Invalid email' }
]
