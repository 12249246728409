import { Tooltip, Typography } from 'antd'

import { ColorAvatar } from './ColorAvatar'
import { Block } from './Block'

import { ICompany } from 'types/company.types'

interface Props {
  company: ICompany
  ellipsis?: boolean
  collapsed?: boolean
  type?: 'success' | 'danger'
}

export const CompanyTitle: ReactFC<Props> = (props) => {
  const { company, ellipsis, collapsed, type } = props

  const style = {
    fontWeight: 600,
    width: ellipsis ? 180 : 'auto'
  }

  return (
    <Tooltip title={collapsed ? company.name : null} placement="right">
      <Block className="CompanyTitle">
        <Block className="CompanyTitleAvatar">
          <ColorAvatar name={company.name} src={company.image} size={32} />
        </Block>
        <Typography.Text ellipsis={true} style={style} type={type} className="CompanyTitleName">
          {company.alias || company.name}
        </Typography.Text>
      </Block>
    </Tooltip>
  )
}
