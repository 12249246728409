import React from 'react'
import { createRoot } from 'react-dom/client'
import { ConfigProvider, ThemeConfig } from 'antd'

import { App } from 'app/App'

import { log } from 'utils/log'
import { sentry } from 'utils/sentry'
import { config } from 'utils/config'
import { analytics } from 'utils/analytics'
import { commandbar } from 'utils/commandbar'

import './styles/app.scss'
import './styles/index.css'
import './styles/print.css'

log('Start Equility')

if (!config.isLocal) {
  sentry.init(config.sentry.dsn)
  commandbar.init(config.commandbar.org)
  analytics.init(config.ga.id)
}

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

const theme: ThemeConfig = {
  token: {
    colorBgLayout: '#DDF0E1',
    colorPrimary: '#0F9648',
    colorLink: '#0F9648',
    colorSuccess: '#0F9648',
    colorWarning: '#faad14',
    colorError: '#f5222d',
    colorText: 'rgba(0, 0, 0, 0.65)',
    colorTextSecondary: 'rgba(0, 0, 0, 0.45)',
    colorTextHeading: 'rgba(0, 0, 0, 0.85)',
    colorBorder: '#d9d9d9',
    borderRadius: 6,
    boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    fontSize: 16,
    fontFamily: 'Figtree',
    controlHeight: 40,
    
  },
  components: {
    Button: {
      defaultShadow: 'none',
      primaryShadow: 'none',
      boxShadow: 'none',
      fontWeight: 600,
    }
  }
}

if (window.self !== window.top) {
  root.render(<div />)
} else {
  root.render(
    // <React.StrictMode>
      <ConfigProvider theme={theme}>
        <App />
      </ConfigProvider>
    // </React.StrictMode>
  )
}
