import dayjs, { Dayjs } from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.locale('en')
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(relativeTime)
dayjs.extend(quarterOfYear)

export function unixToDate(value?: number, format?: string) {
  if (!value) return ''
  return dayjs(value).format(format || 'LL')
}

export function stringToDate(value?: string, format?: string) {
  if (!value) return ''
  return dayjs(value.split('T')[0]).format(format || 'LL')
}

export function stringToDayjs(value?: string) {
  if (!value) return undefined
  return dayjs(value.split('T')[0])
}

export function sortByDate(a: string, b: string) {
  return dayjs(a).unix() - dayjs(b).unix()
}

export function toSafeDateString(
  value: string | number | Date | Dayjs,
  format?: string,
) {
  return dayjs(value).format(format || 'YYYY-MM-DD')
}

export function fromNow(value: string | number | Date | Dayjs) {
  return dayjs(value).fromNow()
}

export function yearStart(format?: string) {
  return dayjs()
    .startOf('year')
    .format(format || 'YYYY-MM-DD')
}

export function prevMonthStart(format?: string) {
  return dayjs()
    .subtract(1, 'month')
    .startOf('month')
    .format(format || 'YYYY-MM-DD')
}

export function prevMonthEnd(format?: string) {
  return dayjs()
    .subtract(1, 'month')
    .endOf('month')
    .format(format || 'YYYY-MM-DD')
}

export const formatDateByPeriod = (date: any, period: string) => {
  const d = dayjs(date)

  switch (period) {
    case 'month':
      return d.format('MMM YYYY')
    case 'quarter':
      return `Q${d.quarter()} ${d.year()}`
    case 'year':
      return d.year().toString()
    default:
      return ''
  }
}
