import { Typography } from 'antd'

import { toCurrency } from 'utils/numbers'

interface ColorAmountProps {
  amount: number
}

export const ColorAmount: ReactFC<ColorAmountProps> = (props) => {
  const { amount } = props
  
  const style = {
    color: amount < 0 ? 'darkred' : 'darkgreen'
  }

  return (
    <Typography.Text style={style}>
      {toCurrency(amount)}
    </Typography.Text>
  )
}
