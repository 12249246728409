import { useEffect, useState } from 'react'
import { Card, Divider, message, Slider, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'

import { Plaid } from './PlaidLink'
import { Upload } from './Upload'

import { api } from 'utils/axios'
import { ITx } from 'types/tx.types'
import { errorMsg } from 'utils/errorMsg'
import { IAccount } from 'types/company.types'
import { Btn } from 'components/Btn'
import { Block } from 'components/Block'

const { Title, Text } = Typography

enum UploadType {
  None,
  File,
  Plaid
}

interface Props {
  account?: IAccount
  range: any
  daysRange: number
  onChange: (tsx: ITx[], isPlaid: boolean) => void
  setDaysRange: (v: number) => void
  onComplete: () => void
  onCancel: () => void
}

export const ReconcileSettings: ReactFC<Props> = (props) => {
  const { account, range, daysRange, setDaysRange, onChange, onComplete, onCancel } = props

  const [plaidToken, setPlaidToken] = useState('')
  const [plaidRefresh, setPlaidRefresh] = useState(0)
  const [ready, setReady] = useState<UploadType>(UploadType.None)

  useEffect(() => {
    api
      .get('/plaid/link_token')
      .then(({ data }) => setPlaidToken(data.link_token))
      .catch((err) => message.error(errorMsg(err), 5))
  }, [])

  const handleChange = (tsx: ITx[], isPlaid = false) => {
    setReady(isPlaid ? UploadType.Plaid : UploadType.File)
    onChange(tsx, isPlaid)
  }

  const handleCancel = () => {
    setPlaidRefresh(Date.now())
    setReady(UploadType.None)
    onCancel()
  }

  return (
    <FlexSpace direction="vertical" style={{ padding: 25, borderRadius: 4, minHeight: 580 }} size="large">
      <FlexSpace direction="vertical">
        <Title level={5}>Specify date range</Title>
        <Text>Number of days before and after transaction date to look for potential matches</Text>
        <Card>
          <Slider defaultValue={daysRange} min={1} max={5} marks={marks} onChange={setDaysRange} />
        </Card>
      </FlexSpace>
      <Divider style={{ marginBottom: 0 }} />
      {!!plaidToken && (
        <Block style={{ display: ready !== UploadType.File ? 'block' : 'none' }}>
          <Plaid
            token={plaidToken}
            range={range}
            account={account}
            refresh={plaidRefresh}
            onChange={(r) => handleChange(r, true)}
            onCancel={() => setReady(UploadType.None)}
          />
        </Block>
      )}
      {ready === UploadType.None && (
        <Divider style={{ margin: 0 }}>OR</Divider>
      )}
      <Block style={{ display: ready !== UploadType.Plaid ? 'block' : 'none' }}>
        <Upload account={account} onChange={(r) => handleChange(r, false)} onCancel={() => setReady(UploadType.None)} />
      </Block>
      {ready !== UploadType.None && (
        <>
          <Divider style={{ marginTop: -20 }} />
          <Btn type="primary" title="Start reconciliation" block onClick={onComplete} />
        </>
      )}
      <Btn title="Back" block onClick={handleCancel} />
    </FlexSpace>
  )
}

var marks = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }
