import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { message } from 'antd'

import { FullLoader } from 'components/FullLoader'

export const ShopifyRedirect = () => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const search = Object.fromEntries(searchParams) as any
    search.public_token 
      ? onSuccess(search.public_token) 
      : onError()
  }, [])

  const onSuccess = (token: string) => {
    const msg = 'Shopify access granted'
    const t = { type: 'SHOPIFY_ADDED', token }
    localStorage.setItem('eq_message', JSON.stringify(t))
    postMessage(t, '*')
    message.success(msg, 5)
    onClose()
  }

  const onError = () => {
    const msg = 'Access denied by Shopify'
    const t = { type: 'SHOPIFY_DENIED' }
    localStorage.setItem('eq_message', JSON.stringify(t))
    postMessage(t, '*')
    message.error('Error: ' + msg, 5)
    onClose()
  }

  const onClose = () => {
    setTimeout(() => window.close(), 5000)
  }

  return <FullLoader />
}
