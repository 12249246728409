import { FC, useRef, useState } from 'react'
import { Divider, Space, Spin, Tabs, TabsProps, Typography } from 'antd'

import { Btn } from 'components/Btn'
import { Page } from 'components/Page'
import { Block } from 'components/Block'
import { Search } from 'components/Search'
import { FlexSpace } from 'components/FlexSpace'

import { SettingForm } from './components/SettingsForm'
import { FormulaEditor } from './components/FormulaEditor'

import { ICustomRule, ICustomRuleSettings, ICustomRuleTarget, IEditorData, IEditorType } from './types'

// TEMP
import { evaluate } from 'mathjs'
import { useCustomRules } from './hooks/rules.hook'
import { api } from 'utils/axios'
import { ITx } from 'types/tx.types'
import { prevMonthEnd, stringToDayjs, toSafeDateString, yearStart } from 'utils/dates'
import { Dayjs } from 'dayjs'
import { IReport, IRow } from 'types/qbo.types'

// 

const { Title } = Typography

type TabKey = 'all' | 'A' | 'P' | 'B'

const initialDateRange: [Dayjs | undefined, Dayjs | undefined] = [
  stringToDayjs(yearStart()),
  stringToDayjs(prevMonthEnd())
]

const initialData: ICustomRuleSettings = {
  name: 'Custom Rule',
  method: 'Cash',
  aggregation: 'total',
  daterange: initialDateRange,
  target: '>',
  value: 30,
  unit: 'percentage'
}

export const CustomRules: FC = () => {
  const editorRef = useRef<HTMLDivElement>(null)

  const [l, setL] = useState(false)
  const [formula, setFormula] = useState<IEditorData[]>([])
  const [caret, setCaret] = useState<number | undefined>(undefined)

  const [settings, setSettings] = useState<ICustomRuleSettings>(initialData)

  const [tab, setTab] = useState<TabKey>('all')
  const [search, setSearch] = useState('')

  const { loading, data, company } = useCustomRules()

  console.log(settings)


  const addToFormula = (value: IEditorData) => {
    const prev = caret ? formula[caret] : formula[formula.length - 1]
    const position = caret === undefined ? formula.length : caret + 1
    const nextFormula = [...formula]

    if (value.type === 'N' && prev?.type === 'N') {
      prev.name += value.name
    } else {
      nextFormula.splice(position, 0, value)
      setCaret(position)
    }

    setFormula(validate(nextFormula))

    setTimeout(() => {
      editorRef.current?.focus()
    })
  }

  const removeFromFormula = () => {
    const position = caret === undefined ? formula.length - 1 : caret
    const nextFormula = [...formula]

    if (nextFormula[position]?.type === 'N' && nextFormula[position]?.name.length > 1) {
      nextFormula[position].name = nextFormula[position].name.slice(0, -1)
    } else {
      nextFormula.splice(position, 1)
      setCaret(position > 0 ? position - 1 : undefined)
    }

    setFormula(validate(nextFormula))
  }

  const onSubmit = async () => {
    if (!settings || !company) return

    const scope = {} as any
    const expression = formula
      .map((v, i) => {
        if (v.type === 'N') return v.name
        if (v.type === 'O') return v.name

        const variable = v.type.toLowerCase() + i

        if (v.type === 'A') scope[variable] = v.id
        else scope[variable] = v.name

        return `${v.type.toLowerCase()}${i}`
      })
      .join('')

    const r: ICustomRule = { expression, scope, formula, settings }
    console.log(r)

    setL(true)

    try {
      const s = {} as any

      const balances = await fetchBalances({
        accounting_method: settings.method,
        start_date: toSafeDateString(settings.daterange[0]!),
        end_date: toSafeDateString(settings.daterange[1]!)
      } as any, company.id)

      console.log('BALANCES', balances)

      const [pf, bs] = await fetchReports({
        accounting_method: settings.method,
        start_date: toSafeDateString(settings.daterange[0]!),
        end_date: toSafeDateString(settings.daterange[1]!)
      }, company.id)
        .then(({ profitAndLoss, balanceSheet }) => {
          return [
            toEditorData(flattenReport(profitAndLoss), 'P', 'Profit & Loss'),
            toEditorData(flattenReport(balanceSheet), 'B', 'Balance Sheet')
          ]
        })

      console.log('PF', pf, 'BS', bs)

      for (let key of Object.keys(scope)) {
        const t = key[0]
  
        switch (t) {
          case 'a':
            const total = await queryQboTxTotal({
              applicationId: company.id,
              from: toSafeDateString(settings.daterange[0]!),
              to: toSafeDateString(settings.daterange[1]!),
              accountQboId: scope[key],
              unreconciled: false,
              accountingMethod: settings.method
            })

            if (settings.aggregation === 'balance') {
              console.log(123123123, scope[key], balances)
              const b = balances.find((v) => scope[key] === v.id.toString())
              s[key] = b?.balance || 0
              break
            }
  
            console.log('TOTAL ' + scope[key], total)
            s[key] = total
            break
  
          case 'p':
            const p = pf.find((v) => scope[key] === v.name)
            s[key] = p?.value || 0
            break
          case 'b':
            const b = bs.find((v) => scope[key] === v.name)
            s[key] = b?.value || 0
            break
          default:
            break
        }
      }

      console.log('SCOPE', s)

      // Object.entries(scope).forEach(([key, value]) => {
      //   const f = data.find((v) => value === v.name)
      //   s[key] = f?.value || 0
      // })

      const result = evaluate(expression, s)

      const result2 = settings.unit === 'percentage' ? result * 100: result
      const result3 = compare(settings.target, result2, settings.value)
      const msg = `
        FORMULA: ${expression}
        SCOPE: ${JSON.stringify(s)}
        COMPARE: ${result2} ${settings.target} ${settings.value}
        RESULT: ${result3 ? 'PASS' : 'FAIL'}
      `

      setL(false)
      alert(msg)
    } catch (error: Error | any) {
      setL(false)
      alert(error.message)
    }
  }

  const dataList = data
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((v) => tab === 'all' || v.type === tab)
    .filter((v) => !search || v.name.toLowerCase().includes(search.toLowerCase()))

  return (
    <Page title={'Custom Rules'}>
      <Title level={5}>Settings</Title>
      <SettingForm
        initialData={settings}
        onSubmit={setSettings}
      />
      <Divider />
      <Title level={5}>Formula</Title>
      <FlexSpace direction="vertical">
        <FormulaEditor
          ref={editorRef}
          formula={formula}
          caret={caret}
          setCaret={setCaret}
          onChange={addToFormula}
          onBackspace={removeFromFormula}
        />
        <FlexSpace spacebetween style={{ width: 600 }}>
          <Space wrap>
            {operators.map((v) => (
              <Btn
                key={'Op' + v.id}
                size="small"
                title={v.name}
                style={{ minWidth: 30 }}
                onClick={() => addToFormula(v)}
              />
            ))}
          </Space>
          <Space wrap>
            <Btn size="small" title="Clear" onClick={() => setFormula([])} disabled={!formula.length} loading={l} />
            <Btn size="small" type="primary" title="Test Run" onClick={onSubmit} disabled={!formula.length} loading={l} />
          </Space>
        </FlexSpace>
      </FlexSpace>
      <Divider />

      <Spin spinning={loading || l}>
        <Tabs
          items={items}
          defaultActiveKey={tab}
          onChange={(key) => {
            setTab(key as TabKey)
            setSearch('')
          }}
        />
        <FlexSpace size="large" direction="vertical">
          <FlexSpace size="large">
            <Search value={search} onChange={setSearch} />
          </FlexSpace>
          <Block style={{ height: 300, overflow: 'scroll' }}>
            <FlexSpace direction="vertical">
              {dataList.map((v, i) => (
                <Btn
                  key={'dataList' + i}
                  size="small"
                  onClick={() => addToFormula(v as any)}
                  title={v.name + ' (' + v.subType + ')'}
                />
              ))}
            </FlexSpace>
          </Block>
        </FlexSpace>
      </Spin>
    </Page>
  )
}

export function queryQboTxTotal(params: ITempParams) {
  return api.get<ITempResponse>('/integrations/qbo/transactions', { params })
    .then(({ data }) => data)
    .then(({ mapList }) => mapList.map(tx => tx.amount))
    .then(amounts => {
      console.log('AMOUNTS', amounts)
      const rt = amounts.reduce((a, b) => {
        return parseFloat((Math.round((a + b) * 100) / 100).toFixed(2))
      }, 0)
      return rt
    })
}

function compare(target: ICustomRuleTarget, v1: number, v2: number) {
  switch (target) {
    case '>':
      return v1 > v2
    case '<':
      return v1 < v2
    case '=>':
      return v1 >= v2
    case '=<':
      return v1 <= v2
    default:
      return false
  }
}

const operators: IEditorData[] = [
  { id: 1, name: '+', type: 'O' },
  { id: 2, name: '-', type: 'O' },
  { id: 3, name: '*', type: 'O' },
  { id: 4, name: '/', type: 'O' },
  { id: 5, name: '(', type: 'O' },
  { id: 6, name: ')', type: 'O' }
]

const items: TabsProps['items'] = [
  {
    key: 'all',
    label: 'All'
  },
  {
    key: 'A',
    label: 'Chart of Accounts'
  },
  // {
  //   key: 'T',
  //   label: 'Account types'
  // },
  // {
  //   key: 'S',
  //   label: 'Account subtypes'
  // },
  {
    key: 'P',
    label: 'Profit & Loss'
  },
  {
    key: 'B',
    label: 'Balance Sheet'
  }
]

const validate = (nextFormula: IEditorData[]) => {
  if (nextFormula.length === 0) return nextFormula

  nextFormula.forEach((v, i) => {
    if (v.type !== 'O' && nextFormula[i - 1]) {
      v.error = nextFormula[i - 1]?.type !== 'O'
    }

    if (v.type === 'O' && v.name !== '(' && v.name !== ')') {
      v.error = nextFormula[i - 1]?.type === 'O' && nextFormula[i - 1]?.name !== ')'
    }

    if (v.name === ')') {
      const p1 = nextFormula.filter((vv) => vv.name === '(')
      const p2 = nextFormula.filter((vv) => vv.name === ')')
      v.error = p1.length < p2.length || nextFormula[i - 1]?.name === '('
    }

    if (v.name === '(' && nextFormula[i - 1]) {
      v.error = nextFormula[i - 1]?.type !== 'O'
    }

    if (v.type === 'N' && nextFormula[i - 1]?.type === 'O') {
      v.error = nextFormula[i - 1].name === ')'
    }

    if (v.type === 'N' && nextFormula[i - 1]?.type !== 'O') {
      // v.error = nextFormula[i - 1].name !== '('
    }
  })

  return nextFormula
}


interface ITempParams {
  applicationId: number
  from: string
  to: string,
  accountQboId: string
  unreconciled: boolean
  accountingMethod: 'Cash' | 'Accrual'
}

interface ITempResponse {
  mapList: ITx[]
  transactions: any[]
}



function toEditorData(flatReport: IFlatReportData[], type: IEditorType, subType: string): IEditorData[] {
  return flatReport
    .filter(v => !v.id)
    .map(v => ({...v, type, subType }))
}

function fetchReports(params: IFetchReportsParams, companyId: number) {
  return api.get<IReportsResponse>(`/applications/${companyId}/overview`, { params })
    .then(({ data }) => data)
}

function fetchBalances(params: IFetchReportsParams, companyId: number) {
  const temp = {
    companyId, 
    accounts: [],
    from: params.start_date,
    to: params.end_date, 
    method: params.accounting_method
  }
  return api.get<IBalancesResponse>(`/finreview/accounting/balances`, { params: temp })
    .then(({ data }) => data)
}

function flattenReport(report: IReport): IFlatReportData[] {
  const r: IFlatReportData[] = []

  flatten(report.Rows.Row)

  function flatten(row: IRow[]) {
    row.forEach((row: IRow) => {
      if (row.type === 'Section' && row.Summary) {
        r.push({
          id: '',
          name: row.Summary.ColData[0].value,
          value: parseFloat(row.Summary.ColData[1].value)
        })
        if (row.Rows?.Row)
          flatten(row.Rows.Row)
      } else if (row.type === 'Data') {
        if (row.ColData)
          r.push({
            id: row.ColData[0].id,
            name: row.ColData[0].value,
            value: parseFloat(row.ColData[1].value)
          })
      }
    })
  }
  
  return r
}


// Interfaces

interface IFlatReportData {
  id: string
  name: string
  value: number
}

interface IFetchReportsParams {
  accounting_method: 'Cash' | 'Accrual'
  start_date: string
  end_date: string
}

interface IReportsResponse {
  profitAndLoss: IReport
  balanceSheet: IReport
}

type IBalance = {
  id: number
  name: string
  balance: number
}

type IBalancesResponse = IBalance[]