import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { currentAccounts, currentCompany } from 'recoil/companies.state'
import { IAccount } from 'types/company.types'
import { IReport, IRow } from 'types/qbo.types'

import { api } from 'utils/axios'
import { prevMonthEnd, yearStart } from 'utils/dates'
import { accountName, getAccountSubType } from 'utils/accountName'

import { IEditorData, IEditorType } from '../types'

export const useCustomRules = () => {
  const company = useRecoilValue(currentCompany)
  const accounts = useRecoilValue(currentAccounts)

  const [loading, setLoading] = useState<boolean>(false)

  const [profitAndLoss, setProfitAndLoss] = useState<IFlatReportData[]>([])
  const [balanceSheet, setBalanceSheet] = useState<IFlatReportData[]>([])

  const data: IEditorData[] = [
    ...toEditorData(profitAndLoss, 'P', 'Profit & Loss'),
    ...toEditorData(balanceSheet, 'B', 'Balance Sheet'),
    ...toEditorDataAccounts(accounts)
  ]

  useEffect(() => {
    if (!company?.id) return
    setLoading(true)
    fetchReports(undefined, company?.id)
      .then(({ profitAndLoss, balanceSheet }) => {
        setProfitAndLoss(flattenReport(profitAndLoss))
        setBalanceSheet(flattenReport(balanceSheet))
        setLoading(false)
      })
  
  }, [company?.id])



  return { loading, data, company }
}


// Defaults

const frDefaultParams: IFetchReportsParams = { 
  accounting_method: 'Cash',
  start_date: yearStart(),
  end_date: prevMonthEnd()
}

// Utils

function toEditorData(flatReport: IFlatReportData[], type: IEditorType, subType: string): IEditorData[] {
  return flatReport
    .filter(v => !v.id)
    .map(v => ({...v, type, subType }))
}

function toEditorDataAccounts(accounts: IAccount[]): IEditorData[] {
  return accounts
    .filter(v => v.active)
    .map(v => ({ 
      id: v.qboId, 
      name: accountName(v.name, v.number),
      type: 'A',
      subType: getAccountSubType(v),
    }))

}

function fetchReports(params = frDefaultParams, companyId: number) {
  return api.get<IReportsResponse>(`/applications/${companyId}/overview`, { params })
    .then(({ data }) => data)
}

function flattenReport(report: IReport): IFlatReportData[] {
  const r: IFlatReportData[] = []

  flatten(report.Rows.Row)

  function flatten(row: IRow[]) {
    row.forEach((row: IRow) => {
      if (row.type === 'Section' && row.Summary) {
        r.push({
          id: '',
          name: row.Summary.ColData[0].value,
          value: parseFloat(row.Summary.ColData[1].value)
        })
        if (row.Rows?.Row)
          flatten(row.Rows.Row)
      } else if (row.type === 'Data') {
        if (row.ColData)
          r.push({
            id: row.ColData[0].id,
            name: row.ColData[0].value,
            value: parseFloat(row.ColData[1].value)
          })
      }
    })
  }
  
  return r
}


// Interfaces

interface IFlatReportData {
  id: string
  name: string
  value: number
}

interface IFetchReportsParams {
  accounting_method: 'Cash' | 'Accrual'
  start_date: string
  end_date: string
}

interface IReportsResponse {
  profitAndLoss: IReport
  balanceSheet: IReport
}