import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Alert, Card, Form, Input, Layout, Typography, message } from 'antd'

import { Btn } from 'components/Btn'
import { Logo } from 'components/Logo'
import { Header } from 'components/Header'
import { FlexSpace } from 'components/FlexSpace'
import { HeadTitle } from 'components/HeadTitle'

import { rules } from './auth.utils'
import { api } from 'utils/axios'
import { authAtom } from 'recoil/auth.state'

import './style.css'

export const ResetPass: ReactFC = () => {
  const [auth] = useRecoilState(authAtom)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate()
  const location = useLocation()

  const { from } = (location.state as any) || { from: { pathname: '/' } }

  const handleSubmit = (data: any) => {
    setLoading(true)
    api
      .post('auth/reset-password', data)
      .then(() => {
        message.success('Email with password reset instructions was sent to your inbox')
        navigate('/')
      })
      .catch((err) => setError(err?.response?.data?.message || err?.message || 'Error'))
      .finally(() => setLoading(false))
  }

  if (auth) return <Navigate to={from} replace />

  return (
    <Layout className="AuthLayout">
      <HeadTitle title="Reset Password" />
      <Layout.Content className="AuthLayoutContent NoSelect">
        <FlexSpace direction="vertical" size="large">
          <LogoHeader />
          <Card className="AuthCard" bordered={false}>
            <FlexSpace direction="vertical" className="signin-card-content">
              <Header size={46} style={{ marginBottom: 24 }}>
                Reset Password
              </Header>
              <Form layout="vertical" size="large" requiredMark={false} onFinish={handleSubmit}>
                <Form.Item name="email" label="Enter your email address to reset password" rules={rules.email}>
                  <Input placeholder="example@mail.com" autoFocus autoComplete="new-email" />
                </Form.Item>
                <Form.Item>
                  <FlexSpace className="AuthCardFooter" spacebetween>
                    <Btn title="Submit" type="primary" loading={loading} htmlType="submit" width={464} />
                  </FlexSpace>
                </Form.Item>
              </Form>
              {error && <Alert message="Error" description={error} type="error" showIcon />}
              <FlexSpace direction="horizontal" spacebetween>
                <FlexSpace direction="horizontal" className="AuthFooterLink">
                  <Typography.Text>New customer?</Typography.Text>
                  <Link to="/signup">Start here</Link>
                </FlexSpace>
                <Link to="/signin">Sign In</Link>
              </FlexSpace>
            </FlexSpace>
          </Card>
        </FlexSpace>
      </Layout.Content>
    </Layout>
  )
}

const LogoHeader = () => (
  <div className="AuthTitleContainer">
    <Logo width={187} />
  </div>
)
