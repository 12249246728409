import { useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { message, Result, Space, Spin, Tag, Tooltip, Typography } from 'antd'
import { Column, CompactTable } from '@table-library/react-table-library/compact'
import { useRowSelect } from "@table-library/react-table-library/select"
import { useTheme } from "@table-library/react-table-library/theme"
import { Navigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'

import { Btn } from 'components/Btn'
import { Page } from 'components/Page'
import { Block } from 'components/Block'
import { FlexSpace } from 'components/FlexSpace'
import { Search } from 'components/Search'
import { CompanyTitle } from 'components/CompanyTitle'
import { AddCompany } from 'components/AddCompany'
import { HeadTitle } from 'components/HeadTitle'

import { currentUser } from 'recoil/user.state'
import { companyAtom, companiesAtom } from 'recoil/companies.state'
import { ICompany } from 'types/company.types'
import { errorMsg } from 'utils/errorMsg'
import { api } from 'utils/axios'
import { stringToDate } from 'utils/dates'

import QboLogo from 'assets/qbo/logo-quickbooks.png'

import './style.scss'

export const Dashboard: ReactFC = () => {
  const navigate = useNavigate()
  
  const user = useRecoilValue(currentUser)
  const [companyId, setCompany] = useRecoilState(companyAtom)
  const [companies, setCompanies] = useRecoilState(companiesAtom)
  const refreshUser = useRecoilRefresher_UNSTABLE(currentUser)

  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const ownCompanies = companies.filter((v) => v.role.id === 0).length
  const maxCompanies = user?.subscription?.quantity || 0
  const remainingCompanies = maxCompanies - ownCompanies

  const data: ICompany[] = companies.filter((v) => {
    return v.name?.toLowerCase().search(filter?.toLowerCase()) > -1
  }).sort((a, b) => {
    const aName = a.alias?.toLowerCase() || a.name?.toLowerCase()
    const bName = b.alias?.toLowerCase() || b.name?.toLowerCase()
    return aName.localeCompare(bName)
  })

  const select = useRowSelect({ nodes: data }, {
    state: { ids: [companyId] },
    onChange: (v) => handleSelect(v.payload.id),
  })

  const theme = useTheme([{
    Table: `--data-table-library_grid-template-columns: auto 120px 160px 260px 260px 100px`
  }])

  const refreshCompanies = () => {
    return api.get<ICompany[]>('applications')
      .then(({data}) => {
        setCompanies(data)
        setError(false)
      })
      .catch((err) => {
        message.error(errorMsg(err), 5)
        setError(true)
      })
  }

  useEffect(() => {
    setLoading(true)
    refreshCompanies()

    const i = setInterval(() => {
      refreshCompanies()
    }, 10000)

    setTimeout(() => {
      setLoading(false)
    }, 3000)

    return () => {
      clearInterval(i)
    }
  }, [])

  // if (!user?.subscription && !user?.subscribed_before && !companies.length) return <StartTrial />
  // if (!user?.subscription && !companies.length) return <NoSubscription />
  if (!companies.length) return <Navigate to="/welcome" />
  if (error) return <ErrorMsg />

  const handleDelete = (id: number, e: any) => {
    e?.stopPropagation()
    const role = companies.find(v => v.id === id)?.role
    if (role?.id === 0)
      return handleDeleteCompany(id)

    setLoading(true)
    const data = { application: id, user: user?.id }
    api.delete(`/applications/user`, { data })
      .finally(() => refreshCompanies())
      .finally(() => setLoading(false))
  }

  const handleDeleteCompany = (id: number) => {
    setLoading(true)
    const name = companies.find(v => v.id === id)?.name
    api.post(`/applications/delete`, { application: id, name })
      .finally(() => refreshCompanies())
      .finally(() => setLoading(false))
  }

  const handleSelect = (id: number) => {
    if (!id) return
    setCompany(id)
    navigate(`/company/${id}/settings/integrations`)
    api.post(`/logger`, { appId: id })
      .catch(console.log)
  }

  const handleDowngrade = () => {
    setLoading(true)
    const payload = { quantity: ownCompanies }
    api.put('/stripe/subscription', payload)
      .then(() => message.info('Subscription downgraded successfully'))  
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => {
        refreshUser()
        refreshCompanies()
        setLoading(false)
      })
  }

  const columns: Column<ICompany>[] = [
    { label: 'Company', renderCell: (company: ICompany) => <CompanyTitle company={company}  />, resize: true },
    { label: 'Role', renderCell: (company: ICompany) => company.role.name, resize: true },
    { label: 'Tier', resize: true, renderCell: (company: ICompany) => {
        if (!company.accessLevel) return 'Free'
        return <Tooltip title={'Subscription by: ' + company.owner?.firstName + ' ' + company.owner?.lastName}>
          <FlexSpace>
            {company.accessLevel === 1 ? 'Pro' : 'Advanced'}
            <FontAwesomeIcon icon={faQuestionCircle} />
          </FlexSpace>
        </Tooltip>
      }
    },
    {
      label: 'Last Financial Review', resize: true, renderCell: (company: ICompany) => {
        const checked_on = company.compliance?.checked_on
        if (!company.compliance || !checked_on) return 'Not reviewed yet'

        const from = stringToDate(company.compliance.checked_through_start, 'MM/DD/YY')
        const to = stringToDate(company.compliance.checked_through_end, 'MM/DD/YY')

        return (
          <Tooltip title={`${from} - ${to}`}>
            <FlexSpace>
              {company.progress + '% Accurate'}
              <FontAwesomeIcon icon={faQuestionCircle} />
            </FlexSpace>
          </Tooltip>
        )
      }
    },
    { label: 'QuickBooks', renderCell: (company: ICompany) => {
      const isQBOConnected = !!company.integrations.find(v => v.id === 1)?.status
        if (loading) return null
        return isQBOConnected
          ? <div style={{ minWidth: 0, display: 'flex', alignItems: 'center' }} >
              <img src={QboLogo} alt="QuickBooks" style={{ height: 25, marginRight: 10 }} />
              <span className='ellipsis'>{company.name}</span>
            </div>
          : <Tag color="error" style={{ fontSize: 16 }}>Disconnected</Tag>
    } },
    { label: 'Action', renderCell: (company: ICompany) => {
        if (company.role.id === 0)
          return (
            <Btn
              type="link"
              danger
              title="Delete"
              confirm="Are you sure to delete this company?"
              onClick={(e) => handleDelete(company.id, e)}
              style={{ padding: 0, height: 25 }}
            />
          )
        else return null
    } }
    
  ]

  return (
    <Page title="All Companies">
      <HeadTitle title="Dashboard" />
      <FlexSpace direction="vertical" size="large">
        <FlexSpace size="large">
          <Search value={filter} onChange={setFilter} />
          <AddCompany />
        </FlexSpace>
        <Block className="Companies">
          <Spin spinning={loading}>
            <CompactTable
              theme={theme}
              columns={columns} 
              data={{ nodes: data }} 
              select={select} 
              layout={{ custom: true }}
            />
          </Spin>
        </Block>
        {remainingCompanies > 0 && (
          <Block className="RemainingCompanies">
            <Space>
              <Typography>You have {remainingCompanies} unused {remainingCompanies > 1 ? 'companies' : 'company'} in your subscription</Typography>
              <Btn type="link" danger onClick={handleDowngrade}>Downgrade quantity</Btn>
            </Space>
          </Block>
        )}
      </FlexSpace>
    </Page>
  )
}

const ErrorMsg = () => {
  return (
    <Page title="All Companies">
      <Result
        status="warning"
        title="Something gone wrong!"
        subTitle="Check your internet connection and try again"
      />
    </Page>
  )
}
