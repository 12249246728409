import { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useRutterLink } from 'react-rutter-link'
import { Divider, Select, Spin, Typography, message } from 'antd'

import { Btn } from 'components/Btn'
import { Page } from 'components/Page'
import { FlexSpace } from 'components/FlexSpace'
import { RangePicker } from 'components/RangePicker'

import { companiesAtom, currentAccounts, currentCompany } from 'recoil/companies.state'
import { api } from 'utils/axios'
import { toCurrency } from 'utils/numbers'
import { ICompany } from 'types/company.types'
import { errorMsg } from 'utils/errorMsg'
import { stringToDayjs } from 'utils/dates'

const defaultFrom = '2023-09-01'
const defaultTo = '2023-11-30'
const defaultDateRange = [stringToDayjs(defaultFrom), stringToDayjs(defaultTo)]

export const RutterPage = () => {
  const company = useRecoilValue(currentCompany)
  const accounts = useRecoilValue(currentAccounts)
  const setCompanies = useSetRecoilState(companiesAtom)

  const [loading, setLoading] = useState(false)
  const [matched, setMatched] = useState<{[key: string]: string}>({})
  const [dateRange, setDateRange] = useState<any>(defaultDateRange)
  const [code, setCode] = useState('')

  const [commerce, setCommerce] = useState<ShopifyReport>()
  const [accounting, setAccounting] = useState<QboReport>()

  const integration = company?.integrations?.find(v => v.name === 'Rutter')
  const isConnected = !!integration?.status

  const refreshCompanies = () => {
    return api
      .get<ICompany[]>('applications')
      .then(({ data }) => setCompanies(data))
      .catch(console.log)
  }

  console.log('code', code)

  const rutterConfig = {
    publicKey: 'f1d459e4-c68f-459f-8655-fc5feeb48437', // PROD
    onSuccess: (token: string) => {
      setCode(token)
      message.success('success')
      api.post('/integrations', { token, companyId: company?.id, provider: 'Rutter' })
        .then(() => refreshCompanies())
        .catch(err => message.error(errorMsg(err), 5))
        .finally(() => setLoading(false))
    }
  }

  const { open } = useRutterLink(rutterConfig)

  const onSubmit = () => {
    console.log('submit', matched)
    const from = dateRange[0].format('YYYY-MM-DD')
    const to = dateRange[1].format('YYYY-MM-DD')

    setLoading(true)
    getShopifyReport(company?.id, from, to)
      .then(r => {
        const { meta, ...rest } = r
        setCommerce(rest)
      })
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
      
    getQboReport(Object.values(matched), company?.id, from, to)
      .then(setAccounting)
      .catch(err => message.error(errorMsg(err), 5))
  }

  const getAccName = (id: string) => {
    const acc = accounts.find(v => v.qboId === id)
    return acc?.name ?? ''
  }

  const getCommerceValue = (accType: IAccType) => {
    return toCurrency((commerce as any)?.[accType.id] || 0)
  }

  const getQboValue = (accType: IAccType) => {
    const id = matched?.[accType.id]
    return toCurrency((accounting as any)?.[id] || 0)
  }

  const getDifference = (accType: IAccType) => {
    const id = matched?.[accType.id]
    const c = (commerce as any)?.[accType.id] || 0
    const a = (accounting as any)?.[id] || 0
    return toCurrency(c - a)
  }

  return (
    <Page title="Rutter">
      <FlexSpace direction="vertical">
        <FlexSpace spacebetween>
          <Typography.Title level={4}>Setup Shopify</Typography.Title>
          <Typography>Status: {isConnected ? 'Connected' : 'Disconnected'}</Typography>
          <Btn type="primary" onClick={() => open({ platform: "SHOPIFY" })} title="Connect" loading={loading} />
        </FlexSpace>
        <Divider />
        <FlexSpace direction="vertical">
          <Typography.Title level={4}>Setup Accounts</Typography.Title>
          <FlexSpace direction="vertical">
            {shopifyAccounts.filter(v => !v.isCalc).map((accType) => (
              <FlexSpace key={accType.id}>
                <Typography.Text style={{ width: 200, display: 'block' }}>
                  {accType.name}
                </Typography.Text>
                <Select 
                  className="SelectPrimary"
                  style={{ width: 360 }} 
                  placeholder="Account"
                  options={accounts.map(v => ({ label: v.name, value: v.qboId }))}
                  filterOption={(input, option) => 
                    (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                  }
                  showSearch
                  onSelect={v => setMatched({ ...matched, [accType.id]: v })}
                />
              </FlexSpace>
            ))}
          </FlexSpace>
        </FlexSpace>
        <Divider />
        <FlexSpace direction="vertical" size="large">
          <FlexSpace spacebetween>
            <Typography.Title level={4} style={{ margin: 0 }}>Results</Typography.Title>
            <RangePicker value={dateRange} onChange={setDateRange} />
            <Btn type="primary" onClick={onSubmit} title="Run" loading={loading} width={100} />
          </FlexSpace>
          <FlexSpace direction="vertical">
            <Spin spinning={loading}>
            <FlexSpace>
              <Typography.Text style={{ width: 200, display: 'block' }}>
                <b>Shopify</b>
              </Typography.Text>
              <Typography.Text style={{ width: 200, display: 'block' }}>
                <b>Shopify Totals</b>
              </Typography.Text>
              <Typography.Text style={{ width: 400, display: 'block' }}>
                <b>QBO GL Account</b>
              </Typography.Text>
              <Typography.Text style={{ width: 200, display: 'block' }}>
                <b>QBO Totals</b>
              </Typography.Text>
              <Typography.Text style={{ width: 200, display: 'block' }}>
                <b>Difference</b>
              </Typography.Text>
            </FlexSpace>
            {shopifyAccounts.map((accType) => (
              <FlexSpace key={accType.id}>
                <Typography.Text style={{ width: 200, display: 'block' }}>
                  {accType.name}
                </Typography.Text>
                <Typography.Text style={{ width: 200, display: 'block' }}>
                  {getCommerceValue(accType)}
                </Typography.Text>
                <Typography.Text style={{ width: 400, display: 'block' }}>
                  {getAccName(matched?.[accType.id])}
                </Typography.Text>
                <Typography.Text style={{ width: 200, display: 'block' }}>
                  {getQboValue(accType)}
                </Typography.Text>
                <Typography.Text style={{ width: 200, display: 'block' }}>
                  {getDifference(accType)}
                </Typography.Text>
              </FlexSpace>
            ))}
            <FlexSpace>
              <pre>{JSON.stringify(commerce, null, 2)}</pre>
              <pre>{JSON.stringify(accounting, null, 2)}</pre>
            </FlexSpace>
            </Spin>
        </FlexSpace>
      </FlexSpace>
      </FlexSpace>
    </Page>
  )
}

interface IAccType {
  id: string
  name: string
  isCalc: boolean
}

const shopifyAccounts: IAccType[] = [
  {id: 'gross', name: 'Gross Sales', isCalc: false},
  {id: 'discount', name: 'Discounts', isCalc: false},
  {id: 'returns', name: 'Returns', isCalc: false},
  {id: 'net', name: 'Net Sales', isCalc: true},
  {id: 'shipping', name: 'Shipping', isCalc: false},
  // {id: 'tax', name: 'Taxes', isCalc: false},
  // {id: 'liability', name: 'Gift Cards', isCalc: false},
  {id: 'total', name: 'Total', isCalc: true},
  {id: 'payments', name: 'Payments', isCalc: true},
]

function getShopifyReport(companyId=104, from='2023-09-01', to='2023-11-30') {
  return api.get<ShopifyReport>(`/finreview/commerce` , { params: { companyId, from, to } })
    .then(res => res.data)
}

function getQboReport(accounts: string[], companyId=104, from='2023-09-01', to='2023-11-30') {
  return api.get<QboReport>(`/finreview/accounting/totals` , { params: { accounts, companyId, from, to } })
    .then(res => res.data)
}

interface QboReport {
  [id: string]: number
}

interface ShopifyReport {
  payments: number
  returns: number
  refunded: number
  total: number
  discount: number
  shipping: number
  tax: number
  net: number
  gross: number
  meta?: any
}