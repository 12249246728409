import { Alert, Button, Popover, Tag, Typography } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

import { FlexSpace } from 'components/FlexSpace'
// import { InfoBtn } from 'components/HelpBtn'

import { IResultMessage, IRuleResult } from 'types/compliance.types'
import { MessageAction } from './MessageAction'
import { faCircleExclamation, faWarning } from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { toCurrency } from 'utils/numbers'

const { Text } = Typography

interface Props {
  item: IRuleResult
  to: string
  finished?: boolean
  viewOnly?: boolean
  onUpdate: (v: IRuleResult) => void
  force?: boolean
}

export const RuleResult: ReactFC<Props> = (props) => {
  const { item, to, finished, viewOnly, force, onUpdate } = props

  const bg = item.status === 'Passed' 
    ? '#F2FDE8' 
    : item.status === 'Warning' 
      ? '#F8E8C8' 
      : '#FDEBE7'

  const color = item.status === 'Passed' 
    ? '#55931D' 
    : item.status === 'Warning' 
      ? '#b67a00' 
      : '#933c3c'

  const handleUpdate = (value: IResultMessage, i: number) => {
    const next = { ...item }

    next.results = next.results?.slice() || []
    next.results[i] = value

    const passedResults = next.results.filter(v => v.status === 'Passed')

    next.status = passedResults.length !== next.results?.length
      ? 'Failed'
      : 'Passed'

    onUpdate(next)
  }

  if (!item?.rule && !force) return null

  const description = <span
    dangerouslySetInnerHTML={{ __html: item.rule.description }} 
    style={{ display: 'inline-block', maxWidth: 700 }}
  />

  const iconStatus = item.status === 'Passed'
    ? faCheckCircle
    : item.status === 'Failed'
      ? faCircleXmark
      : faWarning

  const isOveride = !!item.results?.map(v => v.meta?.overide).filter(v => !!v).length
  const status = item.status === 'Passed'
    ? isOveride ? 'User Passed' : 'Passed'
    : item.status

  return (
    <FlexSpace direction="vertical" className="RuleResult">
      <FlexSpace spacebetween style={{ pageBreakAfter: 'avoid' }}>
        <Typography.Title level={5} style={{ margin: 0 }}>
          {item.rule?.name || item.rule?.title}
          <Popover content={description}>
            <Button type="text">
              <FontAwesomeIcon icon={faCircleExclamation} style={{ color: '#a0e8fc' }} />
            </Button>
          </Popover>
        </Typography.Title>
        <Tag color={bg} style={{ padding: '2px 8px' }}>
          <FlexSpace>
            <FontAwesomeIcon icon={iconStatus} color={color} size={'lg'} />
            <Typography.Text style={{ color, fontSize: 16, fontWeight: 700 }}>{status}</Typography.Text>
          </FlexSpace>
        </Tag>
      </FlexSpace>
      {item.info && (
        <Text>{item.info}</Text>
      )}
      <FlexSpace direction="vertical" size="large" style={{ marginTop: 12 }}>
        {item.results?.map((v, i) => (
          <Alert
            key={i + '_a'}
            message={<ResultMessage msg={v} to={to} />}
            style={getAlertStyle(v)}
            action={<MessageAction finished={finished || viewOnly} msg={v} onChange={v => handleUpdate(v, i)} />}
            className="RuleResultAlert"
          />
        ))}
      </FlexSpace>
    </FlexSpace>
  )
}

function getAlertStyle(v: IResultMessage) {
  const background = v.status === 'Passed'
    ? '#F2FDE8'
    : '#FDEBE7'
  return {
    background, 
    border: 'none'
  }
}

const ResultMessage = ({ msg, to }: { msg: IResultMessage, to: string }) => {
  const isReconcile = !!msg.meta?.isReconcile
  const style = { display: 'inline-block', paddingRight: 20 }

  // console.log('msg', msg)

  if (!!msg.meta?.isUncat && msg.status === 'Passed') {
    return (
      <FlexSpace direction="vertical" size="small" style={style}>
        <Text>
          <span style={style} dangerouslySetInnerHTML={{ __html: msg.message }} />
        </Text>
      </FlexSpace>
    )
  }

  if (!isReconcile) 
    return (
      <span style={style} dangerouslySetInnerHTML={{ __html: msg.message }} />
    )

  const acc = msg.meta?.account
  const uncleared = msg.meta?.uncleared || 0
  const unclearedList = msg.meta?.txs[0]?.list as any[]
  const unclearedTotal = unclearedList.reduce((a, b) => a + b.amount, 0) || 0
  const balance = msg.meta?.balance || 0
  const reconciledThrough = msg.meta?.reconciledThrough || 'N/A'
  const isOveride = !!msg.meta?.overide
  const overideUsername = msg.meta?.overide?.username || ''
  const warning = msg.meta?.account?.warning || ''

  const unclearedText = !!uncleared
    ? <span>Uncleared transactions: <b>{uncleared}</b> uncleared transaction(s) totaling {toCurrency(unclearedTotal)}</span>
    : 'None'

  const reviewText = !!uncleared ? ' need(s) to be reviewed.' : ''

  return (
    <FlexSpace direction="vertical" size="small" style={style}>
      {/* <span style={style} dangerouslySetInnerHTML={{ __html: msg.message }} /> */}
      <Text><b>{acc.name}</b> - {msg.status}</Text>
      <Text><u>Reconciled Through:</u> <b>{reconciledThrough}</b></Text>
      <Text><u>Uncleared transactions:</u> {unclearedText} {reviewText}</Text>
      <Text><u>Ending balance on <b>{to}</b> (including uncleared transactions):</u> <b>{toCurrency(balance)}</b></Text>
      {warning && <Text><b><u>Account warning:</u></b> {warning}</Text>}
      {isOveride && <Text><b>{overideUsername}</b> overrode the status</Text>}
    </FlexSpace>
  )
}
