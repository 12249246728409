import { Btn } from 'components/Btn'

interface SubscribeBtnProps {
  name: string
  isTrial: boolean
  onClick: () => void
}

export const SubscribeBtn: ReactFC<SubscribeBtnProps> = (props) => {
  const { name, isTrial, onClick } = props

  const title = isTrial 
    ? 'Try for free' 
    : `Upgrade to ${name}`

  const confirm = isTrial
    ? 'You will be charged after the trial period'
    : 'Current subsription will be upgraded. Are you sure?'

  return (
    <Btn 
      block
      type="primary" 
      title={title} 
      onClick={() => onClick()} 
      confirm={confirm}
    />
  )
}
