import { Suspense } from 'react'
import { useRecoilValue } from 'recoil'
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom'
import { Tabs } from 'antd'

import { ErrorBoundary } from 'components/ErrorBoundary'
import { FullLoader } from 'components/FullLoader'
import { HeadTitle } from 'components/HeadTitle'
import { Page } from 'components/Page'
import { Block } from 'components/Block'

import { Charts } from './tabs/Charts'
import { Financials } from './tabs/Financials'

import { currentAccounts, currentCompany } from 'recoil/companies.state'

export const Analytics = () => {
  const navigate = useNavigate()

  const company = useRecoilValue(currentCompany)
  const accounts = useRecoilValue(currentAccounts)

  const baseUrl = `/company/${company?.id}/analytics`
  const match = useMatch(baseUrl + '/:activeKey')
  const activeKey = (match as any)?.params.activeKey

  const items = [
    { label: 'Financial Statements', key: 'financial' },
    { label: 'Charts', key: 'charts' },
  ]

  const handleChange = (key: string) => {
    navigate(`${baseUrl}/${key}`)
  }

  if (!activeKey) return <Navigate to={baseUrl + '/financial'} />

  if (!accounts) return <div />

  return (
    <Page title={'Analytics'}>
      <HeadTitle title={'Analytics'} />
      <Tabs
        items={items}
        onChange={(key) => handleChange(key)}
        activeKey={activeKey}
        style={{ marginBottom: -16 }}
        className="NoSelect"
      />
      <Block style={{ marginTop: 16 }} />

      <ErrorBoundary>
        <Suspense fallback={<FullLoader />}>
          <Routes>
            <Route path="/financial" element={<Financials />} />
            <Route path="/charts" element={<Charts />} />
            <Route path="*" element={<Navigate replace to={`${baseUrl}/financial`} />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </Page>
  )
}
