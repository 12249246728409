import { FunctionComponent } from 'react'
import { useRecoilValue } from 'recoil'
import { Avatar, List, Typography } from 'antd'

import { Btn } from 'components/Btn'
import { Page } from 'components/Page'
import { FlexSpace } from 'components/FlexSpace'
import { HeadTitle } from 'components/HeadTitle'

import { IExpert } from 'types/expert.types'
import { dataExperts } from 'recoil/data.state'

const { Text, Title } = Typography

export const Accountant: ReactFC = () => {
  const experts = useRecoilValue(dataExperts)

  return (
    <Page title={'Advisory'}>
      <HeadTitle title="Advisory" />
      <FlexSpace direction="vertical">
        <Title level={5}>
          As part of your Equility subscription, you can schedule one meeting a 
          month with our founder to discuss you financial review results 
          and the best ways to tackle these issues.
        </Title>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={experts}
          renderItem={(item) => (
            <List.Item key={item.id} actions={[<BookBtn item={item} />]}>
              <ItemMeta item={item} />
              <Text>
                I am driven by the efficiency that technology provides. 
                By embracing a tech-centric approach to accounting, I hope to build Equility into the cornerstone of every financial tech stack.
              </Text>
              {/* <div dangerouslySetInnerHTML={{ __html: item.description }} /> */}
            </List.Item>
          )}
        />
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
          <iframe
            title="EQUILITY: Automating Financial Review Process and Ensuring Accuracy 👨‍💼" 
            src="https://www.loom.com/embed/31e0f09abda448f79183fcd8d4dfe4e4?sid=1ff6c5c3-be54-4e04-b63b-b85fc0be829a" 
            allowFullScreen 
            style={{ 
              borderRadius: 6,
              border: 'none',
              position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' 
            }}
          />
        </div>
      </FlexSpace>
    </Page>
  )
}

interface ItemProps {
  item: IExpert
}

const ItemMeta: FunctionComponent<ItemProps> = ({ item }) => {
  return (
    <List.Item.Meta
      avatar={<Avatar src={item.avatar} size={64} />}
      title={<a href={item.contact_url}>{item.name}</a>}
      // description={<ItemMetaDescription item={item} />}
      description={<i>Founder of Equility</i>}
    />
  )
}

// const ItemMetaDescription: FunctionComponent<ItemProps> = ({ item }) => {
//   return (
//     <FlexSpace>
//       <Text>Company:</Text>
//       <Link to={item.company_url} target="_blank">
//         {item.company_name}
//       </Link>{' '}
//     </FlexSpace>
//   )
// }

const BookBtn: FunctionComponent<ItemProps> = ({ item }) => {
  return (
    <Btn 
      title="Book Meeting"
      type="primary"
      onClick={() => window.open(item.contact_url, '_blank')}
    />
  )
}

