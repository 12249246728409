import { useLocation, useNavigate } from 'react-router-dom'
import { Table, message } from 'antd'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { Block } from 'components/Block'
import { Btn } from 'components/Btn'

import { sortByDate, stringToDate } from 'utils/dates'
import { ICompliance, IRuleResult } from 'types/compliance.types'
import { FlexSpace } from 'components/FlexSpace'
import { api } from 'utils/axios'
import { currentCompany, currentCompliances } from 'recoil/companies.state'
import { errorMsg } from 'utils/errorMsg'

interface Props {
  list: ICompliance[]
  loading?: boolean
}

export const ComplianceHistory: ReactFC<Props> = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  
  const company = useRecoilValue(currentCompany)
  const refresh = useRecoilRefresher_UNSTABLE(currentCompliances)

  const { list, loading } = props

  const viewOnly = company?.role.id === 2000

  const handleDelete = (id: number) => {
    api.delete(`/application_rules/results/${id}`)
      .then(() => refresh())
      .catch(error => message.error(errorMsg(error), 5))
  }

  return (
    <Block className="FullTable">
      <Table
        className="Table"
        dataSource={list}
        columns={columns(location.pathname, viewOnly, handleDelete, navigate)}
        pagination={false}
        loading={loading}
        rowKey={'id'}
      />
    </Block>
  )
}

function columns(path: string, viewOnly: boolean, onDelete: (id: number) => void, navigate: any) {
  return [
    {
      title: 'Checked On',
      dataIndex: 'checked_on',
      render: (v: string) => stringToDate(v, 'llll'),
      sorter: (a: any, b: any) => sortByDate(a.checked_on, b.checked_on),
    },
    {
      title: 'Start Date',
      dataIndex: 'checked_through_start',
      render: (v: string) => stringToDate(v.split('T')[0]),
      sorter: (a: any, b: any) => sortByDate(a.checked_through_start, b.checked_through_start)
    },
    {
      title: 'End Date',
      dataIndex: 'checked_through_end',
      render: (v: string) => stringToDate(v.split('T')[0]),
      sorter: (a: any, b: any) => sortByDate(a.checked_through_end, b.checked_through_end)
    },
    {
      title: 'Accuracy',
      dataIndex: 'results',
      render: (results: IRuleResult[]) => {
        const passed = results?.filter(v => v.status === 'Passed').length
        const progress = Math.round(100 / results.length * passed)
        return progress + '%'
      }
    },
    {
      title: '',
      dataIndex: 'id',
      width: 110,
      render: (id: number) => (
        <FlexSpace size="large">
          <Btn 
            title="View" 
            type="primary" 
            onClick={() => navigate(`${path}/${id}`)}
          />
          {!viewOnly && (
            <Btn
              onClick={() => onDelete(id)}
              type="text"
              tooltip='Delete record'
              mouseEnterDelay={2}
              confirm='Are you sure to delete this record?'
              icon={<FontAwesomeIcon icon={faTrash} color={'#f5222d'} />}
            />
          )}
        </FlexSpace>
      )
    }
  ]
}
