import { useEffect, useState } from 'react'
import { message, Segmented, Select, Table, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { UserAvatar } from 'components/UserAvatar'
import { Search } from 'components/Search'
import { Btn } from 'components/Btn'
import { Block } from 'components/Block'

import { api } from 'utils/axios'
import { IRole, ITeamMember } from 'types/user.types'
import { stringToDate } from 'utils/dates'
import { InviteUser } from './InviteUser'
import { errorMsg } from 'utils/errorMsg'
import { ICompany } from 'types/company.types'

interface Props {
  company: ICompany
}

export const Team: ReactFC<Props> = (props) => {
  const { company } = props

  const [team, setTeam] = useState<ITeamMember[]>([])
  const [invites, setInvites] = useState([])

  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [inviteModal, setInviteModal] = useState(false)

  const [tab, setTab] = useState('Accepted')
  const showInvites = tab === 'Invited'
  const isAdmin = company.role.id === 0
  const viewOnly = company.role.id === 2000

  useEffect(() => {
    fetchAll()
  }, [])

  const fetchAll = () => {
    setLoading(true)
    const baseUrl = '/applications/' + company.id
    Promise.all([api.get(baseUrl + '/members'), api.get(baseUrl + '/invites')])
      .then((r) => {
        setTeam(r[0].data)
        setInvites(r[1].data)
      })
      .finally(() => setLoading(false))
  }

  const sendInvite = (value: string, role: number) => {
    const email = value.trim().toLowerCase()
    
    if (team.find(v => v.email.toLowerCase() === email))
      return message.error('User already exists')

    setLoading(true)
    const payload = { application: company.id, email, role }
    api.post('/invites', payload)
      .then(() => message.success('Success'))
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => {
        setTab('Invited')
        fetchAll()
      })
  }

  const handleCancelInvite = (invite: string) => {
    api.delete('/invites/' + invite)
      .then(() => message.success('Success'))
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => fetchAll())
  }

  const handleResendInvite = (email: string) => {
    api.post('/invites/resend', { application: company.id, email })
      .then(() => message.success('Success'))
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => fetchAll())
  }

  const handleRemoveMember = (user: number) => {
    setLoading(true)
    const data = { application: company.id, user }
    api.delete('/applications/user', { data })
      .then(() => message.warning('Member removed'))
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => fetchAll())
  }

  const handleRoleChange = (role: number, user: number) => {
    setLoading(true)
    const data = { application: company.id, user, role }
    api.post('/applications/role', data)
      .then(() => message.success('Member updated'))
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => fetchAll())
  }

  const columns = showInvites 
    ? invitecolumns(handleCancelInvite, handleResendInvite, viewOnly)
    : teamcolumns(company.role.id, handleRemoveMember, isAdmin, handleRoleChange)
    
  const dataSource = showInvites ? invites : team

  return (
    <FlexSpace direction="vertical" size="large">
      <FlexSpace size="large">
        <Segmented options={['Accepted', 'Invited']} onChange={(r) => setTab(r as string)} />
        <Search value={filter} onChange={setFilter} />
        <Btn title="Invite teammate" type="primary" disabled={loading || viewOnly} onClick={() => setInviteModal(true)} />
      </FlexSpace>
      <InviteUser onSubmit={sendInvite} open={inviteModal} onCancel={() => setInviteModal(false)} />
      <Block className="FullTable">
        <Table
          className="Table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
          rowKey={'id'}
        />
      </Block>
    </FlexSpace>
  )
}

function teamcolumns(role: number, onRemove: (id: number) => void, isAdmin: boolean, onRole: (v: number, userId: number) => void) {
  return [
    {
      title: 'Teammate',
      key: 'firstName',
      dataIndex: 'firstName',
      render: (_: string, user: ITeamMember) => {
        return (
          <FlexSpace>
            <UserAvatar url={user.photoUrl} />
            <Typography>{parseName(user.firstName, user.lastName)}</Typography>
          </FlexSpace>
        )
      }
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (role: IRole, user: ITeamMember) => {
        if (role.id === 0)
          return (
            <Typography.Text style={{ paddingLeft: 10 }}>
              {role.name}
            </Typography.Text>
          )

        const props = {
          disabled: !isAdmin,
          style: { width: 120 },
          value: role.id + ''
        }

        return (
          <Select {...props} onChange={v => onRole(parseInt(v), user.id)}>
            <Select.Option value="2000">View Only</Select.Option>
            <Select.Option value="1000">Member</Select.Option>
          </Select>
        )
      }
    },
    {
      title: 'Joined',
      key: 'assignedOn',
      dataIndex: 'assignedOn',
      render: (v: string) => stringToDate(v)
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: 120,
      render: (id: number, user: ITeamMember) => {
        if (role > 1000) return null
        if (role >= user.role.id) return null
        return <Btn type="link" danger title="Remove" onClick={() => onRemove(id)} />
      }
    }
  ]
}

function invitecolumns(cancel: (id: string) => void, resend: (id: string) => void, viewOnly: boolean) {
  return [
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'Invited On',
      key: 'invitedOn',
      dataIndex: 'invitedOn',
      render: (v: string) => stringToDate(v)
    },
    {
      title: 'Invited By',
      key: 'invitedBy',
      dataIndex: 'invitedBy',
      render: (v: any) => parseName(v.firstName, v.lastName)
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: 200,
      render: (id: string, invite: any) => {
        if (viewOnly) return null
        return (
          <FlexSpace direction="horizontal">
            <Btn type='link' title="Resend" onClick={() => resend(invite.email)} />
            <Btn type='link' danger title='Rescind' onClick={() => cancel(id)} />
          </FlexSpace>
        )
      }
    }
  ]
}

function parseName(firstName: string, lastName: string) {
  return (firstName + ' ' + lastName).trim()
}
