import { api } from 'utils/axios'

// export function queryAccount(application: number, accountId: number) {
//   return api.get('/accounts/' + accountId, { params: { application } })
//     .then(({ data }) => data)
// }

export function getBalances(applicationId: number, params: Object) {
  return  api.get('/applications/' + applicationId + '/balances', { params })
      .then(({ data }) => data as any[])
}

export function queryQboTransactions(params: any) {
  return api.get('/integrations/qbo/transactions', { params })
    .then(({ data }) => data)
}

export function getShopifyReport(companyId: number, from: string, to: string) {
  return api.get<ShopifyReport>(`/finreview/commerce` , { params: { companyId, from, to } })
    .then(res => res.data)
}

export function getQboReport(accounts: string[], companyId=104, from='2023-09-01', to='2023-11-30') {
  return api.get<QboReport>(`/finreview/accounting/totals` , { params: { accounts, companyId, from, to } })
    .then(res => res.data)
}

export function getQboReport2(accounts: string[], companyId: number, from='2023-09-01', to='2023-11-30') {
  return api.get<AccData[]>(`/finreview/accounting/total` , { params: { accounts, companyId, from, to } })
    .then(res => res.data)
}

interface AccData {
  id: string
  name: string
  amount: number
}

interface QboReport {
  [id: string]: number
}

interface ShopifyReport {
  meta?: any
  accural: {
    payments: number
    returns: number
    refunded: number
    total: number
    discount: number
    shipping: number
    tax: number
    net: number
    gross: number
  }
  cash: {
    payments: number
    returns: number
    refunded: number
    total: number
    discount: number
    shipping: number
    tax: number
    net: number
    gross: number
  }
}