import { config } from './config'

class Storage {
  getItem<T>(itemName: string): T | null {
    try {
      const auth = localStorage.getItem(config.prefix + itemName) || ''
      return JSON.parse(auth) as T || null
    } catch (error) {
      return null
    }
  }

  setItem(itemName: string, item: any): void {
    localStorage.setItem(config.prefix + itemName, JSON.stringify(item))
  }

  removeItem(itemName: string): void {
    localStorage.removeItem(config.prefix + itemName)
  }

  clearAll(): void {
    localStorage.clear()
  }
}

const storage = new Storage()
export { storage }