
import { toSafeDateString } from 'utils/dates'
import { toCurrency } from 'utils/numbers'
import { queryQboTransactions } from './apiutils'
import { AccountType, IResultMessage, IRuleResult } from 'types/compliance.types'

interface IDepreciationRule {
  accountType: AccountType
  defaultRuleResult: IRuleResult
  accounts: any[]
  applicationId: number
  startDate: string
  endDate: string
  accountingMethod: string
}

export const depreciationRule = async ({
  accountType,
  defaultRuleResult,
  accounts,
  applicationId,
  startDate,
  endDate,
  accountingMethod
}: IDepreciationRule): Promise<IRuleResult> => {
  // const purposeAccs = accounts.filter(v => v.fixed_asset_purpose)

  // const otherAccs = accounts.filter(v =>
  //   v.accountType === accountType.accountType &&
  //   accountType.accountSubTypes.some((subtype) => subtype === v.accountSubType)
  // )

  // log('depreciationRule otherAccs', otherAccs)

  const getAccounts = (type: string, subType: string): any[] =>
    accounts.filter((account) => account.accountSubType === subType)
      // .filter(v => !v.parentQBOId)


  const getTransactions = async (account: any) => {
    const { mapList } = await queryQboTransactions({
      applicationId: applicationId,
      from: toSafeDateString(startDate),
      to: toSafeDateString(endDate),
      accountQboId: account.qboId,
      unreconciled: false,
      excludeSub: true,
      accountingMethod
    })
    
    // console.log(account, accountingMethod, mapList)

    return mapList || []
  }

  const getResult = async (
    accounts: any[],
    accumAccounts: any[],
    warningMessage: string,
    failMessage: string,
    passMessage: string,
    accType: string
  ): Promise<IResultMessage> => {
    // log('getResult accs ' + accType, accounts, accumAccounts)
    // console.log('getResult accs ' + accType, accounts, accumAccounts)

      const transactionsArr = (await Promise.all(accounts.map(getTransactions)))
        .map((v, i) => v.map((vv: any) => ({ ...vv, acc: accounts[i] })))
      const transactions = transactionsArr.flat()

      // console.log('transactionsArr', transactionsArr)

      const accumTransactionsArr = (await Promise.all(accumAccounts.map(getTransactions)))
        .map((v, i) => v.map((vv: any) => ({ ...vv, acc: accumAccounts[i] })))
      const accumTransactions = accumTransactionsArr.flat()

      const transactionsSum = transactions.reduce(
        (accum, trn) => accum + (trn?.amount ? parseFloat(trn.amount) : 0),
        0
      )
      const accumTransactionsSum = accumTransactions.reduce(
        (accum, trn) => accum + (trn?.amount ? parseFloat(trn.amount) : 0),
        0
      )

      const txs = transactionsArr.map((v, i) => {
        return {
          account: accounts[i],
          txs: v,
          sum: v.reduce((acc: number, tx: any) => acc + parseFloat(tx.amount || '0'), 0)
        }
      })

      const atxs = accumTransactionsArr.map((v, i) => {
        return {
          account: accumAccounts[i],
          txs: v,
          sum: v.reduce((acc: number, tx: any) => acc + parseFloat(tx.amount || '0'), 0)
        }
      })

      const tsxSum = txs.reduce((sum, v) => sum + v.sum , 0)
      const atsxSum = atxs.reduce((sum, v) => sum + v.sum , 0)


      // log('getResult txs', txs, 'Sum', tsxSum)
      // log('getResult atxs', atxs, 'Sum', atsxSum)

      // log('getResult tx', accounts, transactions, transactionsSum, accumTransactions, accumTransactionsSum)

      const accMsg = txs.length
        ? '<br/><br/>' + accType + ' Expense: ' + toCurrency(tsxSum) 
          // + ` (${transactions.length} transactions)`
          + '<br /><small>' + txs.map(v => '"' + getAccName(v.account) + '": ' + toCurrency(v.sum)).join('<br />') 
          + '</small>'
        : `<br />No ${accType} Expense accounts`

      const acuAccMsg = atxs.length
        ? '<br/><br/> Accumulated '+ accType + ': ' + toCurrency(atsxSum) 
          // + ` (${accumTransactions.length} transactions)`
          + '<br /><small>' + atxs.map(v => '"' + getAccName(v.account) + '": ' + toCurrency(v.sum)).join('<br />') 
          + '</small>'
        : `<br />No Accumulated ${accType} Expense accounts`

      const noAccs = (!txs.length && !atxs.length)
        ? '<br /><small>' + warningMessage + '</small>'
        : ''

      const meta =  {
        txs: [{
          type: accType,
          list: transactions,
        }, {
          type: 'Accumulated ' + accType,
          list: accumTransactions,
        }]
      }
      
      return parseFloat((transactionsSum + accumTransactionsSum).toFixed(2)) === 0
        ? {
            status: 'Passed',
            message: passMessage + (noAccs || (accMsg + acuAccMsg)),
            meta
          }
        : {
            status: 'Failed',
            message: failMessage + accMsg + acuAccMsg,
            meta
          }
    // } 
  }

  const depreciationResult = await getResult(
    getAccounts('Other Expense', 'Depreciation'),
    getAccounts('Fixed Asset', 'AccumulatedDepreciation'),
    "You don't have Depreciation and Accumulated Depreciation accounts",
    'During this period, the sum of your accumulated depreciation accounts on the balance sheet does not equal the sum of your depreciation expense on the income statement.',
    'You have properly depreciated your tangible fixed assets',
    'Depreciation'
  )

  const amortizationResult = await getResult(
    getAccounts('Other Expense', 'Amortization'),
    getAccounts('Fixed Asset', 'AccumulatedAmortization'),
    "You don't have Amortization and Accumulated Amortization accounts",
    'During this period, the sum of your accumulated amortization accounts on the balance sheet does not equal the sum of your amortization expense on the income statement.',
    'You have properly amortized your intangible fixed assets',
    'Amortization'
  )

  // const getResult2 = async (
  //   accounts: IAccount[],
  //   warningMessage: string,
  //   failMessage: string,
  //   passMessage: string
  // ): Promise<IResultMessage> => {
  //   if (!accounts.length)
  //     return { status: 'Warning', message: warningMessage }
  
  //   const parentAccs: any[] = [] // accounts.filter(v => isPuproseParrent(v.fixed_asset_purpose))
  //   const transactions = (await Promise.all(parentAccs.map(getTransactions))).flat()

  //   if (transactions.length)
  //     return { status: 'Failed', message: failMessage }
  //   else 
  //     return { status: 'Passed', message: passMessage }
  // }

  // const additionalResult = await getResult2(
  //   purposeAccs,
  //   "You don't have amortized/depreciated Fixed Assets accounts",
  //   'During this period, the sum of your accumulated amortization accounts on the balance sheet does not equal the sum of your amortization expense on the income statement.',
  //   'You have properly amortized/depreciated your fixed assets'  
  // )

  // log('depreciationRule additionalResult', additionalResult)

  defaultRuleResult.results = [depreciationResult, amortizationResult]   // additionalResult
  defaultRuleResult.status = getStatus(defaultRuleResult.results)

  return defaultRuleResult
}


function getStatus(results: IResultMessage[]) {
  if (results.find(v => v.status === 'Failed'))
    return 'Failed'

  if (results.length === results.filter(v => v.status === 'Passed').length)
    return 'Passed'

  return 'Warning'
}

// function isPuproseParrent(puprose: any) {
//   if (!puprose) return false
//   return puprose.name.search('Parent') > -1
// }

function getAccName(acc: any) {
  if (!acc.number) return acc.name
  return acc.number + ' ' + acc.name
}