import { IRule, IRuleResult, AccountType, IResultMessage, equityAccountMapping } from "types/compliance.types"
import { toSafeDateString } from "utils/dates"
import { queryQboTransactions } from "./apiutils"

interface INumberOfTransactionsRule {
  rule: IRule
  defaultRuleResult: IRuleResult
  accounts: any[]
  applicationId: number
  startDate: string
  endDate: string
  accountType: AccountType
  accountingMethod: string
  cType: string
}

export const numberOfTransactionsRule = async ({
  rule,
  defaultRuleResult,
  accounts,
  applicationId,
  startDate,
  endDate,
  accountType,
  accountingMethod,
  cType
}: INumberOfTransactionsRule): Promise<IRuleResult> => {
  let equityAccounts = accounts.filter(
    (account) =>
      account.accountType === accountType.accountType &&
      accountType.accountSubTypes.some((subtype) => subtype === account.accountSubType)
  )

  if (!equityAccounts.length) {
    defaultRuleResult.status = 'Passed'
    defaultRuleResult.results = [
      {
        message: `You’ve passed the ${cType} compliance check and are not using any non-compliance GL accounts`,
        status: 'Passed'
      }
    ]
  } else {
    let resultMessages: IResultMessage[] = []
    for (let account of equityAccounts) {
      const { transactions, mapList } = await queryQboTransactions({
        applicationId: applicationId,
        from: toSafeDateString(startDate),
        to: toSafeDateString(endDate),
        accountQboId: account.qboId,
        unreconciled: false,
        accountingMethod
      })
      if (!!transactions?.Rows?.Row?.length) {
        const meta = {
          txs: [{
            type: account.name,
            list: mapList.map((v: any) => ({...v, acc: account})),
          }],
          account
        }
        const accType = (equityAccountMapping as any)[account.accountSubType]
        resultMessages.push({
          status: 'Failed',
          message: `We have found transactions in the following non-compliance ${cType}: <br />${account.name} - ${accType}`,
          meta,
        })
      }
    }

    // if (equityAccounts.length) {
    //   for (let account of equityAccounts) {
    //     const accType = (equityAccountMapping as any)[account.accountSubType]
    //     resultMessages.push({
    //       status: 'Failed',
    //       message: `We have found non-compliance ${cType} account: ${account.name} - ${accType}`,
    //     })
    //   }
    // }

    if (!resultMessages.length) {
      defaultRuleResult.status = 'Passed'
      defaultRuleResult.results = [
        {
          message: `You’ve passed the ${cType} compliance check and are not using any non-compliance GL accounts`,
          status: 'Passed'
        }
      ]
    } else {
      defaultRuleResult.status = 'Failed'
      defaultRuleResult.results = [{ status: 'Failed', message: rule.failureMsg  }, ...resultMessages]
    }
  }
  return defaultRuleResult
}
