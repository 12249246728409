import { useEffect, useState } from 'react'
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'
import { Select, Table, Typography } from 'antd'

import { Btn } from 'components/Btn'
import { Block } from 'components/Block'
import { FlexSpace } from 'components/FlexSpace'

import { ICompany } from 'types/company.types'
import { currentAccounts, currentCompany, currentReconciliations } from 'recoil/companies.state'
import { toCurrency } from 'utils/numbers'
import { accountName } from 'utils/accountName'
import { sortByDate, stringToDate } from 'utils/dates'
import { api } from 'utils/axios'
import { IReconcile } from 'types/reconcile.types'

const { Text } = Typography

interface Acc {
  label: JSX.Element
  value: string
}

interface Props {
  list: any[]
  loading?: boolean
  company: ICompany
}

export const ReconcileHistory: ReactFC<Props> = (props) => {

  const location = useLocation()
  const navigate = useNavigate()

  const company = useRecoilValue(currentCompany)
  const accounts = useRecoilValue(currentAccounts)
  const refreshRecs = useRecoilRefresher_UNSTABLE(currentReconciliations)

  const [list, setList] = useState<IReconcile[]>([])
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<Acc>()

  useEffect(() => {
    if (!company) return
    setLoading(true)
    refreshRecs()
    api.get<IReconcile[]>(`/reconciliation/all?application=${company.id}`)
      .then(({ data }) => setList(data))
      .finally(() => setLoading(false))
  }, [])

  const recAccs: Acc[] = accounts
    .map((v) => ({
      value: v.qboId,
      label: (
        <FlexSpace spacebetween>
          <span>{accountName(v.name, v.number)}</span>
          <small><i style={{ paddingRight: 10, opacity: 0.5 }}>{v.accountType}</i></small>
        </FlexSpace>
      )
    }))

  const items = recAccs
    .filter((v) => list.filter((vv) => vv.qbo_account_id === v.value).length)

  const dataSource = list
    .filter((v) => v.qbo_account_id === selected?.value)
    .sort((a, b) => sortByDate(b.reconciled_on, a.reconciled_on))

  return (
    <FlexSpace direction="vertical" size="large" style={{ paddingTop: 20 }}>
      <FlexSpace size="large">
        <Text strong>Choose Account:</Text>
        <Select
          placeholder="Account"
          options={items}
          className="SelectPrimary"
          style={{ width: 360 }}
          value={selected?.value}
          onSelect={(_: any, v: Acc) => setSelected(v)}
        />
      </FlexSpace>
      <Block className="FullTable">
        <Table
          className="Table"
          dataSource={dataSource}
          columns={columns(location.pathname, navigate)}
          pagination={false}
          loading={loading}
          rowKey={'reconciliation_id'}
        />
      </Block>
    </FlexSpace>
  )
}

function columns(path: string, navigate: any) {
  return [
    {
      title: 'Statement ending date',
      dataIndex: 'end_date',
      render: (value: string) => stringToDate(value),
      sorter: (a: any, b: any) => sortByDate(a.end_date, b.end_date)
    },
    {
      title: 'Reconciled On',
      dataIndex: 'reconciled_on',
      render: (value: string) => stringToDate(value),
      sorter: (a: any, b: any) => sortByDate(a.reconciled_on, b.reconciled_on)
    },
    {
      title: 'Starting Balance',
      dataIndex: 'starting_balance',
      render: (value: string) => toCurrency(parseFloat(value) || 0),
      sorter: (a: any, b: any) => a.starting_balance - b.starting_balance
    },
    {
      title: 'Ending Balance',
      dataIndex: 'ending_balance',
      render: (value: string) => toCurrency(parseFloat(value) || 0),
      sorter: (a: any, b: any) => a.ending_balance - b.ending_balance
    },
    {
      title: '',
      dataIndex: 'reconciliation_id',
      render: (id: number, _: any, i: number) => (
        <Btn 
          title="View" 
          type="primary" 
          disabled={!!i} 
          onClick={() => navigate(`${path}/${id}`)}
        />
      )
    }
  ]
}
