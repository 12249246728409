import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { message } from 'antd'

import { FullLoader } from 'components/FullLoader'

import { queryCreateIntegration } from 'components/AddCompany'
import { companiesAtom } from 'recoil/companies.state'

export const QboSync = () => {
  const companies = useRecoilValue(companiesAtom)

  const [ready, setReady] = useState(false)
  const [searchParams] = useSearchParams()
  const search = Object.fromEntries(searchParams) as any

  const t = () => {
    if (search.error && search.error === 'access_denied') {
      const t = { type: 'COMPANY_ADD_DENIED', msg: 'Access denied by QuickBooks' }
      localStorage.setItem('eq_message', JSON.stringify(t))
      postMessage(t, '*')
    }

    if (search.code && search.realmId) {
      queryCreateIntegration(window.location.search)
        .then((r) => {
          if (!r?.application?.id) return
          const { id, name } = r.application
          const isPresent = !!companies.find((v) => v.id === id)
          const item = { type: 'COMPANY_ADDED', id, name, isPresent }
          localStorage.setItem('eq_message', JSON.stringify(item))
        })
        .catch((e) => {
          const msg = e?.response?.data?.message || e.message
          const item = { type: 'COMPANY_ADD_DENIED', msg }
          message.error('Error: ' + msg, 60)
          postMessage(item, '*')
          localStorage.setItem('eq_message', JSON.stringify(item))
        })
        .finally(() => {
          setReady(true)
          window.close()
        })
    }
  }

  useEffect(() => {
    t()
  }, [])

  console.log('QboSync', ready)

  // if (ready) {
  //   // window.close()
  //   return <p>Ready</p>
  // }

  return <FullLoader />
}
