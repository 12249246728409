import { toSafeDateString } from 'utils/dates'
import { toCurrency } from 'utils/numbers'
import { log } from 'utils/log'

import { IRuleResult, RuleMessageActionType } from 'types/compliance.types'
import { queryQboTransactions } from './apiutils'

interface IUndepositFundsRule {
  balances: any[]
  defaultRuleResult: IRuleResult
  accounts: any[]
  applicationId: number
  startDate: string
  endDate: string
  accountingMethod: string
}

export const undepositFundsRule = async (sourceData: IUndepositFundsRule): Promise<IRuleResult> => {
  const { accounts } = sourceData
  const { applicationId, startDate, endDate } = sourceData
  const { defaultRuleResult, accountingMethod } = sourceData

  const undepositFunds = accounts
    .filter(v => v?.accountType === 'Other Current Asset' && v?.accountSubType === 'UndepositedFunds')

  console.log('undepositFunds', undepositFunds)

  if (!undepositFunds.length) {
    defaultRuleResult.status = 'Passed'
    defaultRuleResult.results = [
      {
        message: 'You do not have any accounts of this type',
        status: 'Passed'
      }
    ]
  } else {
    for (let account of undepositFunds) {
      const { mapList } = await queryQboTransactions({
        applicationId: applicationId,
        from: toSafeDateString(startDate),
        to: toSafeDateString(endDate),
        accountQboId: account.qboId,
        unreconciled: false,
        accountingMethod
      })

      console.log('undepositFunds mapList', mapList)

      const meta =  {
        txs: [{
          type: account.name,
          list: mapList.map((v: any) => ({...v, acc: account})),
        }],
        endDate
      }

      console.log('account.balance', account.balance)

      if (!!mapList.length || account.balance) {
        const lastBalance = mapList[mapList.length - 1]?.balance || 0
        const generalBalance = account.balance || lastBalance

        log('undepositFunds balance', generalBalance, lastBalance)

        if (generalBalance === 0) {
          defaultRuleResult.status = 'Passed'
          defaultRuleResult.results = [
            {
              message: 'Your Undeposited Funds balance is $0.00 for this period',
              status: 'Passed',
            }
          ]
        } else {
          const lastBalanceTxt = toCurrency(generalBalance)
          const lastBalanceDate = endDate
          defaultRuleResult.status = 'Warning'
          defaultRuleResult.results = [
            {
              message: `The balance of your Undeposited Funds account is ${lastBalanceTxt} on ${lastBalanceDate}. Please review and confirm that this is accurate`,
              status: 'Warning',
              balance: lastBalanceTxt,
              action: {
                text: 'Accurate',
                type: RuleMessageActionType.Force
              },
              meta 
            }
          ]
        }
      } else {
        defaultRuleResult.status = 'Warning'
        defaultRuleResult.results = [
          {
            message: 'Your Undeposited Funds balance is $0.00 for this period',
            status: 'Passed',
            balance: '$0.00',
            action: {
              text: 'Accurate',
              type: RuleMessageActionType.Force
            },
            meta
          }
        ]
        
      }
    }
  }
  return defaultRuleResult
}
