import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Modal, message } from 'antd'

import { appAtom } from 'recoil/app.state'
import { authAtom } from 'recoil/auth.state'
import { companiesAtom } from 'recoil/companies.state'
import { currentUser } from 'recoil/user.state'
import { api } from 'utils/axios'

const { confirm } = Modal

export const SearchParams: ReactFC = () => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()

  const [app, setApp] = useRecoilState(appAtom)
  const auth = useRecoilValue(authAtom)
  const setCompanies = useSetRecoilState(companiesAtom)
  const refreshUserInfo = useRecoilRefresher_UNSTABLE(currentUser)

  const [searchParams, setSearchParams] = useSearchParams()
  const search = Object.fromEntries(searchParams) as any

  useEffect(() => {
    if (!auth) return

    const qbosync = localStorage.getItem('qbosync')
    if (search.sync === '1') messageApi.success('Sync success')
    else if (search.sync === '0') messageApi.warning('Sync failed')

    if (search.daterange) return

    if (search.sync) {
      // setSearchParams('')
      localStorage.removeItem('qbosync')
      return
    }

    if (qbosync) {
      const t = JSON.parse(qbosync)
      const url = decodeURIComponent(t?.url || '').split('/company/')[1]
      if (!url) return
      localStorage.removeItem('qbosync')
      confirm({
        title: 'Sync data',
        content: 'It looks like you were in the middle of a Quickbooks Online sync. Would you like to resume it?',
        onOk() {
          navigate('/company/' + url)
        },
        onCancel() {}
      })
    }
  }, [auth])

  useEffect(() => {
    if (!search?.invite_id) return
    if (!!app.invite) return
    setApp({ invite: search.invite_id })
    setSearchParams('')
  }, [app, search])

  useEffect(() => {
    if (app.invite && auth?.token) {
      api
        .patch('/invites', { id: app.invite })
        .then(() => api.get('applications'))
        .then(({ data }) => setCompanies(data))
        .then(() => {
          refreshUserInfo()
          setApp({})
          navigate('/')
          message.info('Invite accepted')
        })
        .catch(() => {
          message.warning('Invite is not acceptable')
        })
    }
  }, [app, auth])

  return <>{contextHolder}</>
}
