import { useEffect, useMemo, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Typography, Space, Statistic, message } from 'antd'
import { debounce } from 'lodash'

import { Btn } from 'components/Btn'
import { Block } from 'components/Block'
import { FlexSpace } from 'components/FlexSpace'

import { toCurrency } from 'utils/numbers'
import { currentCompany } from 'recoil/companies.state'
import { IAccount } from 'types/company.types'
import { IReconcile } from 'types/reconcile.types'
import { errorMsg } from 'utils/errorMsg'
import { IMatcher } from 'types/matcher'
import { api } from 'utils/axios'
import { log } from 'utils/log'
import { RecInfo } from './RecInfo'

const { Text } = Typography

interface DifferenceProps {
  account?: IAccount
  data: {
    deposits: number
    depositsAmount: number
    payments: number
    paymentsAmount: number
    startingBalance: number
    endingBalance: number
  }
  rec: IReconcile
  matcher: IMatcher
  setBlockExit: (v: boolean) => void
}

export const Difference: ReactFC<DifferenceProps> = (props) => {
  const { account, data, rec, matcher, setBlockExit } = props

  const navigate = useNavigate()
  const company = useRecoilValue(currentCompany)

  const isCC = !!account?.isCredit
  const cleared = data.startingBalance - Math.abs(data.paymentsAmount) + data.depositsAmount
  const delta = data.endingBalance - cleared
  const endingBalance = data.endingBalance || 0

  const doneUrl = `https://c1.qbo.intuit.com/qbo1/login?pagereq=switchCompany%3FcompanyId%3D${company?.qbo_id}%26navigationURL%3DreconcileAccount|accountId=${account?.qboId}`

  const doneLinkRef = useRef<HTMLAnchorElement>(null)
  const viewOnly = company?.role.id === 2000

  log(rec?.result?.length, matcher.qbo.filter((v) => v.matchedTo.length || v.forceMatch || v.deleted).length)
  log('dayrange', matcher.dayrange)

  useEffect(() => {
    if (!company) return
    debounceSave()
  }, [company, data])

  const debounceSave = useMemo(() => debounce(() => {
    saveResult2()
  }, 5000), [matcher.qbo])

  const handleDone = () => {
    if (!company) return

    setBlockExit(false)
    saveResult().then(() => {
      sendDataToPlugin(rec)
      doneLinkRef.current?.click()
      console.log(doneLinkRef.current?.href)
      // navigate('/company/' + company?.id + '/reconcile/results')
    })
  }

  const handleSave = () => {
    setBlockExit(false)
    saveResult().then(() => {
      navigate('/company/' + company?.id + '/reconcile/results')
    })
  }

  const saveResult2 = () => {
    const payload = {
      ...rec,
      dayrange: matcher.dayrange,
      application_id: company?.id,
      result: JSON.stringify(matcher.qbo.filter((v) => v.matchedTo.length || v.forceMatch || v.deleted)),
      temp_data: JSON.stringify({ qbo: matcher.qbo, bank: matcher.bank })
    }

    if (!matcher.qbo.length) return

    api.patch(`/reconciliation/${rec.reconciliation_id || ''}`, payload)
      .then(() => log('Reconciliation saved'))
      .catch((err) => message.error(errorMsg(err), 5))
  }

  const saveResult = (dr = matcher.dayrange) => {
    const payload = {
      ...rec,
      dayrange: dr,
      application_id: company?.id,
      result: JSON.stringify(matcher.qbo.filter((v) => v.matchedTo.length || v.forceMatch || v.deleted)),
      temp_data: JSON.stringify({ qbo: matcher.qbo, bank: matcher.bank })
    }

    const action = rec.reconciliation_id ? api.patch : api.post

    return action(`/reconciliation/${rec.reconciliation_id || ''}`, payload)
      .then(() => log('Reconciliation saved'))
      .catch((err) => message.error(errorMsg(err), 5))
  }

  const confirmDateRange = (editDayrange: number) => {
    matcher.changeDayrange(editDayrange)
    setTimeout(() => {
      message.success('Date range differential updated')
      saveResult(editDayrange)
    })
  }

  const valueStyle = { fontSize: 'xx-large' }

  const bankUnmatched = matcher.bank.filter(v => !v.matchedTo.length)
  const adaptedDelta = parseFloat(delta.toFixed(2))
  const doneReady = !bankUnmatched.length && !adaptedDelta
  const dayrange = matcher.dayrange 

  return (
    <Block className="FullWidth Difference">
      <Row style={{ margin: 20 }}>
        <Col span={6}>
          <RecInfo 
            accountName={account?.name || ''}
            accountType={account?.accountType || ''}
            startDate={rec.start_date}
            endDate={rec.end_date}
            dayrange={dayrange}
            viewOnly={viewOnly}
            onDayrangeChange={confirmDateRange}
            footer={
              <FlexSpace direction="vertical">
                <DoneBtn onDone={handleDone} disabled={!doneReady} />
                <SaveBtn onSave={handleSave} />
              </FlexSpace>
            }
          />
          <a ref={doneLinkRef} href={doneUrl} target="_blank" style={{ display: 'none' }} rel="noreferrer">
            Done
          </a>
        </Col>
        <Col span={18}>
          <Block className="DifferenceHeader">
            <Row className="HeaderSpace">
              <Col span={7} offset={3}>
                <Statistic valueStyle={valueStyle} title={<Ellipsis children="STATEMENT ENDING BALANCE" />} value={toCurrency(endingBalance)} />
              </Col>
              <Col span={1}>
                <Statistic valueStyle={valueStyle} title={<Text>&nbsp;</Text>} value="-" />
              </Col>
              <Col span={6}>
                <Statistic valueStyle={valueStyle} title={<Ellipsis children="CLEARED BALANCE" />} value={toCurrency(cleared)} />
              </Col>
              <Col span={1}>
                <Statistic valueStyle={valueStyle} title={<Text>&nbsp;</Text>} value="=" />
              </Col>
              <Col span={4}>
                <Statistic valueStyle={valueStyle} title={<Ellipsis children="DIFFERENCE" />} value={toCurrency(delta)} />
              </Col>
            </Row>
          </Block>
          <Block className="DifferenceCleared">
            <Space className="ClearedSpace">
              <Statistic title="BEGINNING BALANCE" value={toCurrency(data.startingBalance)} style={{ minWidth: 135 }} />
              {isCC ? (
                <>
                   <Statistic title={<Text>&nbsp;</Text>} value={'+'} />
                   <Statistic
                      title={`${data.deposits} CHARGES`}
                      value={toCurrency(data.depositsAmount, false)}
                      style={{ minWidth: 125 }}
                    />
                    <Statistic title={<Text>&nbsp;</Text>} value={'-'} />
                    <Statistic
                      title={`${data.payments} PAYMENTS`}
                      value={toCurrency(data.paymentsAmount, false)}
                      style={{ minWidth: 125 }}
                    />
                </>
              ) : (
                <>
                  <Statistic title={<Text>&nbsp;</Text>} value={'-'} />
                  <Statistic
                    title={`${data.payments} PAYMENTS`}
                    value={toCurrency(data.paymentsAmount, false)}
                    style={{ minWidth: 125 }}
                  />
                  <Statistic title={<Text>&nbsp;</Text>} value={'+'} />
                  <Statistic
                    title={`${data.deposits} DEPOSITS`}
                    value={toCurrency(data.depositsAmount, false)}
                    style={{ minWidth: 125 }}
                  />
                </>
              )}
            </Space>
          </Block>
        </Col>
      </Row>
    </Block>
  )
}

const Ellipsis: ReactFC = ({ children }) => {
  return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
    {children}
  </div>
}

interface DoneBtnProps {
  onDone: () => void
  disabled?: boolean
}
const DoneBtn: ReactFC<DoneBtnProps> = (props) => {
  const doneTooltip = props.disabled
  ? 'Please make sure that your difference is $0.00 and that all unmatched transactions have been added to QuickBooks.'
  : 'Click to save reconciliation results and go back to QuickBooks'

  return (
    <Btn
      type="primary"
      title="Done"
      size="small"
      mouseEnterDelay={props.disabled ? 0.1 : 2}
      tooltip={doneTooltip}
      tooltipPlacement={'right'}
      confirm="Do you want to export your reconciled results to QuickBooks?"
      onClick={props.onDone}
      disabled={props.disabled}
      block
    />
  )
}

interface SaveBtnProps {
  onSave: () => void
}
const SaveBtn: ReactFC<SaveBtnProps> = (props) => {
  return (
    <Btn
      title="Save for later"
      size="small"
      tooltip="Click to save reconciliation results for later"
      tooltipPlacement={'right'}
      confirm="Are you sure you want to save reconciliation results and exit?"
      onClick={props.onSave}
      block
    />
  )
}

function sendDataToPlugin(rec: IReconcile) {
  const data = { type: "FROM_PAGE_REC", rec }

  window.postMessage(data, "*")
} 