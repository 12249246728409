import { Suspense } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'

import { AppLayout } from '../app/AppLayout'
import { FullLoader } from './FullLoader'
import { ErrorBoundary } from './ErrorBoundary'

import { authAtom } from 'recoil/auth.state'
import { currentUser } from 'recoil/user.state'

export const ProtectedRoute: ReactFC = () => {
  const location = useLocation()
  const signInUrl = `/signin?returnUrl=${location.pathname + location.search}`

  const [auth] = useRecoilState(authAtom)
  const user = useRecoilValue(currentUser)

  if (!auth) return <Navigate to={signInUrl} />

  console.log('user', user)

  if (!!user && !user?.isEmailVerified && !user?.googleSignIn) 
    return <Navigate to="/verify-email" />

  return (
    <ErrorBoundary>
      <AppLayout>
        <Suspense fallback={<FullLoader />}>
          <Outlet />
        </Suspense>
      </AppLayout>
    </ErrorBoundary>
  )
}
