import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { Col, Divider, Row, Select, Typography } from 'antd'
import { Alert, message } from 'antd'
import { Spin, SpinProps } from 'antd'

import { Btn } from 'components/Btn'
import { Block } from 'components/Block'
import { Header } from 'components/Header'
import { FlexSpace } from 'components/FlexSpace'
import { RangePicker } from 'components/RangePicker'
import { NumberInput } from 'components/NumberInput'

import { ReconcileSettings } from '../components/ReconcileSettings'

import { currentAccounts, currentCompany } from 'recoil/companies.state'
import { stringToDayjs, toSafeDateString } from 'utils/dates'
import { accountName, getBankAccounts } from 'utils/accountName'
import { IReconcile } from 'types/reconcile.types'
import { ITx } from 'types/tx.types'
import { api } from 'utils/axios'
import { qboToTxs } from 'utils/tx'
import { errorMsg } from 'utils/errorMsg'
import { currentUser } from 'recoil/user.state'
import { toCurrency } from 'utils/numbers'
import { analytics } from 'utils/analytics'

const { Text } = Typography

interface Acc {
  name: string
  label: JSX.Element
  value: string
}

interface SearchParams {
  accountId: string
  startingBalance: string
  endingBalance: string
  from: string
  to: string
  sync?: string
}

interface Props {
  onSubmit: (result: IReconcile) => void
}

export const ReconcileRun: ReactFC<Props> = (props) => {
  const { onSubmit } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const search: SearchParams = Object.fromEntries(searchParams) as any

  const user = useRecoilValue(currentUser)
  const accounts = useRecoilValue(currentAccounts)
  const company = useRecoilValue(currentCompany)

  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<Acc>()
  const [range, setRange] = useState<any>([])
  const [startBalance, setStartBalance] = useState<number>()
  const [endBalance, setEndBalance] = useState<number>()
  const [step, setStep] = useState(0)
  const [isPlaid, setIsPlaid] = useState(false)


  const [daysRange, setDaysRange] = useState(4)
  const [backTxs, setBankTsx] = useState<ITx[] | null>(null)

  const recAccounts = getBankAccounts(accounts)
  const accNumber = recAccounts[0]?.qboId
  const account = recAccounts.find(v => v.qboId === selected?.value)

  const isPluginInstalled = !!document.documentElement.hasAttribute('equility-extension')
  const isQBOConnected = !!company?.integrations.find(v => v.id === 1)?.status

  const backurl = encodeURIComponent(window.location.pathname)
  const basicUrl = 'https://accounts.intuit.com/app/sign-in?app_group=QBO&asset_alias=Intuit.accounting.core.qbowebapp&redirect_uri='
  const qboUrl = `https://app.qbo.intuit.com/app/companyselection?pagereq=switchCompany&companyId=${company?.qbo_id}`
  const navUrl = `&navigationURL=reconcile?accountId=${accNumber}%2526showHistory%3Dtrue%2526displayReport%3Dtrue`
  const addStr = `%2526equilityRefreshData%3D1%2526backurl%3D${backurl}%2526sync%3D1`

  const doneUrl = basicUrl + encodeURIComponent(qboUrl + navUrl + addStr)
  const doneLinkRef = useRef<HTMLAnchorElement>(null)

  const accList: Acc[] = recAccounts
    .map((v) => ({
      name: accountName(v.name, v.number),
      value: v.qboId,
      label: (
        <FlexSpace spacebetween>
          <span>{accountName(v.name, v.number)}</span>
          <small><i style={{ paddingRight: 10, opacity: 0.5 }}>{v.accountType}</i></small>
        </FlexSpace>
      )
    }))

  useEffect(() => {
    window.postMessage({ type: "GET_AUTH" }, "*")

    if (search.sync) {
      localStorage.removeItem('qbosync')
    }

    if (!company || !accounts.length || !search.accountId) return

    const acc = accList.find((v) => v.value === search.accountId)
    if (!acc) return setSelected(undefined)

    handleSelectAcc(acc, search.to)
    if (search.startingBalance)
      setStartBalance(parseFloat(search.startingBalance) || undefined)
    if (search.endingBalance)
      setEndBalance(parseFloat(search.endingBalance) || undefined)

    if (!search.from || !search.to) return

    // const from = stringToDayjs(search.from)
    // const to = stringToDayjs(search.to)
    // setRange([from, to])
  }, [search, accounts, company])

  const handleSelectAcc = (acc: Acc, todate?: string) => {
    setSelected(acc)
    setStartBalance(undefined)
    setEndBalance(undefined)
    setSearchParams('')

    const account = recAccounts.find((v) => v.qboId === acc.value)
    const reconciled_through = account?.reconciled_through?.split('T') || []
    const from = stringToDayjs(reconciled_through[0] || '12/31/1999')?.add(1, 'day')
    const to = todate && stringToDayjs(todate)
    const balance = account?.ending_balance ? parseFloat(account.ending_balance) : undefined
    if (balance) setStartBalance(balance)
    setRange(to ? [from, to] : [from, null])
  }

  const handleNext = () => {
    setStep(1)
  }

  const handleSubmit = () => {
    localStorage.removeItem('qbosync')

    if (!backTxs) return message.warning('Please upload bank transactions')
    if (!selected) return

    const balanceDiff = (endBalance || 0) - (startBalance || 0)
    const bankBalance = backTxs.reduce((a, b) => a + b.amount, 0)
    // console.log(balanceDiff, bankBalance)
    // console.log(isPlaid) // TODO

    if (!isPlaid && (toCurrency(balanceDiff) !== toCurrency(bankBalance))) {
      return message.warning(`The transactions within this CSV file total ${toCurrency(bankBalance)}, `
        + 'which does not match the different between the ‘Beginning Balance’'
        + `and ‘Ending Balance’ of ${toCurrency(balanceDiff)}. `
        + 'Please re-upload your CSV file and make sure that these figures match.'
      , 10)
    }

    setLoading(true)

    const params = {
      accountQboId: selected.value,
      applicationId: company?.id,
      from: toSafeDateString(range[0]),
      to: toSafeDateString(range[1]),
      unreconciled: true,
      includePrevUncleared: true
    }

    api
      .get('/integrations/qbo/transactions', { params })
      .then(({ data }) => {
        const qbolist = qboToTxs(data.mapList)

        const maxLength = Math.max(qbolist.length, backTxs.length)

        if (maxLength > 5000) {
          message.info(`You're attempting to upload ${maxLength} transactions. We're currently working on being able to easily support uploads of more than 5,000 transactions, but in the meantime, you might experience a decrease in perform speed. If you encounter this issue, we would appreciate a message through the chatbot, so we can contact you directly once improvements are released.`, 10)
          analytics.event.reconciliation.limit()
        }

        analytics.event.reconciliation.start()

        onSubmit({
          qbo_account_id: selected.value,
          bank: '',
          reconciled_on: '',
          ending_balance: (endBalance || 0).toFixed(2),
          starting_balance: (startBalance || 0).toFixed(2),
          start_date: toSafeDateString(range[0]),
          end_date: toSafeDateString(range[1]),
          qbo_transaction_count: qbolist.length,
          temp_data: {
            qbo: qbolist,
            bank: backTxs
          },
          dayrange: daysRange,
          result: []
        })
      })
      .catch((err) => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
  }

  const handleUpdate = async () => {
    if (!company || !user) return

    try {
      const backurl = new URL(window.location.href.split('?')[0])
      if (account?.qboId)
        backurl.searchParams.set('accountId', account.qboId) 

      const data = { type: "FROM_PAGE_TASK", company, backurl: backurl.toString() }

      localStorage.setItem('qbosync', JSON.stringify({ date: Date.now(), url: backurl.toString() }))
      window.postMessage(data, "*")
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Spin spinning={loading}>
      <Block className="FullWidth" style={{ marginBottom: -20 }}>
        <Row>
          <Col span={12} style={{ padding: '20px 24px' }}>
            <Disabled spinning={!!step}>
              <FlexSpace direction="vertical" size="large" style={{ width: 426 }}>
              <Header size={28}>Run reconciliation</Header>
              <Typography.Text style={{ width: 600, display: 'block', maxWidth: '100%' }}>
                <strong style={{ fontWeight: 700 }}>QuickBooks Online</strong><br />
                <span>If you’re starting your reconciliation within QuickBooks Online, please make sure that you’re logged into our plugin. If you are, then you’ll be able to click the ‘Reconcile with Equility’ button from within your reconciliation.</span>
              </Typography.Text>
              <Typography.Text style={{ width: 600, display: 'block', maxWidth: '100%' }}>
                <strong style={{ fontWeight: 700 }}>Equility</strong><br />
                <span>If you’re starting your reconciliation from within Equility, please make sure to click the ‘Update from QuickBooks’ button. Once your reconciliation dates and balances are entered, simply click the ‘Next’ button.</span>
              </Typography.Text>
              <a style={{ fontWeight: 600 }} href="https://www.youtube.com/watch?v=Cg2sdzMzhbw" target="_blank" rel="noreferrer">
                Account Reconciliation Walkthrough Video
              </a>
              <Select
                showSearch
                placeholder="Account"
                options={accList}
                disabled={!accList.length}
                // className="SelectPrimary"
                style={{ width: 424 }}
                value={selected}
                onSelect={(_: any, v: Acc) => handleSelectAcc(v)}
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
              />
              <FlexSpace direction="vertical">
                <Text>Reconciliation Date Range:</Text>
                <RangePicker
                  onChange={(v) => setRange(v || [])} 
                  value={range}
                  disabled={!selected}
                />
              </FlexSpace>
              <FlexSpace spacebetween>
                <Text style={{ width: 200 }}>
                  Beginning balance:
                </Text>
                <NumberInput value={startBalance} onChange={setStartBalance} />
              </FlexSpace>
              <FlexSpace spacebetween>
                <Text style={{ width: 200 }}>
                  Ending balance:
                </Text>
                <NumberInput value={endBalance} onChange={setEndBalance} />
              </FlexSpace>
              <Divider />
              {range[0] && (
                <Alert message="Accuracy Check: Please check to make sure that the data entered above is correct. If not, please adjust manually to ensure that the account reconciliation runs correctly" />
              )}
              {!!account?.warning && <Alert message={prepareAccWarn(account.warning)} type="warning" />}
              <a ref={doneLinkRef} href={doneUrl} style={{ display: 'none' }}>Done</a>
              <FlexSpace spacebetween>
                <Btn
                  title="Next"
                  type="primary"
                  width={200}
                  disabled={!selected || !range[0] || !range[1]}
                  onClick={handleNext}
                />
                {/* {isPluginInstalled && (
                  <Btn
                    title="Update from QuickBooks"
                    onClick={handleUpdate}
                  />
                )} */}
              </FlexSpace>
              {!isQBOConnected && (
                <Alert
                  type="warning"
                  message="Please reconnect to QuickBooks"
                  description="Go to Company Settings > Integration > Reconnect, then restart reconciliation from within QuickBooks Online"
                />
              )}
              </FlexSpace>
            </Disabled>
          </Col>
          <Col span={12} style={{ background: '#ECFAF1' }}>
            <Disabled spinning={!step}>
              <ReconcileSettings
                range={range}
                onChange={(tsx, isPlaid) => {
                  setBankTsx(tsx)
                  setIsPlaid(isPlaid)
                }}
                daysRange={daysRange}
                setDaysRange={setDaysRange}
                account={account}
                onComplete={handleSubmit}
                onCancel={() => setStep(0)}
              />
            </Disabled>
          </Col>
        </Row>
      </Block>
    </Spin>
  )
}

const Disabled: ReactFC<SpinProps> = (props) => {
  return <Spin {...props} indicator={<></>} />
}

function prepareAccWarn(msg: string) {
  const s = msg.split(': ')
  const n = s[1].replaceAll(',', '').replace('$', '')
  return s[0] + ': ' + toCurrency(parseFloat(n))
}