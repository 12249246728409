import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Avatar, Input, Tooltip, Typography, Upload, message } from 'antd'
import type { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload/interface'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { Btn } from 'components/Btn'
import { Block } from 'components/Block'
import { FlexSpace } from 'components/FlexSpace'
import { FullLoader } from 'components/FullLoader'

import { Team } from '../components/Team'

import { companiesAtom } from 'recoil/companies.state'
import { IAuthCredentials } from 'types/auth.types'
import { ICompany } from 'types/company.types'
import { config } from 'utils/config'
import { storage } from 'utils/storage'
import { api } from 'utils/axios'

import QboLogo from 'assets/qbo/logo-quickbooks.png'

interface Props {
  company: ICompany | null
}

export const General: ReactFC<Props> = (props) => {
  const { company } = props
  const setCompanies = useSetRecoilState(companiesAtom)

  const [isRename, setIsRename] = useState(false)
  const [name, setName] = useState(company?.alias || company?.name || '')
  const [loadingName, setLoadingName] = useState(false)

  const viewOnly = company?.role?.id === 2000

  const refreshCompanies = () => {
    return api.get<ICompany[]>('applications')
      .then(({data}) => setCompanies(data))
      .catch(console.log)
  }

  const handleCancelRename = () => {
    setName(company?.alias || company?.name || '')
    setIsRename(false)
  }

  const handleRename = () => {
    setIsRename(false)
    setLoadingName(true)
    api.post(`applications/${company?.id}/rename`, { alias: name })
      .then(() => {
        refreshCompanies()
        message.success('Company name has been changed.')
      })
      .catch(console.log)
      .finally(() => setLoadingName(false))
  }

  if (!company) return <FullLoader />

  return (
    <FlexSpace direction="vertical" size="large">
      
      <FlexSpace direction="vertical" size="large">
        <Typography.Title level={4}>General</Typography.Title>
        <FlexSpace>
          <Input
            className='CompanyNameInput'
            placeholder="Company name" 
            value={name} 
            onChange={(e) => setName(e.target.value)}
            style={{ width: 500 }}
            disabled={loadingName || !isRename || viewOnly}
          />
          {!isRename && (
            <Btn title="Rename" width={140} onClick={() => setIsRename(!isRename)} disabled={viewOnly} />
          )}
          {isRename && (
            <FlexSpace>
              <Btn title="Save" type="primary" width={140} onClick={handleRename} disabled={loadingName} />
              <Btn title="Cancel" onClick={handleCancelRename} disabled={loadingName}  />
            </FlexSpace>
          )}
        </FlexSpace>
        <FlexSpace>
          
          <img alt='QuickBooks Online' src={QboLogo} width={32}  />
          <Typography.Text strong>{company.name}</Typography.Text>
        </FlexSpace>
      </FlexSpace>
      <Block>
        <Typography.Title level={4}>Team</Typography.Title>
        <Team company={company} />
      </Block>
    </FlexSpace>
  )
}

function getBase64(img: RcFile, callback: (url: string) => void) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

function beforeUpload(file: RcFile) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) 
    message.error('You can only upload JPG/PNG file!')

  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) 
    message.error('Image must smaller than 2MB!')

  return isJpgOrPng && isLt2M
}