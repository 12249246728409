import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Modal, Typography } from 'antd'

import { Btn } from './Btn'
import { MatchAccounts } from 'pages/setup/components/MatchAccounts'
import { FlexSpace } from './FlexSpace'

import { api } from 'utils/axios'
import { currentAccounts, currentCompany } from 'recoil/companies.state'
import { ICompanySettings } from 'types/company.types'

const { Text} = Typography

interface Props {
  title?: string
  onChange?: () => void
}

export const ShopifyMatch = (props: Props) => {
  const { title, onChange } = props

  const company = useRecoilValue(currentCompany)
  const accounts = useRecoilValue(currentAccounts)

  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [matched, setMatched] = useState<{ [key: string]: string[] }>({})

  useEffect(() => {
    if (!company?.settings?.merchant?.matched) return
    setMatched(company.settings.merchant.matched)
  }, [company?.settings])

  const onClose = () => setModal(false)
  const onUpdate = () => {
    const merchant = { matched }
    const setting = { ...company?.settings, merchant }

    setLoading(true)
    saveSettings(company?.id as number, setting)
      .then(() => {
        setLoading(false)
        setModal(false)
        onChange && onChange()
      })
      .finally(() => setLoading(false))
  }

  const modalFooter = [
    <FlexSpace style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }} key="fxss">
      <Btn title="Cancel" onClick={onClose} />
      <Btn title="Submit" onClick={onUpdate} disabled={loading} type="primary" />
    </FlexSpace>
  ]
  return (
    <>
      <Btn title={title || 'Setup'} onClick={() => setModal(true)} />
      <Modal title="Setup your accounts" open={modal} footer={modalFooter} onCancel={onClose} width={755}>
        <FlexSpace direction="vertical" size="large" style={{ marginTop: 20, marginBottom: 20 }}>
          <Text>We need to know how you match your e-commerce data to your general ledger accounts</Text>
          <MatchAccounts
            value={matched}
            baseAccs={shopifyAccounts}
            accounts={accounts}
            onChange={v => setMatched({...matched, ...v,})}
          />
        </FlexSpace>
      </Modal>
    </>
  )
}

const shopifyAccounts = [
  {id: 'gross', name: 'Gross Sales', isCalc: false},
  {id: 'discount', name: 'Discounts', isCalc: false},
  {id: 'returns', name: 'Returns', isCalc: false},
  {id: 'net', name: 'Net Sales', isCalc: true},
  {id: 'shipping', name: 'Shipping', isCalc: false},
  // {id: 'tax', name: 'Taxes', isCalc: false},
  // {id: 'liability', name: 'Gift Cards', isCalc: false},
  {id: 'total', name: 'Total', isCalc: true},
  {id: 'payments', name: 'Payments', isCalc: true},
]

export function saveSettings(companyId: number, settings: ICompanySettings) {
  return api.post(`/applications/${companyId}/settings`, { settings })
    .then(res => res.data)
}