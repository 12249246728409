import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { message } from 'antd'

import { plaidAtom } from 'recoil/plaid.state'
import { api } from 'utils/axios'
import { log } from 'utils/log'

const plaidMsgKey = 'plaidMessage'
const interval = 10000

export const PlaidFetcher = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const [plaidState, setPlaidState] = useRecoilState(plaidAtom)

  useEffect(() => {
    log('PlaidFetcher', plaidState)

    if (!plaidState) {
      close()
      return
    }

    open()

    const i = setInterval(() => {
      handlePlaid(plaidState)
    }, interval)

    return () => {
      clearInterval(i)
    }
  }, [plaidState])

  const handlePlaid = (params: any) => {
    if (!params) return
    api
      .get(`/plaid/transactions`, { params })
      .then(({ status }) => {
        if (status !== 204) {
          success()
          setTimeout(() => {
            setPlaidState(null)
          }, 5000)
        }
      })
  }

  const close = () => {
    messageApi.destroy(plaidMsgKey)
  }

  const open = () => {
    messageApi.open({
      key: plaidMsgKey,
      type: 'loading',
      content: 'Plaid pulls your transactions for the first time. This may take a few minutes.',
      duration: 0
    })
  }

  const success = () => {
    messageApi.open({
      key: plaidMsgKey,
      type: 'success',
      content: 'Plaid sync success. You can now reconcile your transactions.',
      duration: 5
    })
  }
  
  return contextHolder
}
