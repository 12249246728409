import React, { useEffect } from 'react'
import { Menu, Tag } from 'antd'
import { ChartTypesProps, Item } from '../types/chart.types'
import { MultiGroupDropdown } from './MultiGroupDropdown'

const { SubMenu } = Menu

export const ChartTypes: React.FC<ChartTypesProps> = ({ data, onSelectionChange, selectedKeys, setSelectedKeys }) => {
  useEffect(() => {
    if (data && data.length && selectedKeys.length === 0) {
      if (data?.[0]?.children?.[0]) {
        const firstKey = getKeyFromData(data?.[0]?.children?.[0])
        setSelectedKeys([firstKey])
        onSelectionChange([firstKey])
      }
    }
  }, [data, onSelectionChange, selectedKeys.length])

  const getKeyFromData = (item: Item, parentKey = '') => {
    return parentKey ? `${item.key}` : item.key
  }

  const handleItemClick = (itemKey: string) => {
    let updatedSelectedKeys: string[]
    if (selectedKeys.includes(itemKey)) {
      updatedSelectedKeys = selectedKeys.filter((k) => k !== itemKey)
    } else {
      updatedSelectedKeys = [...selectedKeys, itemKey]
    }
    setSelectedKeys(updatedSelectedKeys)
    onSelectionChange(updatedSelectedKeys)
  }

  const renderMenuItems = (items: Item[], parentKey = '') => {
    return items.map((item) => {
      const itemKey = item.key
      const isSelected = selectedKeys.includes(itemKey)

      return item.children && item.children.length ? (
        <SubMenu key={item.key} title={item.name} style={isSelected ? { opacity: 0.6 } : {}}>
          {renderMenuItems(item.children, itemKey)}
        </SubMenu>
      ) : (
        <Menu.Item key={itemKey} onClick={() => handleItemClick(itemKey)} style={isSelected ? { opacity: 0.6 } : {}}>
          {item.name}
        </Menu.Item>
      )
    })
  }

  console.log('renderMenuItems', renderMenuItems)

  const findItemNameByKey = (key: string, items: Item[], parentKey = ''): string | null => {
    for (const item of items) {
      const itemKey = getKeyFromData(item, parentKey)
      if (itemKey === key) return item.name
      if (item.children) {
        const name = findItemNameByKey(key, item.children, itemKey)
        if (name) return name
      }
    }
    return null
  }

  const getSelectedItemsText = (): string[] => {
    return selectedKeys.map((key) => findItemNameByKey(key, data)).filter(Boolean) as string[]
  }

  const selectedItemsText = getSelectedItemsText()

  const handleClose = (removedIndex: number) => {
    const keyToRemove = selectedKeys[removedIndex]
    const updatedSelectedKeys = selectedKeys.filter((key) => key !== keyToRemove)
    setSelectedKeys(updatedSelectedKeys)
    onSelectionChange(updatedSelectedKeys)
  }

  return (
    <div style={{ marginBottom: '10px', marginTop: '30px' }}>
      {selectedItemsText.length && (
        <span>
          <span style={{ marginRight: '10px' }}> Showing: </span>
          <MultiGroupDropdown
            items={data}
            defaultValue={selectedKeys}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            onChange={onSelectionChange}
          />
          <span style={{ marginRight: '10px' }}></span>
          {selectedItemsText.map((text, index) => (
            <span key={index}>
              {index !== 0 ? 'and ' : ''}
              <Tag
                closable={selectedItemsText.length > 1}
                onClose={(e) => {
                  e.preventDefault()
                  return handleClose(index)
                }}
              >
                {text}
              </Tag>
            </span>
          ))}
        </span>
      )}
    </div>
  )
}
